import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { WorksListItem } from 'components/pages';
import { Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import Sortable from 'sortablejs';

import './_WorksList.scss';

const WorksList = inject('LanguageStore', 'WorksStore')(observer(({ isDraggable, setIsDraggable, LanguageStore, WorksStore }) => {
    const { works, getWorks, updateWorksGroupLocal, updateWorksGroup, createWorksGroup, deleteWorkGroup, deleteWorkGroupLocal, updateWorksGroupSort } = WorksStore;
    const { language } = LanguageStore;

    const changeButtonText = isDraggable ? 'Save the order' : 'Change the order';

    const [worksCopy, setWorksCopy] = useState(works);
    const [groupsOpened, setGroupsOpened] = useState({});
    const [changingGroup, setChangingGroup] = useState(null);
    const [inputValue, setInputValue] = useState('');

    // Включение режима Drag and Drop
    const makeDraggable = () => {
        if (isDraggable) {
            updateWorksGroupSort(worksCopy);
        }

        setIsDraggable(prevIsDraggable => !prevIsDraggable);
        setGroupsOpened({});
    };

    // Обновление имени группы
    const updateGroupName = () => {
        if (changingGroup !== null) {
            const group = works[changingGroup];
            const name = inputValue.trim() !== '' ? inputValue.trim() : group.name;

            if (group.fake) {
                if (name === '') {
                    deleteWorkGroupLocal(changingGroup);
                } else {
                    createWorksGroup(changingGroup, { name });
                }
            } else if (name !== group.name) {
                updateWorksGroup(group.id, changingGroup, { name });
            }
        }
    };

    // Отлов клика по иконке изменить
    const handleChangeIconClick = (index, event) => {
        event.stopPropagation();

        updateGroupName();
        setInputValue(works[index].name);
        setChangingGroup(index);
    };

    // Отлов клика по иконке удалить
    const handleRemoveButtonClick = (index, event) => { 
        event.stopPropagation();

        const workGroup = works[index];
        
        setChangingGroup(null);
        setGroupsOpened(prevGroupsOpened => (
            { ...prevGroupsOpened, [index]: false }
        ));

        if (workGroup.fake) {
            deleteWorkGroupLocal(index);
        } else {
            deleteWorkGroup(workGroup.id, index)
                .then(({ status }) => {
                    if (status === 200) {
                        getWorks();
                    }
                });
        }
    };

    // Отлов клика по окну
    const handleWindowClick = () => {
        updateGroupName();
        setChangingGroup(null);
        setInputValue('');
    };

    // Отлов ввода в поле ввода
    const handleInputChange = event => {
        const input = event.target;
        const value = input.value;

        setInputValue(value);
    };

    // Отлов клика по разделу с работами
    const handleWorkItemClick = (index, event) => {
        event.stopPropagation();

        setGroupsOpened(prevGroupsOpened => ({ ...prevGroupsOpened, [index]: !prevGroupsOpened[index] }));
    };

    // Отлов клика по полю выбора
    const handleShowButtonClick = (index, event) => {
        event.stopPropagation();

        const chooseBox = event.target;
        const checked = chooseBox.checked;

        const workGroup = works[index];

        updateWorksGroup(workGroup.id, index, { show: checked });
    };

    // Добавление раздела
    const addSection = event => {
        event.stopPropagation();

        const index = works.length;

        setChangingGroup(index);

        updateWorksGroupLocal({ 
            data: {
                name: '',
                show: false,
                fake: true,
                work: []
            } 
        });
    };

    // Отлов окончания dnd
    const handleDragEnd = event => {
        const { newIndex, oldIndex } = event;

        if (newIndex !== oldIndex) {
            const workGroupsCards = [...document.querySelectorAll(`.WorksListItem[data-work-group-id]`)];
            
            const workGroups = works.map(workGroup => {
                const workGroupsCard = workGroupsCards.find(card => Number(card.dataset.workGroupId) === workGroup.id);
                return { ...workGroup, sort: workGroupsCards.indexOf(workGroupsCard) }
            });
            
            setWorksCopy(workGroups);
        }
    };

    // Сброс фокуса на работах, если кликнуть на любое место, кроме поля ввода
    useEffect(() => {
        window.addEventListener('click', handleWindowClick);

        return () => {
            window.removeEventListener('click', handleWindowClick);
        }
    }, [works, changingGroup, inputValue]);

    // Добавление dnd разделам
    useEffect(() => {
        const sortableList = document.querySelector('.WorksList__items');

        const sortable = new Sortable(sortableList, { 
            animation: 150,
            disabled: !isDraggable,
            onEnd: handleDragEnd
        });

        return () => {
            sortable.destroy();
        }
    }, [isDraggable]);

    // Открытие папок
    useEffect(() => {
        const groups = works.map((work, index) => [index, work.work.length > 0]);
        setGroupsOpened(Object.fromEntries(groups));
    }, [works]);

    return (
        <div className = 'WorksList'>
            <div className = 'WorksList__head'>
                <Link to = '/works/add-work/general' style = {{ pointerEvents: isDraggable ? 'none' : 'auto' }}>
                    <Button content = {{ text: translate('Add work', language) }} 
                            theme = 'grey' 
                            disabled = { isDraggable } />
                </Link>

                <div className = 'WorksList__options'>
                    <Button content = {{ icon: 'plus-in-circle', text: translate('Add section', language) }} 
                            theme = 'grey' 
                            pure = { true } 
                            disabled = { isDraggable || works.find(workGroup => workGroup.fake) }
                            onClick = { addSection } />

                    <Button content = {{ icon: 'position-arrows', text: translate(changeButtonText, language) }} 
                            theme = 'grey'
                            pure = { true }
                            onClick = { makeDraggable } />
                </div>
            </div>
            
            <div className = 'WorksList__items'>
                { works.map((workGroup, index) => (
                    <WorksListItem key = { workGroup.id }
                                   dataWorkGroupIndex = { index }
                                   item = { workGroup }
                                   value = { inputValue }
                                   options = {{ opened: groupsOpened[index], isChanging: index === changingGroup }}
                                   isDraggable = { isDraggable }
                                   handlers = {{
                                       onChangeIconClick: handleChangeIconClick.bind(null, index),
                                       onInputChange: handleInputChange,
                                       onRemoveButtonClick: handleRemoveButtonClick.bind(null, index),
                                       onWorkItemClick: handleWorkItemClick.bind(null, index),
                                       onShowButtonClick: handleShowButtonClick.bind(null, index)
                                   }} />  
                )) }
            </div>
        </div>
    )
}));

export { WorksList };