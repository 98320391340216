// Обертка для fetch
import { BASE_URL, BASE_API_URL } from 'constants/BASE_URL';

const request = async(url, options = {}) => {
    const response = await fetch(url.replace(BASE_URL, BASE_API_URL), {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...options.headers,
        },
        credentials: 'include',
        ...options
    });

    if (response.status === 401) {
        window.location.assign(`${BASE_URL}/registration`);
    }

    return response
};

export { request };
