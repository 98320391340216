import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { WorksList } from 'components/pages';
import { CabinetContent, CabinetAlerts } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

import './_Works.scss';

const Works = inject('LanguageStore', 'WorksStore', 'CabinetStore', 'LoadingStore', 'UserStore')(observer(({ LanguageStore, WorksStore, LoadingStore, UserStore }) => {
    const [isDraggable, setIsDraggable] = useState(false);

    const { language } = LanguageStore;
    const { works, getWorks } = WorksStore;
    const { setLoading } = LoadingStore;
    const { user } = UserStore;

    const isExistOneWork = Boolean(works.find(workGroup => workGroup.work.length !== 0));
    const isNoPayedWork = Boolean(works.find(workGroup => !workGroup.isArchive && workGroup.work.find(work => work.attention === 'no_active')));
    const isNoSelectedWork = Boolean(works.find(workGroup => !workGroup.isArchive && workGroup.work.find(work => work.attention === 'no_profession')));

    const entrustAlert = {
        'ru-RU': {
            'firstPart': 'Вы можете добавлять работы самостоятельно или',
            'secondPart': 'поручить это нам',
            'thirdPart': '. Работам будут присвоены максимально поисковые характеристики'
        },

        'en-US': {
            'firstPart': 'You can add work yourself or',
            'secondPart': 'entrust it to us',
            'thirdPart': '. Works will be assigned the maximum search characteristics'
        }
    }
    
    // Запрос работ
    useEffect(() => {
        if (works.length === 0) {
            setLoading('mainContent', true);

            getWorks()
                .then(() => setLoading('mainContent', false));

            return () => {
                setLoading('mainContent', false);
            }
        } else {
            getWorks()
        }
    }, []);

    return (
        <div className = 'Works'>
            { works.length > 0 && (
                <CabinetContent>
                    { ((!isExistOneWork || isNoPayedWork || isNoSelectedWork) && (
                        <CabinetAlerts>
                            { !isExistOneWork && (
                                <>
                                    <Alert text = { translate('You can be found through the content and description of your projects. The more projects you add and the better you describe them, the most likely you are to be invited to a new one. Do not forget to add team members in Credits if you worked with someone else.', language) }
                                           icon = 'question'
                                           padding = { true } 
                                           bordered = { true } />
                                           

                                    <Alert padding = { true } bordered = { true }>
                                        { translate('You can add work yourself or entrust it to us. Works will be assigned the maximum search characteristics-first', language) }

                                        &nbsp;
                                        <Link to = '/services/work' className = 'Alert__textLink'>
                                            <span className = 'Alert__text Alert__text--action'>
                                                { translate('You can add work yourself or entrust it to us. Works will be assigned the maximum search characteristics-middle', language) }
                                            </span>
                                        </Link>
                                        
                                        { translate('You can add work yourself or entrust it to us. Works will be assigned the maximum search characteristics-end', language) }
                                    </Alert>
                                </>
                            ) }

                            { isNoPayedWork && (
                                <Alert text = { translate(user.activeCabinet === 'executor' ? 'In the sections, there are jobs that are not displayed with a marked unpaid profession' : 'In the sections, there are jobs that are not displayed with a marked unpaid direction', language ) }
                                       padding = { true }
                                       bordered = { true }
                                       type = 'warn' />
                            ) }

                            { isNoSelectedWork && (
                                <Alert text = { translate(user.activeCabinet === 'executor' ? 'There are jobs in the sections that are not displayed with a profession that is not selected' : 'There are works in folders that are not displayed because of an unselected direction', language ) }
                                       padding = { true }
                                       bordered = { true }
                                       type = 'warn' />
                            ) }
                        </CabinetAlerts>
                    )) }

                    <WorksList isDraggable = { isDraggable } setIsDraggable = { setIsDraggable } />
                        
                    { isExistOneWork && (
                        <CabinetAlerts>
                            <Alert padding = { true } bordered = { true }>
                                { entrustAlert[language].firstPart }

                                &nbsp;
                                <a className = 'Works__alertLink' href = { `${BASE_URL}/services/work` } target = '_blank' rel = 'noopener noreferrer'>
                                    { entrustAlert[language].secondPart }
                                </a>

                                { entrustAlert[language].thirdPart }
                            </Alert>
                        </CabinetAlerts>
                    ) }
                </CabinetContent>
            ) }
        </div>
    )
}));

export { Works };