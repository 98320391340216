// Получение полного пути картинки
import { BASE_URL } from 'constants/BASE_URL';

function getImageFullPath(path) {
    if (path && !/^data:image/.test(path)) {
        return `${BASE_URL}/storage${path}`;
    }

    return path;
}

export { getImageFullPath };