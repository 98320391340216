import React, { useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamAdvOtherDirectorShop = inject('LanguageStore', 'WorksStore', 'CabinetStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { currentWork } = WorksStore;
    const { searchInCabinet } = CabinetStore;

    const { directors_workshop_second_director, directors_workshop_casting_director } = currentWork.team;
    const { directors_workshop_cracker, directors_workshop_props } = currentWork.team;

    const { prepareGroup } = itemProps;

    const values = {
        'directors_workshop_second_director': directors_workshop_second_director.map(item => item.value),
        'directors_workshop_casting_director': directors_workshop_casting_director.map(item => item.value),
        'directors_workshop_cracker': directors_workshop_cracker.map(item => item.value),
        'directors_workshop_props': directors_workshop_props.map(item => item.value)
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;
            const executorObj = { name: checkedValue, name_en: checkedValue, surname: checkedValue, surname_en: checkedValue };

            searchInCabinet('executor', 'personalData', 'profile', key, executorObj, result => {
                setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
            });
        }, 350)    
    , []);

    return (
        <div className = { WorkTeamSectionClass }>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'directorShop') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Director\'s shop', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(directors_workshop_second_director, 'directors_workshop_second_director', 'PersonName', findSelectItems, values) }
                            { prepareGroup(directors_workshop_casting_director, 'directors_workshop_casting_director', 'PersonName', findSelectItems, values) }
                            { prepareGroup(directors_workshop_cracker, 'directors_workshop_cracker', 'PersonName', findSelectItems, values) }
                            { prepareGroup(directors_workshop_props, 'directors_workshop_props', 'PersonName', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamAdvOtherDirectorShop };