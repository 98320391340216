import React, { memo, useState, useEffect } from 'react';

import { generateId, setInlineStyles } from 'helpers';
import classNames from 'classnames';

import './_Tooltip.scss';

const Tooltip = memo(({ text, showOn = 'hover', direction = 'up', position, disabled, wrapClassName, children }) => {
    const [tooltipId, setTooltipId] = useState('');
    const [shown, setShown] = useState(false);

    const tooltipContent = [].concat(children).find(child => child.props.className === 'Tooltip__content');
    const tooltipTarget = [].concat(children).find(child => child !== tooltipContent);

    const TooltipWrapClass = classNames('Tooltip__wrap', wrapClassName);
   
    const TooltipClass = classNames('Tooltip', {
        'Tooltip--direction-up': direction === 'up',
        'Tooltip--direction-down': direction === 'down',
        'Tooltip--direction-left': direction === 'left',
        'Tooltip--direction-right': direction === 'right',
        'Tooltip--position-top': position === 'top',
        'Tooltip--position-bottom': position === 'bottom',
        'Tooltip--position-left': position === 'left',
        'Tooltip--position-right': position === 'right',
        'Tooltip--show': shown && !disabled
    });

    // Показ всплывающей подсказки
    const setTooltipPosition = target => {
        const tooltip = document.querySelector(`[data-tooltip-id='${target.getAttribute('data-tooltip-target-id')}']`);

        const tooltipRect = tooltip.getBoundingClientRect();

        const tooltipWidth = tooltipRect.width;
        const tooltipHeight = tooltipRect.height;

        const tooltipMargin = 10;
        const tooltipArrowMargin = 6;

        const targetRect = target.getBoundingClientRect();

        const targetWidth = targetRect.width;
        const targetHeight = targetRect.height;

        if (direction) {
            switch (direction) {
                case 'up':
                    setInlineStyles(tooltip, { 
                        transform: `translateX(${-tooltipWidth / 2 + targetWidth / 2}px) translateY(${-tooltipHeight - tooltipMargin}px)` 
                    });

                    break
                case 'down':
                    setInlineStyles(tooltip, { 
                        transform: `translateX(${-tooltipWidth / 2 + targetWidth / 2}px) translateY(${targetHeight + tooltipMargin}px)`
                    });

                    break
                case 'left':
                    setInlineStyles(tooltip, { 
                        transform: `translateX(${-tooltipWidth - tooltipMargin}px) translateY(${targetHeight / 2 - tooltipHeight / 2}px)`
                    });

                    break
                case 'right':
                    setInlineStyles(tooltip, { 
                        transform: `translateX(${tooltipMargin + targetWidth}px) translateY(${targetHeight / 2 - tooltipHeight / 2}px)`
                    });

                    break
            }
        }

        if (position) {
            const leftRightTranslateX = {
                left: -tooltipWidth - tooltipMargin,
                right: targetWidth + tooltipMargin
            };

            const upDownTranslateY = {
                up: -tooltipHeight - tooltipMargin,
                down: targetHeight + tooltipMargin
            };

            switch (position) {
                case 'top':
                    setInlineStyles(tooltip, { 
                        transform: `translateX(${leftRightTranslateX[direction]}px) translateY(${-tooltipArrowMargin}px)`
                    });

                    break
                case 'bottom':
                    setInlineStyles(tooltip, { 
                        transform: `translateX(${leftRightTranslateX[direction]}px) translateY(${targetHeight - tooltipHeight + tooltipArrowMargin}px)`
                    });

                    break
                case 'left':
                    setInlineStyles(tooltip, { 
                        transform: `translateX(${-tooltipArrowMargin}px) translateY(${upDownTranslateY[direction]}px)` 
                    });

                    break
                case 'right':
                    setInlineStyles(tooltip, { 
                        transform: `translateX(${-tooltipWidth + targetWidth + tooltipArrowMargin}px) translateY(${upDownTranslateY[direction]}px)` 
                    });

                    break
            }
        }
    };

    // Показ всплывающего окна
    const showTooltip = () => setShown(true);

    // Скрытие всплывающей подсказки
    const hideTooltip = () => setShown(false);

    // Показ по клику
    const showTooltipByClick = () => {
        setShown(prevShown => !prevShown);
    };

    // Скрытие по клике вне цели
    const hideTooltipByClicOutside = (target, tooltip, event) => {
        const currentTarget = event.target;

        if (!target.contains(currentTarget) && !tooltip.contains(currentTarget)) {
            setShown(false);
        }
    };

    // Установка обработчиков(hover)
    const setHoverEventHandlers = target => {
        if (target && !disabled) {
            target.addEventListener('mouseenter', showTooltip);
            target.addEventListener('mouseleave', hideTooltip);
        }
    };

    // Удаление обработчиков(hover)
    const removeHoverEventHandlers = target => {
        if (target) {
            target.removeEventListener('mouseenter', showTooltip);
            target.removeEventListener('mouseleave', hideTooltip);
        }
    };

    // Установка обработчиков(click)
    const setClickEventHandlers = (target, hideTooltipByClicOutside) => {
        if (target && !disabled) {
            target.addEventListener('click', showTooltipByClick);
            window.addEventListener('click', hideTooltipByClicOutside);
        }
    };

    // Удаление обработчиков(click)
    const removeClickEventHandlers = (target, hideTooltipByClicOutside) => {
        if (target) {
            target.removeEventListener('click', showTooltipByClick);
            window.removeEventListener('click', hideTooltipByClicOutside)
        }
    };

    // Показ и скрытие всплывающей подсказки
    useEffect(() => {
        const tooltipTarget = document.querySelector(`[data-tooltip-target-id='${tooltipId}']`);
        const tooltip = document.querySelector(`[data-tooltip-id='${tooltipId}']`);

        const setTooltipPositionBinded = setTooltipPosition.bind(null, tooltipTarget);
        const hideTooltipByClicOutsideBinded = hideTooltipByClicOutside.bind(null, tooltipTarget, tooltip);

        if (tooltipTarget) {
            tooltipTarget.addEventListener('mouseenter', setTooltipPositionBinded);
        }
        
        switch (showOn) {
            case 'hover':
                setHoverEventHandlers(tooltipTarget);
                break
            case 'click':
                setClickEventHandlers(tooltipTarget, hideTooltipByClicOutsideBinded);
                break
        }
        
        return () => {
            if (tooltipTarget) {
                tooltipTarget.removeEventListener('mouseenter', setTooltipPositionBinded);
            }

            switch (showOn) {
                case 'hover':
                    removeHoverEventHandlers(tooltipTarget);
                    break
                case 'click':
                    removeClickEventHandlers(tooltipTarget, hideTooltipByClicOutsideBinded);
                    break
            }
        }
    }, [tooltipId, disabled]);

    // Генерирование id для всплывающей подсказки
    useEffect(() => setTooltipId(generateId()), []);

    // Пересчет позиции при изменении контента
    useEffect(() => {
        const tooltipTarget = document.querySelector(`[data-tooltip-target-id='${tooltipId}']`);
        
        setTooltipPosition(tooltipTarget);
    }, [tooltipId, text]);

    return (
        <div className = { TooltipWrapClass }>
            { React.cloneElement(tooltipTarget, { 'data-tooltip-target-id': tooltipId }) }
            
            <div className = { TooltipClass } data-tooltip-id = { tooltipId }>
                { text || tooltipContent }
            </div>
        </div>
    )
});

export { Tooltip };