import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { WorkAside } from 'components/pages';
import { Main, MainContent, Header } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate, prepareWorkModePath } from 'helpers';
import { workRoutes, routeNames } from 'routes';

import './_Work.scss';

const Work = inject('LanguageStore', 'WorksStore', 'LoadingStore', 'UserStore', 'CabinetStore')(observer(({ location, LanguageStore, WorksStore, LoadingStore, UserStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { works, currentWork, getWork, getWorks, updateCurrentWorkLocal } = WorksStore;
    const { cabinet, clearCabinetSearch } = CabinetStore;
    const { setLoading } = LoadingStore;
    const { user } = UserStore;

    const { personalData } = cabinet;

    const pageName = location.pathname.split('/')[3];
    const budgetRedirectStatement = pageName === 'budgets' && currentWork.id && !['fullLengthArtMovie', 'commercialAdvertising'].includes(currentWork.workType);

    // Получение доступных секций
    const getExecutorAvaibleRoutes = () => {
        const { speciality } = personalData;
        
        if (currentWork.fields && currentWork.fields.profession) {
            const currentWorkProfessionsId = currentWork.fields.profession;
            const chosenProfessions = speciality.map(item => item.profession).filter(item => currentWorkProfessionsId.includes(item.id));
            const choosenProfessionsPermissions = [...new Set(chosenProfessions.map(item => item.permission).flat())];
            
            const isHard = choosenProfessionsPermissions.find(item => item === 'hard');
            const isMedium = choosenProfessionsPermissions.find(item => item === 'medium');

            if (isHard) return workRoutes.map(item => item.items.map(item => item)).flat().map(item => item.path.replace('/', ''))
            if (isMedium) return workRoutes.slice(0, 2).map(item => item.items.map(item => item)).flat().map(item => item.path.replace('/', ''))

            return workRoutes.slice(0, 1).map(item => item.items.map(item => item)).flat().map(item => item.path.replace('/', ''))
        }

        return workRoutes.slice(0, 1).map(item => item.items.map(item => item)).flat().map(item => item.path.replace('/', ''))
    };

    const availableRoutes = personalData && personalData.speciality ? getExecutorAvaibleRoutes() : workRoutes;

    // Запрос работ
    useEffect(() => {
        if (works.length === 0) {
            setLoading('mainContent', true);

            getWorks()
                .then(() => setLoading('mainContent', false));

            return () => {
                setLoading('mainContent', false);
            }
        }
    }, []);

    // Смена текущего заголовка страницы
    useEffect(() => {
        document.title = translate(routeNames[`/works/mode/${pageName}`], language);
    }, [location, language]);

    // Изменения режима работы
    useEffect(() => {
        const mode = location.pathname.split('/')[2].split('-')[0];
        
        if (mode === 'edit') {
            setLoading('main', true);

            const workId = location.pathname.split('/')[4];

            getWork(workId)
                .then(() => updateCurrentWorkLocal({ mode: mode }))
                .then(() => setLoading('main', false))
                .catch(() => {
                    updateCurrentWorkLocal({ mode: null });
                    setLoading('main', false);
                });
        } else if (mode === 'add') {
            updateCurrentWorkLocal({ mode: mode });
        }

        return () => {
            updateCurrentWorkLocal({ mode: false, errors: {}, updated: [] }, true);
            clearCabinetSearch();
        }
    }, []);

    const redirectStatement = (
        currentWork.mode === null                                                 || 
        currentWork.wasCreated                                                    ||
        budgetRedirectStatement                                                   || 
        (currentWork.mode === 'add' && currentWork.id)                            ||
        (currentWork.mode === 'add' && !currentWork.id && pageName !== 'general') ||
        (user.activeCabinet !== 'executor' && user.activeCabinet !== 'studio')    ||
        (user.activeCabinet === 'executor' && !availableRoutes.includes(pageName))
    );

    let redirect = null;

    if (redirectStatement) {
        if (currentWork.mode === 'add' && !currentWork.id && pageName !== 'general') {
            redirect = <Redirect to = '/works/add-work/general' />;
        } else if (currentWork.wasCreated) {
            redirect = <Redirect to = { `/works/edit-work/general/${currentWork.id}` } />;
        } else if (currentWork.id !== undefined && (budgetRedirectStatement || (user.activeCabinet === 'executor' && !availableRoutes.includes(pageName)))) {
            redirect = <Redirect to = { `/works/edit-work/general/${currentWork.id}` } />;
        }   else if (currentWork.mode === null) {
            redirect = <Redirect to = '/works' />;
        } else if (user.activeCabinet && !['executor', 'studio'].includes(user.activeCabinet)) {
            redirect = <Redirect to = '/' />;
        }
    }

    return (
        <div className = 'Work'>
            <Header />
            
            { redirectStatement && redirect }
            
            { currentWork.mode && cabinet.personalData && (
                <Main>
                    <WorkAside location = { location } routes = { workRoutes } currentWork = { currentWork } />

                    <MainContent>
                        <Switch>
                            { workRoutes.map(section => section.items).flat().map(route => {
                                const preparedPath = prepareWorkModePath(location, route);
                                
                                return (
                                    <Route key = { route.path }
                                           path = { preparedPath } 
                                           exact = { route.exact || true } 
                                           component = { route.component } />
                                )
                            }) }

                            <Route path = '*' render = { () => <Redirect to = '/page-not-found' /> } />
                        </Switch>
                    </MainContent>
                </Main>
            ) }
        </div>
    )
}));

export { Work };