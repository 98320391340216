import React, { useState, Fragment } from 'react';

import { RemoveSureModal } from 'components/containers';
import { SearchType, Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, isObject } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';
import classNames from 'classnames';

import './_SearchesListItem.scss';

const SearchesListItem = inject('LanguageStore', 'SearchesStore')(observer(({ search, LanguageStore, SearchesStore }) => {
    const [removeModalOpened, setRemoveModalOpened] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    const { language } = LanguageStore;
    const { deleteSearch } = SearchesStore;

    const { type, createdAt, params } = search;
    const { info: { itemCount, workCount } } = params;
    const searchParams = params.params;

    const prettyParams = search[`prettyParams-${language}`];
    const prettyParamsNames = Object.keys(prettyParams);
    const resultsCount = itemCount + workCount;

    const SearchesListItemClass = classNames('SearchesListItem', {
        'SearchesListItem--opened': isOpened
    });

    const showButtonText = isOpened ? 'Hide': 'More detailed';

    const deleteSearchBinded = deleteSearch.bind(null, search.id);

    // Показ контента
    function showContent() {
        setIsOpened(prevIsOpened => !prevIsOpened);
    }

    // Удаление поиска
    function removeSearch() {
        setRemoveModalOpened(true);
    }

    // Получение фильтрованных параметров
    function preparePrettyParams(params) {
        const paramsArr = [].concat(params);
        const filteredParams = paramsArr.filter(param => param && param !== 'no-selected');
        const prettyParams = filteredParams.length > 0 ? filteredParams.join(', ') : '—';

        return prettyParams;
    }

    // Получение ссылки для повторения поиска
    function getSearchLink(params, parentName) {
        const readyParams = [];
        const paramsNames = Object.keys(params).filter(name => params[name] && params[name] !== 'no-selected');
        
        paramsNames.forEach(name => {
            let readyParam = null;

            if (isObject(params[name])) {
                const readyParentName = [parentName, name].filter(name => name)
                    .map((name, index) => index === 0 ? name : `[${name}]`)
                    .join('');
                readyParam = getSearchLink(params[name], readyParentName);
            } else {
                readyParam = `${parentName ? `${parentName}[${name}]` : name}=${params[name]}`;
            }

            readyParam && readyParams.push(readyParam);
        });

        return readyParams.join('&');
    }

    return (
        <div className = { SearchesListItemClass }>
            <div className = 'SearchesListItem__header'>
                <div className = 'SearchesListItem__title'>
                    <span className = 'SearchesListItem__titleText'>
                        { `${translate('ResultsSearch', language)}: ${resultsCount}` }
                        &nbsp;
                        <span className = 'SearchesListItem__title--color-grey'>
                            { `(${translate('FromTime', language).toLowerCase()} ${createdAt})` }
                        </span>
                    </span>

                    <SearchType type = { type } />
                </div>
            </div>

            <div className = 'SearchesListItem__body'>
                { prettyParamsNames.length > 0 && (
                    <div className = 'SearchesListItem__params'>
                        { prettyParamsNames.map((name, index) => (
                            <Fragment key = { index }>
                                <span className = 'SearchesListItem__paramsName'>
                                    { name }:
                                </span>

                                <span className = 'SearchesListItem__paramsValue'>
                                    { preparePrettyParams(prettyParams[name]) }
                                </span>
                            </Fragment>
                        )) }
                    </div>
                ) }
            </div>

            <div className = 'SearchesListItem__buttons'>
                <Button content = {{ text: translate('Remove', language) }} onClick = { removeSearch } />

                <Button content = {{ text: translate(showButtonText, language) }} onClick = { showContent } />
                
                <a href = { `${BASE_URL}/search?${getSearchLink(searchParams)}` } target = '_blank' rel = 'noopener noreferrer'>
                    <Button content = {{ text: `${translate('Repeat', language)} →` }} />
                </a>
            </div>

            { removeModalOpened && (
                <RemoveSureModal title = { translate('Do you really want to delete the search?', language) }
                                 modalOpened = { removeModalOpened }
                                 setModalOpened = { setRemoveModalOpened }
                                 onRemoveClick = { deleteSearchBinded } />
            ) }
        </div>
    )
}));

export { SearchesListItem };