import React from 'react';

import { Footer } from 'components/containers';

import { inject, observer } from 'mobx-react';

import './_MainContent.scss';

const MainContent = inject('LoadingStore')(observer(({ LoadingStore, children }) => {
    const { loading } = LoadingStore;

    return (
        <div className = 'MainContent'>
            { children }

            { !loading.main && !loading.mainContent && <Footer /> }
        </div>
    )
}));

export { MainContent };