import React from 'react';

import { ChooseIcon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_TabsListsNav.scss';

const TabsListsNav = inject('LanguageStore', 'TabsStore')(observer(({ tabs, currentTab, onTabClick, LanguageStore, TabsStore }) => {
    const { language } = LanguageStore;
    const { changeTabShow } = TabsStore;

    const { clients, skills, links, fest } = tabs;

    const existLinksIds = [];
    const uniqueLinksItems = [];

    for (let item of links.items) {
        if (!existLinksIds.includes(item.id)) {
            existLinksIds.push(item.id);
            uniqueLinksItems.push(item);
        }
    }

    const tabsInfo = [
        { name: 'clients', title: `${translate('Clients', language)} (${clients.items.length})` },
        { name: 'skills', title: `${translate('Special skills', language)} (${skills.items.length})` },
        { name: 'links', title: `${translate('Communication', language)} (${uniqueLinksItems.length})` },
        { name: 'fest', title: `${translate('Awards', language)} (${fest.items.length})` }
    ];

    // Отвлов клика по элементу
    function handleTabClick(name) {
        if (name !== currentTab) {
            onTabClick(name);
        }
    }

    // Отвлов изменения видимости
    function handleShowChange(name) {
        changeTabShow(name);
    }

    // Отмена клика по вкладке
    function preventTabChange(event) {
        event.stopPropagation();
    }

    return (
        <div className = 'TabsListsNav'>
            { tabsInfo.map((tab, index) => {
                const { name, title } = tab;

                const handleTabClickBinded = handleTabClick.bind(null, name);
                const handleShowChangeBinded = handleShowChange.bind(null, { name });

                const TabsListsNavItemClass = classNames('TabsListsNav__item', {
                    'TabsListsNav__item--active': name === currentTab
                });
                
                return (
                    <div key = { index } className = { TabsListsNavItemClass } onClick = { handleTabClickBinded }>
                        <span className = 'TabsListsNav__itemText'>
                            { title }
                        </span>

                        <div className = 'TabsListsNav__itemIcon' onClick = { preventTabChange }>
                            <ChooseIcon text = { translate('Hide a section from the site', language) }  
                                        changeText = { translate('Don`t hide a section from the site', language) }
                                        checked = { !tabs[name].show }
                                        tooltipDirection = 'down'
                                        tooltipPosition = 'left'
                                        onChange = { handleShowChangeBinded } />
                        </div>
                    </div>
                )
            }) }
        </div>
    )
}));

export { TabsListsNav };