import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { AgentContractorsList } from 'components/pages';
import { CabinetContent, CabinetAlerts } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const AgentContractors = inject('LanguageStore', 'CabinetStore', 'AgentExecutorsStore', 'LoadingStore')(observer(({ LanguageStore, CabinetStore, AgentExecutorsStore, LoadingStore }) => {
    const { language } = LanguageStore;
    const { subscriptions } = CabinetStore;
    const { executors, getExecutors } = AgentExecutorsStore;
    const { setLoading } = LoadingStore;

    const [isLoading, setIsLoading] = useState(executors.length === 0);

    const payAlertTranslations = {
        'ru-RU': {
            first: 'Для отображения на сайте и добавления исполнителей необходимо',
            second: 'первый пакет (5 исполнителей). Первые два месяца - 1 рубль'
        },

        'en-US': {
            first: 'To display on the site and add performers, you need to',
            second: 'for the first package (5 performers). The first two months - 1 ruble'
        },
    };

    // Запрос исполнителей
    useEffect(() => {
        if (executors.length === 0) {
            setLoading('mainContent', true);

            getExecutors()
                .then(() => {
                    setIsLoading(false);
                    setLoading('mainContent', false); 
                });

            return () => {
                setLoading('mainContent', false);
            }
        }
    }, []);

    return (
        <div className = 'AgentContractors'>
            { !isLoading && (
                <CabinetContent>
                    <CabinetAlerts>
                        <Alert text = { translate('One agent package includes 5 executors. In the future you can increase the number of packages', language) } 
                            icon = 'question' />

                        { subscriptions.agent && subscriptions.agent[0].invoice[0].status === 'no_payed' && (
                            <Alert type = 'warn'>
                                { payAlertTranslations[language].first }

                                &nbsp;
                                <Link to = '/payment' className = 'Alert__textLink'>
                                    <span className = 'Alert__text Alert__text--action'>
                                        { translate('Pay', language).toLowerCase() }
                                    </span>
                                </Link>
                                &nbsp;
                                
                                { payAlertTranslations[language].second }
                            </Alert>
                        ) }
                    </CabinetAlerts>

                    <AgentContractorsList />
                </CabinetContent>
            ) }
        </div>
    )
}));

export { AgentContractors };