import React, { useState, useEffect, useRef } from 'react';

import { WorkCard } from 'components/pages';
import { RemoveSureModal } from 'components/containers';
import { Icon, Tooltip, ChooseBox, Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';
import Sortable from 'sortablejs';

import './_WorksListItem.scss';

const WorksListItem = inject('LanguageStore', 'WorksStore')(observer(({ dataWorkGroupIndex, item, value, options, isDraggable, handlers, LanguageStore, WorksStore, ...restProps }) => {
    const [removeModalOpened, setRemoveModalOpened] = useState(false);

    const inputRef = useRef(null);

    const { language } = LanguageStore;
    const { works, replaceWorkToGroup, updateWorksSort, updateWorkGroupId } = WorksStore;

    const { name, work, show, isTrash, isOtherUser, isArchive, isMovie, isAdv } = item;
    const { onChangeIconClick, onInputChange, onRemoveButtonClick, onWorkItemClick, onShowButtonClick } = handlers;
    const { opened, isChanging } = options;

    const isDefault = (isTrash || isOtherUser || isArchive) ? false : true;
    const canChange = isDefault;
    const canDelete = isDefault;

    const WorksListItemClass = classNames('WorksListItem', {
        'WorksListItem--opened': opened && !isChanging,
        'WorksListItem--changing': isChanging,
        'WorksListItem--draggable': isDraggable
    });

    // Получение работы по id
    const getWork = id => {
        for (let group of works) {
            for (let work of group.work) {
                if (work.id === +id) {
                    return Object.clone(work);
                }
            }
        }

        return undefined;
    };

    // Отлов окончания dnd
    const handleDragEnd = ({ newIndex, oldIndex, from, to, item, target }) => {
        const isSameGroup = from === to;
        const groupId = +(isSameGroup ? from : to).closest('.WorksListItem').dataset.workGroupId;
        const toGroupId = +to.closest('.WorksListItem').dataset.workGroupId;
        const workId = +item.dataset.workId;
        const workItems = isSameGroup ? target : to;
        const workCards = [...workItems.children];
        const newWorkList = [];

        if ((isSameGroup && newIndex !== oldIndex) || !isSameGroup) {
            workCards.forEach((card, index) => {
                const work = getWork(card.dataset.workId);
                newWorkList.push({ id: work.id, sort: index });
            });

            if (isSameGroup) {
                updateWorksSort({ groupId, sort: newWorkList });
            } else {
                replaceWorkToGroup(toGroupId, newIndex, workId);

                updateWorkGroupId(workId, groupId)
                    .then(() => updateWorksSort({ groupId, sort: newWorkList }));
            }
        }
    };

    // Отлов перетаскивания
    const handleDragMove = ({ from, to, dragged }) => {
        const isSameGroup = from === to;

        if (!isSameGroup) {
            const groupId = +to.closest('.WorksListItem').dataset.workGroupId;
            const group = works.find(group => group.id === groupId);
            const work = getWork(dragged.dataset.workId);
         
            if (group.isOtherUser && !work.isOtherUserWork) {
                return false
            }
        }
    };

    // Отлов переноса в новых список
    const handleDragAdd = ({ from, to, item, oldIndex, newIndex }) => {
        to.insertBefore(item.cloneNode(true), to.children[newIndex]);

        item.style.display = 'none';
        from.insertBefore(item, from.children[oldIndex]);
    };

    // Фокус на поле ввода если работа изменяется или создается
    useEffect(() => {
        if (isChanging) {
            inputRef.current.focus();
        }
    }, [isChanging]);

    // Сброс ссылки на поле ввода
    useEffect(() => {
        return () => inputRef.current = null;
    }, []);

    // Добавление dnd
    useEffect(() => {
        const sortableLists = document.querySelectorAll('.WorksListItem__items');

        const sortable = new Sortable(sortableLists[dataWorkGroupIndex], { 
            animation: 150, 
            group: 'shared',
            handle: '.WorkCard__option--drag',
            onEnd: handleDragEnd,
            onMove: handleDragMove,
            onAdd: handleDragAdd
        });

        return () => {
            sortable.destroy();
        }
    }, [work.length]);
  
    return (
        <div className = { WorksListItemClass } data-work-group-index = { dataWorkGroupIndex } data-work-group-id = { item.id } { ...restProps }>
            <div className = 'WorksListItem__head' onClick = { onWorkItemClick }>
                <Icon name = 'arrow-long' className = 'WorksListItem__showArrow' />

                <div className = 'WorksListItem__info'>
                    <div className = 'WorksListItem__infoLeft'>
                        { isChanging && !isDraggable ? (
                            <input className = 'WorksListItem__input' 
                                   value = { value } 
                                   ref = { inputRef } 
                                   onChange = { onInputChange }
                                   onClick = { event => event.stopPropagation() } />
                        ) : (
                            <span className = 'WorksListItem__name'>
                                { name } 

                                &nbsp;

                                { isOtherUser && (
                                    <span className = 'WorksListItem__name WorksListItem__name--member'>
                                        ({ translate('You are team member', language) })
                                    </span>
                                ) }
                            </span>
                        ) }

                        { (canChange && !isChanging && !isDraggable)  && (
                            <Tooltip text = { translate('Change name', language) }>
                                <Icon name = 'edit' className = 'WorksListItem__editIcon' data-work-group-index = { dataWorkGroupIndex } onClick = { onChangeIconClick } />
                            </Tooltip>
                        ) }
                    </div>

                    <div className = 'WorksListItem__infoRight'>
                        { !isDraggable ? (
                            ( isChanging && canDelete && !isAdv && !isMovie ? (
                                <Button content = {{ icon: 'cross-in-circle', text: translate('Remove section', language) }}
                                        className = 'WorksListItem__removeButton'
                                        theme = 'grey' 
                                        pure = { true }
                                        onClick = { setRemoveModalOpened.bind(null, true) } />
                            ) : (
                                <span className = 'WorksListItem__count'>
                                    { `${translate('WorksGenitive', language)} (${work.length})` }
                                </span>
                            ) )
                        ) : (
                            <Icon name = 'drag-points' className = 'WorksListItem__dragIcon' />
                        ) }
                    </div>
                </div>
                            
                { (!isOtherUser && !isArchive && !isDraggable) && (
                    <div className = 'WorksListItem__chooseBox' onClick = { event => event.stopPropagation() }>
                        <ChooseBox type = 'checkbox'
                                   checked = { show } 
                                   text = { translate('Show', language) }
                                   theme = 'white'
                                   onChange = { onShowButtonClick } />
                    </div>
                ) }
            </div>

            <div className = 'WorksListItem__body'>
                <div className = 'WorksListItem__items' key = { work.length }>
                    { work.map((workItem, index) => (
                        <WorkCard key = { workItem.id }
                                  dataWorkIndex = { index }
                                  workGroupIndex = { dataWorkGroupIndex }
                                  data-work-id = { workItem.id }
                                  item = { workItem } />
                    )) }

                </div>

                <div className = 'WorksListItem__hide' onClick = { onWorkItemClick }>
                    { translate('Hide', language) } ↑
                </div>
            </div>

            { removeModalOpened && (
                <RemoveSureModal title = { translate('Do you really want to delete the section?', language) }
                                 text = { `${translate('This action is irreversible', language)}. ${translate('All works in this section will be moved to the Archive', language)}` }
                                 modalOpened = { removeModalOpened }
                                 setModalOpened = { setRemoveModalOpened }
                                 onRemoveClick = { onRemoveButtonClick } />
            ) }
        </div>
    )
}));

export { WorksListItem };