import React, { useState, useEffect, Children, cloneElement } from 'react';

import classNames from 'classnames';

const CabinetAccordionList = ({ className, children }) => {
    const [activeAccordionIndex, setActiveAccordionIndex] = useState(undefined);

    const CabinetAccordionListClass = classNames('CabinetAccordionList', className);

    const modifiedChildren = Children.map(children, (child, index) => {
        const openAccordionBinded = openAccordion.bind(null, index);
        const isOpened = Boolean(index === activeAccordionIndex);
        const newProps = { isOpened, onOpenClick: openAccordionBinded };
        
        return cloneElement(child, newProps);
    });

    // Открытие аккордиона
    function openAccordion(index, event) {
        event.stopPropagation();

        setActiveAccordionIndex(prevActiveAccordionIndex => index === prevActiveAccordionIndex ? undefined : index);
    }

    // Отлов клика вне
    function handleOutsideClick() {
        setActiveAccordionIndex(undefined);
    }

    // Обработчик клика вне
    useEffect(() => {
        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        }
    }, []);

    return (
        <div className = { CabinetAccordionListClass }>
            { modifiedChildren }
        </div>
    )
};

export { CabinetAccordionList };