import React, { useState } from 'react';

import { Modal } from 'components/containers';
import { Button, SubscriptionTimeCard, Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_SubscriptionTimeModal.scss';

const SubscriptionTimeModal = inject('LanguageStore', 'CabinetStore', 'InvoicesStore')(observer(({ modalOpened, setModalOpened, invoice, openPayModal, LanguageStore }) => {
    const [currentTime, setCurrentTime] = useState('month');

    const { language } = LanguageStore;

    const days = {
        executor: {
            'ru-RU': 'за 50 дней',
            'en-US': 'for 50 days'
        },

        studio: {
            'ru-RU': 'за 50 дней',
            'en-US': 'for 50 days'
        },

        agent: {
            'ru-RU': 'за 50 дней',
            'en-US': 'for 50 days'
        },

        filmSchool: {
            'ru-RU': 'за 50 дней',
            'en-US': 'for 50 days'
        },
    }

    // Закрытие модального окна
    const closeModal = () => setModalOpened(false);

    // Выбор срока подписки
    const changeTime = type => {
        setCurrentTime(type);
    };

    return (
        <Modal title = { translate('Subscription period', language) } opened = { modalOpened } setOpened = { closeModal }>
            <div className = 'SubscriptionTimeModal'>
                <SubscriptionTimeCard time = 'year'
                                      name = 'time'
                                      value = 'year'
                                      invoice  = { invoice }
                                      price = { invoice.priceList.rubYear }
                                      discount = { invoice.discount }
                                      checked = { currentTime === 'year' }
                                      chooseTime = { changeTime.bind(null, 'year') } />

                <SubscriptionTimeCard name = 'time'
                                      value = 'month'
                                      invoice = { invoice }
                                      price = { invoice.priceList.rubMonth }
                                      discount = { invoice.discount }
                                      checked = { currentTime === 'month' }
                                      chooseTime = { changeTime.bind(null, 'month') } />
            </div>

            <div className = 'Modal__actions SubscriptionTimeModal__actions'>
                <div className = 'Modal__actionsSection Modal__actionsSection--left'>
                    { invoice.amount === 1 && (
                        <Alert className = 'SubscriptionTimeModal__alert'>
                            { translate('The current subscription price will be', language) }

                            &nbsp;
                            <span className = 'SubscriptionTimeModal__alertRuble'>
                                1 { translate('RubleFull', language).toLowerCase() } { days[invoice.cabinet][language] }
                            </span>
                        </Alert>
                    ) }
                </div>

                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: translate('Cancel', language) }} onClick = { closeModal } />
                    <Button content = {{ text: translate('Further', language) }}
                            onClick = { openPayModal.bind(null, currentTime) } />
                </div>
            </div>
        </Modal>
    )
}));

export { SubscriptionTimeModal };
