import React, { useState, useCallback } from 'react';

import { CabinetSection, NewCabinetEntityModal } from 'components/containers';
import { ChooseIcon, Icon, SelectPure, Tooltip, ChooseBox } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate, debounce } from 'helpers';

import './_AgentGuilds.scss';

const AgentGuilds = inject('LanguageStore', 'CabinetStore', 'GuildsStore')(observer(({ LanguageStore, CabinetStore, GuildsStore }) => {
    const [selectInput, setSelectInput] = useState('');
    const [openedEntityModal, setOpenedEntityModal] = useState(false);

    const { language } = LanguageStore;
    const { cabinet, updateCabinetSection, deleteFromCabinetSection } = CabinetStore;
    const { guilds, findGuild } = GuildsStore;

    const cabinetGuilds = cabinet.personalData ? cabinet.personalData.guilds : null;

    const preparedGuilds = (cabinetGuilds && guilds) && [{
        id: 0,
        items: guilds.map(guild => ({
            id: guild.id,
            text: guild[`name-${language}`],
            selected: Boolean(cabinetGuilds.find(guildItem => guildItem.guildId === guild.id)),
            data: guild
        }))
    }];

    const guildActionButton = {
        text: translate('Add a new guild', language),
        restProps: {
            onClick: setOpenedEntityModal.bind(null, true)
        }
    };

    const findGuildItem = useCallback(
        debounce(value =>  findGuild(value === '' ? '*' : value), 350)
    , []);

    // Отлов ввода в поле ввода
    const handleInputChange = event => {
        const input = event.target;
        const value = input.value;

        setSelectInput(value);
        findGuildItem(value.trim());
    };

    // Отлов фокуса на поле ввода
    const handleInputFocus = () => findGuild('*');

    // Показ гильдии
    const showGuild = event => {
        const chooseIcon = event.target;
        const checked = chooseIcon.checked;
        const guildIndex = chooseIcon.closest('.AgentGuilds__item').dataset.guildIndex;
        const guild = cabinetGuilds[guildIndex];

        updateCabinetSection('guilds', { id: guild.id, show: checked });
    };

    // Удаление гильдии
    const deleteGuild = event => {
        const token = event.target.closest('.AgentGuilds__token');
        const guildIndex = Number(token.closest('.AgentGuilds__item').dataset.guildIndex);
        const guild = cabinetGuilds[guildIndex];

        deleteFromCabinetSection('guilds', guild.id, guildIndex);
    };

    // Добавление гильдии
    const addGuild = event => {
        const dropdownItem = event.target.closest('.SelectDropdown__sectionItem');
        const data = JSON.parse(dropdownItem.dataset.data);
        const guildId = data.id;
        const equalGuild = cabinetGuilds.find(guild => guild.guildId === guildId);

        if (!equalGuild) {
            setSelectInput('');
            updateCabinetSection('guilds', { guildId: guildId, show: false });
        }
    };

    // Создание гильдии
    const addNewGuild = guild => {
        setSelectInput('');
        setOpenedEntityModal(false);

        updateCabinetSection('guilds', { guildId: guild.id, show: false });
    };

    // Включение кросс-ссылки
    const enableCrossLink = (id, event) => {
        const checkbox = event.target;
        const checked = checkbox.checked;

        updateCabinetSection('guilds', { id, crossLink: checked });
    };

    return cabinetGuilds && (
        <CabinetSection id = 'guildsAndUnionists' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Guilds and unionists', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'CabinetSection__container'>
                    <div className = 'AgentGuilds'>
                        <SelectPure inputProps = {{
                                        label: translate('The name of the guild', language),
                                        value: selectInput
                                    }}
                                    items = { preparedGuilds }
                                    actionButton = { guildActionButton }
                                    onInputFocus = { handleInputFocus }
                                    onInputChange = { handleInputChange }
                                    onDropdownItemClick = { addGuild } />

                        <div className = 'AgentGuilds__list'>
                            { cabinetGuilds.map(guild => {
                                const guildIndex = cabinetGuilds.indexOf(guild);

                                return (
                                    <div className = 'AgentGuilds__item' key = { guildIndex } data-guild-index = { guildIndex }>
                                        <div className = 'AgentGuilds__token' onClick = { deleteGuild }>
                                            <span className = 'AgentGuilds__tokenText'>{ guild[`guildName-${language}`] }</span>
                                            <Icon name = 'cross-tiny' className = 'AgentGuilds__tokenIcon' />
                                        </div>

                                        <ChooseIcon text = { translate('Show in profile', language) }
                                                    changeText = { translate('Don`t show in profile', language) }
                                                    tooltipDirection = 'right'
                                                    checked = { guild.show }
                                                    name = 'show'
                                                    onChange = { showGuild } />

                                        <ChooseBox type = 'checkbox'
                                                   text = { translate('Enable cross-reference', language) }
                                                   checked = { guild.crossLink }
                                                   onChange = { enableCrossLink.bind(null, guild.id) }
                                                   disabled = { !guild.linkProfile }>
                                            <Tooltip wrapClassName = 'ExecutorEducation__crossLinkTooltipWrap'
                                                     text = { translate('You can include a link to go to guild profile', language) }
                                                     direction = 'down'
                                                     position = 'left'>
                                                <Icon name = 'question' className = 'ChooseBox__icon ChooseBox__icon--question' />
                                            </Tooltip>
                                        </ChooseBox>
                                    </div>
                                )
                            }) }
                        </div>
                    </div>
                </div>
            </div>

            { openedEntityModal && (
                <NewCabinetEntityModal type = 'guild'
                                       modalOpened = { openedEntityModal }
                                       setModalOpened = { setOpenedEntityModal }
                                       onAdd = { addNewGuild } />
            ) }
        </CabinetSection>
    )
}));

export { AgentGuilds };
