import React from 'react';

import { ManagementData, ManagementPassword, ManagementSettings } from 'components/pages';
import { CabinetContent } from 'components/containers';

const Management = () => (
    <div className = 'Management'>
        <CabinetContent>
            <ManagementData />
            <ManagementPassword />
            <ManagementSettings />
        </CabinetContent>
    </div>
);

export { Management };