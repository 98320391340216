import React, { useState, useEffect } from 'react';

import { RequestsList } from 'components/pages';
import { CabinetContent, CabinetAlerts } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const Requests = inject('LanguageStore', 'LoadingStore', 'RequestsStore')(observer(({ LanguageStore, LoadingStore, RequestsStore }) => {
    const { language } = LanguageStore;
    const { setLoading } = LoadingStore;
    const { getRequests, requests } = RequestsStore;

    const [isLoading, setIsLoading] = useState(requests.length === 0);

    useEffect(() => {
        if (requests.length === 0) {
            setLoading('mainContent', true);

            getRequests()
                .then(() => {
                    setIsLoading(false);
                    setLoading('mainContent', false);
                });

            return () => {
                setLoading('mainContent', false);
            }
        } else {
            getRequests();
        }
    }, []);

    return (
        <div className = 'Requests'>
            { !isLoading && (
                <CabinetContent>
                    <CabinetAlerts>
                        <Alert text = { translate('The status of your requests is shown here. The first request is free. You can create a query through the search page when there are no matching results. We will select the necessary performer (company or staff) for any of your tasks for shooting and post-production', language) } />
                    </CabinetAlerts>

                    { requests.length > 0 ? (
                        <RequestsList requests = { requests } />
                    ) : (
                        <Alert text = { translate('You have no requests', language) } />
                    ) }
                </CabinetContent>
            ) }
        </div>
    )
}));

export { Requests };