import React from 'react';

import { CabinetSection } from 'components/containers';
import { CopyText } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_ReferralLink.scss';

const ReferralLink = inject('LanguageStore', 'UserStore')(observer(({ LanguageStore, UserStore }) => {
    const { language } = LanguageStore;
    const { user } = UserStore;

    return (
        <div className = 'Referral__section'>
            <div className = 'Referral__sectionTitle'>
                <span className = 'Referral__sectionTitleText'>
                    { translate('Referral link', language) }
                </span>
            </div>

            <div className = 'Referral__sectionContent'>
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinetSection__content'>
                        <div className = 'ReferralLink'>
                            <span className = 'ReferralLink__text'>
                                { translate('You can use this link to register your friends to receive free months of subscriptions. It can be used in social networks. networks, via email or SMS:', language) }
                            </span>

                            <CopyText
                                className = 'ReferralLink__copy'
                                text = { user.referralLink }
                                copyText = { translate('Copy the link', language) }
                                copiedText = { translate('Link copied', language) }
                            />
                        </div>
                    </div>
                </CabinetSection>
            </div>
        </div>
    )
}));

export { ReferralLink };