import React from 'react';

import { ExecutorNav, ExecutorGeneral, ExecutorContacts, ExecutorAgents, 
         ExecutorLocations, ExecutorEducation, ExecutorMoreInfo, 
         ExecutorSocialNetworks, ExecutorFilmography, ExecutorSummary,
         ExecutorLanguages, ExecutorGuilds } from 'components/pages';
import { CabinetContent, CabinetAlerts } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const Executor = inject('LanguageStore', 'CabinetStore')(observer(({ LanguageStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { cabinet } = CabinetStore;
    
    const specialities = (cabinet.personalData && cabinet.personalData.speciality ) || [];
    const moderationSpeciality = specialities.find(speciality => speciality.status === 'moderation');

    return (
        <div className = 'Executor'>
            <ExecutorNav />
    
            <CabinetContent>
                { moderationSpeciality && (
                    <CabinetAlerts>
                        { moderationSpeciality && (
                            <Alert text = { translate('Classification "Pro" and "New blood" are confirmed by the site administration after adding works', language) }
                                   padding = { true }
                                   bordered = { true } />
                        ) }
                    </CabinetAlerts>
                ) }

                <ExecutorGeneral />
                <ExecutorContacts />
                <ExecutorAgents />
                <ExecutorLocations />
                <ExecutorEducation />
                <ExecutorLanguages />
                <ExecutorGuilds />
                <ExecutorMoreInfo />
                <ExecutorSocialNetworks />
                <ExecutorFilmography />
                <ExecutorSummary />
            </CabinetContent>
        </div>
    )
}));

export { Executor };