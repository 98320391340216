// Хранилище пользователя

import { action, observable, runInAction, decorate } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class UserStore {
    user = {
        activeCabinet: null,
        existsCabinets: null
    }

    // Получение пользователя
    async getUser() {
        const responseUser = await request(`${BASE_URL}/api/user`);
        const responseExistsCabinets = await request(`${BASE_URL}/api/user/cabinet/exists`);

        if (responseUser.status === 200 && responseExistsCabinets.status === 200) {
            const dataUser = await responseUser.json();
            const dataExistsCabinets = await responseExistsCabinets.json();
            const user = Object.assign(this.user, dataUser, { existsCabinets: dataExistsCabinets });

            runInAction(() => this.user = user);

            return dataUser
        }
    }

    // Обновление пользователя
    async updateUser(obj) {
        const response = await request(`${BASE_URL}/api/user/update`, { method: 'POST', body: JSON.stringify(obj) });

        if (response.status === 200) {
            const data = await response.json();
            const user = { ...this.user, ...data.user };

            runInAction(() => this.user = user);
        }
    }

    // Изменение кабинета
    async changeCabinet(type) {
        await this.updateUser({ activeCabinet: type });
    }

    // Изменение пароля
    async changePassword(obj) {
        const response = await request(`${BASE_URL}/api/user/change-password`, { method: 'POST', body: JSON.stringify({ ...obj }) });
        const data = await response.json();

        if (response.status === 200) {
            this.user.passwordErrors = {};
        } else if (response.status === 422) {
            this.user.passwordErrors = data.errors;
        }

        return response;
    }

    // Приглашение пользователя
    async inviteUser(obj) {
        const response = await request(`${BASE_URL}/api/user/invite`, { method: 'POST', body: JSON.stringify(obj) });
        const data = await response.json();

        if (response.status === 200) {
            return data.result
        } else if(response.status === 422) {
            this.user.inviteErrors = data.errors;
        }

        throw data.errors
    };

    // Выход с аккаунта
    async logout() {
        const response = await request(`${BASE_URL}/api/auth/logout`, { method: 'POST' });

        if (response.status === 200) {
            window.location.assign(BASE_URL);
        }
    }
}

decorate(UserStore, {
    user: observable,
    getUser: action.bound,
    updateUser: action.bound,
    changeSubCabinet: action.bound,
    changeCabinet: action.bound,
    changePassword: action.bound,
    inviteUser: action.bound
});

export default new UserStore();
