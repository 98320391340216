import React, { useState } from 'react';

import { CabinetSection, ImageWithCropper, SpecialtiesTable,
         NewProfessionModal, NewProfessionDetailedModal } from 'components/containers';
import { Input, ChooseBox, Button, Alert, Icon, Tooltip } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounceEvent, getImageFullPath } from 'helpers';

import './_ExecutorGeneral.scss';

const ExecutorGeneral = inject('LanguageStore', 'CabinetStore', 'UserStore')(observer(({ UserStore, CabinetStore, LanguageStore }) => {
    const [imageUploadModalOpened, setImageUploadModalOpened] = useState(false);
    const [newProfessionModalOpened, setNewProfessionModalOpened] = useState(false);
    const [newProfessionModalDetailedOpened, setNewProfessionModalDetailedOpened] = useState(false);

    const { cabinet, subscriptions, updateCabinetSection } = CabinetStore;
    const { language } = LanguageStore;
    const { user } = UserStore;
    
    const { discounts } = user;
    const profile = cabinet.personalData ? cabinet.personalData.profile : null;
    const profileErrors = (cabinet.errors && cabinet.errors.profile) || {};

    const specialties = cabinet.personalData ? cabinet.personalData.speciality: null;

    // Открытие детанольно модального окна и закрытие обычного
    const openNewProfessionModalDetailed = () => {
        setNewProfessionModalOpened(false);
        setNewProfessionModalDetailedOpened(true);
    };

    const newProfessionModalActionButtonProps = {
        text: `${translate('Select manually', language)}...`,
        restProps: {
            onClick: openNewProfessionModalDetailed
        }
    }

    // Изменение основной информации о пользователе
    const changeUserInfo = debounceEvent(event => {
        const input = event.target;
        const name = input.name;
        const value = input.value.trim();

        const requestObj = {
            [name]: value
        };

        if (language === 'en-US') {
            requestObj[name.replace('ru-RU', 'en-US')] = value;
        }

        updateCabinetSection('profile', requestObj);
    }, 350);

    // Удаление фотографии кабинета
    const removePhoto = () => {
        updateCabinetSection('profile', { photo: null });
    };

    // Изменение фотографии кабинета
    const changeCabinetPhoto = link => {
        updateCabinetSection('profile', { photo: link });
    };

    // Показывать все професии вместе
    const showAllProfessions = event => {
        updateCabinetSection('profile', { specialityShowTogether: event.target.checked });
    };

    // Открыть модальное окно для добавления профессий
    const openAddNewProfessionModal = () => {
        setNewProfessionModalOpened(true);
    };
    
    return profile && (
        <CabinetSection id = 'general' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('General', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'ExecutorGeneral'>
                    <div className = 'ExecutorGeneral__info'>
                        <div className = 'ExecutorGeneral__infoInputs CabinetSection__container'>
                            <Input label = { translate('PersonName', language) }
                                   name = 'name-ru-RU'
                                   defaultValue = { profile['name-ru-RU'] }
                                   error = { profileErrors['name-ru-RU'] }
                                   required = { true }
                                   onInput = { changeUserInfo } />

                            <Input label = { translate('Surname', language) }
                                   name = 'surname-ru-RU'
                                   defaultValue = { profile['surname-ru-RU'] }
                                   error = {  profileErrors['surname-ru-RU'] }
                                   required = { true }
                                   onInput = { changeUserInfo } />

                            { language === 'ru-RU' && (
                                <>
                                    <Input label = { translate('Name (for English version)', language) }
                                           name = 'name-en-US'
                                           defaultValue = { profile['name-en-US'] }
                                           onInput = { changeUserInfo } />

                                    <Input label = { translate('Surname (for English version)', language) }
                                           name = 'surname-en-US'
                                           defaultValue = { profile['surname-en-US'] }
                                           onInput = { changeUserInfo } />
                                </>
                            ) }
                        </div>

                        <div className = 'ExecutorGeneral__img'>
                            <ImageWithCropper src = { getImageFullPath(profile.photo) }
                                              modalOpened = { imageUploadModalOpened }
                                              setModalOpened = { setImageUploadModalOpened }
                                              size = 'md'
                                              requirements = {{ minWidth: 400, minHeight: 400 }}
                                              settings = {{ minCropBoxWidth: 50, minCropBoxHeight: 50, aspectRatio: 1 / 1 }}
                                              bgImg = 'mountain'
                                              buttonText = { translate('Change photo', language) }
                                              onImageLoadOnServer = { changeCabinetPhoto }
                                              onRemoveClick = { removePhoto } />
                        </div>
                    </div>

                    { (specialties && specialties.length !== 0) && (
                        <div className = 'ExecutorGeneral__specialties'>
                            <CabinetSection sub = { true }>
                                <div className = 'CabinetSection__title'>
                                    <span className = 'CabinetSection__titleText'>{ translate('Specialties', language) }</span>

                                    { specialties.length > 1 && (
                                        <>
                                            <ChooseBox type = 'checkbox'
                                                       checked = { profile.specialityShowTogether }
                                                       text = { translate('Display all specialties in profile together', language) }
                                                       onChange = { showAllProfessions } />

                                            <Tooltip text = { translate('If you disable the settings, Your profile will display only the profession through which You will be found in the search', language) }
                                                     direction = 'down'
                                                     position = 'left'
                                                     wrapClassName = 'ExecutorGeneral__specialtiesTooltip'>
                                                <Icon name = 'question' className = 'ExecutorGeneral__specialtiesIcon' />
                                            </Tooltip>
                                        </>
                                    ) }
                                </div>

                                <div className = 'CabinetSection__content'>
                                    <SpecialtiesTable items = { specialties } />
                                </div>
                            </CabinetSection>
                        </div>
                    ) }

                    { subscriptions.executor === null && subscriptions.studio === null && (
                        <div className = 'ExecutorGeneral__firstBuy'>
                            <div className = 'ExecutorGeneral__firstBuyInfo'>
                                <span className = 'ExecutorGeneral__firstBuyTitle'>
                                    { translate('Display on the site and in the search for the first 50 days — 1 ruble.', language) }
                                </span>

                                <Button content = {{ text: translate('Add specialty', language) }}
                                        className = 'ExecutorGeneral__firstBuyButton'
                                        theme = 'blue'
                                        onClick = { openAddNewProfessionModal } />
                            </div>

                            <Alert text = { translate('To work properly, you need to add and pay for at least one specialty', language) }
                                   className = 'ExecutorGeneral__firstBuyAlert'
                                   padding = { false }
                                   bordered = { false } />
                        </div>
                    ) }
                </div>
            </div>

            { (subscriptions.executor || subscriptions.studio) && (
                <div className = 'CabinetSection__actions'>
                    <div className = 'CabinetSection__actionsLeft'>
                        <Button content = {{ icon: 'plus-in-circle', text: translate('Add specialty', language) }}
                                className = 'ExecutorGeneral__addSpeciality'
                                theme = 'grey'
                                pure = { true }
                                disabled = { specialties && specialties.length === 5 }
                                onClick = { openAddNewProfessionModal } />

                        { discounts[user.activeCabinet] !== 0 && (
                            <Alert text = { `${discounts[user.activeCabinet]}% ${translate(discounts[user.activeCabinet] === 50 ? 'Discount on additional speciality' : 'Discount on speciality', language).toLowerCase()}` }
                                   padding = { false }
                                   bordered = { false } />
                        ) }
                    </div>
                </div>
            ) }

            { newProfessionModalOpened && (
                <NewProfessionModal modalOpened = { newProfessionModalOpened }
                                    selectActionButton = { newProfessionModalActionButtonProps }
                                    setModalOpened = { setNewProfessionModalOpened } />
            ) }

            { newProfessionModalDetailedOpened && (
                <NewProfessionDetailedModal modalOpened = { newProfessionModalDetailedOpened } setModalOpened = { setNewProfessionModalDetailedOpened } />
            ) }
        </CabinetSection>
    )
}));

export { ExecutorGeneral };
