import React, { useState, useCallback } from 'react';

import { CabinetSection, NewAgentModal, RemoveAgentModal } from 'components/containers';
import { SelectPure, AgentCard } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate, debounce, getAgentName } from 'helpers';

import './_ExecutorAgents.scss';

const ExecutorAgents = inject('LanguageStore', 'CabinetStore')(observer(({ LanguageStore, CabinetStore }) => {
    const [selectInput, setSelectInput] = useState('');
    const [newAgentModalOpened, setNewAgentModalOpened] = useState(false);
    const [removeAgentModalOpened, setRemoveAgentModalOpened] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);

    const { language } = LanguageStore;
    const { cabinet, updateCabinetSection, searchInCabinet, deleteFromCabinetSection } = CabinetStore;

    const agents = cabinet.personalData ? cabinet.personalData.agents : null;

    const agentSections = (agents && cabinet.search && cabinet.search.agentList) && [{ 
        id: 0, 
        title: translate('Agents', language),
        items: cabinet.search.agentList.map(agent => ({
            id: agent.id,
            text: getAgentName(agent, language),
            selected: Boolean(agents.find(agentItem => agentItem.agentId === agent.id)),
            data: agent
        }))  
    }];

    const selectInputProps = {
        label: translate('New agent', language),
        value: selectInput
    };

    const selectActionProps = {
        text: translate('Invite a new agent', language),
        restProps: {
            onClick: setNewAgentModalOpened.bind(null, true)
        }
    };
    
    const findAgent = useCallback(
        debounce(value => {
            const preparedValue = value === '' ? '*' : value;

            searchInCabinet('agent', 'personalData', 'profile', 'agentList', { 
                'name': preparedValue,
                'surname': preparedValue,
                'name_en': preparedValue,
                'surname_en': preparedValue,
                'company': preparedValue,
                'company_en': preparedValue
            });
        }, 350)
    , []);

    // Отлов фокуса на поле ввода
    const handleInputFocus = () => {
        searchInCabinet('agent', 'personalData', 'profile', 'agentList', { 
            'name': '*',
            'surname': '*',
            'name_en': '*',
            'surname_en': '*',
            'company': '*',
            'company_en': '*'
        });
    };

    // Отлов ввода в поле ввода
    const handleInputChange = event => {
        const input = event.target;
        const value = input.value;

        setSelectInput(value);
        findAgent(value.trim());
    };

    // Отлов изменения полей выбора
    const handleChooseBoxChange = event => {
        const chooseBox = event.target;
        const name = chooseBox.name;
        const checked = chooseBox.checked; 

        const agentIndex = JSON.parse(chooseBox.closest('.AgentCard').dataset.agentIndex);
        const agent = agents[agentIndex];

        updateCabinetSection('agents', { id: agent.id, agentId: agent.agentId, [name]: checked });
    };

    // Установка индекса агента для удаления
    const handleRemoveClick = index => {
        setCurrentIndex(index);
        setRemoveAgentModalOpened(true);
    };

    // Удаление агента
    const removeAgent = index => {
        const agent = agents[index];
        
        deleteFromCabinetSection('agents', agent.id, index)
            .then(() => setRemoveAgentModalOpened(false));
    };

    // Добавление агента
    const addAgent = (profile, direction) => {
        const isAgentExist = Boolean(agents.find(agent => agent.agentId === profile.id));
        
        if (!isAgentExist) {
            updateCabinetSection('agents', { agentId: profile.id, show: false, crossLink: false, direction });
        }
    };

    // Отлов клика по элементу списка
    const handleSelectDropdownItemClink = event => {
        const dropdownItem = event.target.closest('.SelectDropdown__sectionItem');
        const data = JSON.parse(dropdownItem.dataset.data);
        
        addAgent(data);
    };

    // Выбор направления у агента
    const changeAgentDirection = (agent, event) => {
        const radio = event.target;
        const value = radio.value;

        updateCabinetSection('agents', { id: agent.id, agentId: agent.agentId, direction: value });
    };

    return agents && (
        <CabinetSection id = 'agents' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Agents', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'ExecutorAgents'>
                    { agents.length > 0 && (
                        <div className = 'ExecutorAgents__list'>
                            { agents.map((agentItem, index) => (
                                <AgentCard key = { index }
                                           data-agent-index = { index }
                                           agent = { agentItem }
                                           onRemoveClick = { handleRemoveClick.bind(null, index) }
                                           onChooseBoxChange = { handleChooseBoxChange }
                                           changeAgentDirection = { changeAgentDirection.bind(null, agentItem) } />
                            )) }
                        </div>
                    ) }
                    
                    <div className = 'ExecutorAgents__select'>
                        <SelectPure inputProps = { selectInputProps } 
                                    items = { agentSections }
                                    actionButton = { selectActionProps }
                                    disabled = { true }
                                    onInputFocus = { handleInputFocus }
                                    onInputChange = { handleInputChange }
                                    onDropdownItemClick = { handleSelectDropdownItemClink } />
                    </div>

                    { newAgentModalOpened && (
                        <NewAgentModal modalOpened = { newAgentModalOpened } 
                                       setModalOpened = { setNewAgentModalOpened }
                                       onInvite = { addAgent } />
                    ) }

                    { removeAgentModalOpened && (
                        <RemoveAgentModal modalOpened = { removeAgentModalOpened } 
                                          setModalOpened = { setRemoveAgentModalOpened }
                                          removeAgent = { removeAgent.bind(null, currentIndex) } />
                    ) }
                </div>
            </div>
        </CabinetSection>
    )
}));

export { ExecutorAgents };