// Глобальное хранилище
import { observable, decorate } from 'mobx';

import UserStore from './UserStore';
import LoadingStore from './LoadingStore';
import LanguageStore from './LanguageStore';
import CabinetStore from './CabinetStore';
import ToolsStore from './ToolsStore';
import GeolocationStore from './GeolocationStore';
import GuildsStore from './GuildsStore';
import UniversityStore from './UniversityStore';
import WorksStore from './WorksStore';
import ProfessionsStore from './ProfessionsStore';
import AdDirectionsStore from './AdDirectionsStore';
import GenresStore from './GenresStore';
import FestivalStore from './FestivalStore';
import AgentExecutorsStore from './AgentExecutorsStore';
import CompanyStore from './CompanyStore';
import MusicStore from './MusicStore';
import InvoicesStore from './InvoicesStore';
import CouponsStore from './CouponsStore';

class RootStore {
    stores = {
        userStore: UserStore,
        loadingStore: LoadingStore,
        languageStore: LanguageStore,
        cabinetStore: CabinetStore,
        toolsStore: ToolsStore,
        geolocationStore: GeolocationStore,
        guildsStore: GuildsStore,
        universityStore: UniversityStore,
        worksStore: WorksStore,
        professionsStore: ProfessionsStore,
        adDirectionsStore: AdDirectionsStore,
        genresStore: GenresStore,
        festivalStore: FestivalStore,
        agentExecutorsStore: AgentExecutorsStore,
        companyStore: CompanyStore,
        musicStore: MusicStore,
        invoicesStore: InvoicesStore,
        couponsStore: CouponsStore
    };

    setRootStoretoStores() {
        Object.keys(this.stores).map(key => this.stores[key].root = this);
    }
}

decorate(RootStore, {
    stores: observable
});

const rootStore = new RootStore();
rootStore.setRootStoretoStores();

export default rootStore;