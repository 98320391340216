import React from 'react';

import { BookmarksCompaniesItem } from 'components/pages';
import { CabinetAccordionList, CabinetAccordion, CabinetPreloader } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_BookmarksCompanies.scss';

const BookmarksCompanies = inject('LanguageStore')(observer(({ companiesFolders, isLoading, LanguageStore }) => {
    const { language } = LanguageStore;

    return (
        <div className = 'BookmarksCompanies'>
            <CabinetAccordionList className = 'Bookmarks__accordionList'>
                { companiesFolders.map(folder => {
                    const title = folder.folderName;
                    const cabinets = folder.cabinets;
                    const text = `${translate('CompaniesBookmarks', language)} (${cabinets.length})`;
                    
                    return (
                        <CabinetAccordion key = { folder.id } title = { title } text = { text }>
                            <div className = 'BookmarksCompanies__accordionList'>
                                { cabinets.map(cabinet => (
                                    <BookmarksCompaniesItem key = { cabinet.id } cabinet = { cabinet } />
                                )) }
                            </div>
                        </CabinetAccordion>
                    )
                }) }
            </CabinetAccordionList>

            { companiesFolders.length === 0 && (
                <Alert text = { translate('You don\'t have companies in your bookmarks', language) } />
            ) }

            <CabinetPreloader loading = { isLoading } />
        </div>
    )
}));

export { BookmarksCompanies };