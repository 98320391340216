import React from 'react';

import { CabinetSection } from 'components/containers';
import { TextArea } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate, debounceEvent } from 'helpers';

import './_FilmSchoolMoreInfo.scss'

const FilmSchoolMoreInfo = inject('LanguageStore', 'CabinetStore')(observer(({ LanguageStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { cabinet, updateCabinetSection } = CabinetStore;

    const info = cabinet.personalData ? cabinet.personalData.info : null;

    const changeDescription = debounceEvent(event => {
        const textarea = event.target;
        const name = textarea.name;
        const value = textarea.value.trim();
        const newInfo = { [name]: value };

        if (language === 'en-US') {
            newInfo['description-en-US'] = value;
        }

        updateCabinetSection('info', newInfo);
    }, 350);

    return info && (
        <CabinetSection id = 'extraInfo' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Extra info', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'CabinetSection__container'>
                    <div className = 'FilmSchoolMoreInfo'>
                        <TextArea label = { translate('Tell us about yourself', language) }
                                  name = 'description-ru-RU'
                                  defaultValue = { info['description-ru-RU'] }
                                  onChange = { changeDescription } />

                        { language !== 'en-US' && (
                            <TextArea label = { translate('Tell us about yourself in English', language) }
                                      name = 'description-en-US'
                                      defaultValue = { info['description-en-US'] } 
                                      onChange = { changeDescription } />
                        ) }
                    </div>
                </div>
            </div>
        </CabinetSection>
    )
}));

export { FilmSchoolMoreInfo };