import React from 'react';

import { WorkActions, WorkLightingStyleList } from 'components/pages';
import { CabinetContent } from 'components/containers';

const WorkLightingStyle = () => (
    <div className = 'WorkLightingStyle'>
        <CabinetContent>
            <WorkLightingStyleList />
            <WorkActions />
        </CabinetContent>
    </div>
);

export { WorkLightingStyle };