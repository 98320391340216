import React from 'react';

import classNames from 'classnames';

import './_CabinetSection.scss';

const CabinetSection = ({ id, padding, bgColor, bordered, sub, children }) => {
    const CabinetSectionClass = classNames('CabinetSection', {
        'CabinetSection--with-padding': padding,
        'CabinetSection--bg-color-white': bgColor === 'white',
        'CabinetSection--bordered': bordered,
        'CabinetSection--sub': sub
    });

    return (
        <section className = { CabinetSectionClass } id = { id }>
            { children }
        </section>
    )
};

export { CabinetSection };