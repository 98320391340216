import React, { useState } from 'react';

import { TabsListsNav, TabsListsClients, TabsListsSpecialSkills, TabsListsCommunication, TabsListsAwards } from 'components/pages';

import './_TabsLists.scss';

const TabsLists = ({ tabs }) => {
    const [currentTab, setCurrentTab] = useState('clients');

    const { clients, skills, links, fest } = tabs;

    // Отлов клика по элементу навигации
    function changeTab(tabName) {
        setCurrentTab(tabName);
    }

    return (
        <div className = 'TabsLists'>
            <TabsListsNav tabs = { tabs } currentTab = { currentTab } onTabClick = { changeTab } />

            { currentTab === 'clients' && <TabsListsClients items = { clients.items } /> }
            { currentTab === 'skills' && <TabsListsSpecialSkills items = { skills.items } /> }
            { currentTab === 'links' && <TabsListsCommunication items = { links.items } /> }
            { currentTab === 'fest' && <TabsListsAwards items = { fest.items } /> }
        </div>
    )
};

export { TabsLists };