import React from 'react';

import { CabinetSection } from 'components/containers';
import { ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_WorkBudgetsInfo.scss';

const WorkBudgetsInfo = inject('LanguageStore', 'WorksStore')(observer(({ LanguageStore, WorksStore }) => {
    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal } = WorksStore;

    const { budget } = currentWork;
    const { noSelect, low, middle, high } = budget;

    const radioBoxes = {
        'range': ['noSelect', 'low', 'middle', 'high']
    };

    const rangeTexts = {
        fullLengthArtMovie: {
            low: 'Low (1-30 million rubles)',
            middle: 'Average (30-50 million rubles)', 
            high: 'Large (from 50 million rubles)'
        },

        commercialAdvertising: {
            low: 'Low (0.5-1 million rubles)',
            middle: 'Average (1-5 million rubles)', 
            high: 'Large (from 5 million rubles)'
        },
    };

    // Выбор radio
    const chooseRadio = event => {
        const radio = event.target;
        const name = radio.name;
        const value = radio.value;

        const otherRadios = radioBoxes[name].filter(radioBox => radioBox !== value);

        const workBudgetCopy = Object.clone(budget);
        workBudgetCopy[value] = true;

        otherRadios.forEach(otherRadio => workBudgetCopy[otherRadio] = false);
        
        updateCurrentWorkLocal({ budget: workBudgetCopy });
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Budgets', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'WorkBudgetsInfo'>
                    <span className = 'WorkBudgetsInfo__text'>
                        { translate('Below are the figures related to the General practice of Russian film and video production. In international production, you can choose one of three General concepts as close to the pricing of your country', language) }
                    </span>

                    <div className = 'WorkBudgetsInfo__options'>
                        <ChooseBox type = 'radio'
                                   name = 'range'
                                   text = { translate('Not selected', language) }
                                   value = 'noSelect'
                                   checked = { noSelect }
                                   onChange = { chooseRadio } />

                        <ChooseBox type = 'radio'
                                   name = 'range'
                                   text = { translate(rangeTexts[currentWork.workType].low, language) }
                                   value = 'low'
                                   checked = { low }
                                   onChange = { chooseRadio } />

                        <ChooseBox type = 'radio'
                                   name = 'range'
                                   text = { translate(rangeTexts[currentWork.workType].middle, language) }
                                   value = 'middle'
                                   checked = { middle }
                                   onChange = { chooseRadio } />

                        <ChooseBox type = 'radio'
                                   name = 'range'
                                   text = { translate(rangeTexts[currentWork.workType].high, language) }
                                   value = 'high'
                                   checked = { high }
                                   onChange = { chooseRadio } />
                    </div>
                </div>
            </div>
        </CabinetSection> 
    )
}));

export { WorkBudgetsInfo };