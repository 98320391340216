import React, { useState, Fragment } from 'react';

import { RequestsListItemResult } from 'components/pages';
import { SearchType } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_RequestsListItem.scss';

const RequestsListItem = inject('LanguageStore')(observer(({ request, LanguageStore }) => {
    const [isOpened, setIsOpened] = useState(false);

    const { language } = LanguageStore;

    const { status, searchType, comment, managerComment, disclose, result, createdAt } = request;

    const prettyParams = request[`prettyParams-${language}`];
    const prettyParamsNames = Object.keys(prettyParams);

    const RequestsListItemClass = classNames('RequestsListItem', {
        'RequestsListItem--in-work': status === 'in_work',
        'RequestsListItem--ready': status === 'done',
        'RequestsListItem--opened': isOpened
    });

    const readyCount = result.executors.length + result.studios.length;

    const statuses = {
        'in_work': `— ${translate('In work', language)}`,
        'done': `— ${translate('ReadyPerson', language)} (${readyCount})`
    };

    const discloseText = disclose ? 'Do not disclose the applicant' : 'Disclose the applicant';

    const buttonText = isOpened ? `${translate('Roll up', language)} ↑` : `${translate('Disclose', language)} ↓`;

    // Показ контента
    function showContent() {
        setIsOpened(prevIsOpened => !prevIsOpened);
    }

    // Получение фильтрованных параметров
    function preparePrettyParams(params) {
        const paramsArr = [].concat(params);
        const filteredParams = paramsArr.filter(param => param && param !== 'no-selected');
        const prettyParams = filteredParams.length > 0 ? filteredParams.join(', ') : '—';

        return prettyParams;
    }

    return (
        <div className = { RequestsListItemClass }>
            <div className = 'RequestsListItem__header'>
                <span className = 'RequestsListItem__title'>
                    { `${translate('A request from', language)} ${createdAt}` }
                </span>

                <span className = 'RequestsListItem__status'>
                    { statuses[status].toLowerCase() }
                </span>
            </div>

            <div className = 'RequestsListItem__body'>
                { comment && (
                    <div className = 'RequestsListItem__comment'>
                        <span className = 'RequestsListItem__commentTitle'>
                            { translate('Additional comment', language) }
                        </span>

                        <span className = 'RequestsListItem__commentText'>
                            { comment }
                        </span>
                    </div>
                ) }

                { managerComment && (
                    <div className = 'RequestsListItem__comment RequestsListItem__comment--manager'>
                        <span className = 'RequestsListItem__commentTitle'>
                            { translate('Manager\'s comment', language) }
                        </span>

                        <span className = 'RequestsListItem__commentText'>
                            { managerComment }
                        </span>
                    </div>
                ) }
                
                <div className = 'RequestsListItem__searchType'>
                    <SearchType type = { searchType } />
                </div>

                { prettyParamsNames.length > 0 && (
                    <div className = 'RequestsListItem__params'>
                        { prettyParamsNames.map((name, index) => (
                            <Fragment key = { index }>
                                <span className = 'RequestsListItem__paramsName'>
                                    { name }:
                                </span>

                                <span className = 'RequestsListItem__paramsValue'>
                                    { preparePrettyParams(prettyParams[name]) }
                                </span>
                            </Fragment>
                        )) }
                    </div>
                ) }

                <div className = 'RequestsListItem__disclose'>
                    { translate(discloseText, language) }
                </div>

                { (result.executors.length > 0 || result.studios.length > 0) && (
                    <div className = 'RequestsListItem__results'>
                        <span className = 'RequestsListItem__resultsTitle'>
                            { translate('Result', language) }
                        </span>

                        <div className = 'RequestsListItem__resultsList'>
                            { result.executors.map(resultItem => (
                                <RequestsListItemResult key = { resultItem.id } result = { resultItem } cabinetType = 'executor' />
                            )) }

                            { result.studios.map(resultItem => (
                                <RequestsListItemResult key = { resultItem.id } result = { resultItem } cabinetType = 'studio' />
                            )) }
                        </div>
                    </div>
                ) }
            </div>

            <div className = 'RequestsListItem__button' onClick = { showContent }>
                { buttonText }
            </div>
        </div>
    )
}));

export { RequestsListItem };