// Хранилище со счетами

import { decorate, action, observable, runInAction } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class InvoicesStore {
    invoices = []

    // Получение счетов
    async getInvoices() {
        const response = await request(`${BASE_URL}/api/user/invoices`);
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.invoices = data.result);
        }
    }

    // Обновление периода счета
    async updateInvoicePeriod(id, period) {
        const response = await request(`${BASE_URL}/api/user/invoices/update-period`, { method: 'POST', body: JSON.stringify({ id, period }) });
        const data = await response.json();

        if (response.status === 200) {
            const invoiceIndex = this.invoices.indexOf(this.invoices.find(invoice => invoice.id === id));
            const updatedInvoice = data.result[invoiceIndex];

            runInAction(() => this.invoices[invoiceIndex] = updatedInvoice);

            return updatedInvoice
        }
    }
}

decorate(InvoicesStore, {
    invoices: observable,
    getInvoices: action.bound,
    updateInvoicePeriod: action.bound
});

export default new InvoicesStore();
