import React, { useState } from 'react';
import { withRouter, NavLink } from 'react-router-dom';

import { UnsavedDataModal } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, prepareWorkModePath } from 'helpers';
import { routeNames } from 'routes';
import classNames from 'classnames';

import './_WorkAside.scss';

const WorkAside = inject('LanguageStore', 'CabinetStore', 'UserStore')(observer(withRouter(({ location, routes, currentWork, history, LanguageStore, CabinetStore, UserStore }) => {
    const [unsavedDataModalOpened, setUnsavedDataModalOpened] = useState(false);

    const { language } = LanguageStore;
    const { cabinet } = CabinetStore;
    const { user } = UserStore;

    const { fieldComplete, updated } = currentWork;
    const { personalData } = cabinet;

    const fieldNames = {
        '/general': 'fields',
        '/characteristics': 'characteristics',
        '/preparation': 'prepare',
        '/backstage': 'backstage',
        '/skills': 'skils',
        '/additional-equipment': 'optionalEquip',
        '/festivals-and-awards': 'fest',
        '/budgets': 'budget',
        '/locations-time-weather': 'location',
        '/image-property': 'imageProp',
        '/lighting-and-style': 'light',
        '/team': 'team'
    };

    // Получение доступных секций
    const getAvaibleRoutes = () => {
        const professions = user.activeCabinet === 'executor' ? personalData.speciality : personalData.direction;

        if (currentWork.fields && currentWork.fields.profession && professions) {
            const currentWorkProfessionsId = currentWork.fields.profession;
            const chosenProfessions = professions.map(item => item.profession).filter(item => currentWorkProfessionsId.includes(item.id));
            const choosenProfessionsPermissions = [...new Set(chosenProfessions.map(item => item.permission).flat())];
            
            const isHard = !!choosenProfessionsPermissions.find(item => item === 'hard');
            const isMedium = !!choosenProfessionsPermissions.find(item => item === 'medium');
           
            if (isHard) return routes;
            if (isMedium) return routes.slice(0, 2);

            return routes.slice(0, 1);
        }

        return routes.slice(0, 1);
    };

    // Переход на страницу работ
    const goToWorksPage = () => history.push('/works');

    // Проверка несохраненных данных
    const checkUnsavedData = () => {
        if (currentWork.mode === 'edit' && updated.length !== 0) {
            setUnsavedDataModalOpened(true);
        } else {
            goToWorksPage();
        }
    };

    const availableRoutes = cabinet.personalData ? getAvaibleRoutes() : routes;
   
    return (
        <aside className = 'WorkAside'>
            <div className = 'WorkAside__wrap'>
                <nav className = 'WorkAside__nav'>
                    <div className = 'WorkAside__navLink WorkAside__navLink--size-lg' onClick = { checkUnsavedData }>
                        <div className = 'WorkAside__navItem'>
                            <span className = 'WorkAside__navItemText'>← { translate('To works', language) }</span>
                        </div>
                    </div>

                    { routes.map((section, index) => {
                        const sectionClass = classNames('WorkAside__navSection', {
                            'WorkAside__navSection--theme-orange': section.color === 'orange',
                            'WorkAside__navSection--theme-green': section.color === 'green',
                            'WorkAside__navSection--theme-purple': section.color === 'purple',
                            'WorkAside__navSection--disabled': (currentWork.mode === 'add' && index !== 0) || (currentWork.isOtherUserWork === true && index !== 0) || !availableRoutes.includes(section)
                        });

                        return (
                            <div className = { sectionClass } key = { section.name }>
                                <div className = 'WorkAside__navTitle'>{ translate(section.name, language) }</div>

                                <ul className = 'WorkAside__navList'>
                                    { section.items.map(route => {
                                        const WorkAsideNavLinkClass = classNames('WorkAside__navLink', {
                                            'WorkAside__navLink--disabled': (currentWork.mode === 'add' && route.path !== '/general') || (currentWork.isOtherUserWork=== true && (route.path !== '/general' && route.path !== '/team')) || !availableRoutes.includes(section)
                                        });

                                        const preparedPath = prepareWorkModePath(location, route);
                                        const budgetStatement = route.apiName === 'budgets' && !['fullLengthArtMovie', 'commercialAdvertising'].includes(currentWork.workType);
                                        
                                        return !budgetStatement && (
                                            <NavLink to = { preparedPath } 
                                                     key = { route.path } 
                                                     className = { WorkAsideNavLinkClass } 
                                                     activeClassName = 'WorkAside__navLink--active' 
                                                     exact = { route.exact || false }>
                                                <li className = { `WorkAside__navItem` }>
                                                    <span className = 'WorkAside__navItemNotification'></span>

                                                    <span className = 'WorkAside__navItemText'>
                                                        { translate(routeNames[`/works/mode${route.path}`], language) }
                                                    </span>

                                                    { fieldComplete && fieldComplete.includes(fieldNames[route.path]) && (
                                                        <Icon name = 'tick' className = 'WorkAside__navItemTick' />
                                                    ) }
                                                </li>
                                            </NavLink>
                                        )
                                    }) }
                                </ul>
                            </div>
                        )
                    }) }
                </nav>
            </div>

            { unsavedDataModalOpened && (
                <UnsavedDataModal modalOpened = { unsavedDataModalOpened }
                                  setModalOpened = { setUnsavedDataModalOpened }
                                  onGoClick = { goToWorksPage } />
            ) }
        </aside>
    )
})));

export { WorkAside };