import React, { useEffect, useState } from 'react';

import { SearchesList } from 'components/pages';
import { CabinetContent } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_Searches.scss';

const Searches = inject('LanguageStore', 'LoadingStore', 'SearchesStore')(observer(({ LanguageStore, LoadingStore, SearchesStore }) => {
    const { language } = LanguageStore;
    const { setLoading } = LoadingStore;
    const { getSearches, searches } = SearchesStore; 

    const [isLoading, setIsLoading] = useState(searches.length === 0);

    useEffect(() => {
        if (searches.length === 0) {
            setLoading('mainContent', true);

            getSearches()
                .then(() => {
                    setIsLoading(false);
                    setLoading('mainContent', false)
                });

            return () => {
                setLoading('mainContent', false);
            }
        } else {
            getSearches();
        }
    }, []);

    return (
        <div className = 'Searches'>
            { !isLoading && (
                <CabinetContent>
                    <div className = 'Searches__contentWrap'>
                        <div className = 'Searches__title'>
                            { translate('Searches', language) }
                        </div>

                        { searches.length > 0 ? (
                            <SearchesList searches = { searches } />
                        ) : (
                            <Alert text = { translate('You don\'t have saved searches yet', language) } />
                        ) }
                    </div>
                </CabinetContent>
            ) }
        </div>
    )
}));

export { Searches };