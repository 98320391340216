import React, { useState, useEffect, useRef } from 'react';

import { Icon } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_FilterSelect.scss';

const FilterSelect = inject('LanguageStore')(observer(({ type = 'text', id, name, items, current, size = 'md', disabled, single, isChosen, onChoose, LanguageStore, ...restProps }) => {
    const [opened, setOpened] = useState(false);
    const [chosen, setChosen] = useState(false);

    const filterSelectRef = useRef(null);

    const { language } = LanguageStore;

    const FilterSelectClass = classNames('FilterSelect', {
        'FilterSelect--size-md': size === 'md',
        'FilterSelect--size-lg': size === 'lg',
        'FilterSelect--type-social': type === 'social',
        'FilterSelect--type-phone': type === 'phone',
        'FilterSelect--opened': opened,
        'FilterSelect--chosen': (chosen || isChosen) && type !== 'social',
        'FilterSelect--disabled': disabled
    });

    // Открытие и закрытие выпадающиего списка
    const openDropdown = event => {
        const filterSelectCurrent = event.target.closest('.FilterSelect__current');

        if (filterSelectCurrent && filterSelectCurrent.closest('.FilterSelect') === filterSelectRef.current) {
            setOpened(prevState => !prevState);
        } else {
            setOpened(false);
        }
    };

    // Выбор элемента
    const chooseItem = event => {
        const item = event.target.closest('.FilterSelect__item');
        
        if (item) {
            const id = JSON.parse(item.dataset.id);
            const newItem = items.find(item => item.id === id);

            const parent = item.closest('.FilterSelect');
            const parentId = +parent.dataset.id;
            const parentName = parent.dataset.name;
            
            onChoose && onChoose({ parentName, parentId, item: newItem });

            if (isChosen === undefined) {
                setChosen(true);
            }
        }
    };

    // Одиночный выбор
    const chooseItemSingle = event => {
        const filterCurrent = event.currentTarget;

        const parent = filterCurrent.closest('.FilterSelect');
        const parentName = parent.dataset.name;
        const parentId = +parent.dataset.id;

        onChoose && onChoose({ parentName, parentId, item: { value: current } });

        if (isChosen === undefined) {
            setChosen(true);
        }
    };

    // Открытие и закрытие выпадающиего списка по клику на window
    useEffect(() => {
        if (!single) {
            window.addEventListener('click', openDropdown);

            return () => {
                window.removeEventListener('click', openDropdown);
                filterSelectRef.current = null;
            }
        }
    }, []);

    return (
        <div className = { FilterSelectClass } ref = { filterSelectRef } data-id = { id } data-name = { name } { ...restProps }>
            <div className = 'FilterSelect__current' onClick = { single && chooseItemSingle }>
                <span className = 'FilterSelect__currentData'>
                    { type === 'text' && (
                        ( single ? translate(current, language) : translate(current.value, language) )
                    ) }

                    { type === 'phone' &&  (
                        <>
                            <span className = 'FilterSelect__currentPhoneCountry'>
                                { language === 'ru-RU' ? current.country : current.country_en }
                            </span>
                            
                            { current.code }
                        </>
                    ) }

                    { type === 'social' &&  (
                        <>
                            <Icon name = { `${current.type}-small` } />
                        </>
                    ) }
                </span>
                
                { !single && (
                    <Icon name = 'angle' className = 'FilterSelect__currentIcon' />
                ) }
            </div>
            
            { !single && (
                <div className = 'FilterSelect__dropdown' onClick = { chooseItem }>
                    { items.map(item => (
                        <span key = { item.id } className = 'FilterSelect__item' data-id = { item.id }>
                            { type === 'text' && translate(item.value, language) }

                            { type === 'phone' &&  (
                                <>
                                    { language === 'ru-RU' ? item.country : item.country_en } <br/> { item.code }
                                </>
                            ) }

                            { type === 'social' && (
                                <>
                                    <Icon name = { `${item.type}-small` } className = 'FilterSelect__socialIcon' />
                                    <span className = 'FilterSelect__socialType'>{ item.type.toTitleCase() }</span>
                                </>
                            ) }
                        </span>
                    )) }
                </div>
            ) }
        </div>
    )
}));

export { FilterSelect };