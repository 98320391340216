import React, { useState, useEffect } from 'react';

import { TabsLists } from 'components/pages';
import { CabinetContent } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_Tabs.scss';

const Tabs = inject('LanguageStore', 'TabsStore', 'LoadingStore')(observer(({ LanguageStore, TabsStore, LoadingStore }) => {
    const { language } = LanguageStore;
    const { tabs, getTabs } = TabsStore;
    const { setLoading } = LoadingStore;
    
    const isTabsEmpty = Object.keys(tabs).every(name => tabs[name].items.length === 0);
    const [isLoading, setIsLoading] = useState(isTabsEmpty);

    // Запрос вкладок
    useEffect(() => {
        if (isTabsEmpty) {
            setLoading('mainContent', true);

            getTabs()
                .then(() => {
                    setIsLoading(false);
                    setLoading('mainContent', false)
                });

            return () => {
                setLoading('mainContent', false);
            }
        } else {
            getTabs();
        }
    }, []);
    
    return (
        <div className = 'Tabs'>
            { !isLoading && (
                <CabinetContent>
                    <div className = 'Tabs__contentWrap'>
                        <span className = 'Tabs__title'>
                            { translate('Information about the work', language) }
                        </span>

                        <div className = 'Tabs__alert'>
                            <Alert text = { translate('Collect information about your work in the profile tabs.', language) } />
                        </div>

                        <TabsLists tabs = { tabs } />
                    </div>
                </CabinetContent>
            ) }
        </div>
    )
}));

export { Tabs };