import React from 'react';

import { ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_TabsListsSpecialSkills.scss';

const TabsListsSpecialSkills = inject('LanguageStore', 'TabsStore')(observer(({ items, LanguageStore, TabsStore }) => {
    const { language } = LanguageStore;
    const { changeTabShow } = TabsStore;

    return items.length > 0 ? (
        <div className = 'TabsListsSpecialSkills'>
            { items.map(item => (
                <div key = { item.id } className = 'TabsListsSpecialSkills__item'>
                    <span className = 'TabsListsSpecialSkills__itemSkill'>
                        { item[`name-${language}`] || item.name }
                    </span>

                    <ChooseBox text = { translate('Show', language) }
                               checked = { item.show }
                               onChange = { changeTabShow.bind(null, { name: 'skills', equalFunc: skill => skill.id === item.id, id: item.id }) } />
                </div>
            )) }
        </div>
    ) : (
        <div className = 'TabsLists__empty'>
            { translate('You don\'t have special skills', language) }
        </div>
    )
}));

export { TabsListsSpecialSkills };