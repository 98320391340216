// Получение валидного имени для студии
export const getStudioName = (studio, language) => {
    const currentLanguageVersion = studio[`name-${language}`];

    if (!currentLanguageVersion && studio.userObj) {
        return studio.userObj.email
    }

    return currentLanguageVersion
};
