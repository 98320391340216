import React from 'react';

import { CabinetSection } from 'components/containers';
import { ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_WorkLocationTimeWeatherList.scss';

const WorkLocationTimeWeatherList = inject('LanguageStore', 'WorksStore')(observer(({ LanguageStore, WorksStore }) => {
    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal } = WorksStore;

    const { location } = currentWork;
    const { timeDay, timeNight, timeDawn, timeSunset } = location;
    const { interiorInInterior, interiorInNatural } = location;
    const { placeInWater, placeUnderWater, placeInAir, placeUnderground, placeSpace, placeOtherPlanet, placeAnotherReality } = location;
    const { placeTimeInPast, placeTimeInFuture, placeTimePresent, placeTimeInImagination } = location;

    const { placeClarificationHouse, placeClarificationHighRiseBuildin, placeClarificationCityMetropolis, placeClarificationInSuburbsVillage,
            placeClarificationGarden, placeClarificationField, placeClarificationForest, placeClarificationMountains, placeClarificationDesert,
            placeClarificationLake, placeClarificationRiver, placeClarificationWaterfall, placeClarificationSeaOcean, placeClarificationRoad,
            placeClarificationRails, placeClarificationBridge } = location;

    const { weatherSun, weatherCloud, weatherFog, weatherRain, weatherDew, weatherHail, weatherSnowSnowfal, weatherIceIcin,
            weatherStorm, weatherStrongWind, weatherHurricane, weatherBlizzard, weatherDrought, weatherFlood, weatherSmokeFir,
            weatherTornado, weatherSandstorm } = location;

    // Выбор checkbox
    const chooseCheckbox = event => {
        const choseBox = event.target;
        const name = choseBox.name;
        const checked = choseBox.checked;

        const workLocationCopy = Object.clone(location);
        workLocationCopy[name] = checked;

        updateCurrentWorkLocal({ location: workLocationCopy });
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Locations, time, weather', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'WorkLocationTimeWeatherList'>
                    <span className = 'WorkLocationTimeWeatherList__title'>
                        { translate('There are different stories and places of action, specify your special characteristics and properties of the story', language) }
                    </span>

                    <div className = 'WorkLocationTimeWeatherList__section WorkLocationTimeWeatherList__section--sm'>
                        <span className = 'WorkLocationTimeWeatherList__sectionTitle'>{ translate('Time of day', language) }</span>

                        <div className = 'WorkLocationTimeWeatherList__sectionContent'>
                            <ChooseBox name = 'timeDay'
                                       text = { translate('Day', language) }
                                       checked = { timeDay }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'timeNight'
                                       text = { translate('Night', language) }
                                       checked = { timeNight }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'timeDawn'
                                       text = { translate('Dawn', language) }
                                       checked = { timeDawn }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'timeSunset'
                                       text = { translate('Sunset', language) }
                                       checked = { timeSunset }
                                       onChange = { chooseCheckbox } />
                        </div>
                    </div>

                    <div className = 'WorkLocationTimeWeatherList__section WorkLocationTimeWeatherList__section--sm'>
                        <span className = 'WorkLocationTimeWeatherList__sectionTitle'>{ translate('Interior / Exterior', language) }</span>

                        <div className = 'WorkLocationTimeWeatherList__sectionContent'>
                            <ChooseBox name = 'interiorInInterior'
                                       text = { translate('Interior', language) }
                                       checked = { interiorInInterior }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'interiorInNatural'
                                       text = { translate('Exterior', language) }
                                       checked = { interiorInNatural }
                                       onChange = { chooseCheckbox } />
                        </div>
                    </div>

                    <div className = 'WorkLocationTimeWeatherList__section WorkLocationTimeWeatherList__section--lg'>
                        <span className = 'WorkLocationTimeWeatherList__sectionTitle'>{ translate('Special location', language) }</span>

                        <div className = 'WorkLocationTimeWeatherList__sectionContent'>
                            <ChooseBox name = 'placeInWater'
                                       text = { translate('On the water', language) }
                                       size = 'lg'
                                       checked = { placeInWater }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeUnderWater'
                                       text = { translate('Under water', language) }
                                       size = 'lg'
                                       checked = { placeUnderWater }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'placeInAir'
                                       text = { translate('In the air', language) }
                                       size = 'lg'
                                       checked = { placeInAir }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeUnderground'
                                       text = { translate('Under the ground', language) }
                                       size = 'lg'
                                       checked = { placeUnderground }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'placeSpace'
                                       text = { translate('In space', language) }
                                       size = 'lg'
                                       checked = { placeSpace }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeOtherPlanet'
                                       text = { translate('Other planet', language) }
                                       size = 'lg'
                                       checked = { placeOtherPlanet }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'placeAnotherReality'
                                       text = { translate('Another reality', language) }
                                       size = 'lg'
                                       checked = { placeAnotherReality }
                                       onChange = { chooseCheckbox } />
                        </div>
                    </div>

                    <div className = 'WorkLocationTimeWeatherList__section WorkLocationTimeWeatherList__section--lg'>
                        <span className = 'WorkLocationTimeWeatherList__sectionTitle'>{ translate('Action time', language) }</span>

                        <div className = 'WorkLocationTimeWeatherList__sectionContent'>
                            <ChooseBox name = 'placeTimeInPast'
                                       text = { translate('In the past', language) }
                                       size = 'lg'
                                       checked = { placeTimeInPast }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeTimeInFuture'
                                       text = { translate('In the future', language) }
                                       size = 'lg'
                                       checked = { placeTimeInFuture }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'placeTimePresent'
                                       text = { translate('Present', language) }
                                       size = 'lg'
                                       checked = { placeTimePresent }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeTimeInImagination'
                                       text = { translate('In the imagination', language) }
                                       size = 'lg'
                                       checked = { placeTimeInImagination }
                                       onChange = { chooseCheckbox } />
                        </div>
                    </div>

                    <div className = 'WorkLocationTimeWeatherList__section WorkLocationTimeWeatherList__section--lg'>
                        <span className = 'WorkLocationTimeWeatherList__sectionTitle'>{ translate('Clarification of the place of action', language) }</span>

                        <div className = 'WorkLocationTimeWeatherList__sectionContent'>
                            <ChooseBox name = 'placeClarificationHouse'
                                       text = { translate('House', language) }
                                       size = 'lg'
                                       checked = { placeClarificationHouse }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeClarificationHighRiseBuildin'
                                       text = { translate('Multistory building', language) }
                                       size = 'lg'
                                       checked = { placeClarificationHighRiseBuildin }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'placeClarificationCityMetropolis'
                                       text = { translate('City/metropolis', language) }
                                       size = 'lg'
                                       checked = { placeClarificationCityMetropolis }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeClarificationInSuburbsVillage'
                                       text = { translate('On nature/village', language) }
                                       size = 'lg'
                                       checked = { placeClarificationInSuburbsVillage }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'placeClarificationGarden'
                                       text = { translate('Garden', language) }
                                       size = 'lg'
                                       checked = { placeClarificationGarden }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeClarificationField'
                                       text = { translate('Field', language) }
                                       size = 'lg'
                                       checked = { placeClarificationField }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'placeClarificationForest'
                                       text = { translate('Forest', language) }
                                       size = 'lg'
                                       checked = { placeClarificationForest }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeClarificationMountains'
                                       text = { translate('Mountains', language) }
                                       size = 'lg'
                                       checked = { placeClarificationMountains }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeClarificationDesert'
                                       text = { translate('Desert', language) }
                                       size = 'lg'
                                       checked = { placeClarificationDesert }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'placeClarificationLake'
                                       text = { translate('Lake', language) }
                                       size = 'lg'
                                       checked = { placeClarificationLake }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeClarificationRiver'
                                       text = { translate('River', language) }
                                       size = 'lg'
                                       checked = { placeClarificationRiver }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'placeClarificationWaterfall'
                                       text = { translate('Waterfall', language) }
                                       size = 'lg'
                                       checked = { placeClarificationWaterfall }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeClarificationSeaOcean'
                                       text = { translate('Sea/ocean', language) }
                                       size = 'lg'
                                       checked = { placeClarificationSeaOcean }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'placeClarificationRoad'
                                       text = { translate('Road', language) }
                                       size = 'lg'
                                       checked = { placeClarificationRoad }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeClarificationRails'
                                       text = { translate('Rails', language) }
                                       size = 'lg'
                                       checked = { placeClarificationRails }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'placeClarificationBridge'
                                       text = { translate('Bridge', language) }
                                       size = 'lg'
                                       checked = { placeClarificationBridge }
                                       onChange = { chooseCheckbox } />
                        </div>
                    </div>

                    <div className = 'WorkLocationTimeWeatherList__section WorkLocationTimeWeatherList__section--lg'>
                        <span className = 'WorkLocationTimeWeatherList__sectionTitle'>{ translate('Weather and light conditions', language) }</span>

                        <div className = 'WorkLocationTimeWeatherList__sectionContent'>
                            <ChooseBox name = 'weatherSun'
                                       text = { translate('Sun', language) }
                                       size = 'lg'
                                       checked = { weatherSun }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'weatherCloud'
                                       text = { translate('Clouds', language) }
                                       size = 'lg'
                                       checked = { weatherCloud }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'weatherFog'
                                       text = { translate('Fog', language) }
                                       size = 'lg'
                                       checked = { weatherFog }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'weatherRain'
                                       text = { translate('Rain', language) }
                                       size = 'lg'
                                       checked = { weatherRain }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'weatherDew'
                                       text = { translate('Dew', language) }
                                       size = 'lg'
                                       checked = { weatherDew }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'weatherHail'
                                       text = { translate('Hail', language) }
                                       size = 'lg'
                                       checked = { weatherHail }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'weatherSnowSnowfal'
                                       text = { translate('Snow/snowfall', language) }
                                       size = 'lg'
                                       checked = { weatherSnowSnowfal }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'weatherIceIcin'
                                       text = { translate('Ice/icing', language) }
                                       size = 'lg'
                                       checked = { weatherIceIcin }
                                       onChange = { chooseCheckbox } /> 

                            <ChooseBox name = 'weatherStorm'
                                       text = { translate('Storm', language) }
                                       size = 'lg'
                                       checked = { weatherStorm }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'weatherStrongWind'
                                       text = { translate('Strong wind', language) }
                                       size = 'lg'
                                       checked = { weatherStrongWind }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'weatherHurricane'
                                       text = { translate('Hurricane', language) }
                                       size = 'lg'
                                       checked = { weatherHurricane }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'weatherBlizzard'
                                       text = { translate('Blizzard/blizzard', language) }
                                       size = 'lg'
                                       checked = { weatherBlizzard }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'weatherDrought'
                                       text = { translate('Drought', language) }
                                       size = 'lg'
                                       checked = { weatherDrought }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'weatherFlood'
                                       text = { translate('Flood', language) }
                                       size = 'lg'
                                       checked = { weatherFlood }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'weatherSmokeFir'
                                       text = { translate('Smoke/fire', language) }
                                       size = 'lg'
                                       checked = { weatherSmokeFir }
                                       onChange = { chooseCheckbox } />
                            
                            <ChooseBox name = 'weatherTornado'
                                       text = { translate('Tornado', language) }
                                       size = 'lg'
                                       checked = { weatherTornado }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'weatherSandstorm'
                                       text = { translate('Sandstorm', language) }
                                       size = 'lg'
                                       checked = { weatherSandstorm }
                                       onChange = { chooseCheckbox } />
                        </div>
                    </div>
                </div>
            </div>
        </CabinetSection> 
    )
}));

export { WorkLocationTimeWeatherList };