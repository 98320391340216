import React from 'react';

import { ImageWithCropperModal } from 'components/containers';
import { Icon, Tooltip } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_ImageWithCropper.scss';

const ImageWithCropper =  inject('LanguageStore', 'ToolsStore')(observer(({ src, modalOpened, setModalOpened, size = 'sm', settings, buttonText, bgImg, requirements, disabled, onImageLoadOnServer, onRemoveClick, LanguageStore, ToolsStore, ...restProps }) => {
    const { language } = LanguageStore;
    const { uploadImage } = ToolsStore;

    const ImageWithCropperClass = classNames('ImageWithCropper', {
        'ImageWithCropper--size-sm': size === 'sm',
        'ImageWithCropper--size-md': size === 'md',
        'ImageWithCropper--size-lg': size === 'lg',
        'ImageWithCropper--bg-img-mountain': bgImg === 'mountain' && !src,
        'ImageWithCropper--bg-img-star': bgImg === 'star' && !src,
        'ImageWithCropper--empty': !src,
        'ImageWithCropper--disabled': disabled
    });

    return (
        <div className = { ImageWithCropperClass } { ...restProps }>
            <img className = 'ImageWithCropper__img' src = { src } alt = { !src ? '' : 'Изображение' } />

            <label className = 'ImageWithCropper__label' onClick = { setModalOpened.bind(null, true) }>
                { buttonText }
            </label>

            { (src && onRemoveClick) && (
                <Tooltip wrapClassName = 'ImageWithCropper__removeTolltipWrap' text = { translate('Remove image', language) } position = 'left'>
                    <div className = 'ImageWithCropper__removeWrap' onClick = { onRemoveClick }>
                        <Icon name = 'cross-tiny' className = 'ImageWithCropper__remove' />
                    </div>
                </Tooltip>
            ) }

            { modalOpened && (
                <ImageWithCropperModal opened = { modalOpened }
                                       setOpened = { setModalOpened }
                                       imageLink = { src }
                                       settings = { settings }
                                       language = { language }
                                       requirements = { requirements }
                                       uploadImage = { uploadImage }
                                       onImageLoadOnServer = { onImageLoadOnServer } />
            ) }
        </div>
    )
}));

export { ImageWithCropper };