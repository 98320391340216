import React from 'react';

import { Icon, Token } from 'components/ui';

import classNames from 'classnames';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_SelectDropdown.scss';

const SelectDropdown = inject('LanguageStore')(observer(({ LanguageStore, type = 'single', items, actionButton, action, onItemClick }) => {
    const { language } = LanguageStore;
    const defaultTitle = type === 'multiple' ?
        translate('Choose one or more items', language)
        : translate('Choose item', language);

    const SelectDropdownSectionClasses = classNames('SelectDropdown__section', {
        'SelectDropdown__section--minHeight': actionButton?.subText,
    });

    return (
        <div className = 'SelectDropdown'>
            <div className = 'SelectDropdown__list'>
                { items.map(section => (
                    <div className = { SelectDropdownSectionClasses } key = { section.id } id = { section.id }>
                        <span className = 'SelectDropdown__sectionTitle'>{ section.title || defaultTitle }</span>

                        <ul className = 'SelectDropdown__sectionContent'>
                            { section.items.map(sectionItem => {
                                const SelectDropdownSectionItemClass = classNames('SelectDropdown__sectionItem', {
                                    'SelectDropdown__sectionItem--selected': sectionItem.selected,
                                });

                                return (
                                    <li key = { `${sectionItem.id}-${language}` } className = { SelectDropdownSectionItemClass } data-data = { JSON.stringify(sectionItem.data) } data-section-id = { section.id } onMouseDown = { onItemClick }>
                                        <Icon name = { `point-small-${type}` } className = { `SelectDropdown__sectionItemIcon SelectDropdown__sectionItemIcon--point-small-${type}` } />
                                        <span className = 'SelectDropdown__sectionItemText'>
                                            {
                                                sectionItem.data[`surname-${language}`]
                                                    ? sectionItem.data[`name-${language}`] + ' ' + sectionItem.data[`surname-${language}`]
                                                    : sectionItem.data[`name-${language}`] || sectionItem.text || sectionItem.data.text
                                            }
                                        </span>
                                        <Icon name = 'arrow-small' className = 'SelectDropdown__sectionItemIcon SelectDropdown__sectionItemIcon--arrow' />
                                    </li>
                                )
                            }) }
                        </ul>
                    </div>
                )) }
            </div>

            { actionButton && (
                <button className = 'SelectDropdown__actionButton' { ...actionButton.restProps }>
                    { actionButton.text }
                </button>
            ) }

            { actionButton && (
                <div className = 'SelectDropdown__subtext'><span>{ actionButton.subText }</span></div>
            ) }

            { action && action.value && action.value.trim() !== '' && (
                <div className = 'SelectDropdown__action'>
                    <span className = 'SelectDropdown__actionText'>{ action.text }</span>
                    <Token text = { action.value } onClick = { action.onClick } />
                </div>
            ) }
        </div>
    )
}));

export { SelectDropdown };
