// Хранилище с разделами и текущей работой

import { action, observable, runInAction, decorate, toJS } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class WorksStore {
    works = []

    currentWork = {
        mode: false,
        errors: {},
        updated: []
    }

    // Получение работ
    async getWorks(update = false) {
        const user = toJS(this.root.stores.userStore.user);
     
        if (user.activeCabinet === 'executor' || user.activeCabinet === 'studio') {
            const response = await request(`${BASE_URL}/api/user/cabinet/work`);
            const data = await response.json();

            if (response.status === 200) {
                runInAction(() => {
                    if (update) {
                        for (let updatedWorkGroup of data.workGroup) {
                            const currentGroup = this.works.find(work => work.id === updatedWorkGroup.id);

                            this.works[this.works.indexOf(currentGroup)] = { ...currentGroup, ...updatedWorkGroup };
                        }
                    } else {
                        this.works = data.workGroup;
                    }
                });
            }
        }
    }

    // Получение работы
    async getWork(workId) {
        const response = await request(`${BASE_URL}/api/user/cabinet/work/view/${workId}`);
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.currentWork = { ...this.currentWork, ...data.work });
        } else {
            throw new Error(`Work with id ${workId} not found`);
        }
    }

    // Создание раздела с работами
    async createWorksGroup(index, obj) {
        const response = await request(`${BASE_URL}/api/user/cabinet/work/group/create`, { method: 'POST', body: JSON.stringify(obj) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.works[index] = data.workGroup);
        }
    }

    // Обновление раздела с работами
    async updateWorksGroup(id, index, obj) {
        const response = await request(`${BASE_URL}/api/user/cabinet/work/group/update/${id}`, { method: 'POST', body: JSON.stringify(obj) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.works[index] = { ...this.works[index], ...data.workGroup });
        }
    }

    // Создание работы
    async createWork(obj, presenter = 'full') {
        let workSort = 0;

        if (obj.fields.groupId) {
            const section = this.works.find(workGroup => workGroup.id === this.currentWork.fields.groupId); 
            workSort = section.work[section.work.length - 1] ? +section.work[section.work.length - 1].sort + 1 : 0;
        } else {
            const trashSection = this.works.find(workGroup => workGroup.isTrash);
            workSort = trashSection.work[trashSection.work.length - 1] ? +trashSection.work[trashSection.work.length - 1].sort + 1 : 0;
        }

        const preparedObject = {
            ...obj,
            fields: {
                ...obj.fields,
                groupId: obj.fields.groupId || this.works.find(workGroup => workGroup.isTrash).id,
            },
            sort: workSort,
            show: true,
            link: obj.fields.link
        }

        if (obj.fields.customPreview && obj.fields['name-ru-RU']) {
            preparedObject.fields.preview = obj.fields.customPreview;
            obj.fields.customPreview = null;
        }

        if (obj.fields.customPoster && obj.fields['name-ru-RU']) {
            preparedObject.fields.poster = obj.fields.customPoster;
            obj.fields.customPoster = null;
        }

        const response = await request(`${BASE_URL}/api/user/cabinet/work/create?presenter=${presenter}`, { method: 'POST', body: JSON.stringify(preparedObject) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => (
                this.currentWork = { 
                    ...this.currentWork,
                    ...data.work,
                    mode: 'edit',
                    updated: [],
                    wasCreated: true,
                    wasUpdated: true,
                    fields: {
                        ...this.currentWork.fields,
                        ...data.work.fields
                    }
                }
            ));
        } else if (response.status === 422) {
            runInAction(() => (
                this.currentWork = { 
                    ...this.currentWork,
                    errors: {
                        fields: { ...data.errors.fields }
                    }
                }
            ));

            return Promise.reject();
        }
    }

    // Обновление работы
    async updateWork(id, groupIndex, obj) {
        const response = await request(`${BASE_URL}/api/user/cabinet/work/update/${id}`, { method: 'POST', body: JSON.stringify(obj) });
        const data = await response.json();

        if (response.status === 200) {
            const workGroupItems = this.works[groupIndex].work;
            const workItem = workGroupItems.find(work => work.id === id);
            const workIndex = workGroupItems.indexOf(workItem);

            if (workIndex !== null || workIndex !== undefined) {
                runInAction(() => this.works[groupIndex].work[workIndex] = { ...workGroupItems[workIndex], ...data.work });
            }
        }
    }

    async updateWorkGroupId(id, groupId) {
        return await request(`${BASE_URL}/api/user/cabinet/work/change-work-group/${id}`, { method: 'POST', body: JSON.stringify({ groupId }) });
    }

    // Обновление специфичный полей у текущей работы
    async updateCurrentWorkEntities(obj, entityName) {
        const requestURL = `${BASE_URL}/api/user/cabinet/work/update/${this.currentWork.id}/${entityName}`;
        const response = await request(requestURL, { method: 'POST', body: JSON.stringify(obj) });
        const data = await response.json();

        if (response.status === 200) {
            const entityErrorsCopy = Object.assign({}, this.currentWork.errors[entityName]);

            for (let key of Object.keys(entityErrorsCopy)) {
                if (Object.keys(data.work).includes(key)) {
                    entityErrorsCopy[key] = null;
                }
            }

            const entityObj = { 
                ...this.currentWork[entityName], 
                ...data.work
            };

            const updatedEntity = Array.isArray(data.work) ? [...data.work] : entityObj;
            const filteredUpdatedEntities = this.currentWork.updated.filter(entity => entity !== entityName);

            runInAction(() => (
                this.currentWork = { 
                    ...this.currentWork,
                    updated: filteredUpdatedEntities,
                    wasUpdated: true,
                    errors: {
                        ...this.currentWork.errors,
                        [entityName]: entityErrorsCopy
                    },  
                    [entityName]: updatedEntity
                }
            ));
        } else if (response.status === 422) {
            this.currentWork.errors[entityName] = { ...data.errors };
        }
    }

    // Удаление раздела
    async deleteWorkGroup(id, index) {
        const response = await request(`${BASE_URL}/api/user/cabinet/work/group/delete/${id}`, { method: 'DELETE' });

        if (response.status === 200) {
            this.deleteWorkGroupLocal(index);
        }

        return response;
    }

    // Обновление порядка элементов в списке
    async updateWorksGroupSort(obj) {
        const response = await request(`${BASE_URL}/api/user/cabinet/work/group/sort`, { method: 'POST', body: JSON.stringify(obj) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.works = data.workGroup);
        }
    }

    // Обновление порядка работ в разделе 
    async updateWorksSort(obj) {
        return await request(`${BASE_URL}/api/user/cabinet/work/sort`, { method: 'POST', body: JSON.stringify(obj) });
    }

    // Удаление работы
    async deleteWork(id) {
        const response = await request(`${BASE_URL}/api/user/cabinet/work/delete/${id}`, { method: 'DELETE' });

        if (response.status === 200) {
            runInAction(() => this.currentWork = { ...this.currentWork, mode: null, wasCreated: false, wasUpdated: false });
        }
    }

    // Удаление раздела с работами локально
    deleteWorkGroupLocal(index) {
        this.works.splice(index, 1);
    }

    // Обновление раздела с работами локально
    updateWorksGroupLocal(obj) {
        const { index, data } = obj;
        
        if (data) {
            if (index || index === 0) {
                this.works[index] = { ...this.works[index], ...data };
            } else {
                this.works.push({ ...data });
            }
        } else {
            this.works = obj;
        }
    }

    // Обновление разделов локально
    updateWorkGroupsLocal(obj) {
        this.works = obj;
    }

    // Обновление ошибок
    updateCurrentWorkErrors(entityName, obj, reset = false) {
        this.currentWork.errors[entityName] = reset ? obj : { ...this.currentWork.errors[entityName], ...obj };
    }

    // Обновление работы локально
    updateCurrentWorkLocal(obj, reset = false) {
        const errorsCopy = Object.assign({}, this.currentWork.errors);
        const entityNames = Object.keys(obj);
        const pageNames = ['fields', 'characteristics', 'prepare', 'backstage', 'location', 'skils', 'optionalEquip', 'fest', 'budget', 'imageProp', 'light', 'team'];
        const pageEntities = entityNames.find(name => pageNames.includes(name));
        const updatedWork = { ...obj };

        for (let key of Object.keys(obj)) {
            if (errorsCopy[key]) {
                for (let itemKey of Object.keys(errorsCopy[key])) {
                    if (obj[key][itemKey]) {
                        errorsCopy[key][itemKey] = null;
                    }
                }
            }
        }

        if (pageEntities) {
            updatedWork.wasCreated = false;
        }

        if (pageEntities) {
            this.currentWork.updated = [...new Set([...this.currentWork.updated, pageEntities])];
            this.currentWork.wasUpdated = false;
        }
        
        this.currentWork = reset ? updatedWork : { ...this.currentWork, ...updatedWork };
    }

    // Удаление работы локально
    replaceWorkToGroup(newGroupId, newWorkIndex, workId) {
        const currentGroup = this.works.find(group => group.work.find(work => work.id === workId));
        const newGroup = this.works.find(group => group.id === newGroupId);
        const work = currentGroup.work.find(work => work.id === workId);
        const currentWorkIndex = currentGroup.work.indexOf(work);

        runInAction(() => {
            newGroup.work.splice(newWorkIndex, 0, work);
            currentGroup.work.splice(currentWorkIndex, 1);
        });
    }

    // Очистка всех работ
    cleanWorks() {
        runInAction(() => {
            this.works = [];

            this.currentWork = {
                mode: false,
                errors: {},
                updated: []
            };
        });
    }
}

decorate(WorksStore, {
    works: observable,
    currentWork: observable,
    getWorks: action.bound,
    getWork: action.bound,
    updateWorksGroup: action.bound,
    updateWorksGroupLocal: action.bound,
    updateWork: action.bound,
    updateWorkGroupId: action.bound,
    updateCurrentWorkEntities: action.bound,
    updateWorksGroupSort: action.bound,
    updateWorksSort: action.bound,
    deleteWorkGroup: action.bound,
    deleteWorkGroupLocal: action.bound,
    deleteWork: action.bound,
    createWorksGroup: action.bound,
    createWork: action.bound,
    updateCurrentWorkLocal: action.bound,
    updateCurrentWorkErrors: action.bound,
    updateWorkGroupsLocal: action.bound,
    replaceWorkToGroup: action.bound,
    cleanWorks: action.bound
});

export default new WorksStore();