import React from 'react';

import './_Spinner.scss';

const Spinner = () => (
    <svg className = 'Spinner' width = '14' height = '14'>
        <circle className = 'Spinner__circle' strokeWidth = '2' strokeLinecap = 'round' cx = '7' cy = '7' r = '6'></circle>
    </svg>
);

export { Spinner };