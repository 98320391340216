import React from 'react';

import { StudioNav, StudioGeneral, StudioLocations,
         StudioMoreInfo, StudioSocialNetworks, StudioFilmography,
         StudioContacts } from 'components/pages';
import { CabinetContent } from 'components/containers';

const Studio = () => (
    <div className = 'Studio'>
        <StudioNav />
        
        <CabinetContent>
            <StudioGeneral />
            <StudioContacts />
            <StudioLocations />
            <StudioMoreInfo />
            <StudioSocialNetworks />
            <StudioFilmography />
        </CabinetContent>
    </div>
);

export { Studio };