import React from 'react';

import { WorkActions, WorkPreparationImages, WorkPreparationVideos } from 'components/pages';

import { CabinetContent } from 'components/containers';

const WorkPreparation = () => (
    <div className = 'WorkPreparation'>
        <CabinetContent>
            <WorkPreparationImages />
            <WorkPreparationVideos />
            <WorkActions />
        </CabinetContent>
    </div>
);

export { WorkPreparation };