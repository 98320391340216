import React, { useState, useEffect } from 'react';

import { Spinner } from 'components/ui';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounceEvent, request, getExecutorName, getStudioName, getImageFullPath } from 'helpers';
import classNames from 'classnames';

import { BASE_URL } from 'constants/BASE_URL';

import NoPosterLink from 'static/images/no-poster-search.png';

import './_HeaderSearch.scss';

const HeaderSearch = inject('LanguageStore')(observer(({ LanguageStore }) => {
    const { language } = LanguageStore;

    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(null);
    const [items, setItems] = useState({ work: [], cabinets: [] });

    const { work, cabinets } = items;

    const HeaderSearchClass = classNames('HeaderSearch', {
        'HeaderSearch--active': dropdownOpened,
        'HeaderSearch--loading': loading
    });

    // Пользовательский ввод
    const handleInputChange = debounceEvent(event => {
        const input = event.target;
        const value = input.value.trim();
        
        setDropdownOpened(true);
        setLoading(true);
        search(value);
    }, 300);

    // Фокус на поле ввода
    function handleInputFocus() {
        if (isEmpty !== null) {
            setDropdownOpened(true);
        }
    }

    // Скрытие результатов
    function hideDropdown({ target }) {
        const headerSearch = target.closest('.HeaderSearch');
 
        if (!headerSearch) {
            setDropdownOpened(false);
            setLoading(false);
        }
    }

    // Поиск
    async function search(find) {
        const options = { method: 'POST', body: JSON.stringify({ find }) };
        const response = await request(`${BASE_URL}/api/search/fast`, options);
        const data = await response.json();
        
        const { work, cabinets } = data.result;

        setItems({ work: work.slice(0, 3), cabinets: cabinets.slice(0, 3) });
        setIsEmpty(work.length === 0 && cabinets.length === 0);
        setLoading(false);
    }

    // Эффект для обработчика клика(скрытие результатов)
    useEffect(() => {
        window.addEventListener('click', hideDropdown);

        return () => window.removeEventListener('click', hideDropdown);
    }, [dropdownOpened]);

    return (
        <div className = { HeaderSearchClass }>
            <div className = 'HeaderSearch__panel'>
                <input className = 'HeaderSearch__panelInput' 
                       placeholder = { `${translate('Search', language)}...` } 
                       onInput = { handleInputChange }
                       onFocus = { handleInputFocus } />

                <Icon name = 'search' className = 'HeaderSearch__panelIcon' />
            </div>

            <div className = 'HeaderSearch__dropdown'>
                <div className = 'HeaderSearch__dropdownSpinner'>
                    <Spinner />
                </div>

                <div className = 'HeaderSearch__dropdownSections'>
                    { isEmpty && (
                        <div className = 'HeaderSearch__empty'>
                            { translate('Nothing found', language) }
                        </div>
                    ) }

                    { (work.length > 0 || cabinets.length > 0) && (
                        <div className = 'HeaderSearch__dropdownSection HeaderSearch__dropdownSection--items'>
                            { work.length > 0 && (
                                <ul className = 'HeaderSearch__dropdownSectionList'>
                                    { work.map(workItem => {
                                        const { id, poster, link } = workItem;
                                        const name = workItem[`name-${language}`] || workItem['name-ru-RU'];

                                        return (
                                            <li key = { id } className = 'HeaderSearch__dropdownItem' title = { name }>
                                                <a href = { `${link}` } className = 'HeaderSearch__dropdownItemLink' target = '_blank'>
                                                    <img className = 'HeaderSearch__dropdownItemImg' src = { poster ? getImageFullPath(poster) : NoPosterLink } />

                                                    <span className = 'HeaderSearch__dropdownItemText'>
                                                        { name }
                                                    </span>
                                                </a>
                                            </li>
                                        )
                                    }) }
                                </ul>
                            ) }

                            { cabinets.length > 0 && (
                                <ul className = 'HeaderSearch__dropdownSectionList'>
                                    { cabinets.map(cabinet => {
                                        const { id, link, cabinetType } = cabinet;
                                        const name = cabinetType === 'executor' ? getExecutorName(cabinet, language) : getStudioName(cabinet, language);
                                        
                                        return (
                                            <li key = { id } className = 'HeaderSearch__dropdownItem' title = { name }>
                                                <a href = { `${link}` } className = 'HeaderSearch__dropdownItemLink HeaderSearch__dropdownItemLink--person' target = '_blank' rel = 'noopener noreferrer'>
                                                    <span className = 'HeaderSearch__dropdownItemText'>
                                                        { name }
                                                    </span>
                                                </a>
                                            </li>
                                        )
                                    }) }
                                </ul>
                            ) }
                        </div>
                    ) }
                    
                    <div className = 'HeaderSearch__dropdownSection HeaderSearch__dropdownSection--filters'>
                        <span className = 'HeaderSearch__dropdownSectionTitle'>{ translate('Search through detailed filters', language) }</span>

                        <ul className = 'HeaderSearch__dropdownSectionList'>
                            <li className = 'HeaderSearch__dropdownFilter HeaderSearch__dropdownFilter--orange'>
                                <a href = { `${BASE_URL}/search#easy` } className = 'HeaderSearch__dropdownFilterLink' target = '_blank'>
                                    <span className = 'HeaderSearch__dropdownFilterIcon'></span>
                                    <span className = 'HeaderSearch__dropdownFilterText'>
                                        { `${translate('Quick', language)} ${translate('Search', language).toLowerCase()}` } →
                                    </span>
                                </a>
                            </li>

                            <li className = 'HeaderSearch__dropdownFilter HeaderSearch__dropdownFilter--green'>
                                <a href = { `${BASE_URL}/search#middle` } className = 'HeaderSearch__dropdownFilterLink' target = '_blank'>
                                    <span className = 'HeaderSearch__dropdownFilterIcon'></span>
                                    <span className = 'HeaderSearch__dropdownFilterText'>
                                        { `${translate('Advanced', language)} ${translate('Search', language).toLowerCase()}` } →
                                    </span>
                                </a>
                            </li>

                            <li className = 'HeaderSearch__dropdownFilter HeaderSearch__dropdownFilter--purple'>
                                <a href = { `${BASE_URL}/search#hard` } className = 'HeaderSearch__dropdownFilterLink' target = '_blank'>
                                    <span className = 'HeaderSearch__dropdownFilterIcon'></span>
                                    <span className = 'HeaderSearch__dropdownFilterText'>
                                        { `${translate('Professional', language)} ${translate('Search', language).toLowerCase()}` } →
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <a href = { BASE_URL } className = 'HeaderSearch__dropdownShowAll' target = '_blank'>
                    { translate('See results', language) } →
                </a>
            </div>
        </div>
    )
}));

export { HeaderSearch };