import React from 'react';

import { ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_SubscriptionTimeCard.scss';

const SubscriptionTimeCard = inject('LanguageStore')(observer(({ time = 'month', invoice, discount, price, short = false, checked, chooseTime, LanguageStore, ...restProps }) => {
    const { language } = LanguageStore;

    const SubscriptionTimeCardClass = classNames('SubscriptionTimeCard', {
        'SubscriptionTimeCard--short': short,
        'SubscriptionTimeCard--chosen': checked
    });
    
    const preparedPrice = Math.floor(price);
    const monthPrice = Math.floor(time === 'month' ?  preparedPrice : preparedPrice / 12);
    const yearPrice = Math.floor(time === 'month' ?  preparedPrice * 12 : preparedPrice);

    const texts = {
        executor: () => {
            switch (invoice.classification) {
                case 'pro':
                case 'new-blood':
                    return time === 'month' ? 'Payment is made once a month after uploading the work and confirming the classification.' : 'Payment is made once a year after uploading the work and confirming the classification.';
                case 'specialist':
                    return time === 'month' ? 'Payment is made once a month.' : 'Payment is made once a year.';
            }
        },

        studio: () => {
            return time === 'month' ? 'Payment is made once a month.' : 'Payment is made once a year.';
        },

        agent: () => '',
        filmSchool: () => ''
    };

    // Получение красивого числа
    const getBeautifulPrice = price => {
        return `${price}`.split(/(?=(?:...)*$)/).join(' ');
    };

    return (
        <div className = { SubscriptionTimeCardClass } onClick = { chooseTime }>
            <div className = 'SubscriptionTimeCard__info'>
                <span className = 'SubscriptionTimeCard__title'>
                    { translate(time === 'month' ? 'Monthly subscription' : 'Annual subscription', language) }

                    &nbsp;

                    { time === 'year' && (
                        <span className = 'SubscriptionTimeCard__title SubscriptionTimeCard__title--discount'>-25%</span>
                    )  }
                </span>

                <span className = 'SubscriptionTimeCard__text'>
                    { translate(texts[invoice.subjectCabinetType](), language) }
                </span>

                <div className = 'SubscriptionTimeCard__priceInfo'>
                    <div className = 'SubscriptionTimeCard__prices'>
                        <span className = 'SubscriptionTimeCard__price'>
                            { getBeautifulPrice(monthPrice) } { translate('Rub / month', language) }
                        </span>

                        <span className = 'SubscriptionTimeCard__price SubscriptionTimeCard__price--sm'>
                            { getBeautifulPrice(yearPrice) } { translate('Rub / year', language) }
                        </span>
                    </div>

                    {/* { discount !== 0 && (
                        <div className = 'SubscriptionTimeCard__discount'>
                            <span className = 'SubscriptionTimeCard__discountValue'>-{discount}%</span>

                            <span className = 'SubscriptionTimeCard__discountText'>
                                { translate('In the next step', language).toLowerCase() }
                            </span>
                        </div>
                    ) } */}
                </div>
            </div>

            <div className = 'SubscriptionTimeCard__radio'>
                <ChooseBox type = 'radio'
                           checked = { checked }
                           onChange = { () => {} }
                           { ...restProps }  />
            </div>
        </div>
    )
}));

export { SubscriptionTimeCard };
