// Хранилище с направлениями ком.рекламы

import { decorate, action, observable, runInAction } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class AdDirectionsStore {
    adDirections = []

    // Поиск направления для рекламы
    async findAdDirection(name) {
        const response = await request(`${BASE_URL}/api/direct-comerc-adv/find-direct-comerc-adv`, { method: 'POST', body: JSON.stringify({ name }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.adDirections = data.result);
        }
    }
}

decorate(AdDirectionsStore, {
    adDirections: observable,
    findAdDirection: action.bound
});

export default new AdDirectionsStore();