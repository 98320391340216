import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Container } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import { BASE_URL } from 'constants/BASE_URL';

import './_HeaderNav.scss';

const HeaderNav = inject('LanguageStore', 'UserStore', 'WorksStore', 'CabinetStore')(observer(withRouter(({ history, LanguageStore, UserStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { user } = UserStore;
    const { updateCurrentWorkLocal } = WorksStore;
    const { clearCabinetSearch } = CabinetStore;

    // Переход на странице добавления работ
    function goToAddWork() {
        updateCurrentWorkLocal({ mode: 'add', errors: {}, updated: [] }, true);
        clearCabinetSearch();

        history.push('/works/add-work/general');
    }

    return (
        <nav className = 'HeaderNav'>
            <div className = 'HeaderNav__item'>
                <Icon name = 'burger-menu' className = 'HeaderNav__itemIcon' />
                <span className = 'HeaderNav__itemText'>{ translate('Menu', language) }</span>

                <div className = 'HeaderNav__dropdown'>
                    <Container>
                        <div className = 'HeaderNav__dropdownWrap'>
                            <div className = 'HeaderNav__dropdownColumn'>
                                <div className = 'HeaderNav__dropdownBox'>
                                    <span className = 'HeaderNav__dropdownTitle'>{ translate('Search', language) }</span>

                                    <ul className = 'HeaderNav__dropdownList'>
                                        <li className = 'HeaderNav__dropdownListItem'>
                                            <a href = { `${BASE_URL}/search#easy` }>{ translate('Quick', language) }</a>
                                        </li>

                                        <li className = 'HeaderNav__dropdownListItem'>
                                            <a href = { `${BASE_URL}/search#middle` }>{ translate('Advanced', language) }</a>
                                        </li>

                                        <li className = 'HeaderNav__dropdownListItem'>
                                            <a href = { `${BASE_URL}/search#hard` }>{ translate('Professional', language) }</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className = 'HeaderNav__dropdownColumn'>
                                <div className = 'HeaderNav__dropdownBox'>
                                    <span className = 'HeaderNav__dropdownTitle'>{ translate('Services', language) }</span>

                                    <ul className = 'HeaderNav__dropdownList'>
                                        { (user.activeCabinet === 'executor' || user.activeCabinet === 'studio') && (
                                            <li className = 'HeaderNav__dropdownListItem' onClick = { goToAddWork }>
                                                { `${translate('Add / check works', language)}` }
                                            </li>
                                        ) }

                                        <li className = 'HeaderNav__dropdownListItem HeaderNav__dropdownListItem--disabled'>
                                            <Link to = '/'>
                                                { `${translate('Pick a team / staff', language)} (${translate('Soon', language).toLowerCase()})` }
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </nav>
    )
})));

export { HeaderNav };