import React from 'react';

import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';

import './_HeaderLanguage.scss';

const HeaderLanguage = inject('LanguageStore', 'UserStore')(observer(({ LanguageStore, UserStore }) => {
    const { language, setLanguage } = LanguageStore;
    const { user, updateUser } = UserStore;

    // Смена текущего языка
    const changeLanguage = event => {
        const item = event.target.closest('.HeaderLanguage__dropdownItem');

        if (item) {
            const language = item.dataset.value;
            const languageId = user.languages.find(({ fullCode }) => fullCode === language).id;
            
            setLanguage(language);
            updateUser({ languageId });
        }
    };
    
    return (
        <div className = 'HeaderLanguage'>
            <div className = 'HeaderLanguage__current'>
                <Icon name = 'planet' className = 'HeaderLanguage__currentIcon' />
                <span className = 'HeaderLanguage__currentText'>{ language.split('-')[0] }</span>
            </div>

            <ul className = 'HeaderLanguage__dropdown' onClick = { changeLanguage }>
                <li className = 'HeaderLanguage__dropdownItem' data-value = 'ru-RU'>ru / py</li>
                <li className = 'HeaderLanguage__dropdownItem' data-value = 'en-US'>en / en</li>
            </ul>
        </div>
    )
}));

export { HeaderLanguage };