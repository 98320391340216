import React from 'react';
import { Link } from 'react-router-dom';

import { FilmSchoolGraduatesList } from 'components/pages';
import { CabinetContent, CabinetAlerts } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const FilmSchoolGraduates = inject('LanguageStore', 'CabinetStore')(observer(({ LanguageStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { subscriptions } = CabinetStore;

    const payAlertTranslations = {
        'ru-RU': {
            first: 'Для отображения на сайте и добавления выпускников необходимо',
            second: 'первый пакет (10 выпускников). Первые три месяца - 1 рубль'
        },

        'en-US': {
            first: 'To display on the site and add graduates, you need to',
            second: 'for the first package (10 graduates). The first two months - 1 ruble'
        }
    };

    return (
        <div className = 'FilmSchoolGraduates'>
            <CabinetContent>
                <CabinetAlerts>
                    <Alert text = { translate('One film school package includes 10 graduates. In the future, you can increase the number of packages', language) } 
                           icon = 'question' />
                    
                    { subscriptions.filmSchool && subscriptions.filmSchool[0].invoice[0].status === 'no_payed' && (
                        <Alert type = 'warn'>
                            { payAlertTranslations[language].first }

                            &nbsp;
                            <Link to = '/payment' className = 'Alert__textLink'>
                                <span className = 'Alert__text Alert__text--action'>
                                    { translate('Pay', language).toLowerCase() }
                                </span>
                            </Link>
                            &nbsp;
                            
                            { payAlertTranslations[language].second }
                        </Alert>
                    ) }
                </CabinetAlerts>

                <FilmSchoolGraduatesList />
            </CabinetContent>
        </div>
    )
}));

export { FilmSchoolGraduates };