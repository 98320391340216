import React from 'react';

import { ChooseBox, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, getExecutorName } from 'helpers';
import classNames from 'classnames';

import './_FilmSchoolGraduateCard.scss';

const FilmSchoolGraduateCard = inject('LanguageStore', 'CabinetStore')(observer(({ contractor, index, LanguageStore, CabinetStore, ...restProps }) => {
    const { language } = LanguageStore;
    const { updateCabinetSection, deleteFromCabinetSection, getAllSubscriptions } = CabinetStore;
    
    const { executor, id, show } = contractor;

    const removeExecutorBinded = () => {
        deleteFromCabinetSection('graduates', id, index, { page: 'personalData' })
            .then(() => getAllSubscriptions());
    };

    const FilmSchoolGraduateCardClass = classNames('FilmSchoolGraduateCard', {
        'FilmSchoolGraduateCard--no-photo': !executor.photo,
        'FilmSchoolGraduateCard--no-agree': !true,
    });

    // Показ исполнителя
    const showExecutorBinded = event => {
        const checkbox = event.target;
        const checked = checkbox.checked;

        updateCabinetSection('graduates', { id, show: checked }, { page: 'personalData' });
    };

    return (
        <div className = { FilmSchoolGraduateCardClass } data-executor-id = { id } { ...restProps }>
            { executor.photo && (
                <img className = 'FilmSchoolGraduateCard__preview' src = { executor.photo } draggable = 'false' />
            ) }

            <div className = 'FilmSchoolGraduateCard__info'>
                <span className = 'FilmSchoolGraduateCard__name'>{ getExecutorName(executor, language) }</span>
                
                { !true && (
                    <span className = 'FilmSchoolGraduateCard__text'>
                        { translate('Request sent', language).toLowerCase() }
                    </span>
                ) }
            </div>

            { true && (
                <div className = 'FilmSchoolGraduateCard__options'>
                    <div className = 'FilmSchoolGraduateCard__option FilmSchoolGraduateCard__option--data'>
                        { translate('Login details', language) }
                    </div>

                    <div className = 'FilmSchoolGraduateCard__chooseBox'>
                        <ChooseBox type = 'checkbox'
                                   checked = { show }
                                   text = { translate('Show', language) }
                                   theme = 'white-blur'
                                   onChange = { showExecutorBinded } />
                    </div>

                    <div className = 'FilmSchoolGraduateCard__option FilmSchoolGraduateCard__option--drag'>
                        <Icon name = 'drag-points' className = 'FilmSchoolGraduateCard__optionDragIcon' />
                    </div>

                    <div className = 'FilmSchoolGraduateCard__option FilmSchoolGraduateCard__option--remove' onClick = { removeExecutorBinded }>
                        { translate('Unpin', language) }
                    </div>
                </div>
            ) }

            { !true && (
                <div className = 'FilmSchoolGraduateCard__options'>
                    <div className = 'FilmSchoolGraduateCard__option FilmSchoolGraduateCard__option--pure FilmSchoolGraduateCard__option--cancel' onClick = { removeExecutorBinded }>
                        { translate('Cancel', language) }
                    </div>

                    <div className = 'FilmSchoolGraduateCard__option FilmSchoolGraduateCard__option--pure FilmSchoolGraduateCard__option--send-again'>
                        { translate('Send again', language) } →
                    </div>
                </div>
            ) }
        </div>
    )
}));

export { FilmSchoolGraduateCard };