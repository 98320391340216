import React, { useState } from 'react';

import { CabinetSection, ImageWithCropper } from 'components/containers';
import { Input } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounceEvent, getImageFullPath } from 'helpers';

import './_FilmSchoolGeneral.scss';

const FilmSchoolGeneral = inject('CabinetStore', 'LanguageStore')(observer(({ CabinetStore, LanguageStore }) => {
    const [imageUploadModalOpened, setImageUploadModalOpened] = useState(false); 

    const { cabinet, updateCabinetSection } = CabinetStore;
    const { language } = LanguageStore;

    const profile = cabinet.personalData ? cabinet.personalData.profile : null;
    const profileErrors = (cabinet.errors && cabinet.errors.profile) || {};

    // Изменение основной информации о пользователе
    const changeUserInfo = debounceEvent(event => {
        const input = event.target;
        const name = input.name;
        const value = input.value.trim();

        const requestObj = {
            [name]: value
        };

        if (language === 'en-US') {
            requestObj[name.replace('ru-RU', 'en-US')] = value;
        }

        updateCabinetSection('profile', requestObj);
    }, 350);

    // Изменение фотографии кабинета
    const changeCabinetPhoto = link => {
        updateCabinetSection('profile', { photo: link });
        setImageUploadModalOpened(false);
    };

    // Удаление фотографии кабинета
    const removePhoto = () => {
        updateCabinetSection('profile', { photo: null });
    };

    return profile && (
        <CabinetSection id = 'general' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('General2', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'FilmSchoolGeneral'>
                    <div className = 'FilmSchoolGeneral__info'>
                        <div className = 'FilmSchoolGeneral__infoInputs CabinetSection__container'>
                            <Input label = { translate('School name', language) } 
                                   name = 'name-ru-RU' 
                                   defaultValue = { profile['name-ru-RU'] } 
                                   error = { profileErrors['name-ru-RU'] } 
                                   required = { true } 
                                   onInput = { changeUserInfo } />

                            <Input label = { translate('Abbreviation', language) } 
                                   name = 'abbreviation-ru-RU' 
                                   defaultValue = { profile['abbreviation-ru-RU'] }
                                   error = {  profileErrors['abbreviation-ru-RU'] } 
                                   required = { true } 
                                   onInput = { changeUserInfo } />

                            { language === 'ru-RU' && (
                                <Input label = { translate('School name (for English version)', language) } 
                                       name = 'name-en-US' 
                                       defaultValue = { profile['name-en-US'] } 
                                       onInput = { changeUserInfo } />
                            ) }

                            { language === 'ru-RU' && (
                                <Input label = { translate('Abbreviation (for English version)', language) } 
                                       name = 'abbreviation-en-US' 
                                       defaultValue = { profile['abbreviation-en-US'] } 
                                       onInput = { changeUserInfo } />
                            ) }
                        </div>

                        <div className = 'FilmSchoolGeneral__img'>
                            <ImageWithCropper src = { getImageFullPath(profile.photo) }
                                              modalOpened = { imageUploadModalOpened }
                                              setModalOpened = { setImageUploadModalOpened }
                                              size = 'md'
                                              requirements = {{ minWidth: 400, minHeight: 400 }}
                                              settings = {{ minCropBoxWidth: 50, minCropBoxHeight: 50, aspectRatio: 1 / 1 }}
                                              bgImg = 'mountain'
                                              buttonText = { translate('Change photo', language) }
                                              onImageLoadOnServer = { changeCabinetPhoto }
                                              onRemoveClick = { removePhoto } />
                        </div>
                    </div>
                </div>
            </div>
        </CabinetSection> 
    )
}));

export { FilmSchoolGeneral };