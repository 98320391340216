import React, { useState, useEffect, useRef } from 'react';

import { SelectDropdown, Icon } from 'components/ui';

import classNames from 'classnames';

import './_SelectFilter.scss';

const SelectFilter = ({ current, items = [], selected, actionButton, onWindowClick, onDropdownItemClick }) => {
    const [active, setActive] = useState(false);

    const selectRef = useRef(null);

    const SelectFilterClass = classNames('SelectFilter', {
        'SelectFilter--active': active && items.length > 0,
        'SelectFilter--selected': selected
    });

    // Отлов клика по элементу выпадающего списка
    const handleDropdownItemClick = event => {
        onDropdownItemClick && onDropdownItemClick(event);
        setActive(false);
    };

    // Отлов клика на окне браузера
    const handleWindowClick = event => {
        const select = event.target.closest('.SelectFilter');

        if (!select || select !== selectRef.current ) {
            onWindowClick && onWindowClick(event);
            setActive(false);
        }
    };

    // Отлов клика по текущему элементу
    const handleCurrentItemClick = () => {
        setActive(true);
    };

    // Добавление обработчиков событий и их сброс вместе с ссылкой на поле выбора
    useEffect(() => {
        window.addEventListener('click', handleWindowClick);

        return () => {
            window.removeEventListener('click', handleWindowClick);
            selectRef.current = null;
        }
    }, []);

    return (
        <div className = { SelectFilterClass } ref = { selectRef }>
            <div className = 'SelectFilter__current' onClick = { handleCurrentItemClick }>
                <span className = 'SelectFilter__currentText'>{ current }</span>
                <Icon name = 'angle' className = 'SelectFilter__currentIcon' />
            </div>

            <SelectDropdown items = { items } 
                            actionButton = { actionButton } 
                            onItemClick = { handleDropdownItemClick } />
        </div>
    )
};

export { SelectFilter };