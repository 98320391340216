import React from 'react';

import { SearchesListItem } from 'components/pages';

import './_SearchesList.scss';

const SearchesList = ({ searches }) => (
    <div className = 'SearchesList'>
        { searches.reverse().map(search => (
            <SearchesListItem key = { search.id } search = { search } />
        )) }
    </div>
);

export { SearchesList };