import React, { useState } from 'react';

import { Modal } from 'components/containers';
import { Button, Input, SelectPure, FilterSelect, ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, isValidEmail } from 'helpers';
import { PHONE_CODES } from 'constants/constants';

import './_NewAgentModal.scss';

const NewAgentModal = inject('LanguageStore', 'UserStore')(observer(({ modalOpened, setModalOpened, onInvite, LanguageStore, UserStore }) => {
    const { language } = LanguageStore;
    const { inviteUser } = UserStore;

    const [agent, setAgent] = useState({ name: '', surname: '', email: '', direction: 'no_select', phoneCode: '+7', phoneWithoutCode: '', emailLanguage: language.split('-')[0] });

    const { name, surname, email, direction, emailLanguage, phoneCode, phoneWithoutCode } = agent;

    const isEnoughData = name && surname && isValidEmail(email);

    const currentPhoneCode = PHONE_CODES.find(code => code.code === phoneCode);
    
    const preparedDirections = [{
        id: 0,
        items: [
            { id: 0, text: translate('Film-1', language), selected: direction === 'movie', data: 'movie' },
            { id: 1, text: translate('Advertising', language), selected: direction === 'adv', data: 'adv' },
            { id: 2, text: translate('Without direction', language), selected: direction === 'no_select', data: 'no_select' },
        ]
    }];

    const selectInputProps = {
        label: translate('Direction', language),
        value: preparedDirections[0].items.find(item => item.selected).text
    };

    // Отлов ввода в поле ввода
    const handleInputCnange = event => {
        const input = event.target;
        const value = input.value.trim();
        const name = input.name;

        setAgent(prevAgent => ({ ...prevAgent, [name]: value }));
    };

    // Выбор направления агента
    const chooseDirection = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        setAgent(prevAgent => ({ ...prevAgent, direction: data }));
    };

    // Выбор кода страны(телефон)
    const choosePhoneCode = ({ item }) => {
        if (item.code !== phoneCode) {
            setAgent(prevAgent => ({ ...prevAgent, phoneCode: item.code }));
        }
    };

    // Смена телефона
    const changePhone = event => {
        const input = event.target;
        const value = input.value.replace(/\D/g, '');

        setAgent(prevAgent => ({ ...prevAgent, phoneWithoutCode: value }));
    };

    // Выбор языка письма
    const chooseEmailLanguage = event => {
        const radio = event.target;
        const value = radio.value;

        setAgent(prevAgent => ({ ...prevAgent, emailLanguage: value }));
    };

    // Пригласить агента
    const inviteAgent = () => {
        inviteUser({
            [`name-${language}`]: name,
            [`surname-${language}`]: surname,
            email: email,
            emailLanguage: emailLanguage,
            phone: phoneWithoutCode ? `${phoneCode} ${phoneWithoutCode}` : '',
            cabinetType: 'agent',
        }).then(data => onInvite(data, direction))
          .then(() => setModalOpened(false));
    };

    return (
        <Modal title = { translate('New agent', language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'NewAgentModal'>
                <Input label = { translate('PersonName', language) } name = 'name' required onInput = { handleInputCnange } />
                <Input label = { translate('Surname', language) } name = 'surname' required onInput = { handleInputCnange } />

                <SelectPure inputProps = { selectInputProps }
                            items = { preparedDirections }
                            onDropdownItemClick = { chooseDirection } />

                <Input label = { `Email ${translate('For invitation', language).toLowerCase()}` } 
                       name = 'email' 
                       required 
                       onInput = { handleInputCnange } />

                <div className = 'NewAgentModal__phone'>
                    <FilterSelect type = 'phone'
                                  current = { currentPhoneCode }
                                  items = { PHONE_CODES }
                                  onChoose = { choosePhoneCode } />

                    <Input label = { translate('Phone', language) } 
                           value = { phoneWithoutCode }
                           onChangeHandle = { changePhone } />
                </div>
            </div>

            <div className = 'Modal__actions'>
                <div className = 'Modal__actionsSection Modal__actionsSection--left'>
                    <div className = 'NewAgentModal__emailLanguage'>
                        <span className = 'NewAgentModal__emailLanguageText'>
                            { translate('The text of the invitation', language) }
                        </span>

                        <ChooseBox type = 'radio'
                                   value = 'ru' 
                                   checked = { emailLanguage === 'ru' }
                                   size = 'lg-sm'
                                   text = { translate('In Russian', language).toLowerCase() }
                                   onChange = { chooseEmailLanguage } />
                        
                        <ChooseBox type = 'radio'  
                                   value = 'en' 
                                   checked = { emailLanguage === 'en' }
                                   size = 'lg-sm'
                                   text = { translate('In English', language).toLowerCase() }
                                   onChange = { chooseEmailLanguage } />
                    </div>
                </div>

                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: translate('Add', language) }} 
                            disabled = { !isEnoughData }
                            onClick = { inviteAgent } />
                </div>
            </div>
        </Modal>
    )
}));

export { NewAgentModal };