export const routeNames = {
    '/': 'Personal data',
    '/works': 'Works',
    '/statistics': 'Statistics',
    '/bookmarks': 'Bookmarks',
    '/tabs': 'To display in the profile',
    '/searches': 'Searches',
    '/requests': 'Requests',
    '/payment': 'Payment',
    '/month-free': 'Month free',
    '/settings': 'Settings',
    '/contractors': 'Contractors',
    '/graduates': 'Graduates',
    '/works/mode/general': 'General information',
    '/works/mode/team': 'Team',
    '/works/mode/characteristics': 'Characteristics',
    '/works/mode/preparation': 'Preparation',
    '/works/mode/backstage': 'Backstage',
    '/works/mode/festivals-and-awards': 'Festivals and awards',
    '/works/mode/skills': 'Skills',
    '/works/mode/image-property': 'Image property',
    '/works/mode/lighting-and-style': 'Lighting and style',
    '/works/mode/additional-equipment': 'Additional equipment',
    '/works/mode/locations-time-weather': 'Locations, time, weather',
    '/works/mode/budgets': 'Budgets',
    '/page-not-found': 'Page not found'
};