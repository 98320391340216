import React, { useState, useEffect, useCallback } from 'react';

import { Modal } from 'components/containers';
import { Button, Input, SelectPure, ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';

import classNames from 'classnames';

import './_NewProfessionModal.scss';

const NewProfessionModal = inject('LanguageStore', 'ProfessionsStore', 'CabinetStore', 'InvoicesStore')(observer(({ modalOpened, selectActionButton, setModalOpened, InvoicesStore, LanguageStore, ProfessionsStore, CabinetStore }) => {
    const [selectInputValue, setSelectInputValue] = useState(undefined);
    const [currentClassifications, setCurrentClassifications] = useState([]);
    const [currentClassification, setCurrentClassification] = useState('');

    const { language } = LanguageStore;
    const { getInvoices } = InvoicesStore;
    const { professions, currentProfession, findProfession, updateCurrentProfessionLocal } = ProfessionsStore;
    const { cabinet, updateCabinetSection, updateCabinetSectionErrors, getAllSubscriptions } = CabinetStore;

    const { speciality } = cabinet.personalData;

    const preparedProfessions = professions.length > 0 && professions.map(depart => ({
        id: depart.id,
        title: depart[`name-${language}`] || depart['name-ru-RU'],
        items: depart.profession.map(profession => {
            const isChosen = currentProfession.id === profession.id && currentProfession.departId === depart.id;
            const isExistProfession = Boolean(speciality.find(item => item.professionId === profession.id && item.departId === depart.id));

            return {
                id: profession.id,
                text: profession[`name-${language}`] || profession['name-ru-RU'],
                selected: isChosen || isExistProfession,
                data: { ...profession, departId: depart.id }
            }
        })
    }));

    const selectInputsProps = {
        label: translate('Name', language),
        value: selectInputValue !== undefined ? selectInputValue : currentProfession[`name-${language}`]
    };

    // Поиск профессия с задержкой
    const findProfessionDebounced = useCallback(
        debounce(value => findProfession(value === '' ? '*' : value), 350)    
    , []);

    // Отлов фокуса на поле ввода
    const handleSelectInputFocus = () => {
        findProfession('*');
    };

    // Отлов ввода в поле ввода
    const handleSelectInputChange = event => {
        const input = event.target;
        const value = input.value;

        setSelectInputValue(value);

        findProfessionDebounced(value.trim());
    };

    // Отлов клика по элементу в поле выбора
    const handleSelectDropdownItemClick = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const isChosen = currentProfession.id === data.id && currentProfession.departId === data.departId;
        const isExistProfession = Boolean(speciality.find(item => item.professionId === data.id && item.departId === data.departId));

        if (!isExistProfession && !isChosen) {
            setSelectInputValue(undefined);
            setCurrentClassifications(data.classification);
            setCurrentClassification(data.classification[0]);

            updateCurrentProfessionLocal(data);
        }
    };

    // Обновление даты у текущей работы
    const updateCurrentProfessionDate = event => {
        const input = event.target;
        const value = input.value.trim();

        updateCurrentProfessionLocal({ date: `${value}-01-01` });
        updateCabinetSectionErrors('speciality', { workStart: null });
    };

    // Изменение классификации
    const changeClassification = event => {
        const radio = event.target;
        const classification = radio.value;

        setCurrentClassification(classification);
    };

    // Добавление профессии
    const addProfession = () => {
        const workStart = /\b\d{4}-01-01\b/.test(currentProfession.date) ? currentProfession.date : `${new Date().getFullYear()}-01-01`;

        updateCabinetSection('speciality', { 
            professionId: currentProfession.id,
            classification: currentClassification,
            departId: currentProfession.departId === 0 ? null : currentProfession.departId,
            workStart: workStart
        }).then(async() => {
            await getAllSubscriptions();
            await getInvoices();

            setModalOpened(false)
        })
          .catch(() => {});
    };

    // Очистка текущей работы
    useEffect(() => {
        return () => {
            updateCurrentProfessionLocal({}, true);
            updateCabinetSectionErrors('speciality', { workStart: null });
        }
    }, []);

    return (
        <Modal title = { translate('Choose speciality', language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'NewProfessionModal'>
                <SelectPure inputProps = { selectInputsProps }
                            items = { preparedProfessions || [] }
                            actionButton = { selectActionButton }
                            onInputFocus = { handleSelectInputFocus }
                            onInputChange = { handleSelectInputChange }
                            onDropdownItemClick = { handleSelectDropdownItemClick } />

                <Input label = { translate('Since what year', language) }
                       value = { currentProfession.date !== undefined ? currentProfession.date.split('-')[0] : '' }
                       error = { cabinet.errors.speciality && cabinet.errors.speciality.workStart }
                       onChangeHandle = { updateCurrentProfessionDate } />

                { currentClassifications.map(classification => {
                    const classificationCountsClass = classNames('NewProfessionModal__classficationCounts', {
                        'NewProfessionModal__classficationCounts--selected': (['pro', 'new-blood'].includes(classification) && currentClassification === classification) || !['pro', 'new-blood'].includes(classification)
                    });
                    
                    return (
                        <div className = 'NewProfessionModal__classfication' key = { classification }>
                            { ['pro', 'new-blood'].includes(classification) && (
                                <ChooseBox type = 'radio'
                                           name = 'classification'
                                           value = { classification }
                                           icon = { `${classification}-${language}` }
                                           size = 'lg'
                                           checked = { classification === currentClassification }
                                           onChange = { changeClassification } />
                            ) }   
                            
                            <div className = 'NewProfessionModal__classficationInfo'>
                                <div className = { classificationCountsClass }>
                                    <span className = 'NewProfessionModal__classficationCount'>
                                        { Math.round(currentProfession.priceList[classification].rubYear / 12) } ₽/{ translate('MonthShort', language).toLowerCase() } —&nbsp;
                                        { translate('When paying for a year', language).toLowerCase() }
                                    </span>
                                    
                                    <span className = 'NewProfessionModal__classficationCount'>
                                        { currentProfession.priceList[classification].rubMonth } ₽/{ translate('MonthShort', language).toLowerCase() } —&nbsp;
                                        { translate('When paying for a month', language).toLowerCase() }
                                    </span>
                                </div>

                                { ['pro', 'new-blood'].includes(classification) && (
                                    <span className = 'NewProfessionModal__classficationText'>
                                        { classification === 'pro' ?  translate('Among the works, you must have 1 full-length film or 10 commercial commercials, or 10 music videos', language) : translate('Who starts, but has already made interesting projects', language) }
                                    </span>
                                ) }
                            </div>
                        </div>
                    )
                }) }
            </div>

            <div className = 'Modal__actions'>
                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: translate('Add', language) }}
                            disabled = { !currentProfession.id }
                            onClick = { addProfession } />
                </div>
            </div>
        </Modal>
    )
}));

export { NewProfessionModal };