import React from 'react';
import { inject, observer } from 'mobx-react';

import { Icon } from 'components/ui';

import classNames from 'classnames';

import './_Token.scss';

const Token = inject('LanguageStore')(observer(({ LanguageStore, text, data, canDelete = false, handleRemoveIconClick, handlers, ...restProps }) => {
    const { language } = LanguageStore;

    const TokenClass = classNames('Token', {
        'Token--removable': canDelete
    });

    return (
        <div className = { TokenClass } data-data = { JSON.stringify(data) } { ...restProps }>
            <span className = 'Token__text'>{ data[`name-${language}`] || text }</span>

            { canDelete && (
                <Icon name = 'cross-tiny' className = 'Token__icon Token__icon--cross' onClick = { handlers.handleRemoveIconClick } />
            ) }
        </div>
    )
}));

export { Token };