import React, { useState } from 'react';

import { PaymentExpensesTable } from 'components/pages';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_PaymentExpenses.scss';

const PaymentExpenses = inject('LanguageStore', 'InvoicesStore')(observer(({ prepareSubscription, LanguageStore, InvoicesStore }) => {
    const [filters, setFilters] = useState({
        'all': { text: 'All', active: true },
        'paid': { text: 'PaidSeveral', active: false },
        'not-paid': { text: 'UnpaidSeveral', active: false }
    });

    const { language } = LanguageStore;
    const { invoices } = InvoicesStore;

    const [filteredInvoices, setFilteredInvoices] = useState(null);

    // Выбор фильра
    const chooseFilter = apiName => {
        const reversedInvoices = invoices.reverse();
        const filterIsActive = filters[apiName].active;
        const activeFilterName = Object.keys(filters).find(key => filters[key].active);

        if (!filterIsActive) {
            switch(apiName) {
                case 'all':
                    setFilteredInvoices(reversedInvoices);
                    break;
                case 'paid':
                    setFilteredInvoices(reversedInvoices.filter(invoice => invoice.status === 'payed'));
                    break;
                case 'not-paid':
                    setFilteredInvoices(reversedInvoices.filter(invoice => invoice.status === 'no_payed'));
                    break;
            }

            setFilters(prevFilters => ({
                ...prevFilters,
                [activeFilterName]: { ...prevFilters[activeFilterName], active: false },
                [apiName]: { ...prevFilters[apiName], active: true }
            }));
        }
    };

    return (
        <div className = 'Payment__section'>
            <div className = 'Payment__sectionTitle'>
                <span className = 'Payment__sectionTitleText'>{ translate('Expenses', language) }</span>

                <div className = 'PaymentExpenses__filters'>
                    { Object.keys(filters).map(key => {
                        const filterItemClass = classNames('PaymentExpenses__filtersItem', {
                            'PaymentExpenses__filtersItem--active': filters[key].active
                        });

                        return (
                            <div key = { key } className = { filterItemClass } onClick = { chooseFilter.bind(null, key) }>
                                { translate(filters[key].text, language) }
                            </div>
                        )
                    }) }
                </div>
            </div>

            <div className = 'Payment__sectionContent'>
                <PaymentExpensesTable items = { filteredInvoices || invoices.reverse() } prepareSubscription = { prepareSubscription } />
            </div>
        </div>
    )
}));

export { PaymentExpenses };