// Получение id видео из ссылки
function getYouTubeVideoId(url) {
    let id = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (id[2] !== undefined) {
        id = id[2].split(/[^0-9a-z_\-]/i);
        id = id[0];
    }

    return id;
}

export { getYouTubeVideoId };