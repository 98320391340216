import React from 'react';

import { PaymentCard } from 'components/pages';
import { CabinetSection } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_PaymentOther.scss';

const PaymentOther = inject('LanguageStore', 'CabinetStore', 'UserStore')(observer(({ titles, prepareSubscription, LanguageStore, CabinetStore, UserStore }) => {
    const { language } = LanguageStore;
    const { subscriptions } = CabinetStore;
    const { user } = UserStore;

    const otherCabinetSubscriptions = Object.keys(subscriptions).filter(cabinet => {
        return cabinet !== user.activeCabinet && subscriptions[cabinet] !== null
    });

    return otherCabinetSubscriptions.length > 0 && (
        <div className = 'Payment__section'>
            <div className = 'Payment__sectionTitle'>
                <span className = 'Payment__sectionTitleText'>{ translate('Subscriptions of other personal accounts', language) }</span>
            </div>

            <div className = 'Payment__sectionContent'>
                <div className = 'PaymentOther'>
                    { otherCabinetSubscriptions.map(cabinet => (
                        <CabinetSection key = { cabinet } sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                            {/* <div className = 'CabinetSection__title' style = {{ gridAutoColumns: 'auto' }}>
                                <span className = 'CabinetSection__titleText'>{ translate(titles[cabinet], language) }</span>
                            </div> */}

                            <div className = 'CabinteSection__content'>
                                <div className = 'PaymentOther__list'>
                                    { subscriptions[cabinet].map(subscription => (
                                        <PaymentCard key = { subscription.id } subscription = { prepareSubscription(subscription, cabinet) } />
                                    )) }
                                </div>
                            </div>
                        </CabinetSection>
                    )) }
                </div>
            </div>
        </div>
    )
}));

export { PaymentOther };