import React from 'react';

import { Icon } from 'components/ui';

import './_More.scss';

const More = ({ ...restProps }) => (
    <div className = 'More' { ...restProps }>
        <Icon name = 'points' className = 'More__icon' />
    </div>
);

export { More };