import React, { useState, useEffect, useCallback } from 'react';

import { Modal } from 'components/containers';
import { Button, SelectPure, Input, FilterSelect, ChooseBox, SelectFilter } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, getExecutorName, debounce } from 'helpers';
import { PHONE_CODES } from 'constants/constants';

import './_InviteExecutorAgentModal.scss';

const InviteExecutorAgentModal = inject('LanguageStore', 'CabinetStore', 'ProfessionsStore', 'UserStore')(observer(({ modalOpened, setModalOpened, onInvite, agentExecutors, UserStore, LanguageStore, CabinetStore, ProfessionsStore }) => {
    const [executors, setExecutors] = useState([]);
    const [currentExecutor, setCurrentExecutor] = useState({});

    const [selectInputValue, setSelectInputValue] = useState(undefined);
    const [selectProfessionInputValue, setSelectProfessionInputValue] = useState(undefined);

    const [isDetailedProfession, setIsDetailedProfession] = useState(false);
    const [isNewExecutor, setIsNewExecutor] = useState(false);

    const [currentClassifications, setCurrentClassifications] = useState([]);
    const [currentClassification, setCurrentClassification] = useState('');

    const [showClassification, setShowClassification] = useState(false);

    const { language } = LanguageStore;
    const { searchInCabinet } = CabinetStore;
    const { user, inviteUser } = UserStore;
    const { professions, departs, structs, currentProfession, findDepart, findStruct, findProfession, updateCurrentProfessionLocal, clearCurrentProfession } = ProfessionsStore;

    const { name, surname, email, emailLanguage, phoneCode, phoneWithoutCode } = currentExecutor;

    const { email: emailErrors, phone: phoneErrors, name: nameErrors, surname: surnameErrors } = user &&  user.inviteErrors ? user.inviteErrors : {};

    const isEnoughData = name && surname && email && (phoneWithoutCode && phoneWithoutCode.length > 9 && phoneWithoutCode.length < 13);

    const currentPhoneCode = PHONE_CODES.find(code => code.code === phoneCode);

    const selectInputProps = {
        label: translate('Executor', language),
        value: selectInputValue !== undefined ? selectInputValue : currentExecutor.validName
    };

    const selectSpecialistActionButton = {
        text: translate('Invite a new executor', language),
        restProps: {
            onClick: () => {
                setCurrentExecutor({ phoneCode: '+7', emailLanguage: language.split('-')[0] });
                setIsNewExecutor(true);
            }
        }
    };

    const selectProfessionInputsProps = {
        label: translate('Name', language),
        value: selectProfessionInputValue !== undefined ? selectProfessionInputValue : currentProfession[`name-${language}`]
    };

    const selectProfessionActionButton = {
        text: `${translate('Select manually', language)}...`,
        restProps: {
            onClick: () => {
                setCurrentClassifications([]);
                setCurrentClassification('');
                findStruct('*');
                setIsDetailedProfession(true);
            }
        }
    };

    const preparedExecutors = [{
        id: 0,
        items: executors.map(executor => {
            const validName = getExecutorName(executor, language);
            const isExecutorExist = Boolean(agentExecutors.find(item => item.executorCabinetId === executor.cabinet_id));

            return {
                id: executor.cabinet_id,
                text: validName,
                selected: executor.cabinet_id === currentExecutor.cabinet_id || isExecutorExist,
                data: { validName, ...executor }
            }
        })
    }];
    
    const preparedProfessions = professions.length > 0 && professions.map(depart => ({
        id: depart.id,
        title: depart[`name-${language}`] || depart['name-ru-RU'],
        items: depart.profession.map(profession => {
            const isChosen = currentProfession.id === profession.id && currentProfession.departId === depart.id;
            // TODO: почему-то существующие профессии специалиста были выбраными и их нельзя было выбрать при инвайте агенту
            // const isExistProfession = currentExecutor.speciality && Boolean(currentExecutor.speciality.find(item => item.professionId === profession.id && item.departId === depart.id));

            return {
                id: profession.id,
                text: profession[`name-${language}`] || profession['name-ru-RU'],
                // selected: isChosen || isExistProfession,
                selected: isChosen,
                data: { ...profession, departId: depart.id }
            }
        })
    }));

    const isNewExecutorValid = isNewExecutor && isEnoughData && currentProfession.id !== undefined;
    const isExistExecutorValid = !isNewExecutor && currentExecutor.cabinet_id && currentProfession.id !== undefined;
    
    const isInviteButtonDisabled = !isNewExecutorValid && !isExistExecutorValid;

    // Поиск исполнителей с задержкой
    const findExecutorDebounced = useCallback(
        debounce(value => {
            const checkedValue = value === '' ? '*' : value;
            
            const searchObj = { 
                name: checkedValue, 
                name_en: checkedValue, 
                surname: checkedValue, 
                surname_en: checkedValue
            };

            searchInCabinet('executor', 'personalData', 'profile', 'key', searchObj, result => {
                setExecutors(result);
            });
        }, 350) 
    , []);

    // Поиск профессия с задержкой
    const findProfessionDebounced = useCallback(
        debounce(value => findProfession(value === '' ? '*' : value), 350)    
    , []);

    // Отлов фокуса на поле ввода с профессией
    const focusProfessionSelect = () => findProfession('*');

    // Изменение селекта профессии
    const changeProfessionSelect = event => {
        const input = event.target;
        const value = input.value;

        setSelectProfessionInputValue(value);

        findProfessionDebounced(value.trim());
    };

    // Отлов клика по элементу в поле выбора
    const chooseSelectProfession = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const isChosen = currentProfession.id === data.id && currentProfession.departId === data.departId;
        // TODO: почему-то существующие профессии специалиста были выбраными и их нельзя было выбрать при инвайте агенту
        // const isExistProfession = currentExecutor.speciality && Boolean(currentExecutor.speciality.find(item => item.professionId === data.id && item.departId === data.departId));

        // if (!isChosen && !isExistProfession) {
        if (!isChosen) {
            setSelectInputValue(undefined);

            // Получаем классификацию выбранного специалиста по этой профессии
            const executorClassification = currentExecutor && currentExecutor.specialities ? currentExecutor.specialities.filter((speciality) => {
                return speciality.professionId === data.id;
            }) : [];

            setShowClassification(!executorClassification.length);
            setCurrentClassifications(data.classification);
            setCurrentClassification(!!executorClassification.length && executorClassification[0].classification || data.classification[0]);

            updateCurrentProfessionLocal(data);
        }
    };

    // Поиск исполнителей
    const searchExecutors = event => {
        const input = event.target;
        const value = input.value;

        setSelectInputValue(value);
        findExecutorDebounced(value.trim());
    };

    // Выбор исполнителя
    const chooseExecutor = event => {
        const item = event.currentTarget;
        const executor = JSON.parse(item.dataset.data);

        const validName = getExecutorName(executor, language);

        setSelectInputValue(validName);
        setCurrentExecutor(executor);
    };

    // Приглашение исполнителя
    const inviteExecutor = () => {
        if (isNewExecutor) {
            inviteUser({
                [`name-${language}`]: name,
                [`surname-${language}`]: surname,
                email: email,
                emailLanguage: emailLanguage,
                phone: `${phoneCode} ${phoneWithoutCode}`,
                workId: currentProfession && currentProfession.id || '',
                cabinetType: 'executor',
            }).then(data => onInvite(data, currentClassification, currentProfession))
        } else {
            onInvite(currentExecutor, currentClassification, currentProfession);
        }
    };

    // Отлов ввода в поле ввода
    const changeNewExecutor = event => {
        const input = event.target;
        const value = input.value.trim();
        const name = input.name;

        setCurrentExecutor(prevCurrentExecutor => ({ ...prevCurrentExecutor, [name]: value }));
    };

    // Выбор кода страны(телефон)
    const choosePhoneCode = ({ item }) => {
        if (item.code !== phoneCode) {
            setCurrentExecutor(prevCurrentExecutor => ({ ...prevCurrentExecutor, phoneCode: item.code }));
        }
    };

    // Смена телефона
    const changePhone = event => {
        const input = event.target;
        const value = input.value.replace(/\D/g, '');

        setCurrentExecutor(prevCurrentExecutor => ({ ...prevCurrentExecutor, phoneWithoutCode: value }));
    };

    // Изменение классификации
    const changeClassification = event => {
        const radio = event.target;
        const classification = radio.value;

        setCurrentClassification(classification);
    };

    // Выбор состава
    const chooseItemComposition = event => {
        const chooseBox = event.target;
        const compositionId = JSON.parse(chooseBox.dataset.id);

        findDepart(compositionId);
        updateCurrentProfessionLocal({}, true);
    };

    // Отлов клика по элементу списка в поле выбора
    const handleSelectDropdownItemClick = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const isChosen = currentProfession.id === data.id && currentProfession.departId === data.departId;
        const isExistProfession = Boolean(currentExecutor.speciality && currentExecutor.speciality.find(item => item.professionId === data.id && item.departId === data.departId));

        if (!isExistProfession && !isChosen) {
            setCurrentClassifications(data.classification);
            setCurrentClassification(data.classification[0]);

            updateCurrentProfessionLocal(data);
        }
    };

    // Выбор профессии(radio)
    const chooseProfessionRadio = profession => {
        setCurrentClassifications(profession.classification);
        setCurrentClassification(profession.classification[0]);

        updateCurrentProfessionLocal(profession);
    };

    // Выбор языка письма
    const chooseEmailLanguage = event => {
        const radio = event.target;
        const value = radio.value;

        setCurrentExecutor(prevCurrentExecutor => ({ ...prevCurrentExecutor, emailLanguage: value }));
    };

    // Очистка текущей работы
    useEffect(() => {
        return () => {
            updateCurrentProfessionLocal({}, true);
        }
    }, []);

    return (
        <Modal title = { translate('Invite a executor', language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'InviteExecutorAgentModal'>
                { isNewExecutor ? (
                    <div className = 'InviteExecutorAgentModal__newExecutor'>
                        <Input 
                            label = { translate('PersonName', language) } 
                            name = 'name' 
                            required 
                            error={ nameErrors }
                            onInput = { changeNewExecutor } />
                        <Input 
                            label = { translate('Surname', language) } 
                            name = 'surname' 
                            required 
                            error={ surnameErrors }
                            onInput = { changeNewExecutor } />
        
                        <Input label = { `Email ${translate('For invitation', language).toLowerCase()}` } 
                               name = 'email' 
                               required
                               error={ emailErrors }
                               onInput = { changeNewExecutor } />
        
                        <div className = 'InviteExecutorAgentModal__newExecutorPhone'>
                            <FilterSelect type = 'phone'
                                          current = { currentPhoneCode }
                                          items = { PHONE_CODES }
                                          onChoose = { choosePhoneCode } />
        
                            <Input label = { translate('Phone', language) } 
                                   value = { phoneWithoutCode }
                                   error= { phoneErrors }
                                   required
                                   onChangeHandle = { changePhone } >
                            </Input>
                        </div>
                    </div>
                ) : (
                    <SelectPure inputProps = { selectInputProps }
                                items = { preparedExecutors }
                                actionButton = { selectSpecialistActionButton }
                                onInputChange = { searchExecutors }
                                onInputFocus = { () => findExecutorDebounced('*') }
                                onDropdownItemClick = { chooseExecutor } />
                ) }
                
                { Object.keys(currentExecutor).length > 0 && (
                    <div className = 'InviteExecutorAgentModal__section'>
                        <span className = 'InviteExecutorAgentModal__sectionTitle'>
                            { translate('Profession', language) }
                        </span>

                        <div className = 'InviteExecutorAgentModal__sectionContent'>
                            { isDetailedProfession ? (
                                <div className = 'InviteExecutorAgentModal__info'>
                                    <div className = 'InviteExecutorAgentModal__infoSection'>
                                        <span className = 'InviteExecutorAgentModal__infoSectionTitle'>
                                            { translate('Composition', language) }
                                        </span>
                
                                        <div className = 'InviteExecutorAgentModal__infoSectionContent'>
                                            { structs.map(struct => (
                                                <ChooseBox key = { struct.id }
                                                           name = 'composition'
                                                           data-id = { struct.id }
                                                           type = 'radio'
                                                           size = 'lg' 
                                                           text = { struct[`name-${language}`] || struct['name-ru-RU'] }
                                                           onChange = { chooseItemComposition } />
                                            )) }
                                        </div>
                                    </div>
                
                                    { departs.length > 0 && (
                                        <div className = 'InviteExecutorAgentModal__infoSection'>
                                            <span className = 'InviteExecutorAgentModal__infoSectionTitle'>
                                                { translate('Profession', language) }
                                            </span>
                
                                            <div className = 'InviteExecutorAgentModal__infoSectionContent'>
                                                { departs.map(depart => {
                                                    const currentText = currentProfession.departId === depart.id ? currentProfession[`name-${language}`] : depart[`name-${language}`];
                                                    const preparedProfessions = [{
                                                        id: depart.id,
                                                        title: depart[`name-${language}`],
                                                        items: depart.profession.map(profession => {
                                                            const isChosen = currentProfession.id === profession.id && currentProfession.departId === depart.id;
                                                            const isExistProfession = Boolean(currentExecutor.speciality && currentExecutor.speciality.find(item => item.professionId === profession.id && item.departId === depart.id));
                                                            return {
                                                                id: profession.id,
                                                                text: profession[`name-${language}`] || profession['name-ru-RU'],
                                                                selected: isChosen || isExistProfession,
                                                                data: { ...profession, departId: depart.id }
                                                            }
                                                        })
                                                    }];
                                                    return depart.id !== null ? (
                                                        <SelectFilter key = { depart.id }
                                                                    current = {currentText }
                                                                    selected = { currentProfession.departId === depart.id }
                                                                    items = { preparedProfessions }
                                                                    onDropdownItemClick = { handleSelectDropdownItemClick } />
                                                    ) : (
                                                        ( depart.profession.map(profession => {
                                                            const isExistProfession = Boolean(currentExecutor.speciality && currentExecutor.speciality.find(item => item.professionId === profession.id && item.departId === depart.id));
                                                            return (
                                                                <ChooseBox key = { profession.id }
                                                                        type = 'radio'
                                                                        name = 'profession'
                                                                        size = 'lg'
                                                                        disabled = { isExistProfession }
                                                                        text = { profession[`name-${language}`] || profession['name-ru-RU'] }
                                                                        onChange = { chooseProfessionRadio.bind(null, profession) } />
                                                            )
                                                        }) )
                                                    )
                                                }) }
                                            </div>
                                        </div>
                                    ) }
                                </div>
                            ) : (
                                <SelectPure inputProps = { selectProfessionInputsProps }
                                            items = { preparedProfessions || [] }
                                            actionButton = { selectProfessionActionButton }
                                            onInputFocus = { focusProfessionSelect }
                                            onInputChange = { changeProfessionSelect }
                                            onDropdownItemClick = { chooseSelectProfession } />
                            ) }
                        </div>
                    </div>
                ) }

                { showClassification && currentClassifications.length > 0 && Object.keys(currentProfession).length > 0 && (
                    <div className = 'InviteExecutorAgentModal__infoSection'>
                        <span className = 'InviteExecutorAgentModal__infoSectionTitle'>
                            { translate('Classification', language) }
                        </span>

                        <div className = 'InviteExecutorAgentModal__infoSectionContent'>
                            { currentClassifications.map(classification => (
                                <div className = 'InviteExecutorAgentModal__classfication' key = { classification }>
                                    { ['pro', 'new-blood'].includes(classification) && (
                                        <ChooseBox type = 'radio'
                                                   name = 'classification'
                                                   value = { classification }
                                                   icon = { `${classification}-${language}` }
                                                   size = 'lg'
                                                   checked = { classification === currentClassification }
                                                   onChange = { changeClassification } />
                                    ) }   
                                    
                                    <div className = 'InviteExecutorAgentModal__classficationInfo'>
                                        { ['pro', 'new-blood'].includes(classification) && (
                                            <span className = 'InviteExecutorAgentModal__classficationText'>
                                                { classification === 'pro' ?
                                                    translate('Who works for a long time and has more than one award, a full-length film or a dozen videos', language)
                                                : translate('Who starts, but has already made interesting projects', language) }
                                            </span>
                                        ) }
                                    </div>
                                </div>
                            )) }
            
                            { currentClassifications.length === 1 && (
                                <div className = 'InviteExecutorAgentModal__classficationText'>
                                    { translate('The chosen profession has no different classifications', language) }
                                </div>
                            ) }
                        </div>
                    </div>
                ) }

                <span className = 'InviteExecutorAgentModal__text'>
                    { translate('Sent with the prior oral consent of the recipient for cooperation', language) }
                </span>
            </div>

            <div className = 'Modal__actions'>
                { isNewExecutor && (
                    <div className = 'Modal__actionsSection Modal__actionsSection--left'>
                        <div className = 'NewExecutorModal__emailLanguage'>
                            <span className = 'NewExecutorModal__emailLanguageText'>
                                { translate('The text of the invitation', language) }
                            </span>

                            <ChooseBox type = 'radio'
                                       value = 'ru' 
                                       checked = { emailLanguage === 'ru' }
                                       size = 'lg-sm'
                                       text = { translate('In Russian', language).toLowerCase() }
                                       onChange = { chooseEmailLanguage } />
                            
                            <ChooseBox type = 'radio'  
                                       value = 'en' 
                                       checked = { emailLanguage === 'en' }
                                       size = 'lg-sm'
                                       text = { translate('In English', language).toLowerCase() }
                                       onChange = { chooseEmailLanguage } />
                        </div>
                    </div>
                ) }

                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    { (isDetailedProfession || isNewExecutor) && (
                        <Button content = {{ text: translate('Cancel', language) }}
                                onClick = { setModalOpened.bind(null, false) } />
                    ) }
                    
                    <Button content = {{ text: translate('Invite', language) }} 
                            disabled = { isInviteButtonDisabled }
                            onClick = { inviteExecutor } />
                </div>
            </div>
        </Modal>
    )
}));

export { InviteExecutorAgentModal };