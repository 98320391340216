import React from 'react';

import { Icon } from 'components/ui';

import classNames from 'classnames';

import './_Button.scss';

const Button = ({ className, content, theme, disabled, pure, size, fluid, ...restProps }) => {
    const ButtonClass = classNames('Button', className, {
        'Button--theme-blue': theme === 'blue',
        'Button--theme-grey': theme === 'grey',
        'Button--size-sm': size === 'sm',
        'Button--pure': pure,
        'Button--fluid': fluid,
        'Button--disabled': disabled
    });

    return (
        <button className = { ButtonClass } { ...restProps }>
            <div className = 'Button__wrap'>
                { Object.keys(content).map(key => {
                    switch(key) {
                        case 'text':
                            return <span key = { key } className = 'Button__text'>{ content[key] }</span>
                        case 'icon':
                            return <Icon key = { key } name = { content[key] } className = 'Button__icon' />
                        default:
                            return null
                    }
                }) }
            </div>
        </button>
    )
};

export { Button }; 