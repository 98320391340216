import React from 'react';

import { PaymentCard } from 'components/pages';
import { Modal } from 'components/containers';
import { Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_CancelSubscriptionModal.scss';

const CancelSubscriptionModal = inject('LanguageStore', 'CabinetStore', 'InvoicesStore', 'UserStore')(observer(({ modalOpened, setModalOpened, subscription, UserStore, LanguageStore, CabinetStore, InvoicesStore }) => {
    const { language } = LanguageStore;
    const { getAllSubscriptions, updateCabinetSection } = CabinetStore;
    const { getInvoices } = InvoicesStore;
    const { getUser } = UserStore;

    // Закрытие модального окна
    const closeModal = () => setModalOpened(false);

    // Отмена подписки
    const cancelSubscription = () => {
        let promise = null;

        switch(subscription.cabinet) {
            case 'executor':
                promise = updateCabinetSection('speciality', { id: subscription.id, status: 'delete' }, {
                    cabinet: 'executor',
                    page: 'personalData'
                });

                break
            case 'studio':
                promise = updateCabinetSection('direction', { id: subscription.id, status: 'delete' }, {
                    cabinet: 'studio',
                    page: 'personalData'
                });

                break
            case 'client':
                promise = updateCabinetSection('subscribe', { id: subscription.id, status: 'delete' }, {
                    cabinet: 'client',
                    page: 'personalData'
                });

                break
            case 'agent':
                promise = updateCabinetSection('subscribe', { id: subscription.id, status: 'delete' }, {
                    cabinet: 'agent',
                    page: 'personalData'
                });

                break
            case 'filmSchool':
                promise = updateCabinetSection('subscribe', { id: subscription.id, status: 'delete' }, {
                    cabinet: 'filmSchool',
                    page: 'personalData'
                });

                break
        }

        promise.then(() => {
            setModalOpened(false);
            getAllSubscriptions();
            getInvoices();
            getUser();
        });
    };

    return (
        <Modal title = { translate('Do you really want to unsubscribe?', language) } opened = { modalOpened } setOpened = { closeModal }>
            <div className = 'CancelSubscriptionModal'>
                <PaymentCard subscription = { subscription } short = { true } />
            </div>

            <div className = 'Modal__actions Modal__actions--fluid'>
                <div className = 'Modal__actionsSection Modal__actionsSection--left'>
                    <Button content = {{ text: translate('No, leave', language) }}
                            fluid = { true }
                            onClick = { closeModal } />
                </div>

                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: translate('Yes, cancel', language) }}
                            fluid = { true }
                            onClick = { cancelSubscription } />
                </div>
            </div>
        </Modal>
    )
}));

export { CancelSubscriptionModal };