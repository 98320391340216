import React, { useState, useEffect } from 'react';

import { BookmarksScreenNav, BookmarksPeople, BookmarksCompanies, BookmarksWorks } from 'components/pages';

import { inject, observer } from 'mobx-react';

import './_Bookmarks.scss';

const Bookmarks = inject('BookmarksStore')(observer(({ BookmarksStore }) => {
    const [currentScreen, setCurrentScreen] = useState('peopleFolders');
    const [loadingScreens, setLoadingScreens] = useState({ peopleFolders: true, companiesFolders: false, workFolders: false });

    const { executorsFolders, workFolders, getExecutorsFolders, getWorksFolders } = BookmarksStore;

    const peopleFolders = executorsFolders.filter(folder => folder.type === 'executor');
    const companiesFolders = executorsFolders.filter(folder => folder.type === 'studio');

    const getFunctions = {
        'peopleFolders': [getExecutorsFolders, peopleFolders.length],
        'workFolders': [getWorksFolders, workFolders.length],
        'companiesFolders': [getExecutorsFolders, companiesFolders.length]
    };

    const counts = [peopleFolders.length, companiesFolders.length, workFolders.length];

    // Получение данных для экрана
    function getDataFormScreen(screenName) {
        const [getFunction, count] = getFunctions[screenName];

        setCurrentScreen(screenName);

        if (count === 0) {
            setLoadingScreens(prevLoadingScreens => {
                return { ...prevLoadingScreens, [screenName]: true }
            });
        }

        getFunction()
            .then(() => {
                setLoadingScreens(prevLoadingScreens => {
                    return { ...prevLoadingScreens, [screenName]: false }
                });
            });
    }

    // Получение всех вкладок
    useEffect(() => {
        const isEmpty = counts.every(count => count === 0);

        if (isEmpty) {
            const promises = Object.keys(getFunctions).map(key => getFunctions[key][0]());

            Promise.all(promises);
        }
    }, []);

    return (
        <div className = 'Bookmarks'>
            <div className = 'Bookmarks__contentWrap'>
                <BookmarksScreenNav counts = { counts } currentScreen = { currentScreen } onScreenChange = { getDataFormScreen } />

                <div className = 'Bookmarks__screens'>
                    { currentScreen === 'peopleFolders' && (
                        <BookmarksPeople peopleFolders = { peopleFolders } isLoading = { loadingScreens.peopleFolders } />
                    ) }

                    { currentScreen === 'companiesFolders' && (
                        <BookmarksCompanies companiesFolders = { companiesFolders } isLoading = { loadingScreens.companiesFolders } />
                    ) }

                    { currentScreen === 'workFolders' && (
                        <BookmarksWorks workFolders = { workFolders } isLoading = { loadingScreens.workFolders } />
                    ) }
                </div>
            </div>
        </div>
    )
}));

export { Bookmarks };