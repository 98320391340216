import React from 'react';

import { Modal } from 'components/containers';
import { Button, Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_SummaryRemoveModal.scss';

const SummaryRemoveModal = inject('LanguageStore')(observer(({ modalOpened, setModalOpened, deleteSummary, LanguageStore }) => {
    const { language } = LanguageStore;

    // Закрытие модального окна
    const closeModal = () => setModalOpened(false);

    return (
        <Modal title = { translate('Do you really want to delete the summary?', language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'SummaryRemoveModal'>
                <Alert text = { translate('This action is irreversible', language) } type = 'warn' />
            </div>

            <div className = 'Modal__actions Modal__actions--fluid'>
                <div className = 'Modal__actionsSection Modal__actionsSection--left'>
                    <Button content = {{ text: translate('No, leave', language) }}
                            fluid = { true }
                            onClick = { closeModal } />
                </div>

                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: translate('Yes, remove', language) }}
                            fluid = { true }
                            onClick = { deleteSummary } />
                </div>
            </div>
        </Modal>
    )
}));

export { SummaryRemoveModal };