import React from 'react';

import { CabinetSection } from 'components/containers';
import { ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_WorkLightingStyleList.scss';

const WorkLightingStyleList = inject('LanguageStore', 'WorksStore')(observer(({ LanguageStore, WorksStore }) => {
    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal } = WorksStore;

    const { light } = currentWork;

    const { placePavilion, placeNatural } = light;
    const { stylesNoSelect, stylesLow, stylesHigh } = light;
    const { contrastNoSelect, contrastLow, contrastHigh } = light;
    const { lightNoSelect, lightNatural, lightStyling } = light;
    const { colorNoSelect, colorDeep, colorPlain } = light;

    const radioBoxes = {
        'style': ['stylesNoSelect', 'stylesLow', 'stylesHigh'],
        'contrast': ['contrastNoSelect', 'contrastLow', 'contrastHigh'],
        'light': ['lightNoSelect', 'lightNatural', 'lightStyling'],
        'color': ['colorNoSelect', 'colorDeep', 'colorPlain']
    };

    // Выбор радио
    const chooseRadio = event => {
        const radio = event.target;
        const name = radio.name;
        const value = radio.value;

        const otherRadios = radioBoxes[name].filter(radioBox => radioBox !== value);
        const workLightCopy = Object.clone(light);
        workLightCopy[value] = true;

        otherRadios.forEach(otherRadio => workLightCopy[otherRadio] = false);

        updateCurrentWorkLocal({ light: workLightCopy });
    };

    // Выбор чекбокса
    const chooseCheckbox = event => {
        const checkbox = event.target;
        const name = checkbox.name;
        const checked = checkbox.checked;

        const workLightCopy = Object.clone(light);
        workLightCopy[name] = checked;

        updateCurrentWorkLocal({ light: workLightCopy });
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Lighting and style', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'WorkLightingStyleList'>
                    <span className = 'WorkLightingStyleList__title'>
                        { translate('Individual image characteristics to search for a specific image style', language ) }
                    </span>

                    <div className = 'WorkLightingStyleList__section'>
                        <span className = 'WorkLightingStyleList__sectionTitle'>{ translate('Shooting location', language) }</span>

                        <div className = 'WorkLightingStyleList__sectionContent'>
                            <ChooseBox name = 'placePavilion'
                                       text = { translate('In the pavilion', language) }
                                       checked = { placePavilion }
                                       onChange = { chooseCheckbox } />

                            <ChooseBox name = 'placeNatural'
                                       text = { translate('LocationsLighting', language) }
                                       checked = { placeNatural }
                                       onChange = { chooseCheckbox } />
                        </div>
                    </div>

                    <div className = 'WorkLightingStyleList__section'>
                        <span className = 'WorkLightingStyleList__sectionTitle'>{ translate('Stylistics', language) }</span>

                        <div className = 'WorkLightingStyleList__sectionContent'>
                            <ChooseBox type = 'radio'
                                       name = 'style'
                                       value = 'stylesNoSelect'
                                       text = { translate('Not selected', language) }
                                       checked = { stylesNoSelect }
                                       onChange = { chooseRadio } />

                            <ChooseBox type = 'radio'
                                       name = 'style'
                                       value = 'stylesLow'
                                       text = { translate('Low key', language) }
                                       checked = { stylesLow }
                                       onChange = { chooseRadio } />

                            <ChooseBox type = 'radio'
                                       name = 'style'
                                       value = 'stylesHigh'
                                       text = { translate('High key', language) }
                                       checked = { stylesHigh }
                                       onChange = { chooseRadio } />
                        </div>
                    </div>

                    <div className = 'WorkLightingStyleList__section'>
                        <span className = 'WorkLightingStyleList__sectionTitle'>{ translate('Contrast', language) }</span>

                        <div className = 'WorkLightingStyleList__sectionContent'>
                            <ChooseBox type = 'radio'
                                       name = 'contrast'
                                       value = 'contrastNoSelect'
                                       text = { translate('Not selected', language) }
                                       checked = { contrastNoSelect }
                                       onChange = { chooseRadio } />

                            <ChooseBox type = 'radio'
                                       name = 'contrast'
                                       value = 'contrastLow'
                                       text = { translate('Low', language) }
                                       checked = { contrastLow }
                                       onChange = { chooseRadio } />

                            <ChooseBox type = 'radio'
                                       name = 'contrast'
                                       value = 'contrastHigh'
                                       text = { translate('High', language) }
                                       checked = { contrastHigh }
                                       onChange = { chooseRadio } />
                        </div>
                    </div>

                    <div className = 'WorkLightingStyleList__section'>
                        <span className = 'WorkLightingStyleList__sectionTitle'>{ translate('Light', language) }</span>

                        <div className = 'WorkLightingStyleList__sectionContent'>
                            <ChooseBox type = 'radio'
                                       name = 'light'
                                       value = 'lightNoSelect'
                                       text = { translate('Not selected', language) }
                                       checked = { lightNoSelect }
                                       onChange = { chooseRadio } />

                            <ChooseBox type = 'radio'
                                       name = 'light'
                                       value = 'lightNatural'
                                       text = { translate('Under natural', language) }
                                       checked = { lightNatural }
                                       onChange = { chooseRadio } />

                            <ChooseBox type = 'radio'
                                       name = 'light'
                                       value = 'lightStyling'
                                       text = { translate('Stylized', language) }
                                       checked = { lightStyling }
                                       onChange = { chooseRadio } />
                        </div>
                    </div>

                    <div className = 'WorkLightingStyleList__section'>
                        <span className = 'WorkLightingStyleList__sectionTitle'>{ translate('Color', language) }</span>

                        <div className = 'WorkLightingStyleList__sectionContent'>
                            <ChooseBox type = 'radio'
                                       name = 'color'
                                       value = 'colorNoSelect'
                                       text = { translate('Not selected', language) }
                                       checked = { colorNoSelect }
                                       onChange = { chooseRadio } />

                            <ChooseBox type = 'radio'
                                       name = 'color'
                                       value = 'colorDeep'
                                       text = { translate('Deep', language) }
                                       checked = { colorDeep }
                                       onChange = { chooseRadio } />

                            <ChooseBox type = 'radio'
                                       name = 'color'
                                       value = 'colorPlain'
                                       text = { translate('Solid', language) }
                                       checked = { colorPlain }
                                       onChange = { chooseRadio } />
                        </div>
                    </div>
                </div>
            </div>
        </CabinetSection>
    )
}));

export { WorkLightingStyleList };
