import React, { useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamArtProductionShop = inject('LanguageStore', 'WorksStore', 'CabinetStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { currentWork } = WorksStore;
    const { searchInCabinet } = CabinetStore;

    const { design_post_assist, design_post_decor, design_post_bytofor, design_post_food, design_post_rec } = currentWork.team;

    const { prepareGroup } = itemProps;

    const isMovieOrSerial = ['fullLengthArtMovie', 'shortArtMovie', 'fullLengthDocumentaryMovie', 'shortDocumentaryMovie', 'serialArt', 'serialDoc'].includes(currentWork.workType);

    const values = {
        'design_post_assist': design_post_assist.map(item => item.value),
        'design_post_decor': design_post_decor.map(item => item.value),
        'design_post_bytofor': design_post_bytofor.map(item => item.value),
        'design_post_food': design_post_food.map(item => item.value),
        'design_post_rec': design_post_rec.map(item => item.value)
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;
            const executorObj = { name: checkedValue, name_en: checkedValue, surname: checkedValue, surname_en: checkedValue };

            searchInCabinet('executor', 'personalData', 'profile', key, executorObj, result => {
                setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
            });
        }, 350)    
    , []);

    return (
        <div className = { WorkTeamSectionClass }>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'artProductionShop') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Art and production shop', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(design_post_assist, 'design_post_assist', 'PersonName', findSelectItems, values) }
                            { prepareGroup(design_post_decor, 'design_post_decor', 'PersonName', findSelectItems, values) }
                            { prepareGroup(design_post_bytofor, 'design_post_bytofor', 'PersonName', findSelectItems, values) }

                            { !isMovieOrSerial && (
                                prepareGroup(design_post_food, 'design_post_food', 'PersonName', findSelectItems, values)       
                            ) }
                        
                            { prepareGroup(design_post_rec, 'design_post_rec', 'PersonName', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamArtProductionShop };