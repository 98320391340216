import React, { useState, useEffect, useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Button, ChooseIcon, ChooseBox, Icon, SelectPure } from 'components/ui';

import { toJS } from 'mobx';
import { observer, inject } from 'mobx-react';
import { translate, debounce } from 'helpers';

import './_FilmSchoolLocations.scss';

const FilmSchoolLocations = inject('LanguageStore', 'CabinetStore', 'GeolocationStore')(observer(({ LanguageStore, CabinetStore, GeolocationStore }) => {
    const [selectInputs, setSelectInputs] = useState({ country: {}, city: {} });

    const { language } = LanguageStore;
    const { cabinet, updateCabinetSection, updateCabinetSectionLocal, deleteFromCabinetSection } = CabinetStore;
    const { geolocation, findCountry, findCity } = GeolocationStore;

    const convertedLanguage = toJS(language);
    const convertedCabinet = toJS(cabinet);
    const convertedGeolocation = toJS(geolocation);

    const locations = convertedCabinet.personalData ? convertedCabinet.personalData.locations : null;
    const worldwide = convertedCabinet.personalData ? convertedCabinet.personalData.profile.worldwide : false;
    
    const generalPreparedCountries = (geolocation.countries && locations) && [{
        id: 0,
        items: geolocation.countries.map(country => ({
            id: country.countryId,
            text: country[`name-${language}`] || country['name-ru-RU'],
            selected: Boolean(locations.find(location => location.default && location.countryId === country.countryId)),
            data: country
        }))
    }];

    const generalPreparedCities = (geolocation.cities && locations) && [{
        id: 0,
        items: geolocation.cities.map(city => ({
            id: city.cityId,
            text: city[`name-${language}`] || city['name-ru-RU'],
            selected: Boolean(locations.find(location => location.default && location.cityId === city.cityId)),
            data: city
        })) 
    }];

    const generalLocation = ((locations && locations.find(location => location.default)) || {});
    const generalIndex = locations && locations.indexOf(generalLocation);

    // Добавление элемента в локацию
    const addItemToLocation = ({ data, isFake, type, locationIndex, isLocationDefault }) => {
        const location = locations[locationIndex];
        
        setSelectInputs(prevSelectInputs => {
            return {
                ...prevSelectInputs,
                [type]: {
                    ...prevSelectInputs[type],
                    [locationIndex]: data[`name-${language}`] || data['name-ru-RU']
                }
            }
        });

        if (isFake) {
            if (type === 'city') {   
                updateCabinetSection('locations', {
                    countryId: data.countryId,
                    cityId: data.cityId,
                    show: location.show,
                    default: isLocationDefault
                });
            } else {
                updateCabinetSectionLocal('locations', {
                    index: locationIndex,
                    data: {
                        [type === 'country' ? 'countryId' : 'cityId']: data[type === 'country' ? 'countryId' : 'cityId'],
                        [type === 'country' ? 'countryName' : 'cityName']: data.name,
                        default: isLocationDefault,
                        selected: true
                    }
                });
            }
        } else {
            if (type === 'city') {
                updateCabinetSection('locations', { id: location.id, countryId: data.countryId, cityId: data.cityId });
            } else {
                setSelectInputs(prevSelectInputs => {
                    return {
                        ...prevSelectInputs,
                        city: {
                            ...prevSelectInputs.city,
                            [locationIndex]: ''
                        }
                    }
                });

                updateCabinetSectionLocal('locations', { index: locationIndex, data: { countryId: data.countryId, cityName: null, cityId: null } });
            }
        }
    };

    // Отлов клика по элементу списка
    const handleSelectDropdownClick = event => {
        const selectDropdownItem = event.currentTarget;
        const data = JSON.parse(selectDropdownItem.dataset.data);
        const selectPure = selectDropdownItem.closest('.SelectPure')
        const executorSelects = selectDropdownItem.closest('.FilmSchoolLocations__selects');
        const locationIndex = JSON.parse(executorSelects.dataset.locationIndex);
        const isLocationDefault = JSON.parse(executorSelects.dataset.default);
        const isFake = JSON.parse(executorSelects.dataset.fake);
        const type = selectPure.dataset.type;
        
        addItemToLocation({
            data,
            locationIndex,
            type,
            isFake,
            isLocationDefault
        });
    };

    // Поиск страны или города
    const findLocationItem = useCallback(
        debounce((group, value, locationIndex, locations, callback) => {
            const location = locations[locationIndex];
            const preparedValue = value === '' ? '*' : value;

            if (group === 'country') {
                findCountry(preparedValue)
                    .then(items => callback(items));
            } else {    
                findCity(preparedValue, location.countryId)
                    .then(items => callback(items));
            }
        }, 350)
    , []);

    // Отлов фокуса по поле ввода
    const handleInputCountryFocus = () => findCountry('*');

    // Отлов фокуса по поле ввода
    const handleInputCityFocus = event => {
        const input = event.target;
        const locationIndex = JSON.parse(input.closest('.FilmSchoolLocations__selects').dataset.locationIndex);
        const location = locations[locationIndex];

        findCity('*', location.countryId);
    };

    // Отлов ввода в поле ввода
    const handleInputChange = event => { 
        const input = event.target;
        const value = input.value;
        const locationIndex = JSON.parse(input.closest('.FilmSchoolLocations__selects').dataset.locationIndex);
        const isFake = JSON.parse(input.closest('.FilmSchoolLocations__selects').dataset.fake);
        const isLocationDefault = JSON.parse(input.closest('.FilmSchoolLocations__selects').dataset.default);
        const group = input.dataset.group;
       
        setSelectInputs(prevSelectInputs => {
            return {
                ...prevSelectInputs,
                [group]: {
                    ...prevSelectInputs[group],
                    [locationIndex]: value
                }
            }
        });

        findLocationItem(group, value, locationIndex, locations, items => {
            const equalItem = items.find(item => item['name-ru-RU'].toLowerCase() === value.toLowerCase() || item['name-en-US'].toLowerCase() === value.toLowerCase());
            
            if (equalItem) {
                addItemToLocation({
                    data: equalItem,
                    locationIndex,
                    type: group,
                    isFake,
                    isLocationDefault
                });
            }
        });
    };

    // Изменение видимости локации
    const showLocation = event => {
        const chooseBox = event.target;
        const checked = chooseBox.checked;
        const executorSelects = chooseBox.closest('.FilmSchoolLocations__selects');
        const locationIndex = JSON.parse(executorSelects.dataset.locationIndex);
        const location = locations[locationIndex];
        const isFake = JSON.parse(executorSelects.dataset.fake);

        if (isFake) {
            updateCabinetSectionLocal('locations', { index: locationIndex, data: { show: checked } });
        } else {
            updateCabinetSection('locations', { id: location.id, show: checked });
        }
    };

    // Добавление локации
    const addLocation = () => {
        updateCabinetSectionLocal('locations', { data: { show: false, default: false, fake: true } });
    };

    // Удаление локации
    const deleteLocation = event => {
        const removeWrap = event.currentTarget;
        const locationIndex = JSON.parse(removeWrap.closest('.FilmSchoolLocations__selects').dataset.locationIndex);
        const location = locations[locationIndex];
        
        deleteFromCabinetSection('locations', location.id, locationIndex);

        setSelectInputs(prevSelectInputs => {
            return {
                country: {
                    ...prevSelectInputs.country,
                    [locationIndex]: undefined
                },

                city: {
                    ...prevSelectInputs.city,
                    [locationIndex]: undefined
                }
            }
        });
    };

    // Выбор всего мира
    const chooseWorldWide = event => {
        const chooseBox = event.target;
        const checked = chooseBox.checked;

        updateCabinetSection('profile', { worldwide: checked });
    };

    // Создание 2 локаций
    useEffect(() => {
        if (locations) {
            if (locations.length === 0) {
                updateCabinetSectionLocal('locations', { data: { show: false, default: true, fake: true } });
            }
        }
    }, [locations]);

    return locations && (
        <CabinetSection id = 'locations' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Locations', convertedLanguage) }</span>

                <ChooseBox text = { translate('Worldwide', language) }
                           checked = { worldwide }
                           onChange = { chooseWorldWide } />
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'CabinetSection__container'>
                    <div className = 'FilmSchoolLocations'>
                        <CabinetSection sub = { true }>
                            <div className = 'CabinetSection__title'>
                                <span className = 'CabinetSection__titleText'>{ translate('Main place', convertedLanguage) }</span>
                                <Icon name = 'asterisk' className = 'CabinetSection__titleIcon CabinetSection__titleIcon--asterisk' />
                            </div>

                            <div className = 'CabinetSection__content'>
                                <div className = 'FilmSchoolLocations__selects' data-location-index = { generalIndex } data-default = { generalLocation.default } data-fake = { generalLocation.fake || 'false' }>
                                    <SelectPure inputProps = {{ 
                                                    'data-group': 'country', 
                                                    label: translate('Country', convertedLanguage), 
                                                    value: selectInputs.country[generalIndex] !== undefined ? selectInputs.country[generalIndex] : generalLocation.countryName
                                                }}
                                                items = { generalPreparedCountries }
                                                onInputChange = { handleInputChange }
                                                onInputFocus = { handleInputCountryFocus }
                                                onDropdownItemClick = { handleSelectDropdownClick }
                                                data-type = 'country' />

                                    <SelectPure inputProps = {{ 
                                                    'data-group': 'city', 
                                                    label: translate('City', convertedLanguage),
                                                    value: selectInputs.city[generalIndex] !== undefined ? selectInputs.city[generalIndex] : generalLocation.cityName
                                                }}
                                                items = { generalPreparedCities }
                                                onInputChange = { handleInputChange }
                                                onInputFocus = { handleInputCityFocus }
                                                onDropdownItemClick = { handleSelectDropdownClick }
                                                disabled = { locations && !generalLocation.countryId }
                                                data-type = 'city' >
                                        <ChooseIcon text = { translate('Show in the search for this location too', convertedLanguage) } 
                                                    changeText = { translate('Don`t show in the search for this location too', convertedLanguage) } 
                                                    tooltipDirection = 'down'
                                                    tooltipPosition = 'left'
                                                    checked = { generalLocation.show }
                                                    onChange = { showLocation } />
                                    </SelectPure>
                                </div>
                            </div>
                        </CabinetSection>

                        <CabinetSection sub = { true }>
                            <div className = 'CabinetSection__title'>
                                <span className = 'CabinetSection__titleText'>{ translate('Additional place', convertedLanguage) }</span>
                            </div>
                            
                            { locations.slice(1).length > 0 && (
                                <div className = 'CabinetSection__content'>
                                    <div className = 'FilmSchoolLocations__additional'>
                                        { locations.slice(1).map(location => {
                                            const preparedCountries = (geolocation.countries && locations) && [{
                                                id: 0,
                                                items: geolocation.countries.map(country => ({
                                                    id: country.countryId,
                                                    text: country[`name-${language}`] || country['name-ru-RU'],
                                                    selected: false,
                                                    data: country
                                                }))
                                            }];
                                        
                                            const preparedCities = (geolocation.cities && locations) && [{
                                                id: 0,
                                                items: geolocation.cities.map(city => ({
                                                    id: city.cityId,
                                                    text: city[`name-${language}`] || city['name-ru-RU'],
                                                    selected: false,
                                                    data: city
                                                }))
                                            }];

                                            const locationIndex = locations.indexOf(location);

                                            preparedCountries && ((preparedCountries[0].items.find(item => item.id === location.countryId) || {}).selected = true);
                                            preparedCities && ((preparedCities[0].items.find(item => item.id === location.cityId) || {}).selected = true);
                                                
                                            return (
                                                <div className = 'FilmSchoolLocations__selects' key = { locationIndex } data-location-index = { locationIndex } data-default = { location.default } data-fake = { location.fake || 'false' }>
                                                    <SelectPure inputProps = {{ 
                                                                    'data-group': 'country', 
                                                                    label: translate('Country', convertedLanguage), 
                                                                    value: selectInputs.country[locationIndex] !== undefined ? selectInputs.country[locationIndex] : location.countryName
                                                                }}
                                                                items = { preparedCountries }
                                                                onInputChange = { handleInputChange }
                                                                onInputFocus = { handleInputCountryFocus }
                                                                onDropdownItemClick = { handleSelectDropdownClick }
                                                                disabled = { locations.indexOf(location) === 1 && generalLocation.fake }
                                                                data-type = 'country' />

                                                    <SelectPure inputProps = {{ 
                                                                    'data-group': 'city', 
                                                                    label: translate('City', convertedLanguage),
                                                                    value: selectInputs.city[locationIndex] !== undefined ? selectInputs.city[locationIndex] : location.cityName
                                                                }}
                                                                items = { preparedCities }
                                                                onInputChange = { handleInputChange }
                                                                onInputFocus = { handleInputCityFocus }
                                                                onDropdownItemClick = { handleSelectDropdownClick }
                                                                disabled = { locations && !location.countryId }
                                                                data-type = 'city' >
                                                        <ChooseIcon text = { translate('Show in the search for this location too', convertedLanguage) } 
                                                                    changeText = { translate('Don`t show in the search for this location too', convertedLanguage) } 
                                                                    tooltipDirection = 'down'
                                                                    tooltipPosition = 'left'
                                                                    checked = { location.show }
                                                                    onChange = { showLocation } />
                                                    </SelectPure>
                                                    
                                                    { !location.fake && (
                                                        <div className = 'FilmSchoolLocations__removeWrap' onClick = { deleteLocation }>
                                                            <Icon name = 'cross-tiny' className = 'FilmSchoolLocations__remove' />
                                                        </div>
                                                    ) }
                                                </div>
                                            )
                                        }) }
                                    </div>
                                </div>
                            ) }
                        </CabinetSection>
                    </div>
                </div>
            </div>
            
            <div className = 'CabinetSection__actions'>
                <Button content = {{ icon: 'plus-in-circle', text: translate('Add', convertedLanguage) }}
                        className = 'FilmSchoolLocations__addButton'
                        disabled = { (locations.length === 2 && locations[1].fake === true) || ((locations[locations.length - 1] || {}).fake) } 
                        theme = 'grey' 
                        pure = { true } 
                        onClick = { addLocation } />
            </div>
        </CabinetSection>
    )
}));

export { FilmSchoolLocations };