import React, { useState, useEffect, useRef } from 'react';

import { inject, observer } from 'mobx-react';

import { SelectInput, SelectDropdown } from 'components/ui';

import { translate } from 'helpers';

import classNames from 'classnames';

import './_SelectPure.scss';

const SelectPure = inject('LanguageStore')(observer(({ items = [], actionButton, action, error, disabled, onInputChange, onInputFocus, onInputBlur, onDropdownItemClick, onWindowClick, children, inputProps, ...restProps }) => {
    const [active, setActive] = useState(false);
    const [wasActive, setWasActive] = useState(false);
    const [wasSelected, setWasSelected] = useState(true);
    const [customError, setCustomError] = useState(null);

    const selectRef = useRef(null);
    const inputRef = useRef(null);
    
    const { language } = restProps.LanguageStore;

    const SelectPureClass = classNames('SelectPure', {
        'SelectPure--active': active && items.length > 0
    });

    // Отлов клика по элементу выпадающего списка
    const handleDropdownItemClick = event => {
        
        onDropdownItemClick && onDropdownItemClick(event);
        setWasActive(true);
        setWasSelected(true);
        setActive(false);
    };

    // Отлов фокуса на поле ввода
    const handleInputFocus = event => {
        onInputFocus && onInputFocus(event);
        setWasActive(true);
        setActive(true);
    };

    // Отлов ввода в поле ввода
    const handleInputChange = event => {
        onInputChange && onInputChange(event);
        setWasActive(true);
        if (wasSelected) {
            setWasSelected(false);
        }
        setActive(true);
    }

    // Отлов клика на окне браузера
    const handleWindowClick = event => {
        const select = event.target.closest('.SelectPure');

        if (!select || select !== selectRef.current ) {
            onWindowClick && onWindowClick(event);
            setActive(false);
        }
    };

    // Отлов клика по иконка в поле ввода
    const setSelectActive = () => {
        const newActive = !active;
        const input = inputRef.current;

        setWasActive(true);
        setActive(newActive);

        newActive ? input.focus() : input.blur();
    };

    // Отлов клика по селекту
    const handleSelectClick = event => {
        setWasActive(true);

        if (event.target.closest('.SelectDropdown__actionButton')) {
            setActive(false);
        }
    };

    // Добавление обработчиков событий и их сброс вместе с ссылкой на поле выбора
    useEffect(() => {
        window.addEventListener('click', handleWindowClick);

        return () => {
            window.removeEventListener('click', handleWindowClick);
            selectRef.current = null;
        }
    }, []);

    useEffect(() => {
        if (!wasActive) return;

        const hasSelectedItem = items?.some((itemParent) => {
            return itemParent?.items?.some(item => {
                return item.text == inputProps.value && item.selected;
            })
        });

        const currentItem = items[0]?.items.find(item => {
            return `${item.data['name-en-US']} ${item.data['surname-en-US']}` === inputProps.value
                || `${item.data['name-ru-RU']} ${item.data['surname-ru-RU']}` === inputProps.value
                || item.data?.userObj?.name === inputProps.value;
        });

        if (currentItem?.data?.userObj?.isInvited) {
            setCustomError(null);

            return;
        }

        const hasntError = (((!inputProps.value || active || wasSelected)
            && (wasSelected || active || !inputProps.value?.length)) || hasSelectedItem);
      
        if (hasntError) {
            setCustomError(null);

            return;
        };

        setCustomError(translate('You need to select a field from the list', language));
    }, [items, inputProps, active]);

    return (
        <div className = { SelectPureClass } ref = { selectRef } onClick = { handleSelectClick } { ...restProps }>
            <SelectInput icons = {['angle']} 
                         disabled = { disabled }
                         error = { error }
                         customError = { customError }
                         handleFocus = { handleInputFocus }
                         handleBlur = { onInputBlur }
                         setSelectActive = { setSelectActive } 
                         onChange = { handleInputChange }
                         ref = { inputRef }
                         { ...inputProps }>
                { children }
            </SelectInput>

            <SelectDropdown items = { items }
                            actionButton = { actionButton }
                            action = { action }
                            onItemClick = { handleDropdownItemClick } />
        </div>
    )
}));

export { SelectPure };