// Хранилище компаний

import { decorate, action } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class CompanyStore {
    // Поиск компании
    async findCompany(obj, callback) {
        const response = await request(`${BASE_URL}/api/company/find-company`, { method: 'POST', body: JSON.stringify(obj) });
        const data = await response.json();

        if (response.status === 200) {
            callback(data.result);
        }
    }
}

decorate(CompanyStore, {
    findCompany: action.bound
});

export default new CompanyStore();