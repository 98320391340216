import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { CabinetSection, RemoveSureModal } from 'components/containers';
import { Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_WorkActions.scss';

const WorkActions = inject('LanguageStore', 'WorksStore', 'LoadingStore', 'ToolsStore')(observer(({ LanguageStore, WorksStore, LoadingStore, ToolsStore }) => {
    const [workRemoveSureModalOpened, setWorkRemoveSureModalOpened] = useState(false);

    const { language } = LanguageStore;
    const { works, currentWork, updateCurrentWorkLocal, updateCurrentWorkEntities, deleteWork, createWork, updateCurrentWorkErrors } = WorksStore;
    const { setLoading } = LoadingStore;

    const archiveGroupId = (works.find(workGroup => workGroup.isArchive) || {}).id;
    const currentPage = window.location.pathname.split('/')[3];

    // Перенос работы в архив
    const replaceWorkToArchive = () => {
        const archiveGroupId = works.find(workGroup => workGroup.isArchive).id;
        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy.groupId = archiveGroupId;

        updateCurrentWorkLocal({ fields: workFieldsCopy });
    };
    
    // Загрузка фотографий
    const prepareImages = async(imagesArr) => {
        const images = [];

        for (let image of imagesArr) {
            images.push(image);
        }

        return images
    };

    // Загрузка видео
    const prepareVideos = async(videos) => {
        for (let video of videos) {
            if (video.customPreview) {
                video.preview = video.customPreview;
                video.customPreview = null;
            }
        }

        return videos
    };

    // Подготовка основных поле текущей работы
    const prepareCurrentWorkFields = async(fields) => {
        const fieldsCopy = Object.clone(fields);

        if (fieldsCopy.customPreview) {
            fieldsCopy.preview = fieldsCopy.customPreview;
            fieldsCopy.customPreview = null;
        }

        if (fieldsCopy.customPoster) {
            fieldsCopy.poster = fieldsCopy.customPoster;
            fieldsCopy.customPoster = null;
        }

        return fieldsCopy
    };

    // Подготовка backstage и подготовка текущей работы
    const prepareCurrentWorkPreparationBackStage = async(entity) => {
        const obj = Object.clone(entity);

        obj.photo = await prepareImages(obj.photo);
        obj.video = await prepareVideos(obj.video);

        return obj
    }

    // Подготовка фестивалей текущей работы
    const prepareCurrentWorkFest = festivals => {
        const festivalsCopy = Object.clone(festivals);
        const filteredFestivals = festivalsCopy.filter(fest => fest.festId);

        if (filteredFestivals.length === 0) {
            filteredFestivals[0] = {
                festId: null,
                fest: { name: '' },
                year: null,
                awards: [{
                        name: '',
                        isParticipant: true,
                        isWinner: false
                }]
            }
        }

        return filteredFestivals
    };

    // Обновление работы на сервере
    const updateCurrentWorkOnServer = async() => {
        const { updated, fieldComplete } = currentWork;

        for (let entity of updated) {
            switch(entity) {
                case 'fields':
                    const workFieldsCopy = await prepareCurrentWorkFields(currentWork.fields);
                    await updateCurrentWorkEntities(workFieldsCopy, 'fields');
                    break;
                case 'characteristics':
                    await updateCurrentWorkEntities(currentWork.characteristics, 'characteristics');
                    break;
                case 'prepare':
                    const workPrepareCopy = await prepareCurrentWorkPreparationBackStage(currentWork.prepare);
                    await updateCurrentWorkEntities(workPrepareCopy, 'prepare');
                    break;
                case 'backstage':
                    const workBackstageCopy = await prepareCurrentWorkPreparationBackStage(currentWork.backstage);
                    await updateCurrentWorkEntities(workBackstageCopy, 'backstage');
                    break;
                case 'fest':
                    const workFestCopy = prepareCurrentWorkFest(currentWork.fest);
                    await updateCurrentWorkEntities({ fest: workFestCopy }, 'fest');
                    break;
                case 'skils':
                    await updateCurrentWorkEntities(currentWork.skils, 'skils');
                    break;
                case 'optionalEquip':
                    await updateCurrentWorkEntities(currentWork.optionalEquip, 'optionalEquip');
                    break;
                case 'location':
                    await updateCurrentWorkEntities(currentWork.location, 'location');
                    break;
                case 'budget':
                    await updateCurrentWorkEntities(currentWork.budget, 'budget');
                    break;
                case 'imageProp':
                    await updateCurrentWorkEntities({ imageProp: currentWork.imageProp }, 'imageProp');
                    break;
                case 'light':
                    await updateCurrentWorkEntities(currentWork.light, 'light');
                    break;
                case 'team':
                    await updateCurrentWorkEntities({ team: currentWork.team }, 'team');
                    break;
                default:
                    break;
            }

            updateCurrentWorkLocal({ fieldComplete: [...new Set([...fieldComplete, ...updated])] });
        }
    };

    // Сохранение работы
    const saveWork = () => {
        if (currentWork.mode === 'add') {
            setLoading('main', true);

            createWork(currentWork)
                .then(() => {
                    updateCurrentWorkErrors('fields', {}, true);
                    updateCurrentWorkLocal({ wasUpdated: true, isPreviewLoaded: true });
                    setTimeout(() => setLoading('main', false), 200);
                })
                .catch(() => setLoading('main', false));
        } else if (currentWork.mode === 'edit') {
            updateCurrentWorkOnServer();
        }
    };

    // Добавление фестиваля
    const addFestival = () => {
        const workFestCopy = Object.clone(currentWork.fest);

        const newFestival = {
            festId: null,
            fest: { name: '' },
            year: null,
            awards: [{
                name: '',
                isParticipant: true,
                isWinner: false
            }]
        };

        workFestCopy.push(newFestival);

        updateCurrentWorkLocal({ fest: workFestCopy });
    };

    // Удаление работы
    const removeWork = () => {
        setWorkRemoveSureModalOpened(false);
        setLoading('main', true);

        deleteWork(currentWork.id)
            .then(() => setTimeout(() => setLoading('main', false), 200));
    };

    return (
        <CabinetSection>
            <div className = 'CabinetSection__actions'>
                { currentPage === 'festivals-and-awards' && (
                    <Button content = {{ text: translate('Add festival', language) }}
                            disabled = { currentWork.fest.length > 0 && !currentWork.fest[currentWork.fest.length - 1].festId }
                            onClick = { addFestival } />
                ) }

                { currentPage === 'general' && (
                    <div className = 'CabinetSection__actionsLeft'>
                        <Button content = {{ text: translate('Delete permanently', language) }}
                                disabled = { currentWork.mode !== 'edit' || currentWork.isOtherUserWork === true }
                                onClick = { setWorkRemoveSureModalOpened.bind(true) } />
                        
                        <Button content = {{ text: translate('Replace to archive', language) }}
                                disabled = { currentWork.fields.groupId === archiveGroupId }
                                onClick = { replaceWorkToArchive } />
                    </div>
                ) }

                <div className = 'CabinetSection__actionsRight'>
                    { (currentWork.wasCreated || currentWork.wasUpdated) && (
                        <>
                            { currentWork.wasCreated && currentPage === 'general' && (
                                <div className = 'WorkActions__changes'>
                                    { translate('The work was created', language) }
                                </div>
                            ) }

                            { currentWork.wasUpdated && !currentWork.wasCreated && (
                                <div className = 'WorkActions__changes'>
                                    { translate('Changes were saved', language) }
                                </div>
                            ) }
                        </>
                    ) }

                    <Button theme = { (currentWork.updated.length !== 0 || !currentWork.wasCreated) ? 'blue' : '' }
                            className = 'Works__saveButton'
                            content = {{ text: translate('Save', language) }}
                            disabled = { (currentWork.mode === 'edit' && currentWork.updated.length === 0) || currentWork.wasCreated }
                            onClick = { saveWork } />

                    { currentWork.wasCreated && currentPage === 'general' && (
                        <Link to = { `/works/edit-work/team/${currentWork.id}` }>
                            <Button content = {{ text: translate('Continue', language) }} />
                        </Link>
                    ) }
                </div>
                
                { workRemoveSureModalOpened && (
                    <RemoveSureModal title = { translate('Do you really want to delete the work?', language) }
                                     text = { `${translate('This action is irreversible', language)}. ${translate('This work will also be removed from the work portfolio in the profiles of your team members', language)}` }
                                     modalOpened = { workRemoveSureModalOpened }
                                     setModalOpened = { setWorkRemoveSureModalOpened }
                                     onRemoveClick = { removeWork } />
                ) }
            </div>
        </CabinetSection> 
    )
}));

export { WorkActions };