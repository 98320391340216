import React, { useState } from 'react';

import { CabinetSection, ImageWithCropper, NewDirectionModal, DirectionsTable } from 'components/containers';
import { Input, ChooseBox, Button, Alert, Tooltip, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounceEvent, getImageFullPath } from 'helpers';

import './_StudioGeneral.scss';

const StudioGeneral = inject('LanguageStore', 'CabinetStore', 'UserStore')(observer(({ UserStore, CabinetStore, LanguageStore }) => {
    const [imageUploadModalOpened, setImageUploadModalOpened] = useState(false);
    const [newDirectionModalOpened, setNewDirectionModalOpened] = useState(false);

    const { cabinet, subscriptions, updateCabinetSection } = CabinetStore;
    const { language } = LanguageStore;
    const { user } = UserStore;

    const { discounts } = user;
    const profile = cabinet.personalData ? cabinet.personalData.profile : null;
    const profileErrors = (cabinet.errors && cabinet.errors.profile) || {};

    const directions = cabinet.personalData ? cabinet.personalData.direction : null;

    // Изменение основной информации о пользователе
    const changeUserInfo = debounceEvent(event => {
        const input = event.target;
        const name = input.name;
        const value = input.value.trim();

        const requestObj = {
            [name]: value
        };

        if (language === 'en-US') {
            requestObj[name.replace('ru-RU', 'en-US')] = value;
        }

        updateCabinetSection('profile', requestObj);
    }, 350);

    // Изменение фотографии кабинета
    const changeCabinetPhoto = link => {
        updateCabinetSection('profile', { photo: link });
        setImageUploadModalOpened(false);
    };

    // Удаление фотографии кабинета
    const removePhoto = () => {
        updateCabinetSection('profile', { photo: null });
    };

    // Показывать направления отдельно
    const showEachDirectionSeparatly = event => {
        updateCabinetSection('profile', { directionShowSingle: event.target.checked });
    };

    // Открыть модальное окно для добавления направлений
    const openAddNewDirectionModal = () => {
        setNewDirectionModalOpened(true);
    };

    return profile && (
        <CabinetSection id = 'general' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('General', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'StudioGeneral'>
                    <div className = 'StudioGeneral__info'>
                        <div className = 'StudioGeneral__infoInputs CabinetSection__container'>
                            <Input label = { translate('Company name', language) }
                                   name = 'name-ru-RU' defaultValue = { profile['name-ru-RU'] }
                                   error = { profileErrors['name-ru-RU'] }
                                   required = { true }
                                   onInput = { changeUserInfo } />

                            { language === 'ru-RU' && (
                                <Input label = { translate('Company name (for English version)', language) }
                                       name = 'name-en-US'
                                       defaultValue = { profile['name-en-US'] }
                                       onInput = { changeUserInfo } />
                            ) }
                        </div>

                        <div className = 'StudioGeneral__img'>
                            <ImageWithCropper src = { getImageFullPath(profile.photo) }
                                              modalOpened = { imageUploadModalOpened }
                                              setModalOpened = { setImageUploadModalOpened }
                                              size = 'md'
                                              requirements = {{ minWidth: 400, minHeight: 400 }}
                                              settings = {{ minCropBoxWidth: 50, minCropBoxHeight: 50, aspectRatio: 1 / 1 }}
                                              bgImg = 'mountain'
                                              buttonText = { translate('Change photo', language) }
                                              onImageLoadOnServer = { changeCabinetPhoto }
                                              onRemoveClick = { removePhoto } />
                        </div>
                    </div>

                    { (directions && directions.length !== 0) && (
                        <div className = 'StudioGeneral__directions'>
                            <CabinetSection sub = { true }>
                                <div className = 'CabinetSection__title'>
                                    <span className = 'CabinetSection__titleText'>{ translate('Direction', language) }</span>

                                    { directions.length > 1 && (
                                        <>
                                            <ChooseBox type = 'checkbox'
                                                       checked = { profile.directionShowSingle }
                                                       text = { translate('Display all directions in profile', language) }
                                                       onChange = { showEachDirectionSeparatly } />

                                            <Tooltip text = { translate('If you disable the setting, Your profile will only display the direction that you will be found in the search', language) }
                                                     direction = 'down'
                                                     position = 'left'
                                                     wrapClassName = 'StudioGeneral__directionsTooltip'>
                                                <Icon name = 'question' className = 'StudioGeneral__directionsIcon' />
                                            </Tooltip>
                                        </>
                                    ) }
                                </div>

                                <div className = 'CabinetSection__content'>
                                    <DirectionsTable items = { directions } />
                                </div>
                            </CabinetSection>
                        </div>
                    ) }

                { subscriptions.executor === null && subscriptions.studio === null && (
                        <div className = 'StudioGeneral__firstBuy'>
                            <div className = 'StudioGeneral__firstBuyInfo'>
                                <span className = 'StudioGeneral__firstBuyTitle'>
                                    { translate('Display on the site and in the search for the first 50 days — 1 ruble.', language) }
                                </span>

                                <Button content = {{ text: translate('Add direction', language) }}
                                        className = 'StudioGeneral__firstBuyButton'
                                        theme = 'blue'
                                        onClick = { openAddNewDirectionModal } />
                            </div>

                            <Alert text = { translate('To work properly, you need to add and pay for at least one direction', language) }
                                   className = 'StudioGeneral__firstBuyAlert'
                                   padding = { false }
                                   bordered = { false } />
                        </div>
                    ) }
                </div>
            </div>

            { (subscriptions.studio || subscriptions.executor) && (
                <div className = 'CabinetSection__actions'>
                    <div className = 'CabinetSection__actionsLeft'>
                        <Button content = {{ icon: 'plus-in-circle', text: translate('Add direction', language) }}
                                className = 'StudioGeneral__addDirection'
                                theme = 'grey'
                                pure = { true }
                                disabled = { directions && directions.length === 5 }
                                onClick = { openAddNewDirectionModal } />

                        { discounts[user.activeCabinet] !== 0 && (
                            <Alert text = { `${discounts[user.activeCabinet]}% ${translate(discounts[user.activeCabinet] === 50 ? 'Discount on additional direction' : 'Discount on direction', language).toLowerCase()}` }
                                padding = { false }
                                bordered = { false } />
                        ) }
                    </div>
                </div>
            ) }

            { newDirectionModalOpened && (
                <NewDirectionModal modalOpened = { newDirectionModalOpened }
                                   setModalOpened = { setNewDirectionModalOpened } />
            ) }
        </CabinetSection>
    )
}));

export { StudioGeneral };
