// Пропуск лишних событый в заданный промежуток времени

import { debounce } from 'lodash';

const debounceEvent = (...args) => {
    const debouncedEvent = debounce(...args);

    return event => {
        event.persist();
        return debouncedEvent(event);
    };
};

export { debounceEvent };
