// Хранилище с запросами
import { action, observable, runInAction, decorate } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class RequestsStore {
    requests = []

    // Получение запросов
    async getRequests() {
        const response = await request(`${BASE_URL}/api/user/custom-query`);
        const data = await response.json();

        runInAction(() => this.requests = data.data);
    
        return response
    }

    // Обновление запроса
    async updateRequest(obj) {
        const options = { method: 'POST', body: JSON.stringify(obj) }
        const response = await request(`${BASE_URL}/api/user/custom-query/update`, options);
        const data = await response.json();

        runInAction(() => this.requests = data.data);
    }

    // Очистка запросов
    cleanRequests() {
        runInAction(() => this.requests = []);
    }

}

decorate(RequestsStore, {
    requests: observable,
    getRequests: action.bound,
    updateRequest: action.bound,
    cleanRequests: action.bound
});

export default new RequestsStore();