import React, { useState } from 'react';

import { Modal } from 'components/containers';
import { Button, Input, Alert, FilterSelect, ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, isValidEmail } from 'helpers';
import { PHONE_CODES } from 'constants/constants';

import './_NewExecutorModal.scss';

const NewExecutorModal = inject('LanguageStore', 'UserStore')(observer(({ place, title, options, modalOpened, setModalOpened, onInvite, LanguageStore, UserStore }) => {
    const { language } = LanguageStore;
    const { inviteUser } = UserStore;
    
    const [executor, setExecutor] = useState({ name: '', surname: '', email: '', phoneCode: '+7', emailLanguage: language.split('-')[0], phoneWithoutCode: '' });
    const [errors, setErrors] = useState({});

    const { name, surname, email, emailLanguage, phoneCode, phoneWithoutCode } = executor;

    const isEnoughData = name && surname && isValidEmail(email);
    const isError = Object.keys(errors).find(name => errors[name]);

    const currentPhoneCode = PHONE_CODES.find(code => code.code === phoneCode);

    // Отлов ввода в поле ввода
    const handleInputCnange = event => {
        const input = event.target;
        const value = input.value.trim();
        const name = input.name;

        if (errors[name]) {
            setErrors(prevErrors => (
                { ...prevErrors, [name]: '' }
            ));
        }

        setExecutor(prevExecutor => ({ ...prevExecutor, [name]: value }));
    };

    // Выбор кода страны(телефон)
    const choosePhoneCode = ({ item }) => {
        if (item.code !== phoneCode) {
            setExecutor(prevExecutor => ({ ...prevExecutor, phoneCode: item.code }));
        }
    };

    // Смена телефона
    const changePhone = event => {
        const input = event.target;
        const value = input.value.replace(/\D/g, '');

        if (errors.phone) {
            setErrors(prevErrors => (
                { ...prevErrors, phone: '' }
            ));
        }

        setExecutor(prevExecutor => ({ ...prevExecutor, phoneWithoutCode: value }));
    };

    // Выбор языка письма
    const chooseEmailLanguage = event => {
        const radio = event.target;
        const value = radio.value;

        setExecutor(prevExecutor => ({ ...prevExecutor, emailLanguage: value }));
    };

    // Пригласить агента
    const inviteExecutor = () => {
        inviteUser({
            name: name,
            surname: surname,
            email: email,
            emailLanguage: emailLanguage,
            phone: phoneWithoutCode ? `${phoneCode} ${phoneWithoutCode}` : '',
            cabinetType: 'executor',
            ...options
        }).then(data => onInvite(data))
          .then(() => setModalOpened(false))
          .catch(errors => setErrors(errors));
    };

    return (
        <Modal title = { translate(title || 'New specialist', language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'NewExecutorModal'>
                <Input label = { translate('PersonName', language) } name = 'name' required onInput = { handleInputCnange } />
                <Input label = { translate('Surname', language) } name = 'surname' required onInput = { handleInputCnange } />

                <Input label = { `Email ${translate('For invitation', language).toLowerCase()}` } 
                       name = 'email' 
                       required
                       error = { errors.email }
                       onInput = { handleInputCnange } />

                <div className = 'NewExecutorModal__phone'>
                    <FilterSelect type = 'phone'
                                  current = { currentPhoneCode }
                                  items = { PHONE_CODES }
                                  onChoose = { choosePhoneCode } />

                    <Input label = { translate('Phone', language) } 
                           value = { phoneWithoutCode }
                           error = { errors.phone }
                           onChangeHandle = { changePhone } />
                </div>

                {/* { place === 'team' && (
                    <Alert text = { translate('As a gift, 1 month of free subscription for every 5 artists who subscribe through Your invitation', language) } />
                ) } */}
            </div>

            <div className = 'Modal__actions'>
                <div className = 'Modal__actionsSection Modal__actionsSection--left'>
                    <div className = 'NewExecutorModal__emailLanguage'>
                        <span className = 'NewExecutorModal__emailLanguageText'>
                            { translate('The text of the invitation', language) }
                        </span>

                        <ChooseBox type = 'radio'
                                   value = 'ru' 
                                   checked = { emailLanguage === 'ru' }
                                   size = 'lg-sm'
                                   text = { translate('In Russian', language).toLowerCase() }
                                   onChange = { chooseEmailLanguage } />
                        
                        <ChooseBox type = 'radio'  
                                   value = 'en' 
                                   checked = { emailLanguage === 'en' }
                                   size = 'lg-sm'
                                   text = { translate('In English', language).toLowerCase() }
                                   onChange = { chooseEmailLanguage } />
                    </div>
                </div>

                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: translate('Add', language) }} 
                            disabled = { !isEnoughData || isError }
                            onClick = { inviteExecutor } />
                </div>
            </div>
        </Modal>
    )
}));

export { NewExecutorModal };