import React, { useState } from 'react';

import { WorkTeamMusicGroupScreenNav, WorkTeamMusicGroupInfo, WorkTeamMusicGroupProduction,
         WorkTeamPostProduction, WorkTeamComputerGraphicsStudio, WorkTeamMusicGroupCompound,
         WorkTeamPreparatoryPeriod, WorkTeamMusicGroupDirectorShop, WorkTeamOperatorShop,
         WorkTeamFinancialAdministrativeShop, WorkTeamArtProductionShop, WorkTeamMakeupSuit,
         WorkTeamSoundPlayground, WorkTeamMusicGroupProfession, WorkTeamServices,
         WorkTeamEditingСolorСorrection, WorkTeamMusicGroupSoundMusiс, WorkTeamMusicGroupComputerGraphics,
         WorkTeamAnimation, WorkTeamMusicGroupPromotion } from 'components/pages';

import './_WorkTeamMusicGroup.scss';

const WorkTeamMusicGroup = ({ itemProps }) => {
    const [currentScreen, setCurrentScreen] = useState('info');
    const [openedSections, setOpenedSections] = useState({});

    // Открытие и закрытие секции
    const openSection = (name, value) => {
        setOpenedSections(prevOpenedSections => {
            const activeSectionName = Object.keys(prevOpenedSections).find(key => prevOpenedSections[key]);
            
            const newSections = {
                ...prevOpenedSections,
                [name]: typeof value === 'boolean' ? value : !prevOpenedSections[name]
            };

            if (activeSectionName && activeSectionName !== name) {
                newSections[activeSectionName] = false;
            }
           
            return newSections
        });
    };

    // Отлов смены экрана
    const handleChangeScreen = screenName => {
        setCurrentScreen(screenName);
    };
    
    return (
        <div className = 'WorkTeamMusicGroup'>
            <WorkTeamMusicGroupScreenNav currentScreen = { currentScreen } onScreenChange = { handleChangeScreen } />

            <div className = 'WorkTeam__screens'>
                { currentScreen === 'info' && (
                    <>
                        <WorkTeamMusicGroupInfo itemProps = { itemProps } 
                                                opened = { openedSections.info }
                                                handleTitleClick = { openSection } />

                        <WorkTeamMusicGroupProduction itemProps = { itemProps } 
                                                      opened = { openedSections.production }
                                                      handleTitleClick = { openSection } />

                        <WorkTeamPostProduction itemProps = { itemProps } 
                                                opened = { openedSections.postProduction }
                                                handleTitleClick = { openSection } />
                        
                        <WorkTeamComputerGraphicsStudio itemProps = { itemProps } 
                                                        opened = { openedSections.computerGraphicsStudio }
                                                        handleTitleClick = { openSection } />                        
                    </>
                ) }
                
                { currentScreen === 'compound' && (
                    <WorkTeamMusicGroupCompound itemProps = { itemProps } 
                                                opened = { openedSections.compound }
                                                handleTitleClick = { openSection } />
                ) }

                { currentScreen === 'entireCrew' && (
                    <>
                        <WorkTeamPreparatoryPeriod itemProps = { itemProps } 
                                                opened = { openedSections.preparatoryPeriod }
                                                handleTitleClick = { openSection } />
                    
                        <WorkTeamMusicGroupDirectorShop itemProps = { itemProps } 
                                                        opened = { openedSections.directorShop }
                                                        handleTitleClick = { openSection } />

                        <WorkTeamOperatorShop itemProps = { itemProps } 
                                              opened = { openedSections.operatorShop }
                                              handleTitleClick = { openSection } />

                        <WorkTeamFinancialAdministrativeShop itemProps = { itemProps } 
                                                             opened = { openedSections.financialAdministrativeShop }
                                                             handleTitleClick = { openSection } />

                        <WorkTeamArtProductionShop itemProps = { itemProps } 
                                                   opened = { openedSections.artProductionShop }
                                                   handleTitleClick = { openSection } />
                                                
                        <WorkTeamMakeupSuit itemProps = { itemProps } 
                                            opened = { openedSections.makeupSuit }
                                            handleTitleClick = { openSection } />

                        <WorkTeamSoundPlayground itemProps = { itemProps } 
                                                 opened = { openedSections.soundPlayground }
                                                 handleTitleClick = { openSection } />

                        <WorkTeamMusicGroupProfession itemProps = { itemProps } 
                                                      opened = { openedSections.otherProfession }
                                                      handleTitleClick = { openSection } />

                        <WorkTeamServices itemProps = { itemProps } 
                                          opened = { openedSections.services }
                                          handleTitleClick = { openSection } />
                    </>
                ) }

                { currentScreen === 'postProductionVFX' && (
                    <>
                        <WorkTeamEditingСolorСorrection itemProps = { itemProps } 
                                                        opened = { openedSections.editingСolorСorrection }
                                                        handleTitleClick = { openSection } />

                        <WorkTeamMusicGroupSoundMusiс itemProps = { itemProps } 
                                                      opened = { openedSections.soundMusic }
                                                      handleTitleClick = { openSection } /> 

                        <WorkTeamMusicGroupComputerGraphics itemProps = { itemProps } 
                                                            opened = { openedSections.computerGraphics }
                                                            handleTitleClick = { openSection } />
                    </>
                ) }

                { currentScreen === 'animation' && (
                    <WorkTeamAnimation itemProps = { itemProps } 
                                       opened = { openedSections.animation }
                                       handleTitleClick = { openSection } />
                ) }
                
                { currentScreen === 'promotion' && (
                    <WorkTeamMusicGroupPromotion itemProps = { itemProps } 
                                                 opened = { openedSections.promotion }
                                                 handleTitleClick = { openSection } />  
                ) }
            </div> 
        </div>
    )
};
export { WorkTeamMusicGroup };