import React, { useState, useEffect } from 'react';

import { Icon } from 'components/ui';

import { generateId } from 'helpers';
import classNames from 'classnames';

import './_ChooseBox.scss';

const ChooseBox = ({ text, type = 'checkbox', size, theme, disabled, checked, fluid, icon, children, ...restProps }) => {
    const [inputId, setInputId] = useState('');

    const ChooseBoxClass = classNames('ChooseBox', {
        'ChooseBox--size-md': size === 'md',
        'ChooseBox--size-lg': size === 'lg',
        'ChooseBox--size-lg ChooseBox--size-lg-sm': size === 'lg-sm',
        'ChooseBox--type-checkbox': type === 'checkbox',
        'ChooseBox--type-radio': type === 'radio',
        'ChooseBox--theme-white': theme === 'white',
        'ChooseBox--theme-white-blur': theme === 'white-blur',
        'ChooseBox--fluid': fluid,
        'ChooseBox--disabled': disabled
    });

    // Генерирование id для поля выбора
    useEffect(() => setInputId(generateId()), []);

    return (
        <div className = { ChooseBoxClass }>
            <input className = 'ChooseBox__input' id = { inputId } type = { type } checked = { checked } { ...restProps } />

            <label className = 'ChooseBox__label' htmlFor = { inputId }>
                { text && (
                    <span className = 'ChooseBox__text'>{ text }</span>
                ) }

                { icon && <Icon name = { icon } /> }
            </label>
            
            { children }
        </div>
    )
};

export { ChooseBox };