// Хранилище с картами

import { decorate, action, observable, runInAction } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class CardsStore {
    cards = []

    // Получение карт
    async getCards() {
        const response = await request(`${BASE_URL}/api/user/cards`);
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.cards = data.result);
        }
    }

    // Выставление активности
    async setActive(id) {
        const options = { method: 'POST', body: JSON.stringify({ id }) };
        const response = await request(`${BASE_URL}/api/user/cards/set`, options);
        const data = await response.json();

        if (data.success) {
            runInAction(() => this.cards = data.result);
        }
    }

    // Удаление карты
    async deleteCard(id) {
        const options = { method: 'DELETE', body: JSON.stringify({ id }) };
        const response = await request(`${BASE_URL}/api/user/cards/remove`, options);
        const data = await response.json();

        if (data.success) {
            const card = this.cards.find(card => card.id === id);
            const index = this.cards.indexOf(card);

            runInAction(() => this.cards.splice(index, 1));
        }
    }
}

decorate(CardsStore, {
    cards: observable,
    getCards: action.bound,
    setActive: action.bound,
    deleteCard: action.bound
});

export default new CardsStore();