import React from 'react';

import LogoSrc from 'static/icons/logo.svg';
import LogoRoundSrc from 'static/icons/logo-round.svg';

const Logo = ({ round }) => (
    ( round ? (
        <img className = 'Logo' src = { LogoRoundSrc } alt = 'Логотип' />
    ): (
        <img className = 'Logo' src = { LogoSrc } alt = 'Логотип' />
    ) )
);

export { Logo };