// Хранилище кабинета

import { action, observable, runInAction, decorate, toJS } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';
import { routes } from 'routes';
import uniqBy from 'lodash/uniqBy';

class CabinetStore {
    cabinet = {
        search: {},
        errors: {}
    }

    subscriptions = {}

    // Получение текущего кабинета
    async getCabinet(type) {
        const response = await request(`${BASE_URL}/api/user/cabinet/view/${type}`);
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.cabinet = { ...this.cabinet, ...data[type] });
        }
    }

    // Получение секции кабинета
    async getCabinetSection(page, section = null) {
        const user = toJS(this.root.stores.userStore.user);
        const currentCabinet = user.activeCabinet;

        const response = await request(`${BASE_URL}/api/user/cabinet/view/${currentCabinet}/${page}${section ? `/${section}` : ''}`);
        const data = await response.json();

        if (response.status === 200) {
            const updatedCabinet = this.cabinet;

            if (!section) {
                const newPageObject = { ...this.cabinet[page], ...data[page] };
                const newPageArray = data[page];

                updatedCabinet[page] = Array.isArray(updatedCabinet[page]) ? newPageArray : newPageObject;
            }

            if (section) {
                const newPageSectionObject = { ...this.cabinet[page][section], ...data[section] };
                const newPageSectionArray = data[section];

                updatedCabinet[page][section] = Array.isArray(updatedCabinet[page][section]) ? newPageSectionArray : newPageSectionObject;
            }

            runInAction(() => this.cabinet = updatedCabinet);
        }
    }

    // Обновление секции
    async updateCabinetSection(name, obj, options = {}) {
        const { cabinet, page, section } = options;

        const user = toJS(this.root.stores.userStore.user);
        const currentCabinet = user.subCabinet || user.activeCabinet;
        const currentPage = routes[currentCabinet].find(route => route.path === window.location.pathname).apiName;

        const response = await request(`${BASE_URL}/api/user/cabinet/update/${cabinet || currentCabinet}/${page || currentPage}/${section || name}`, { method: 'POST', body: JSON.stringify(obj) });
        const data = await response.json();
       
        const sectionIsArray = Array.isArray(this.cabinet[page || currentPage][section || name]);
        let sectionCopy = this.cabinet[page || currentPage][section || name];

        if (response.status === 200) {
            if (sectionIsArray) {
                sectionCopy = data[section || name];
            } else {
                sectionCopy = Object.assign({}, sectionCopy, data[section || name]);
            }

            if (this.cabinet.errors[section || name] && Object.keys(this.cabinet.errors[section || name]).length > 0) {
                const errors = this.cabinet.errors[name];
            
                for (let key of Object.keys(errors)) {
                    if (Object.keys(obj).includes(key)) {
                        errors[key] = null;
                    }
                }

                this.cabinet.errors[section || name] = errors;
            }
        } else if (response.status === 422) {
            this.cabinet.errors[section || name] = { ...this.cabinet.errors[section || name], ...data.errors };
        }

        const sectionContent = sectionIsArray ? uniqBy(sectionCopy, 'id') : sectionCopy;
        this.cabinet[page || currentPage] = { ...this.cabinet[page || currentPage], [section || name]: sectionContent };
      
        runInAction(() => this.cabinet = this.cabinet);

        if (response.status === 422) {
            return Promise.reject();
        }

        return data
    }

    // Удаление поля из секции
    async deleteFromCabinetSection(name, id, index, options = {}) {
        const { cabinet, page } = options;

        const user = toJS(this.root.stores.userStore.user);
        const currentCabinet = user.subCabinet || user.activeCabinet;
        const currentPage = routes[currentCabinet].find(route => route.path === window.location.pathname).apiName;

        const response = await request(`${BASE_URL}/api/user/cabinet/delete/${cabinet || currentCabinet}/${page || currentPage}/${name}/${id}`, { method: 'DELETE' });

        if (response.status === 200 && typeof index === 'number') {
            this.deleteFromCabinetSectionLocal(name, index, options);
        }

        return response
    }

    // Создание кабинета
    async createCabinet(type) {
        const response = await request(`${BASE_URL}/api/user/cabinet/create`, { method: 'POST', body: JSON.stringify({ type: type }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.cabinet = { ...this.cabinet, ...data[type] });
        }
    }

    // Поиск элемента в кабинете
    async searchInCabinet(cabinet, page, section, key, obj, callback) {
        const response = await request(`${BASE_URL}/api/user/cabinet/search/${cabinet}/${page}/${section}`, { method: 'POST', body: JSON.stringify(obj) });
        const data = await response.json();

        if (response.status === 200) {
            if (callback) {
                callback(data.result, key);
            } else {
                runInAction(() => this.cabinet.search[key] = data.result);
            }
        }
    }

    // Получение всех  подписок
    async getAllSubscriptions() {
        const response = await request(`${BASE_URL}/api/user/cabinet/all-subscription`);
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.subscriptions = data);
        }
    }

    // Обновление элемента из секции кабинета локально
    updateCabinetSectionLocal(name, obj) {
        const user = toJS(this.root.stores.userStore.user);
        const currentCabinet = user.subCabinet || user.activeCabinet;
        const currentPage = routes[currentCabinet].find(route => route.path === window.location.pathname).apiName;

        if (currentPage && this.cabinet[currentPage]) {
            if (Array.isArray(this.cabinet[currentPage][name])) {
                const sectionArray = this.cabinet[currentPage][name];
                
                if (obj.index || obj.index === 0) {
                    sectionArray[obj.index] = { ...sectionArray[obj.index], ...obj.data };
                } else {
                    sectionArray.push({ ...obj.data });
                }
            } else {
                const sectionObj = this.cabinet[currentPage][name];
                
                this.cabinet[currentPage][name] = { ...sectionObj, ...obj };
            }
        }
    }

    // Обновление ошибок
    updateCabinetSectionErrors(name, obj) {
        this.cabinet.errors[name] = { ...this.cabinet.errors[name], ...obj };
    }

    // Удаление элемента из секции кабинета локально
    deleteFromCabinetSectionLocal(name, index, options = {}) {
        const { page } = options;

        const user = toJS(this.root.stores.userStore.user);
        const currentCabinet = user.activeCabinet;
        const currentPage = routes[currentCabinet].find(route => route.path === window.location.pathname).apiName;

        if (this.cabinet[page || currentPage] && this.cabinet[page || currentPage][name]) {
            this.cabinet[page || currentPage][name].splice(index, 1);
        }
    }

    // Очистка поиска
    clearCabinetSearch(name) {
        if (name) {
            this.cabinet.search[name] = null;
        } else {
            this.cabinet.search = {};
        }
    };  

    // Очистка кабинета
    clearCabinet() {
        this.cabinet = {
            search: {},
            errors: {}
        }
    }
}

decorate(CabinetStore, {
    cabinet: observable,
    subscriptions: observable,
    getCabinet: action.bound,
    getCabinetSection: action.bound,
    updateCabinetSection: action.bound,
    updateCabinetSectionLocal: action.bound,
    updateCabinetSectionErrors: action.bound,
    deleteFromCabinetSection: action.bound,
    deleteFromCabinetSectionLocal: action.bound,
    createCabinet: action.bound,
    searchInCabinet: action.bound,
    clearCabinet: action.bound,
    clearCabinetSearch: action.bound,
    getAllSubscriptions: action.bound
});

export default new CabinetStore();