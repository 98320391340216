import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { Found, NotFound, Work } from 'components/pages';
import { Preloader, MainPreloader, MainContentPreloader } from 'components/ui';

import { Provider, inject, observer } from 'mobx-react';
import { routes } from 'routes';

import RootStore from 'store/RootStore';
import UserStore from 'store/UserStore';
import LoadingStore from 'store/LoadingStore';
import LanguageStore from 'store/LanguageStore';
import CabinetStore from 'store/CabinetStore';
import ToolsStore from 'store/ToolsStore';
import GeolocationStore from 'store/GeolocationStore';
import GuildsStore from 'store/GuildsStore';
import UniversityStore from 'store/UniversityStore';
import WorksStore from 'store/WorksStore';
import ProfessionsStore from 'store/ProfessionsStore';
import AdDirectionsStore from 'store/AdDirectionsStore';
import GenresStore from 'store/GenresStore';
import FestivalStore from 'store/FestivalStore';
import AgentExecutorsStore from 'store/AgentExecutorsStore';
import CompanyStore from 'store/CompanyStore';
import MusicStore from 'store/MusicStore';
import InvoicesStore from 'store/InvoicesStore';
import CouponsStore from 'store/CouponsStore';
import RequestsStore from 'store/RequestsStore';
import SearchesStore from 'store/SearchesStore';
import BookmarksStore from 'store/BookmarksStore';
import TabsStore from 'store/TabsStore';
import CardsStore from 'store/CardsStore';
import ReferralsStore from 'store/ReferralsStore';

import 'overrides';
import 'polyfils';

import './_index.scss';

const stores = {
    RootStore, 
    UserStore,
    LoadingStore,
    LanguageStore,
    CabinetStore,
    ToolsStore,
    GeolocationStore,
    GuildsStore,
    UniversityStore,
    WorksStore,
    ProfessionsStore,
    AdDirectionsStore,
    GenresStore,
    FestivalStore,
    AgentExecutorsStore,
    CompanyStore,
    MusicStore,
    InvoicesStore,
    CouponsStore,
    RequestsStore,
    SearchesStore,
    BookmarksStore,
    TabsStore,
    CardsStore,
    ReferralsStore
};

const Root = inject('UserStore', 'LoadingStore', 'CabinetStore', 'InvoicesStore', 'CardsStore', 'LanguageStore')(observer(({ UserStore, LoadingStore, CabinetStore, InvoicesStore, CardsStore, LanguageStore }) => {
    const { user, getUser } = UserStore;
    const { loading, setLoading } = LoadingStore;
    const { getCabinet, getAllSubscriptions } = CabinetStore;
    const { getInvoices } = InvoicesStore;
    const { getCards } = CardsStore;
    const { setLanguage } = LanguageStore;

    const activeCabinet = user && user.activeCabinet;

    const correctPaths = activeCabinet && routes[activeCabinet].map(route => route.path);
    const correctWorkPaths = ['/works/add-work/:something', '/works/edit-work/:something/:number'];

    // Запрос информации о пользователе
    useEffect(() => {
        getUser()
            .then(user => {
                const languageCode = user.languages.find(language => language.id === user.languageId)?.fullCode;
                if (languageCode) {
                    setLanguage(languageCode);
                }
                
                return user;
            })
            .then(({ activeCabinet }) => getCabinet(activeCabinet))
            .then(getInvoices)
            .then(getAllSubscriptions)
            .then(getCards)
            .then(() => setLoading('page', false));
    }, []);

    return (
        <>
            <Router>
                <Switch>
                    <Route path = { correctPaths } exact component = { Found } />
                    <Route path = { correctWorkPaths } exact component = { Work } />
                    <Route path = '/page-not-found' exact component = { NotFound } />
                    <Route path = '*' render = { () => <Redirect to = '/page-not-found' /> } />
                </Switch>
            </Router>

            <Preloader loading = { loading.page } />
            <MainPreloader loading = { loading.main } />
            <MainContentPreloader loading = { loading.mainContent } />
        </>
    )
}));

ReactDOM.render((
    <Provider { ...stores }>
        <Root />
    </Provider>
), document.querySelector('#Root'));