import React from 'react';

import { CabinetSection } from 'components/containers';
import { Input, ChooseIcon } from 'components/ui';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { translate, debounceEvent } from 'helpers';

import './_StudioFilmography.scss';

const StudioFilmography = inject('LanguageStore', 'CabinetStore')(observer(({ CabinetStore, LanguageStore }) => {
    const { cabinet, updateCabinetSection } = CabinetStore;
    const { language } = LanguageStore;

    const convertedCabinet = toJS(cabinet);
    const convertedLanguage = toJS(language);

    const filmography = convertedCabinet.personalData ? convertedCabinet.personalData.filmography : null;

    // Изменение ссылки
    const changeLink = debounceEvent(event => {
        const input = event.target;
        const value = input.value.trim();
        const name = input.name;

        updateCabinetSection('filmography', { [name]: value });
    }, 350);

    // Изменение видимости ссылки
    const showLink = event => {
        const сhooseIcon = event.target;
        const checked = сhooseIcon.checked;
        const name = сhooseIcon.dataset.name;

        updateCabinetSection('filmography', { [name]: checked });
    };

    return filmography && (
        <CabinetSection id = 'filmography' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Filmography', convertedLanguage) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'CabinetSection__container'>
                    <div className = 'StudioFilmography'>
                        <Input label = { `${translate('Kinopoisk', convertedLanguage)} (https://www.kinopoisk.ru/lists/m_act[studio]/...)` } 
                               defaultValue = { filmography.kinopoiskLink } 
                               name = 'kinopoiskLink' 
                               icons = {['kinopoisk']}
                               onInput = { changeLink }>
                            <ChooseIcon text = { translate('Show in profile', convertedLanguage) } 
                                        changeText = { translate('Don`t show in profile', convertedLanguage) } 
                                        checked = { filmography.kinopoiskShow }
                                        onChange = { showLink }
                                        data-name = 'kinopoiskShow' />
                        </Input>

                        <Input label = 'IMDb (https://www.imdb.com/search/title/?companies=co...)' 
                               defaultValue = { filmography.imdbLink } 
                               name = 'imdbLink' 
                               icons = {['imdb']}
                               onInput = { changeLink }>
                            <ChooseIcon text = { translate('Show in profile', convertedLanguage) } 
                                        changeText = { translate('Don`t show in profile', convertedLanguage) } 
                                        checked = { filmography.imdbShow }
                                        onChange = { showLink }
                                        data-name = 'imdbShow' />
                        </Input>
                    </div>
                </div>
            </div>
        </CabinetSection> 
    )
}));

export { StudioFilmography };