import React from 'react';

import { CabinetScreenNav } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const WorkTeamMovieSerialScreenNav = inject('LanguageStore')(observer(({ LanguageStore, ...restProps }) => {
    const { language } = LanguageStore;

    const screens = [
        { name: 'studio', title: translate('StudioShort', language) },
        { name: 'compound', title: translate('Compound', language) },
        { name: 'entireCrew', title: translate('The entire crew', language) },
        { name: 'postProductionVFX', title: translate('Post production and VFX', language) },
        { name: 'animation', title: translate('Animation', language) },
        { name: 'promotion', title: translate('Promotion', language) }
    ];

    return (
        <div className = 'CabinetScreenNav__wrap'>
            <CabinetScreenNav screens = { screens } { ...restProps } />
        </div>
    )
}));

export { WorkTeamMovieSerialScreenNav };