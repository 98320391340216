import React from 'react';

import { BookmarksPeopleItem } from 'components/pages';
import { CabinetAccordionList, CabinetAccordion, CabinetPreloader } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_BookmarksPeople.scss';

const BookmarksPeople = inject('LanguageStore')(observer(({ peopleFolders, isLoading, LanguageStore }) => {
    const { language } = LanguageStore;

    const preparedFolders = prepareFolders(peopleFolders);

    function prepareFolders(folders) {
        const uniqueNames = new Set(folders.map(folder => folder.folderName));
        const preparedFolders = [];

        for (let name of uniqueNames) {
            const sameFolders = folders.filter(folder => folder.folderName === name);

            const preparedFolder = {
                id: name,
                folderName: name,
                cabinets: []
            };

            for (let folder of sameFolders) {
                preparedFolder.cabinets.push(...folder.cabinets);
            }

            preparedFolders.push(preparedFolder);
        }

        return preparedFolders;
    }

    return (
        <div className = 'BookmarksPeople'>
            <CabinetAccordionList className = 'Bookmarks__accordionList'>
                { preparedFolders.map(folder => {
                    const title = folder.folderName;
                    const cabinets = folder.cabinets;
                    const text = `${translate('PeopleBookmarks', language)} (${cabinets.length})`;
                    
                    return (
                        <CabinetAccordion key = { folder.id } title = { title } text = { text }>
                            <div className = 'BookmarksPeople__accordionList'>
                                { cabinets.map(cabinet => (
                                    <BookmarksPeopleItem key = { cabinet.id } cabinet = { cabinet } />
                                )) }
                            </div>
                        </CabinetAccordion>
                    )
                }) }
            </CabinetAccordionList>

            { preparedFolders.length === 0 && (
                <Alert text = { translate('You don\'t have people in your bookmarks', language) } />
            ) }

            <CabinetPreloader loading = { isLoading } />
        </div>
    )
}));

export { BookmarksPeople };