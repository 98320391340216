// Хранилище языков

import { action, observable, decorate } from 'mobx';

class LanguageStore {
    language = localStorage.getItem('language') || 'ru-RU';

    // Изменение текущего языка
    setLanguage(language) {
        localStorage.setItem('language', language);
        this.language = language;
    }
}

decorate(LanguageStore, {
    language: observable,
    setLanguage: action.bound
});

export default new LanguageStore();