import React, { useState, useEffect } from 'react';

import { CabinetSection, SummaryRemoveModal } from 'components/containers';
import { Alert, Icon, Tooltip } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, generateId } from 'helpers';

import './_ExecutorSummary.scss';

const ExecutorSummary = inject('LanguageStore', 'CabinetStore', 'ToolsStore')(observer(({ CabinetStore, LanguageStore, ToolsStore }) => {
    const [inputId, setInputId] = useState('');
    const [summaryRemoveModalOpened, setSummaryRemoveModalOpened] = useState(false);

    const { cabinet, updateCabinetSection } = CabinetStore;
    const { language } = LanguageStore;
    const { uploadFile } = ToolsStore;

    const summary = cabinet.personalData ? cabinet.personalData.resume : null;

    // Выбо резюме
    const selectSummary = event => {
        const summary = event.target.files[0];

        if (summary) {   
            const formData = new FormData();
            formData.append('file', summary);

            uploadFile(summary.name, formData, (name, link) => {
                updateCabinetSection('resume', {
                    fileName: name,
                    file: link 
                });
            });
        }
    };

    // Открытие модального окна для удаления
    const openRemoveModal = () => {
        setSummaryRemoveModalOpened(true);
    };

    // Удаление резюме
    const deleteSummary = () => {
        updateCabinetSection('resume', {
            fileName: null,
            file: null 
        }).then(() => setSummaryRemoveModalOpened(false));
    }
    
    // Генерирование id для поля ввода
    useEffect(() => setInputId(generateId()), []);

    return summary && (
        <CabinetSection id = 'summary' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Summary', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'CabinetSection__container'>
                    <div className = 'ExecutorSummary'>
                        { summary.fileName && summary.file ? (
                            <div className = 'ExecutorSummary__file'>
                                <span className = 'ExecutorSummary__fileName'>{ summary.fileName }</span>

                                <div className = 'ExecutorSummary__fileIcons'>
                                    <Tooltip text = { translate('Download file', language) }>
                                        <a href = { summary.file } download = { summary.fileName }>
                                            <Icon name = 'download' className = 'ExecutorSummary__fileIcon' />
                                        </a>
                                    </Tooltip>

                                    <Tooltip text = { translate('Remove file', language) }>
                                        <Icon name = 'cross-tiny' className = 'ExecutorSummary__fileIcon' onClick = { openRemoveModal } />
                                    </Tooltip>
                                </div>
                            </div>
                        ) : (
                            <Alert text = { translate('Your summary can be downloaded from your profile page', language) } padding = { true } bordered = { true } />
                        ) }
                    </div>
                </div>
            </div>
            
            { (!summary.fileName && !summary.file) && (
                <div className = 'CabinetSection__actions'>
                    <div className = 'ExecutorSummary__button'>
                        <input className = 'ExecutorSummary__buttonInput' type = 'file' id = { inputId } onChange = { selectSummary } />

                        <label className = 'ExecutorSummary__buttonLabel' htmlFor = { inputId }>
                            <Icon name = 'plus-in-circle' className = 'ExecutorSummary__buttonLabelIcon' />
                            <span className = 'ExecutorSummary__buttonLabelText'>{ translate('Add', language) }</span>
                        </label>
                    </div>
                </div>
            ) }

            { summaryRemoveModalOpened && (
                <SummaryRemoveModal modalOpened = { summaryRemoveModalOpened }
                                    setModalOpened = { setSummaryRemoveModalOpened }
                                    deleteSummary = { deleteSummary } />
            ) }
        </CabinetSection> 
    )
}));

export { ExecutorSummary };