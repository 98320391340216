// Подготовка подписки к единому API
import { getDeclination } from 'helpers';

const prepareSubscription = (subscription, cabinet) => {
    const preparedSubscription = {};
    const activeInvoice = subscription.invoice.find(invoice => invoice.status === 'payed');
    const noPayedNInvoice = subscription.invoice.find(invoice => invoice.status === 'no_payed');

    preparedSubscription.id = subscription.id;
    preparedSubscription.invoice =  noPayedNInvoice || activeInvoice;
    preparedSubscription.isExpired = subscription.invoice.length > 1 && noPayedNInvoice;
    preparedSubscription.classification = subscription.classification;
    preparedSubscription.startYear = subscription.workStart && subscription.workStart.split('-')[0];
    preparedSubscription.paidUntil = subscription.expiresAt;
    preparedSubscription.amount = preparedSubscription.invoice.amount;
    preparedSubscription.period = subscription.period;
    preparedSubscription.autopay = subscription.autopay;
    preparedSubscription.cabinet = cabinet;
    preparedSubscription.status = subscription.status;
    preparedSubscription.addBy = subscription.addBy;

    switch(cabinet) {
        case 'executor':
            preparedSubscription['name-ru-RU'] = subscription.profession['name-ru-RU'];
            preparedSubscription['name-en-US'] = subscription.profession['name-en-US'];

            // непонятно зачем раньше эта проверка выглядела так
            // if (!preparedSubscription.addBy) {
            if (!preparedSubscription.addBy || preparedSubscription.addBy === 'executor') {
                preparedSubscription.priceList = subscription.profession.priceList[subscription.classification];
            }

            break;
        case 'studio':
            preparedSubscription['name-ru-RU'] = subscription.profession['name-ru-RU'];
            preparedSubscription['name-en-US'] = subscription.profession['name-en-US'];
            preparedSubscription.priceList = subscription.profession.priceList;

            break;
        case 'client':
            preparedSubscription['name-ru-RU'] = preparedSubscription.invoice.description;
            preparedSubscription['name-en-US'] = preparedSubscription.invoice.description;

            break;
        case 'agent':
            preparedSubscription['name-ru-RU'] = preparedSubscription.invoice.description;
            preparedSubscription['name-en-US'] = preparedSubscription.invoice.description;
            preparedSubscription.usersCount = subscription.userAttachCount;
            preparedSubscription.priceList = subscription.priceList;
            preparedSubscription.packetsCount = subscription.count;
            preparedSubscription.countReserved = subscription.countReserved;
            preparedSubscription.canEdit = true;

            preparedSubscription.text = {
                'ru-RU': `${subscription.count} ${getDeclination(subscription.count, ['пакет', 'пакета', 'пакетов'])} (${subscription.userAttachCount} из ${subscription.count * 5 } профилей)`,
                'en-US': `${subscription.count} ${getDeclination(subscription.count, ['packet', 'packets', 'packets'])} (${subscription.userAttachCount} of ${subscription.count * 5} profiles)`
            };

            break;
        case 'filmSchool':
            preparedSubscription['name-ru-RU'] = preparedSubscription.invoice.description;
            preparedSubscription['name-en-US'] = preparedSubscription.invoice.description;
            preparedSubscription.priceList = subscription.priceList;
            preparedSubscription.usersCount = subscription.userAttachCount;
            preparedSubscription.packetsCount = subscription.count;
            preparedSubscription.countReserved = subscription.countReserved;
            preparedSubscription.canEdit = true;

            preparedSubscription.text = {
                'ru-RU': `${subscription.count} ${getDeclination(subscription.count, ['пакет', 'пакета', 'пакетов'])} (${subscription.userAttachCount} из ${subscription.count * 10} профилей)`,
                'en-US': `${subscription.count} ${getDeclination(subscription.count, ['packet', 'packets', 'packets'])} (${subscription.userAttachCount} of ${subscription.count * 10} profiles)`
            };

            break;
    }

    preparedSubscription.nextPayAmount = 0;

    if (!preparedSubscription.addBy || preparedSubscription.addBy === 'executor') {
        preparedSubscription.nextPayAmount = preparedSubscription.period === 'month'
            ? preparedSubscription.priceList.rubMonth
            : preparedSubscription.nextPayAmount = preparedSubscription.priceList.rubYear;
    }

    if (preparedSubscription.cabinet === 'agent' || preparedSubscription.cabinet === 'filmschool') {
        preparedSubscription.nextPayAmount *= preparedSubscription.packetsCount;
    }

    return preparedSubscription
};

export { prepareSubscription };
