import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { SubscriptionModals } from 'components/containers';
import { Icon, Tooltip, ClassificationTooltip, ChooseIcon, ClassificationInfoTooltip } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate } from 'helpers';

import './_SpecialtiesTable.scss';

const SpecialtiesTable = inject('LanguageStore', 'CabinetStore')(observer(({ items, LanguageStore, CabinetStore }) => {
    const [editingMode, setEditingMode] = useState({});
    const [inputValues, setInputValues] = useState({});
    const [openedSubscriptionModals, setOpenedSubscriptionModals] = useState(false);
    const [currentInvoice, setCurrentInvoice] = useState(null);

    const inputRef = useRef(null);

    const { language } = LanguageStore;
    const { updateCabinetSection } = CabinetStore;

    // Показывать специальность в профиле
    const showSpecialityInProfile = event => {
        updateCabinetSection('speciality', {
            id: event.target.closest('.SpecialtiesTable__row--body').id,
            showWorkStart: event.target.checked
        });
    };

    // Изменение режима редактирования
    const makeEditingMode = async(event) => {
        event.stopPropagation();

        const yearSpan = event.currentTarget;
        const year = yearSpan.dataset.year;
        const professionId = JSON.parse(yearSpan.closest('.SpecialtiesTable__row').id);

        const editingModeCopy = Object.clone(editingMode);
        const inputValuesCopy = Object.clone(inputValues);

        const otherProfessions = Object.keys(editingModeCopy).filter(key => Number(key) !== professionId);

        await setYear();

        editingModeCopy[professionId] = true;
        inputValuesCopy[professionId] = year;

        for (let key of otherProfessions) {
            editingModeCopy[key] = false;
        }

        setEditingMode(editingModeCopy);
        setInputValues(inputValuesCopy);
    };

    // Отлов ввода в поле ввода года
    const handleYearInputChange = event => {
        const input = event.target;
        const value = input.value;
        const professionId = JSON.parse(input.dataset.professionId);

        setInputValues(prevInputValues => ({ ...prevInputValues, [professionId]: value }));
    };

    const setYear = async() => {
        const editingModeCopy = Object.clone(editingMode);
        const inputValuesCopy = Object.clone(inputValues);
        const activeProfessionId = Object.keys(editingMode).find(key => editingMode[key]);

        if (activeProfessionId !== undefined) {
            const newYear = inputValuesCopy[activeProfessionId].match(/\d{4}/);

            if (newYear) {
                await updateCabinetSection('speciality', {
                    id: activeProfessionId,
                    workStart: `${newYear[0]}-01-01`
                });
            }

            editingModeCopy[activeProfessionId] = false;
            inputValuesCopy[activeProfessionId] = undefined;

            setEditingMode(editingModeCopy);
            setInputValues(inputValuesCopy);
        }
    };

    // Отлов клика по окну
    const handleWindowClick = async({ target }) => {
        if (!target.closest('.SpecialtiesTable__yearInput')) {
            await setYear();
        }
    };

    // Открытие модального окна для выбора срока подписки
    const openSubscriptionTimeModal = subscription => {
        const priceList = subscription.profession.priceList[subscription.classification];
        const invoice = subscription.invoice.find(invoice => invoice.status === 'no_payed');
        
        setCurrentInvoice({ ...invoice, priceList: priceList });
        setOpenedSubscriptionModals(true);
    };

    // Фокус на поле ввода
    useEffect(() => {
        inputRef.current && inputRef.current.focus();
    }, [editingMode]);

    // Глобальный обработчик(клик не по полю ввода)
    useEffect(() => {
        window.addEventListener('click', handleWindowClick);

        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, [editingMode, inputValues]);

    // Сброс ссылки на поле ввода
    useEffect(() => {
        return () => inputRef.current = null;
    }, []);

    return (
        <div className = 'SpecialtiesTable'>
            <div className = 'SpecialtiesTable__row SpecialtiesTable__row--head'>
                <div className = 'SpecialtiesTable__headItem'>
                    <span className = 'SpecialtiesTable__headItemText'>{ translate('Name', language) }</span>
                </div>

                <div className = 'SpecialtiesTable__headItem'>
                    <span className = 'SpecialtiesTable__headItemText'>{ translate('Working since', language) }</span>
                </div>

                <div className = 'SpecialtiesTable__headItem'>
                    <span className = 'SpecialtiesTable__headItemText'>{ translate('Classification', language) }</span>

                    <ClassificationTooltip wrapClassName = 'SpecialtiesTable__headItemQuestion' direction = 'down' position = 'left'>
                        <Icon name = 'question' className = 'SpecialtiesTable__headItemIcon' />
                    </ClassificationTooltip>
                </div>

                <div className = 'SpecialtiesTable__headItem'>
                    <span className = 'SpecialtiesTable__headItemText'>{ translate('Status', language) }</span>

                    <Tooltip wrapClassName = 'SpecialtiesTable__headItemQuestion' text = { translate('Display the current status of your profession', language) }>
                        <Icon name = 'question' className = 'SpecialtiesTable__headItemIcon' />
                    </Tooltip>
                </div>
            </div>

            { items.map(item => (
                <div className = 'SpecialtiesTable__row SpecialtiesTable__row--body' key = { item.id } id = { item.id }>
                    <div className = 'SpecialtiesTable__bodyItem'>
                        <span className = 'SpecialtiesTable__bodyItemText'>{ item.profession[`name-${language}`] }</span>
                    </div>

                    <div className = 'SpecialtiesTable__bodyItem SpecialtiesTable__bodyItem--work-since'>
                        { editingMode[item.id] ? (
                            <input className = 'SpecialtiesTable__yearInput'
                                   data-profession-id = { item.id }
                                   value = { inputValues[item.id] !== undefined ? inputValues[item.id] : item.workStart && item.workStart.split('-')[0] }
                                   ref = { inputRef }
                                   onChange = { handleYearInputChange } />
                        ) : (
                            <Tooltip text = { translate('Change year', language) } position = 'left'>
                                <span className = 'SpecialtiesTable__bodyItemText' data-year = { item.workStart && item.workStart.split('-')[0] } onClick = { makeEditingMode }>
                                    { item.workStart && item.workStart.split('-')[0] }
                                </span>
                            </Tooltip>
                        ) }

                        <ChooseIcon text = { translate('Show in profile', language) }
                                    changeText = { translate('Don`t show in profile', language) }
                                    tooltipDirection = 'down'
                                    tooltipPosition = 'left'
                                    checked = { item.showWorkStart }
                                    onChange = { showSpecialityInProfile } />
                    </div>

                    <div className = 'SpecialtiesTable__bodyItem'>
                        { item.status !== 'active' && item.status !== 'delete' && item.addBy === 'executor' ? (
                            <Icon name = { `pending-${language}` } />
                        ) : (
                            <ClassificationInfoTooltip classification = { item.classification } wrapClassName = 'SpecialtiesTable__classificationTooltipWrap' direction = 'right'>
                                <Icon name = { `${item.classification}-${language}` } />
                            </ClassificationInfoTooltip>
                        ) }
                    </div>

                    <div className = 'SpecialtiesTable__bodyItem SpecialtiesTable__bodyItem--status'>
                        { item.status === 'moderation' && (
                            <span className = 'SpecialtiesTable__bodyItemText'>
                                <Link className = 'SpecialtiesTable__bodyItemTextLink' to = '/works/add-work/general'>
                                    <span className = 'SpecialtiesTable__bodyItemText--action'>{ translate('PleaseAddWorks', language) }</span>
                                </Link>

                                &nbsp;

                                { `${translate('To confirm the classification', language).toLowerCase()} ` }
                                ({ item.classification }).
                            </span>
                        ) }

                        { item.status === 'waiting_payment' && (
                            <>
                                <span className = 'SpecialtiesTable__bodyItemText'>
                                    { item.classification !== 'specialist' && (
                                        <>
                                            { `${translate('Classification', language)} ` }

                                            <span className = 'SpecialtiesTable__bodyItemText SpecialtiesTable__bodyItemText--classification'>
                                                { item.classification }
                                            </span>
        
                                            {  ` ${translate('SheConfirmed', language).toLowerCase()}. ` }
                                        </>
                                    ) }
                                
                                    <span className = 'SpecialtiesTable__bodyItemText--action'
                                          data-classification = { item.classification }
                                          onClick = { openSubscriptionTimeModal.bind(null, item) }>
                                        { translate('PayYou', language) }
                                    </span>

                                    &nbsp;

                                    { translate('To display in search', language).toLowerCase() }
                                </span>
                            </>
                        ) }

                        { (item.status === 'active' || item.status === 'delete') && (
                            <span className = 'SpecialtiesTable__bodyItemText'>
                                { translate('Paid to', language) } { item.expiresAt }.
                                &nbsp;
                                { item.autopay === 1 && translate('Auto payment included', language) }
                            </span>
                        ) }

                        { item.addBy === 'agent' && (
                            <span className = 'SpecialtiesTable__bodyItemText'>
                                { translate('Paid by the agent', language) }
                            </span>
                        ) }
                    </div>
                </div>
            )) }

            { openedSubscriptionModals && (
                <SubscriptionModals cabinet = 'executor'
                                    setModalsOpened = { setOpenedSubscriptionModals }
                                    invoice = { currentInvoice } />
            ) }
        </div>
    )
}));

export { SpecialtiesTable };
