import React, { useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamAdvOtherClient = inject('LanguageStore', 'WorksStore', 'CabinetStore', 'CompanyStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, CabinetStore, CompanyStore }) => {
    const { language } = LanguageStore;
    const { currentWork } = WorksStore;
    const { searchInCabinet } = CabinetStore;
    const { findCompany } = CompanyStore;

    const { company, brand, brand_manager, manager, producer } = currentWork.team;

    const { prepareGroup } = itemProps;

    const values = {
        'company': company.map(item => item.value),
        'brand': brand.map(item => item.value),
        'brand_manager': brand_manager.map(item => item.value),
        'manager': manager.map(item => item.value),
        'producer': producer.map(item => item.value)
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;

            const companyObj = { name: checkedValue };
            const executorObj = { name: checkedValue, name_en: checkedValue, surname: checkedValue, surname_en: checkedValue };

            switch(name) {
                case 'company':
                case 'brand': {
                    findCompany(companyObj, result => {
                        setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
                    });
                    
                    break;
                }

                case 'brand_manager':
                case 'manager':
                case 'producer': {
                    searchInCabinet('executor', 'personalData', 'profile', key, executorObj, result => {
                        setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
                    });

                    break;
                }
            }
        }, 350)    
    , []);

    return (
        <div className = { WorkTeamSectionClass } id = 'client'>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'client') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Client', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(company, 'company', 'Name', findSelectItems, values) }
                            { prepareGroup(brand, 'brand', 'Name', findSelectItems, values) }
                            { prepareGroup(brand_manager, 'brand_manager', 'PersonName', findSelectItems, values) }
                            { prepareGroup(manager, 'manager', 'PersonName', findSelectItems, values) }
                            { prepareGroup(producer, 'producer', 'PersonName', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamAdvOtherClient };