import React from 'react';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_SearchType.scss';

const SearchType = inject('LanguageStore')(observer(({ type, LanguageStore }) => {
    const { language } = LanguageStore;

    const SearchTypeClass = classNames('SearchType', {
        'SearchType--easy': type === 'easy',
        'SearchType--middle': type === 'middle',
        'SearchType--hard': type === 'hard'
    });

    const texts = {
        'easy': 'Quick',
        'middle': 'Advanced',
        'hard': 'Professional'
    };

    return (
        <div className = { SearchTypeClass }>
            <span className = 'SearchType__icon'></span>

            <span className = 'SearchType__text'>
                { `${translate(texts[type], language)} ${translate('Search', language).toLowerCase()}` }
            </span>
        </div>
    )
}));

export { SearchType };