import React, { forwardRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { SendToArchiveWorkModal } from 'components/containers';
import { ChooseBox, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, getExecutorName, getStudioName, getImageFullPath } from 'helpers';
import classNames from 'classnames';
import { BASE_URL } from 'constants/BASE_URL';

import NoPreviewImage from 'static/images/mountain.png';

import './_WorkCard.scss';

const WorkCard = inject('LanguageStore', 'WorksStore', 'UserStore')(observer(forwardRef(({ dataWorkIndex, workGroupIndex, item, LanguageStore, WorksStore, UserStore, ...restProps }, ref) => {
    const [sendToArchiveModalOpened, setSendToArchiveModalOpened] = useState(false);
    const [modalOptions, setModalOptions] = useState({});

    const { language } = LanguageStore;
    const { works, updateWork, getWorks, updateWorkGroupId } = WorksStore;
    const { user } = UserStore;

    const { activeCabinet } = user;
    const { id, show, previewImg, attention, isOtherUserWork, parentAuthorCabinet } = item;

    const name = item[`name-${language}`] || item['name-ru-RU'];
    
    const WorkCardPreviewClass = classNames('WorkCard__preview', {
        'WorkCard__preview--no': !previewImg
    });

    const WorkCardChooseBoxClass = classNames('WorkCard__chooseBox', {
        'WorkCard__chooseBox--disabled': attention === 'no_active' || attention === 'no_profession'
    });

    // Изменение видимости работы
    const showWork = event => {
        const chooseBox = event.target;
        const checked = chooseBox.checked;

        updateWork(id, workGroupIndex, { show: checked });
    };

    // Отправка работы в архив
    const sendToAchive = event => {
        const workIndex = event.target.closest('.WorkCard').dataset.workIndex;
        const work = works[workGroupIndex].work[workIndex];
        const archiveGroup = works.find(group => group.isArchive);
       
        setModalOptions({ id: work.id, archiveGroupId: archiveGroup.id });
        setSendToArchiveModalOpened(true);
    };

    return (
        <div className = 'WorkCard' data-work-index = { dataWorkIndex } ref = { ref } { ...restProps }>
            <img className = { WorkCardPreviewClass } src = { previewImg ? getImageFullPath(previewImg) : NoPreviewImage } alt = 'Превью видео' />

            <div className = 'WorkCard__info'>
                <a href = { `${BASE_URL}/work/${id}` } className = 'WorkCard__name' target = '_blank'>
                    { name }
                </a>

                { isOtherUserWork && (
                    <div className = 'WorkCard__author'>
                        <span className = 'WorkCard__authorText'>
                            { translate('Added', language) }:&nbsp;
                        </span>

                        <a href = { `${BASE_URL}/${ parentAuthorCabinet.cabinetType === 'executor' ? 'crew' : 'company' }/${parentAuthorCabinet.id}` } className = 'WorkCard__authorLink' target = '_blank'>
                            { parentAuthorCabinet.cabinetType === 'executor' ? getExecutorName(parentAuthorCabinet, language) : getStudioName(parentAuthorCabinet, language) }
                        </a>
                    </div>
                ) }
            </div>

            { (!works[workGroupIndex].isArchive) && (
                <div className = { WorkCardChooseBoxClass }>
                    <ChooseBox type = 'checkbox' 
                               checked = { attention === 'no_active' || attention === 'no_profession' ? false : show } 
                               text = { translate('Show', language) }
                               theme = 'white-blur'
                               onChange = { showWork } />
                </div>
            ) }

            <div className = 'WorkCard__options'>
                <Link to = { `/works/edit-work/general/${id}` } className = 'WorkCard__option WorkCard__option--edit'>
                    { translate('Edit', language) }
                </Link>

                <div className = 'WorkCard__optionDrag'>
                    <div className = 'WorkCard__option WorkCard__option--drag'>
                        <Icon name = 'drag-points' className = 'WorkCard__optionDragIcon' />
                    </div>

                    <span className = 'WorkCard__optionDragText'>
                        { translate('Drag', language) }
                    </span>
                </div>

                { (!works[workGroupIndex].isArchive && !works[workGroupIndex].isOtherUser) && (
                    <div className = 'WorkCard__option WorkCard__option--archive' onClick = { sendToAchive }>
                        { translate('To archive', language) } →
                    </div>
                ) }
            </div>
            
            { ((attention === 'no_active' || attention === 'no_profession') && !works[workGroupIndex].isArchive) && (
                <div className="WorkCard__attention">
                    { attention === 'no_active' ? translate('Your subscription has expired', language) : translate(`No ${activeCabinet === 'executor' ? 'profession' : 'direction'} selected`, language) }. <br></br>
                    { attention === 'no_active' ? translate('Pay to display the work again', language) : translate('Select to display the work', language) }.
                </div>
            ) }

            { sendToArchiveModalOpened && (
                <SendToArchiveWorkModal modalOpened = { sendToArchiveModalOpened }
                                        setModalOpened = { setSendToArchiveModalOpened }
                                        updateWorkGroupId = { updateWorkGroupId }
                                        getWorks = { getWorks }
                                        modalOptions = { modalOptions } />
            ) }
        </div>
    )
})));

export { WorkCard };