import React, { useState } from 'react';

import { CabinetSection } from 'components/containers';
import { Input,Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_ManagementPassword.scss';

const ManagementPassword = inject('LanguageStore', 'UserStore')(observer(({ LanguageStore, UserStore }) => {
    const { language } = LanguageStore;
    const { user, changePassword } = UserStore;
    const [textSuccess, setTextSuccess] = useState('');

    const { password, newPassword, newPassword_confirmation } = user &&  user.passwordErrors ? user.passwordErrors : {};

    // Изменение пароля пользователя
    const changePasswordEvent = async event => {
        event.preventDefault();

        const form = event.target;
        const { password, newPassword, newPassword_confirmation } = form.elements;
    
        const response = await changePassword({
            password: password.value.trim(),
            newPassword: newPassword.value.trim(),
            newPassword_confirmation: newPassword_confirmation.value.trim()
        });

        if (response.status === 200) {
            setTextSuccess(translate('The password has been successfully changed.', language));
            setTimeout(() => {
                setTextSuccess('');
            }, 5000);
        }
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>
                    { translate('Password', language) }
                </span>
            </div>

            <div className = 'CabinteSection__content'>
                <div className = 'CabinetSection__container'>
                    <div className = 'ManagementPassword'>
                        <form className = 'ManagementPassword__form' onSubmit = { changePasswordEvent }>
                            <Input label = { translate('Old password', language) } 
                                   name = 'password' 
                                   error = { password } />

                            <Input label = { translate('New password', language) } 
                                   name = 'newPassword'
                                   error = { newPassword } />

                            <Input label = { translate('Repeat new password', language) } 
                                   name = 'newPassword_confirmation' 
                                   error = { newPassword_confirmation } />

                            <div className = 'CabinetSection__actions'>
                                <Button content = {{ text: translate('Save', language) }} /> { textSuccess && <span className='CabinetSection__text-success'>{ textSuccess }</span> } 
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </CabinetSection>
    )
}));

export { ManagementPassword };