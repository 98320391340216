import React, { useState, useEffect } from 'react';

import ReactApexChart from 'react-apexcharts';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_StatisticsChart.scss';

const en = require("apexcharts/dist/locales/en.json");
const ru = require("apexcharts/dist/locales/ru.json");

const StatisticsChart = inject('LanguageStore', 'CabinetStore')(observer(({ LanguageStore, title, id, options, series }) => {
    const [selection, setSelection] = useState('month');

    const { language } = LanguageStore;

    const defaultLocale = language.split('-')[0];

    const times = [
        { name: 'all', text:translate('For all time', language) },
        { name: 'month', text:translate('For a month', language) },
        { name: 'week', text:translate('For a week', language) },
        { name: 'day', text:translate('For a day', language) }
    ];

    function handleItemClick({ currentTarget }) {
        const time = currentTarget.dataset.time;
        
        const currentDate = new Date().getTime();
        const startDate = new Date('01 Jan 2019').getTime();

        setSelection(time);

        setTimeout(() => {
            switch (time) {
                case 'day':
                    // eslint-disable-next-line no-undef
                    ApexCharts.exec(
                        id,
                        'zoomX',
                        currentDate - 86400000  < startDate ? startDate : currentDate - 86400000,
                        currentDate
                    );
    
                    break;
                case 'week':
                    // eslint-disable-next-line no-undef
                    ApexCharts.exec(
                        id,
                        'zoomX',
                        currentDate - 604800000 < startDate ? startDate : currentDate - 604800000,
                        currentDate
                    );
    
                    break;
                case 'month':
                    // eslint-disable-next-line no-undef
                    ApexCharts.exec(
                        id,
                        'zoomX',
                        currentDate - 2678400000 < startDate ? startDate : currentDate - 2678400000,
                        currentDate
                    );
    
                    break;
                default:
                    // eslint-disable-next-line no-undef
                    ApexCharts.exec(
                        id,
                        'zoomX',
                        startDate,
                        currentDate
                    );
    
                    break;
            }
        }, 0);
    }

    useEffect(() => {
        // eslint-disable-next-line no-undef
        ApexCharts.exec(
            id,
            'setLocale',
            defaultLocale
        );

        setSelection('month');
    }, [defaultLocale, id]);
    
    return (
        <div className = 'StatisticsChart'>
            <span className = 'StatisticsChart__title'>
                { title }
            </span>

            <ul className = 'StatisticsChart__time'>
                { times.map(time => {
                    const itemClass = classNames('StatisticsChart__timeItem', {
                        'StatisticsChart__timeItem--active': selection === time.name
                    });

                    return (
                        <li className = { itemClass } key = { time.name } data-time = { time.name } onClick = { handleItemClick }>
                            { time.text }
                        </li>
                    );
                }) }
            </ul>

            <div className = 'StatisticsChart__content'>
                <ReactApexChart
                    options = {{ ...options, chart: { ...options.chart, locales: [en, ru], defaultLocale, id }, selection }}
                    series = { series }
                    type = 'area'
                    height = { 350 }
                />
            </div>
        </div>
    )
}));

export { StatisticsChart };