import React from 'react';

import { WorkGeneralType, WorkGeneralAdsMusicOther, WorkGeneralCommercialAds,
         WorkGeneralMovieSerial, WorkGeneralShowreel, WorkGeneralAnimations, 
         WorkActions } from 'components/pages';
import { CabinetContent } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const WorkGeneral = inject('LanguageStore', 'WorksStore')(observer(({ LanguageStore, WorksStore }) => {
    const { currentWork } = WorksStore;
    const { language } = LanguageStore;

    const firstContainerRender = [
        'socialAdvertisement', 'musicVideo', 
        'fashionFilm', 'imageVideo', 
        'corporateMovie', 'screensaverIdMovie', 
        'infoProductVideo', 'trainingVideo', 
        'videoAndInfographics', 'backstageShooting'
    ].includes(currentWork.workType);

    const secondContainerRender = ['commercialAdvertising'].includes(currentWork.workType);

    const thirdContainerRender = [
        'fullLengthArtMovie', 'shortArtMovie', 
        'fullLengthDocumentaryMovie', 'shortDocumentaryMovie',
        'serialArt', 'serialDoc'
    ].includes(currentWork.workType);

    const fouthContainerRender = ['showreel'].includes(currentWork.workType);

    const containerToRender = (() => (
        (firstContainerRender && <WorkGeneralAdsMusicOther />)  ||
        (secondContainerRender && <WorkGeneralCommercialAds />) ||
        (thirdContainerRender && <WorkGeneralMovieSerial />)    ||
        (fouthContainerRender && <WorkGeneralShowreel />)
    ))();
   
    return (
        <div className = 'WorkGeneral'>
            <CabinetContent>
                <WorkGeneralType />

                { currentWork.mode === 'add' && (
                    <Alert text = { translate('After saving a job, you will not be able to change the job type.', language) } />
                ) }

                { (currentWork.mode === 'edit' || (currentWork.mode === 'add' && currentWork.isPreviewLoaded)) && (
                    <>
                        { containerToRender }

                        <WorkGeneralAnimations />

                        <WorkActions />
                    </>
                ) }
            </CabinetContent>
        </div>
    )
}));

export { WorkGeneral };