import React from 'react';

import { Modal } from 'components/containers';
import { Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_SendToArchiveWorkModal.scss';

const SendToArchiveWorkModal = inject('LanguageStore')(observer(({ modalOpened, setModalOpened, updateWorkGroupId, getWorks, modalOptions, LanguageStore }) => {
    const { language } = LanguageStore;

    // Закрытие модального окна
    const closeModal = () => setModalOpened(false);

    // Отправка в архив
    const sendToAchive = () => {
        updateWorkGroupId(modalOptions.id, modalOptions.archiveGroupId)
            .then(() => getWorks(true))
            .then(() => closeModal());
    };

    return (
        <Modal title = { translate('The work will be moved to the archive section', language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'SendToArchiveWorkModal'></div>

            <div className = 'Modal__actions Modal__actions--fluid'>
                <div className = 'Modal__actionsSection Modal__actionsSection--left'>
                    <Button content = {{ text: translate('Cancel', language) }}
                            fluid = { true }
                            onClick = { closeModal } />
                </div>

                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: 'OK' }}
                            fluid = { true }
                            onClick = { sendToAchive } />
                </div>
            </div>
        </Modal>
    )
}));

export { SendToArchiveWorkModal };