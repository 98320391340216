import React from 'react';
import { NavLink } from 'react-router-dom';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import { routeNames } from 'routes';
import classNames from 'classnames';

import './_MainAside.scss';

const MainAside = inject('LanguageStore')(observer(({ routes, LanguageStore }) => {
    const { language } = LanguageStore;

    return routes && (
        <aside className = 'MainAside'>
            <div className = 'MainAside__wrap'>
                <nav className = 'MainAside__nav'>
                    <div className = 'MainAside__navSection'>
                        <ul className = 'MainAside__navList'>
                            { routes.filter(route => route.show !== false).map(route => {
                                const MainAsideNavLinkClass = classNames('MainAside__navLink', {
                                    'MainAside__navLink--disabled': route.disabled
                                });

                                return (
                                    <NavLink to = { route.path } 
                                             key = { route.path } 
                                             className = { MainAsideNavLinkClass } 
                                             activeClassName = 'MainAside__navLink--active' 
                                             exact = { route.exact || true }>
                                        <li className = { `MainAside__navItem` }>
                                            <span className = 'MainAside__navItemNotification'></span>

                                            <span className = 'MainAside__navItemText'>
                                                { route.disabled ? `${translate(routeNames[route.path], language)} (${translate('Soon', language).toLowerCase()})` : translate(routeNames[route.path], language) }
                                            </span>
                                        </li>
                                    </NavLink>
                                )
                            }) }
                        </ul>
                        <ul>
                            <li>
                                <a href = 'https://youtu.be/WRFd3ujEYm4' className = 'MainAside__navLink--small' target = '_blank' rel = 'noopener noreferrer'>
                                    { translate('How does my profile work?', language)}
                                </a>
                            </li>
                            <li>
                                <a href = 'https://youtu.be/ODEOmFnX93A' className = 'MainAside__navLink--small' target = '_blank' rel = 'noopener noreferrer'>
                                    { translate('How do they search for me?', language)}
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </aside>
    )
}));

export { MainAside };