import React, { useState } from 'react';

import { CancelSubscriptionModal, SubscriptionModals, EditSubscriptionModals, PaySubscriptionModal } from 'components/containers';
import { Icon, Tooltip, ClassificationInfoTooltip, ChooseBox, Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_PaymentCard.scss';

const PaymentCard = inject('LanguageStore', 'CabinetStore', 'InvoicesStore', 'UserStore')(observer(({ subscription, short, LanguageStore, CabinetStore, InvoicesStore, UserStore }) => {
    const [cancelModalOpened, setCancelModalOpened] = useState(false);
    const [openedSubscriptionModals, setOpenedSubscriptionModals] = useState(false);
    const [editSubscriptionModalsOpened, setEditSubscriptionModalsOpened] = useState(false);
    const [openedPayModal, setOpenedPayModal] = useState(false);

    const { language } = LanguageStore;
    const { getUser } = UserStore;
    const { updateCabinetSection, getAllSubscriptions } = CabinetStore;
    const { getInvoices } = InvoicesStore;

    const { classification, startYear, paidUntil, canEdit } = subscription;

    const startYearTranslations = {
        'ru-RU': `с ${startYear} года`,
        'en-US': `since ${startYear}`
    };

    const isAddByAnybody = subscription.addBy === 'agent' || subscription.addBy === 'filmSchool';

    const PaymentCardClass = classNames('PaymentCard', {
        'PaymentCard--no-payed': !subscription.paidUntil,
        'PaymentCard--add-by': isAddByAnybody,
        'PaymentCard--short': short
    });

    const PaymentCardAutopayPeriodClass = classNames('PaymentCard__settingsSection', {
        'PaymentCard__settingsSection--disabled': !subscription.autopay
    });

    const preparedInvoice = {
        ...subscription.invoice,
        priceList: subscription.priceList
    };

    // Активация подписки
    const updateSubscription = data => {
        let promise = null;

        switch(subscription.cabinet) {
            case 'executor':
                promise = updateCabinetSection('speciality', data, {
                    cabinet: 'executor',
                    page: 'personalData'
                });

                break
            case 'studio':
                promise = updateCabinetSection('direction', data, {
                    cabinet: 'studio',
                    page: 'personalData'
                });

                break
            case 'client':
                promise = updateCabinetSection('subscribe', data, {
                    cabinet: 'client',
                    page: 'personalData'
                });

                break
            case 'agent':
                promise = updateCabinetSection('subscribe', data, {
                    cabinet: 'agent',
                    page: 'personalData'
                });

                break
            case 'filmSchool':
                promise = updateCabinetSection('subscribe', data, {
                    cabinet: 'filmSchool',
                    page: 'personalData'
                });

                break
            default:
                promise = null;
        }

        promise.then(() => {
            getAllSubscriptions();
            getInvoices();
            getUser();
        });
    };

    // Активация подписки
    const activateSubscription = () => {
        updateSubscription({ id: subscription.id, status: 'active' });
    };

    // Изменение периода автооплаты
    const changePeriod = event => {
        const radio = event.target;
        const value = radio.value;

        updateSubscription({ id: subscription.id, period: value });
    };

    // Изменение автооплаты
    const changeAutoPay = event => {
        const сheckbox = event.target;
        const checked = сheckbox.checked;

        updateSubscription({ id: subscription.id, autopay: checked });
    };

    // Открытие модальных окон оплаты
    const openPayModals = () => {
        if (['agent', 'filmSchool'].includes(subscription.cabinet)) {
            setOpenedPayModal(true);
        } else {
            setOpenedSubscriptionModals(true);
        }
    };

    return (
        <div className = { PaymentCardClass }>
            <div className = 'PaymentCard__section PaymentCard__section--main'>
                <div className = 'PaymentCard__info'>
                    { classification && (
                        ( subscription.status !== 'active' && subscription.status !== 'delete' ? (
                            <Icon name = { `pending-${language}` } />
                        ) : (
                            <ClassificationInfoTooltip classification = { classification }
                                                       wrapClassName = 'PaymentCard__classificationTooltipWrap'
                                                       direction = 'down'
                                                       position = 'left'>
                                <Icon name = { `${classification}-${language}` } />
                            </ClassificationInfoTooltip>
                        ) )
                    ) }

                    <span className = 'PaymentCard__infoText'>
                        { subscription[`name-${language}`] }

                        { startYear && (
                            <>
                                &nbsp;
                                <span className = 'PaymentCard__infoText PaymentCard__infoText--color-grey'>
                                    { startYearTranslations[language] }
                                </span>
                            </>
                        ) }
                    </span>

                    { subscription.text && (
                        <span className = 'PaymentCard__infoText PaymentCard__infoText--color-grey'>
                            { subscription.text[language] }
                        </span>
                    ) }
                </div>

                <div className = 'PaymentCard__options'>
                    <span className = 'PaymentCard__paid'>
                        { paidUntil ? (
                            <>
                                { subscription.autopay && subscription.status !== 'delete' && !isAddByAnybody ? (
                                    <>
                                        { language === 'ru-RU' && (
                                            <>
                                                <span className = 'PaymentCard__paid PaymentCard__paid--weight-bold'>
                                                    { paidUntil }
                                                </span>

                                                &nbsp;
                                                { translate('Will be debited', language).toLowerCase() }
                                                &nbsp;

                                                <span className = 'PaymentCard__paid PaymentCard__paid--weight-bold'>
                                                    { subscription.nextPayAmount } ₽
                                                </span>
                                            </>
                                        ) }

                                        { language === 'en-US' && (
                                            <>
                                                <span className = 'PaymentCard__paid PaymentCard__paid--weight-bold'>
                                                    { subscription.nextPayAmount } ₽
                                                </span>

                                                &nbsp;
                                                { translate('Will be debited', language).toLowerCase() }
                                                &nbsp;

                                                <span className = 'PaymentCard__paid PaymentCard__paid--weight-bold'>
                                                    { paidUntil }
                                                </span>
                                            </>
                                        ) }
                                    </>
                                ) : (
                                    <>
                                        { translate('Paid until', language) }

                                        &nbsp;

                                        <span className = 'PaymentCard__paid PaymentCard__paid--weight-bold'>
                                            { paidUntil }
                                        </span>
                                    </>
                                ) }
                            </>
                        ) : subscription.isExpired
                            ? `${translate('Subscription expired', language)} ${subscription.invoice.payedDate}`
                            : translate('Not paid', language)
                        }
                    </span>
                </div>
            </div>

            { !isAddByAnybody && (
                <div className = 'PaymentCard__section PaymentCard__section--settings'>
                    <div className = { PaymentCardAutopayPeriodClass }>
                        <span className = 'PaymentCard__settingsSectionTitle'>
                            { translate('Payment period', language) }
                        </span>

                        <div className = 'PaymentCard__settingsSectionContent'>
                            <ChooseBox type = 'radio'
                                       name = { `period-${subscription.id}` }
                                       value = 'month'
                                       checked = { subscription.period === 'month' }
                                       text = { translate('Per month', language) }
                                       onChange = { changePeriod } />

                            <ChooseBox type = 'radio'
                                       name = { `period-${subscription.id}` }
                                       value = 'year'
                                       checked = { subscription.period === 'year' }
                                       text = { translate('For a year', language) }
                                       onChange = { changePeriod } />
                        </div>
                    </div>

                    <div className = 'PaymentCard__settingsSection'>
                        <span className = 'PaymentCard__settingsSectionTitle'>
                            { translate('Autopay', language) }
                        </span>

                        <div className = 'PaymentCard__settingsSectionContent'>
                            <ChooseBox checked = { subscription.autopay }
                                       text = { translate('Pay automatically', language) }
                                       onChange = { changeAutoPay } />
                        </div>
                    </div>
                </div>
            ) }

            <div className = 'PaymentCard__section PaymentCard__section--actions'>
                { (subscription.cabinet !== 'client'
                    && subscription.status !== 'delete'
                    && (subscription.status !== 'waiting_payment' || subscription.invoice.status !== 'no_payed')) && (
                    <span className = 'PaymentCard__action'
                          onClick = { setCancelModalOpened.bind(null, true) }>
                        { translate('Cancel the subscription', language) } →
                    </span>
                ) }

                { ((subscription.status === 'waiting_payment' || subscription.invoice.status === 'no_payed')
                    && !isAddByAnybody) && (
                    <Button content = {{ text: `${translate('Pay', language) } →` }}
                        theme = 'grey'
                        pure = { true }
                        className = 'PaymentCard__action-button'
                        onClick = { openPayModals } />
                ) }

                { subscription.status === 'delete' && (
                    <span className = 'PaymentCard__action' onClick = { activateSubscription }>
                        { translate('Activate', language) } →
                    </span>
                ) }
            </div>

            { canEdit && !short && (
                <Tooltip wrapClassName = 'PaymentCard__editTooltip'
                         text = { translate('Edit', language) }
                         position = 'left'>
                    <div className = 'PaymentCard__editWrap'
                         onClick = { setEditSubscriptionModalsOpened.bind(null, true) }>
                        <Icon name = 'edit' className = 'PaymentCard__edit' />
                    </div>
                </Tooltip>
            ) }

            { cancelModalOpened && (
                <CancelSubscriptionModal modalOpened = { cancelModalOpened }
                                         setModalOpened = { setCancelModalOpened }
                                         subscription = { subscription } />
            ) }

            { openedSubscriptionModals && (
                <SubscriptionModals cabinet = { subscription.cabinet }
                                    setModalsOpened = { setOpenedSubscriptionModals }
                                    invoice = { preparedInvoice } />
            ) }

            { editSubscriptionModalsOpened && (
                <EditSubscriptionModals modalsOpened = { editSubscriptionModalsOpened }
                                        setModalsOpened = { setEditSubscriptionModalsOpened }
                                        cabinet = { subscription.cabinet }
                                        invoice = { preparedInvoice } />
            ) }

            { openedPayModal && (
                <PaySubscriptionModal modalOpened = { openedPayModal }
                                      setModalOpened = { setOpenedPayModal }
                                      subscription = { subscription } />
            ) }
        </div>
    )
}));

export { PaymentCard };
