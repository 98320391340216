import React from 'react';

import { WorkActions, WorkAdditionalEquipmentList } from 'components/pages';
import { CabinetContent, CabinetAlerts } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';

import './_WorkAdditionalEquipment.scss';

const WorkAdditionalEquipment = inject('LanguageStore')(observer(({ LanguageStore }) => {
    const { language } = LanguageStore;

    const alertText = {
        firstPart: {
            'ru-RU': 'Если Вы не нашли в списке нужную технику,',
            'en-US': 'If some equipment is missing,'
        },
        
        secondPart: {
            'ru-RU': 'напишите нам на почту',
            'en-US': 'email us'
        },

        thirdPart: {
            'ru-RU': 'и мы ее скоро добавим',
            'en-US': 'and we will add it  soon'
        }
    };

    return (
        <div className = 'WorkAdditionalEquipment'>
            <CabinetContent>
                <WorkAdditionalEquipmentList />

                <WorkActions />

                <CabinetAlerts>
                    <Alert>
                        { alertText.firstPart[language] }

                        &nbsp;
                        <a className = 'WorkAdditionalEquipment__alertLink' href = 'mailto:support@radar.film'>
                            { alertText.secondPart[language] }
                        </a>
                        &nbsp;

                        { alertText.thirdPart[language] }
                    </Alert>
                </CabinetAlerts>
            </CabinetContent>
        </div>
    )
}));

export { WorkAdditionalEquipment };