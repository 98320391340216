import React, { useState } from 'react';

import { CabinetNav } from 'components/containers';

const ExecutorNav = () => {
    const [navItems, setNavItems] = useState({
        general: { text: 'Основное', translationKey: 'General', active: false },
        contacts: { text: 'Контакты', translationKey: 'Contacts', active: false },
        agents: { text: 'Агенты', translationKey: 'Agents', active: false },
        locations: { text: 'Локации', translationKey: 'Locations', active: false },
        education: { text: 'Образование', translationKey: 'Education', active: false },
        languages: { text: 'Языки', translationKey: 'Languages', active: false },
        guildsAndUnionists: { text: 'Гильдии/профсоюзы', translationKey: 'Guilds/unionists', active: false },
        extraInfo: { text: 'Дополнительная информация', translationKey: 'Extra info', active: false },
        socialNetworks: { text: 'Соцсети', translationKey: 'Social networks', active: false },
        filmography: { text: 'Фильмография', translationKey: 'Filmography', active: false },
        summary: { text: 'Резюме', translationKey: 'Summary', active: false }
    });

    return (
        <div className = 'ExecutorNav CabinetNav__wrap'>
            <CabinetNav items = { navItems } updateItems = { setNavItems } />
        </div>
    )
};

export { ExecutorNav };