import React, { useState } from 'react';

import { PaymentCard } from 'components/pages';
import { Modal } from 'components/containers';
import { Button, Alert, Input, SubscriptionTimeCard } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { getDeclination, translate } from 'helpers';

import './_EditSubscriptionModal.scss';

const EditSubscriptionModal = inject('LanguageStore', 'CabinetStore', 'InvoicesStore')(observer(({ modalOpened, setModalOpened, subscription, openPayModal, LanguageStore, CabinetStore, InvoicesStore }) => {
    const [packetsCount, setPacketsCount] = useState('1');
    const [currentTime, setCurrentTime] = useState(subscription.status !== 'active' ? 'year' : subscription.period);
    const [inputError, setInputError] = useState(null);
    const [alertText, setAlertText] = useState(null);

    const { language } = LanguageStore;
    const { updateCabinetSection, getAllSubscriptions } = CabinetStore;
    const { getInvoices } = InvoicesStore;

    const packetPersonCount = subscription.cabinet === 'agent' ? 5 : 10;

    const newPacketsCountTranslate = {
        'ru-RU': `${packetsCount} ${getDeclination(Number(packetsCount), ['пакет', 'пакета', 'пакетов'])} = ${Number(packetsCount) * packetPersonCount} специалистов`,
        'en-US': `${packetsCount} ${getDeclination(Number(packetsCount), ['packet', 'packets', 'packets'])} = ${Number(packetsCount) * packetPersonCount} specialists`
    };

    const futherButtonDisabled = inputError || Number(packetsCount) === 0 || (packetsCount === String(subscription.packetsCount) && currentTime === subscription.period && subscription.status === 'active');

    const dateWillChange = {
        'ru-RU': `Стоимость подписки составит ${(subscription.amount / subscription.packetsCount) * packetsCount} рублей и изменится со следующей даты оплаты(${subscription.paidUntil})`,
        'en-US': `The cost of subscription will be ${(subscription.amount / subscription.packetsCount) * packetsCount} rubles and will change from the next payment date(${subscription.paidUntil})`
    };

    const youDecreaseCount = {
        'ru-RU': `Вы уменьшили количество пакетов до ${subscription.countReserved}`,
        'en-US': `You have reduced the number of packages to ${subscription.countReserved}`
    };

    const youIncreaseCount = {
        'ru-RU': `Вы увеличили количество пакетов до ${subscription.countReserved}`,
        'en-US': `You have increased the number of packages to ${subscription.countReserved}`
    };

    // Закрытие модального окна
    const closeModal = () => setModalOpened(false);

    // Смена кол-во пакетов
    const changePacketsCount = event => {
        const input = event.target;
        const value = input.value.trim();
        
        if (value[0] !== '0' && Number(value) <= 50) {
            const newValue = value === '' ? 0 : value;

            setPacketsCount(newValue);
            checkPacketsCount(Number(newValue));
        }
    };

    // Сброс ошибки
    const removeError = () => setInputError('');

    // Проверка кол-во пакетов
    const checkPacketsCount = (newValue) => {
        const currentPersonCount = subscription.usersCount;
        const newPersonCount = newValue * packetPersonCount;
        
        if (newValue === 0) {
            setInputError(translate('Specify the number of packets', language));
        } else if (currentPersonCount > newPersonCount) {
            const notEnoughError = {
                'agent': {
                    'ru-RU': `Для приобретения требуется удалить исполнителей: ${currentPersonCount - newPersonCount}`,
                    'en-US': `To purchase, you need to remove the executors: ${currentPersonCount - newPersonCount}`
                }, 

                'filmSchool': {
                    'ru-RU': `Для приобретения требуется удалить выпускников: ${currentPersonCount - newPersonCount}`,
                    'en-US': `To purchase, you need to remove the graduates: ${currentPersonCount - newPersonCount}`
                }
            };

            setInputError(notEnoughError[subscription.cabinet][language]);
        } else {
            removeError();
        }

        const newAlertText = subscription.packetsCount < newValue ? 'To increase the number of packets you need to pay the difference' : null;
        setAlertText(newAlertText);
    };

    // Обновление ко-во пакетов
    const updateInvoicePackets = () => {
        updateCabinetSection('subscribe', { id: subscription.id, period: currentTime, count: Number(packetsCount) }, {
            cabinet: subscription.cabinet,
            page: 'personalData'
        }).then(() => getInvoices())
          .then(() => {
            if (subscription.packetsCount < Number(packetsCount)) {
                openPayModal(currentTime);
            } else {
                getAllSubscriptions();
                closeModal();
            }
          });
    };

    // Выбор срока подписки
    const changeTime = type => setCurrentTime(type);
    
    return (
        <Modal title = { translate('Edit subscription', language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'EditSubscriptionModal'>
                <div className = 'EditSubscriptionModal__section'>
                    <span className = 'EditSubscriptionModal__sectionTitle'>
                        { translate('Current subscription', language) }:
                    </span>

                    <PaymentCard subscription = { subscription } short = { true } />
                </div>

                <div className = 'EditSubscriptionModal__section EditSubscriptionModal__section--packets-count'>
                    <span className = 'EditSubscriptionModal__sectionTitle'>
                        { `${translate('New number of packages', language)} ${newPacketsCountTranslate[language]}` }:
                    </span>

                    <div className = 'EditSubscriptionModal__packetsInput'>
                        <Input type = 'number'
                               label = { translate('Number of packets', language) }
                               value = { packetsCount }
                               error = { inputError }
                               onChangeHandle = { changePacketsCount } />
                    </div>
                </div>

                { subscription.status !== 'active' && (
                    <div className = 'EditSubscriptionModal__periods'>
                        <SubscriptionTimeCard time = 'year'
                                            name = 'time'
                                            value = 'year'
                                            price = { subscription.priceList.rubYear * packetsCount }
                                            short = { true }
                                            checked = { currentTime === 'year' }
                                            chooseTime = { changeTime.bind(null, 'year') }
                                            invoice = { subscription.invoice } />

                        <SubscriptionTimeCard name = 'time'
                                            value = 'month'
                                            price = { subscription.priceList.rubMonth * packetsCount }
                                            short = { true }
                                            checked = { currentTime === 'month' }
                                            chooseTime = { changeTime.bind(null, 'month') }
                                            invoice = { subscription.invoice } />
                    </div>
                ) }

                { alertText && subscription.status === 'active' && <Alert text = { translate(alertText, language) } /> }
                
                { subscription.status === 'active' && Number(packetsCount) < subscription.packetsCount && Number(packetsCount) !== 0 && subscription.countReserved !== Number(packetsCount) && (
                    <Alert text = { dateWillChange[language] } />
                ) }

                { subscription.status === 'active' && Number(packetsCount) === 0 && subscription.countReserved !== 0 && (
                    <Alert text = { subscription.count < subscription.countReserved ? youDecreaseCount[language] : youIncreaseCount[language] } />
                ) }
            </div>

            <div className = 'Modal__actions'>
                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: translate('Cancel', language) }} onClick = { closeModal } />

                    <Button content = {{ text: translate('Further', language) }}
                            disabled = { futherButtonDisabled }
                            onClick = { updateInvoicePackets } />
                </div>
            </div>
        </Modal>
    )
}));

export { EditSubscriptionModal };