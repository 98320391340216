import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, getImageFullPath } from 'helpers';
import classNames from 'classnames';
import noAvatarImg from 'static/images/no-avatar.jpg';

import { BASE_URL } from 'constants/BASE_URL';

import './_HeaderUser.scss';

const HeaderUser = inject('LanguageStore', 'UserStore', 'CabinetStore', 'LoadingStore')(observer(({ LanguageStore, UserStore, CabinetStore, LoadingStore, history }) => {
    const { language } = LanguageStore;
    const { user, logout, changeCabinet, getUser } = UserStore;
    const { cabinet, getCabinet, createCabinet, clearCabinet } = CabinetStore;
    const { setLoading } = LoadingStore;

    const { activeCabinet, subCabinet, existsCabinets } = user;
    const currentCabinet = subCabinet || activeCabinet;
    const name = cabinet.personalData?.profile?.[`name-${language}`] 
        || cabinet.personalData?.profile?.['name-en-US']
        || cabinet.personalData?.profile?.['name-ru-RU']
        || user.email;
    const userId = cabinet.personalData ? cabinet.personalData.profile.id : '';

    const profileLinkType = {
        'executor': 'crew',
        'studio': 'company',
        'agent': 'agent'
    };
   
    // Изменение текущего кабинета
    const changeCabinetEvent = event => {
        const item = event.currentTarget;
        const value = item.dataset.value;

        if (activeCabinet !== value) {
            setLoading('page', true);
            
            setTimeout(() => {
                clearCabinet();

                getCabinet(value)
                    .then(() => changeCabinet(value))
                    .then(() => {
                        history.push('/');
                        setTimeout(() => setLoading('page', false), 300);
                    });
            }, 200);
        }
    };

    // Создание аккаунта
    const createAccountEvent = event => {
        const item = event.currentTarget;
        const value = item.dataset.value;

        setLoading('page', true);

        createCabinet(value)
            .then(() => getUser())
            .then(() => {
                history.push('/');
                setTimeout(() => setLoading('page', false), 300);
            });
    };
    
    // Выход с аккаунта
    const logoutEvent = () => logout();
    
    return (currentCabinet && cabinet.personalData) && (
        <div className = 'HeaderUser'>
            <div className = 'HeaderUser__info'>
                <div className = 'HeaderUser__main'>
                    <span className = 'HeaderUser__name'>{ name }</span>

                    <span className = 'HeaderUser__type'>
                        { translate(currentCabinet.toLowerCase().toTitleCase(), language) }
                    </span>
                </div>

                <img className = 'HeaderUser__infoImg' src = { cabinet.personalData.profile.photo ? getImageFullPath(cabinet.personalData.profile.photo) : noAvatarImg } alt = 'Фото пользователя' />

                <Icon name = 'angle' className = 'HeaderUser__infoAngle' />
            </div>

            <div className = 'HeaderUser__dropdown'>
                <div className = 'HeaderUser__dropdownSection'>
                    <div className = 'HeaderUser__main HeaderUser__main--dropdown'>
                        <div className = 'HeaderUser__name'>{ name }</div>

                        <div className = 'HeaderUser__type'>
                            { translate(currentCabinet.toLowerCase().toTitleCase(), language) }
                        </div>
                    </div>

                    <Link to = '/' className = 'HeaderUser__dropdownLink'>
                        <div className = 'HeaderUser__dropdownItem'>
                            <span className = 'HeaderUser__dropdownItemText'>{ translate('Edit profile', language) }</span>
                        </div>
                    </Link>

                    { activeCabinet !== 'client' && (
                        <a href = { `${BASE_URL}/${profileLinkType[activeCabinet]}/${userId}` } className = 'HeaderUser__dropdownLink'>
                            <div className = 'HeaderUser__dropdownItem'>
                                <span className = 'HeaderUser__dropdownItemText'>{ translate('View profile', language) }</span>
                            </div>
                        </a>
                    ) }
                </div>

                <div className = 'HeaderUser__dropdownSection'>
                    <Link to = '/settings' className = 'HeaderUser__dropdownLink'>
                        <div className = 'HeaderUser__dropdownItem'>
                            <span className = 'HeaderUser__dropdownItemText'>{ translate('General settings', language) }</span>
                        </div>
                    </Link>
                </div>

                <div className = 'HeaderUser__dropdownSection'>
                    { existsCabinets && Object.keys(existsCabinets).map(key => {
                        const HeaderUserDropdownItemClass = classNames('HeaderUser__dropdownItem', {
                            'HeaderUser__dropdownItem--type': key !== activeCabinet,
                            'HeaderUser__dropdownItem--chosen': key === activeCabinet
                        });

                        return existsCabinets[key] === true && (
                            <div key = { key } className = { HeaderUserDropdownItemClass }>
                                { key === activeCabinet && ( 
                                    <Icon name = 'subitem' className = 'HeaderUser__dropdownItemIcon HeaderUser__dropdownItemIcon--subitem' /> 
                                ) }

                                <span className = 'HeaderUser__dropdownItemText' data-value = { key } onClick = { changeCabinetEvent }>
                                    { translate(key.toLowerCase().toTitleCase(), language) }
                                </span>
                            </div>
                        )
                    }) }

                    { existsCabinets && Object.keys(existsCabinets).some(key => existsCabinets[key] === false) && (
                        <div className = 'HeaderUser__dropdownItem HeaderUser__dropdownItem--add-account'>
                            <Icon name = 'plus-in-circle' className = 'HeaderUser__dropdownItemIcon' />
                            <span className = 'HeaderUser__dropdownItemText'>{ translate('New account', language) }</span>
                            <Icon name = 'angle' className = 'HeaderUser__dropdownItemIcon' />

                            <div className = 'HeaderUser__dropdown HeaderUser__dropdown--sub'>
                                <div className = 'HeaderUser__dropdownSection'>
                                    { existsCabinets && Object.keys(existsCabinets).map(key => {
                                        const disalbed = key === 'agent' || key === 'filmSchool';

                                        const HeaderUserDropdownItem = classNames('HeaderUser__dropdownItem', 'HeaderUser__dropdownItem--sub', {
                                            'HeaderUser__dropdownItem--disabled': disalbed
                                        });

                                        return existsCabinets[key] === false && (
                                            <div key = { key } className = { HeaderUserDropdownItem } data-value = { key } onClick = { createAccountEvent }>
                                                <span className = 'HeaderUser__dropdownItemText'>
                                                    { disalbed ? `${translate(key.toLowerCase().toTitleCase(), language)} (${translate('Soon', language).toLowerCase()})` : translate(key.toLowerCase().toTitleCase(), language) }

                                                    { (user.discounts[key] > 0 || key === 'client') && (
                                                        <>
                                                            { key === 'client' ? (
                                                                <div className = 'HeaderUser__dropdownItemDiscount HeaderUser__dropdownItemDiscount--grey'>
                                                                    &nbsp;- { translate('Free', language).toLowerCase() }
                                                                </div>
                                                            ) : (
                                                                <div className = 'HeaderUser__dropdownItemDiscount HeaderUser__dropdownItemDiscount--blue'>
                                                                    &nbsp;- { `${translate('Discount', language).toLowerCase()} ${user.discounts[key]}%` }
                                                                </div>
                                                            ) }
                                                        </>
                                                    ) }
                                                </span>
                                            </div>
                                        )
                                    }) }
                                </div>
                            </div>
                        </div>
                    ) }
                </div>

                <div className = 'HeaderUser__dropdownSignOut' onClick = { logoutEvent }>
                    { translate('Sign out', language) } →
                </div>
            </div>
        </div>
    )
}));

export default withRouter(HeaderUser);