import React, { useState, useEffect, forwardRef } from 'react';

import { Icon, Token } from 'components/ui';

import { generateId } from 'helpers';
import classNames from 'classnames';

import './_SelectInput.scss';

const SelectInput = forwardRef(({ label, value = '', icons, tokens, required, error, customError, disabled, children, setSelectActive, handleFocus, handleBlur, ...restProps }, ref) => {
    const [inputId, setInputId] = useState('');
    const [inputFocused, setInputFocused] = useState(false);

    const SelectInputClass = classNames('SelectInput', {
        'SelectInput--with-icons': required || icons || children,
        'SelectInput--with-tokens': tokens && tokens.length > 0,
        'SelectInput--focused': inputFocused,
        'SelectInput--filled': value && String(value).trim() !== '',
        'SelectInput--error': error || customError,
        'SelectInput--disabled': disabled
    });

    // Перехват фокуса на зоне ввода
    const handleInputFocus = event => {
        handleFocus && handleFocus(event);
        setInputFocused(true);
    };
    
    // Перехват блюра на зоне ввода
    const handleInputBlur = event => {
        handleBlur && handleBlur(event);
        setInputFocused(false);
    };

    // Генерирование id для поля ввода
    useEffect(() => setInputId(generateId()), []);

    const inputAreaTemplate = (
        <input className = 'SelectInput__area'
               id = { inputId }
               value = { value || '' }
               onFocus = { handleInputFocus }
               onBlur = { handleInputBlur }
               ref = { ref }
               { ...restProps } />
    );

    return (
        <div className = { SelectInputClass }>
            { label && <label className = 'SelectInput__label' htmlFor = { inputId }>{ label }</label> }

            <div className = 'SelectInput__areaWrap'>
                { (tokens && tokens.length > 0) ? (
                    <div className = 'SelectInput__tokens'>
                        { tokens.map(token => (
                            <Token key = { tokens.indexOf(token) }
                                   text = { token.text } 
                                   data = { token.data }
                                   canDelete = { token.canDelete }
                                   handlers = { token.handlers } />
                        )) }

                        { inputAreaTemplate }
                    </div>
                ) : inputAreaTemplate }

                { (required || icons || children) && (
                    <div className = 'SelectInput__icons'>
                        { required && <Icon name = 'asterisk' key = 'asterisk' className = 'SelectInput__iconsIcon SelectInput__iconsIcon--asterisk' /> }

                        { icons && icons.map(icon => (
                            <Icon key = { icon }
                                  name = { icon }  
                                  className = { `SelectInput__iconsIcon SelectInput__iconsIcon--${icon}` }
                                  onClick = { ['multiple', 'angle'].includes(icon) ? setSelectActive : undefined } />
                        )) }

                        { children }
                    </div>
                ) }
            </div>

            <span className = 'SelectInput__bar'></span>

            { (typeof error === 'string' || typeof customError === 'string') && (
                <span className = 'SelectInput__error'>{ error || customError }</span>
            ) }
        </div>
    )
});

export { SelectInput };