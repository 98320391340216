import React from 'react';

import { Icon, ChooseBox, Tooltip, More, AgentDirectionTooltip } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate, getAgentName } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';
import classNames from 'classnames';

import './_AgentCard.scss';

const AgentCard = inject('LanguageStore')(observer(({ agent, LanguageStore, changeAgentDirection, onChooseBoxChange, onRemoveClick, ...restProps }) => {
    const { language } = LanguageStore;

    const { id, agentId, site, show, crossLink, agentSubscribeId, direction } = agent;
    const { phone, email } = agent.agent.userObj;

    const directionTranslations = {
        'no_select': 'Not selected',
        'movie': 'Film',
        'adv': 'Advertising'
    };

    const AgentCardFullNameClass = classNames('AgentCard__fullName', {
        'AgentCard__fullName--disabled': agentSubscribeId === null
    });

    return (
        <div className = 'AgentCard' data-id = { id } { ...restProps }>
            <div className = 'AgentCard__head'>
                <a href = { `${BASE_URL}/agent/${agentId}` } className = { AgentCardFullNameClass } target = '_blank'>
                    { `${getAgentName(agent.agent, language)}` }

                    { agentSubscribeId !== null && (
                        <>&nbsp;→</>
                    ) }
                </a>
            </div>

            <div className = 'AgentCard__body'>
                { (phone || email || site || direction) && (
                    <div className = 'AgentCard__section'>
                        { phone && (
                            <div className = 'AgentCard__item'>
                                <a href = { `tel:${phone.replace(/\s/g, '')}` } className = 'AgentCard__itemLink'>
                                    <Icon name = 'phone' className = 'AgentCard__itemIcon AgentCard__itemIcon--phone' />
                                    <span className = 'AgentCard__itemText' >{ phone }</span>
                                </a>
                            </div>
                        ) }

                        { email && (
                            <div className = 'AgentCard__item'>
                                <a href = { `mailto:${email}` } className = 'AgentCard__itemLink'>
                                    <Icon name = 'email' className = 'AgentCard__itemIcon AgentCard__itemIcon--email' />
                                    <span className = 'AgentCard__itemText' >{ email }</span>
                                </a>
                            </div>
                        ) }

                        { site && (
                            <div className = 'AgentCard__item'>
                                <a href = { site } className = 'AgentCard__itemLink'>
                                    <Icon name = 'site' className = 'AgentCard__itemIcon AgentCard__itemIcon--site' />
                                    <span className = 'AgentCard__itemText' >{ site.replace(/.+\/\//, '') }</span>
                                </a>
                            </div>
                        ) }

                        { direction && (
                            <div className = 'AgentCard__item'>
                                <div className = 'AgentCard__itemLink AgentCard__itemLink--no-hover'>
                                    <Icon name = 'form' className = 'AgentCard__itemIcon AgentCard__itemIcon--form' />

                                    <span className = 'AgentCard__itemText' >
                                        { translate(directionTranslations[direction], language) }
                                    </span>
                                </div>

                                <AgentDirectionTooltip showOn = 'click' 
                                                       agentId = { id } 
                                                       agentDirection = { direction } 
                                                       changeAgentDirection = { changeAgentDirection } 
                                                       direction = 'right' 
                                                       position = 'top'>
                                    <More />
                                </AgentDirectionTooltip>
                            </div>
                        ) }
                    </div>
                ) }

                <div className = 'AgentCard__section'>
                    <div className = 'AgentCard__item'>
                        <ChooseBox type = 'checkbox' 
                                   text = { translate('Show agent', language) } 
                                   theme = 'white' 
                                   checked = { show }
                                   name = 'show'
                                   onChange = { onChooseBoxChange } />
                    </div>
                    
                    <div className = 'AgentCard__item'>
                        <ChooseBox type = 'checkbox' 
                                   text = { translate('Enable cross-reference', language) } 
                                   theme = 'white' 
                                   checked = { crossLink }
                                   name = 'crossLink'
                                   disabled = { agentSubscribeId === null }
                                   onChange = { onChooseBoxChange }>
                            <Tooltip wrapClassName = 'AgentCard__itemTooltipWrap' text = { translate('You can turn off the link to go to your agent\`s profile on this site', language) } direction = 'down'>
                                <Icon name = 'question' className = 'ChooseBox__icon ChooseBox__icon--question' />
                            </Tooltip>
                        </ChooseBox>
                    </div>
                </div>
                
                <Tooltip wrapClassName = 'AgentCard__crossTooltipWrap' text = { translate('Remove agent', language) } direction = 'right' onClick = { onRemoveClick }>
                    <div className = 'AgentCard__crossWrap' onClick = { onRemoveClick }>
                        <Icon name = 'cross-tiny' className = 'AgentCard__cross' />
                    </div>
                </Tooltip>
            </div>
        </div>
    )
}));

export { AgentCard };