import React, { useEffect } from 'react';

import classNames from 'classnames';

import './_CabinetScreenNav.scss';

const CabinetScreenNav = ({ currentScreen, screens, onScreenChange }) => {
    // Смена экрана
    const changeScreen = newScreen => {
        if (currentScreen !== newScreen) {
            onScreenChange && onScreenChange(newScreen);
        }
    };

    useEffect(() => {
        onScreenChange && onScreenChange(currentScreen);
    }, []);

    return (
        <nav className = 'CabinetScreenNav'>
            <ul className = 'CabinetScreenNav__list'>
                { screens.map(screen => {
                    const { name, title } = screen;

                    const screenClass = classNames('CabinetScreenNav__listItem', {
                        'CabinetScreenNav__listItem--active': name === currentScreen
                    });

                    return (
                        <li key = { name } className = { screenClass } onClick = { changeScreen.bind(null, name) }>
                            { title }
                        </li>
                    )
                }) }
            </ul>
        </nav>
    )
};

export { CabinetScreenNav };