import React, { useState } from 'react';

import { WorkTeamAdvOtherScreenNav, WorkTeamAdvOtherClient, WorkTeamAdvOtherAgency,
         WorkTeamAdvOtherProduction, WorkTeamPostProduction, WorkTeamComputerGraphicsStudio,
         WorkTeamSoundMusicDesign, WorkTeamAdvOtherCompound, WorkTeamPreparatoryPeriod,
         WorkTeamAdvOtherDirectorShop, WorkTeamOperatorShop, WorkTeamFinancialAdministrativeShop,
         WorkTeamArtProductionShop, WorkTeamMakeupSuit, WorkTeamSoundPlayground,
         WorkTeamAdvOtherProfession, WorkTeamServices, WorkTeamEditingСolorСorrection,
         WorkTeamAdvOtherSoundMusiс, WorkTeamAdvOtherComputerGraphics, WorkTeamAnimation,
         WorkTeamAdvOtherPromotion } from 'components/pages';

import './_WorkTeamAdvOther.scss';

const WorkTeamAdvOther = ({ itemProps }) => {
    const [currentScreen, setCurrentScreen] = useState('client');
    const [openedSections, setOpenedSections] = useState({});

    // Открытие и закрытие секции
    const openSection = (name, value) => {
        setOpenedSections(prevOpenedSections => {
            const activeSectionName = Object.keys(prevOpenedSections).find(key => prevOpenedSections[key]);
            
            const newSections = {
                ...prevOpenedSections,
                [name]: typeof value === 'boolean' ? value : !prevOpenedSections[name]
            };

            if (activeSectionName && activeSectionName !== name) {
                newSections[activeSectionName] = false;
            }
           
            return newSections
        });
    };

    // Отлов смены экрана
    const handleChangeScreen = screenName => {
        setCurrentScreen(screenName);
    };
    
    return (
        <div className = 'WorkTeamAdvOther'>
            <WorkTeamAdvOtherScreenNav currentScreen = { currentScreen } onScreenChange = { handleChangeScreen } />

            <div className = 'WorkTeam__screens'>
                { currentScreen === 'client' && (
                    <>
                        <WorkTeamAdvOtherClient itemProps = { itemProps } 
                                                opened = { openedSections.client }
                                                handleTitleClick = { openSection } />

                        <WorkTeamAdvOtherAgency itemProps = { itemProps } 
                                                opened = { openedSections.agency }
                                                handleTitleClick = { openSection } />
                        
                        <WorkTeamAdvOtherProduction itemProps = { itemProps } 
                                                    opened = { openedSections.production }
                                                    handleTitleClick = { openSection } />
                        
                        <WorkTeamPostProduction itemProps = { itemProps } 
                                                opened = { openedSections.postProduction }
                                                handleTitleClick = { openSection } />

                        <WorkTeamComputerGraphicsStudio itemProps = { itemProps } 
                                                        opened = { openedSections.computerGraphicsStudio }
                                                        handleTitleClick = { openSection } />
                        
                        <WorkTeamSoundMusicDesign itemProps = { itemProps } 
                                                  opened = { openedSections.soundMusicDesign }
                                                  handleTitleClick = { openSection } />
                    </>
                ) }
                
                { currentScreen === 'compound' && (
                    <WorkTeamAdvOtherCompound itemProps = { itemProps } 
                                              opened = { openedSections.compound }
                                              handleTitleClick = { openSection } />
                ) }
                
                { currentScreen === 'entireCrew' && (
                    <>
                        <WorkTeamPreparatoryPeriod itemProps = { itemProps } 
                                                   opened = { openedSections.preparatoryPeriod }
                                                   handleTitleClick = { openSection } />
                    
                        <WorkTeamAdvOtherDirectorShop itemProps = { itemProps } 
                                                    opened = { openedSections.directorShop }
                                                    handleTitleClick = { openSection } />
                        
                        <WorkTeamOperatorShop itemProps = { itemProps } 
                                              opened = { openedSections.operatorShop }
                                              handleTitleClick = { openSection } />

                        <WorkTeamFinancialAdministrativeShop itemProps = { itemProps } 
                                                             opened = { openedSections.financialAdministrativeShop }
                                                             handleTitleClick = { openSection } />

                        <WorkTeamArtProductionShop itemProps = { itemProps } 
                                                   opened = { openedSections.artProductionShop }
                                                   handleTitleClick = { openSection } />
                                                
                        <WorkTeamMakeupSuit itemProps = { itemProps } 
                                            opened = { openedSections.makeupSuit }
                                            handleTitleClick = { openSection } />

                        <WorkTeamSoundPlayground itemProps = { itemProps } 
                                                 opened = { openedSections.soundPlayground }
                                                 handleTitleClick = { openSection } />

                        <WorkTeamAdvOtherProfession itemProps = { itemProps } 
                                                    opened = { openedSections.otherProfession }
                                                    handleTitleClick = { openSection } />

                        <WorkTeamServices itemProps = { itemProps } 
                                          opened = { openedSections.services }
                                          handleTitleClick = { openSection } />
                    </>
                ) }

                { currentScreen === 'postProductionVFX' && (
                    <>
                        <WorkTeamEditingСolorСorrection itemProps = { itemProps } 
                                                        opened = { openedSections.editingСolorСorrection }
                                                        handleTitleClick = { openSection } />
                        
                        <WorkTeamAdvOtherSoundMusiс itemProps = { itemProps } 
                                                    opened = { openedSections.soundMusic }
                                                    handleTitleClick = { openSection } />

                        <WorkTeamAdvOtherComputerGraphics itemProps = { itemProps } 
                                                          opened = { openedSections.computerGraphics }
                                                          handleTitleClick = { openSection } />
                    </>
                ) }

                { currentScreen === 'animation' && (
                    <WorkTeamAnimation itemProps = { itemProps } 
                                       opened = { openedSections.animation }
                                       handleTitleClick = { openSection } />
                ) }

                { currentScreen === 'promotion' && (
                    <WorkTeamAdvOtherPromotion itemProps = { itemProps } 
                                               opened = { openedSections.promotion }
                                               handleTitleClick = { openSection } />
                ) }
            </div>
        </div>
    )
};
export { WorkTeamAdvOther };