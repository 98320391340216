import React, { useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamOperatorShop = inject('LanguageStore', 'WorksStore', 'CabinetStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { currentWork } = WorksStore;
    const { searchInCabinet } = CabinetStore;

    const { operators_cameraman, operators_focus_puller, operators_dit, operators_logger } = currentWork.team;
    const { operators_gaffer, operators_part_holder, operators_operator_steady_cam, operators_operator_ronin } = currentWork.team;
    const { operators_operator_crane, operators_operator_russian_arm, operators_operator_copter } = currentWork.team;
    const { operators_underwater_cam, operators_photo_backstage } = currentWork.team;

    const { prepareGroup } = itemProps;

    const values = {
        'operators_cameraman': operators_cameraman.map(item => item.value),
        'operators_focus_puller': operators_focus_puller.map(item => item.value),
        'operators_dit': operators_dit.map(item => item.value),
        'operators_logger': operators_logger.map(item => item.value),
        'operators_gaffer': operators_gaffer.map(item => item.value),
        'operators_part_holder': operators_part_holder.map(item => item.value),
        'operators_operator_steady_cam': operators_operator_steady_cam.map(item => item.value),
        'operators_operator_ronin': operators_operator_ronin.map(item => item.value),
        'operators_operator_crane': operators_operator_crane.map(item => item.value),
        'operators_operator_russian_arm': operators_operator_russian_arm.map(item => item.value),
        'operators_operator_copter': operators_operator_copter.map(item => item.value),
        'operators_underwater_cam': operators_underwater_cam.map(item => item.value),
        'operators_photo_backstage': operators_photo_backstage.map(item => item.value)
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;
            const executorObj = { name: checkedValue, name_en: checkedValue, surname: checkedValue, surname_en: checkedValue };

            searchInCabinet('executor', 'personalData', 'profile', key, executorObj, result => {
                setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
            });
        }, 350)    
    , []);

    return (
        <div className = { WorkTeamSectionClass }>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'operatorShop') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Operator shop', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(operators_cameraman, 'operators_cameraman', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_focus_puller, 'operators_focus_puller', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_dit, 'operators_dit', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_logger, 'operators_logger', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_gaffer, 'operators_gaffer', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_part_holder, 'operators_part_holder', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_operator_steady_cam, 'operators_operator_steady_cam', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_operator_ronin, 'operators_operator_ronin', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_operator_crane, 'operators_operator_crane', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_operator_russian_arm, 'operators_operator_russian_arm', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_operator_copter, 'operators_operator_copter', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_underwater_cam, 'operators_underwater_cam', 'PersonName', findSelectItems, values) }
                            { prepareGroup(operators_photo_backstage, 'operators_photo_backstage', 'PersonName', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamOperatorShop };