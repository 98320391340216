import React, { useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamServices = inject('LanguageStore', 'WorksStore', 'CabinetStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { currentWork } = WorksStore;
    const { searchInCabinet } = CabinetStore;

    const { service_driver, service_transport, service_food_in_area_human, service_food_in_area_studio } = currentWork.team;
    const { service_accountant, service_bank, service_insurance_human, service_insurance_studio } = currentWork.team;
    const { service_lawyer_human, service_lawyer_studio, service_security_human, service_security_studio } = currentWork.team;

    const { prepareGroup } = itemProps;

    const values = {
        'service_driver': service_driver.map(item => item.value),
        'service_transport': service_transport.map(item => item.value),
        'service_food_in_area_human': service_food_in_area_human.map(item => item.value),
        'service_food_in_area_studio': service_food_in_area_studio.map(item => item.value),
        'service_accountant': service_accountant.map(item => item.value),
        'service_bank': service_bank.map(item => item.value),
        'service_insurance_human': service_insurance_human.map(item => item.value),
        'service_insurance_studio': service_insurance_studio.map(item => item.value),
        'service_lawyer_human': service_lawyer_human.map(item => item.value),
        'service_lawyer_studio': service_lawyer_studio.map(item => item.value),
        'service_security_human': service_security_human.map(item => item.value),
        'service_security_studio': service_security_studio.map(item => item.value)
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;

            const studioObj = { name: checkedValue, name_en: checkedValue };
            const executorObj = { name: checkedValue, name_en: checkedValue, surname: checkedValue, surname_en: checkedValue };

            switch(name) {
                case 'service_driver':
                case 'service_food_in_area_human':
                case 'service_accountant':
                case 'service_insurance_human':
                case 'service_lawyer_human':
                case 'service_security_human': {
                    searchInCabinet('executor', 'personalData', 'profile', key, executorObj, result => {
                        setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
                    });

                    break
                }

                case 'service_transport':
                case 'service_food_in_area_studio':
                case 'service_bank':
                case 'service_insurance_studio':
                case 'service_lawyer_studio':
                case 'service_security_studio': {
                    searchInCabinet('studio', 'personalData', 'profile', key, studioObj, result => {
                        setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
                    });

                    break
                }
            }
        }, 350)    
    , []);

    return (
        <div className = { WorkTeamSectionClass }>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'services') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Services', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(service_driver, 'service_driver', 'PersonName', findSelectItems, values) }
                            { prepareGroup(service_transport, 'service_transport', 'Name', findSelectItems, values) }
                            { prepareGroup(service_food_in_area_human, 'service_food_in_area_human', 'PersonName', findSelectItems, values) }
                            { prepareGroup(service_food_in_area_studio, 'service_food_in_area_studio', 'Name', findSelectItems, values) }
                            { prepareGroup(service_accountant, 'service_accountant', 'PersonName', findSelectItems, values) }
                            { prepareGroup(service_bank, 'service_bank', 'Name', findSelectItems, values) }
                            { prepareGroup(service_insurance_human, 'service_insurance_human', 'PersonName', findSelectItems, values) }
                            { prepareGroup(service_insurance_studio, 'service_insurance_studio', 'Name', findSelectItems, values) }
                            { prepareGroup(service_lawyer_human, 'service_lawyer_human', 'PersonName', findSelectItems, values) }
                            { prepareGroup(service_lawyer_studio, 'service_lawyer_studio', 'Name', findSelectItems, values) }
                            { prepareGroup(service_security_human, 'service_security_human', 'PersonName', findSelectItems, values) }
                            { prepareGroup(service_security_studio, 'service_security_studio', 'Name', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamServices };