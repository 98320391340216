import React, { useState, useCallback } from 'react';

import { SelectMultiple, ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';

const WorkCharacteristicsMusic = inject('WorksStore', 'LanguageStore', 'GenresStore')(observer(({ WorksStore, LanguageStore, GenresStore }) => {
    const [selectInputValue, setSelectInputValue] = useState(undefined);

    const { currentWork, updateCurrentWorkLocal } = WorksStore;
    const { language } = LanguageStore;
    const { musicGenres, findMusicGenre } = GenresStore;

    const { isInternet, isTv, extraNoSelect, extraLabel, extraIndependentGroup, genresObj } = currentWork.characteristics;

    const musicGenresArr = genresObj || [];

    const preparedGenres = [{
        id: 0,
        items: musicGenres.map(genre => ({
            id: genre.id,
            text: genre.name,
            selected: Boolean(musicGenresArr.find(genreItem => genreItem.id === genre.id)),
            data: genre
        }))
    }];

    // Удаление жанра
    const removeGenre = event => {
        const crossIcon = event.currentTarget;
        const token = crossIcon.closest('.Token');
        const data = JSON.parse(token.dataset.data);

        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        const genreIndex = workCharacteristicsCopy.genresObj.indexOf(workCharacteristicsCopy.genresObj.find(genre => genre.id === data.id));
        const genreIdIndex = workCharacteristicsCopy.genres.indexOf(data.id);

        workCharacteristicsCopy.genresObj.splice(genreIndex, 1);
        workCharacteristicsCopy.genres.splice(genreIdIndex, 1);

        updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
    };

    const selectTokens = musicGenresArr.map(genre => ({
        id: genre.id,
        text: genre.name,
        data: genre,
        canDelete: true,
        handlers: {
            handleRemoveIconClick: removeGenre
        }
    }));

    const radioBoxes = {
        'additional': ['extraNoSelect', 'extraLabel', 'extraIndependentGroup']
    };

    // Поиск жанра музыки с задержкой
    const findMusicGenreDebounced = useCallback(
        debounce(value => findMusicGenre(value === '' ? '*' : value), 350), 
    []);

    // Отлов ввода в поле выбора
    const handleSelectInput = event => {
        const input = event.target;
        const value = input.value;

        setSelectInputValue(value);
        findMusicGenreDebounced(value.trim());
    };

    // Отлов фокуса в поле выбора
    const handleSelectFocus = () => findMusicGenre('*');

    // Выбор жанра
    const chooseGenre = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        const sameGenre = workCharacteristicsCopy.genresObj && workCharacteristicsCopy.genresObj.find(genre => genre.id === data.id);

        if (!sameGenre) {
            !workCharacteristicsCopy.genres && (workCharacteristicsCopy.genres = []);
            workCharacteristicsCopy.genres.push(data.id);

            !workCharacteristicsCopy.genresObj && (workCharacteristicsCopy.genresObj = []);
            workCharacteristicsCopy.genresObj.push(data);

            setSelectInputValue(undefined);
            updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
        }
    };

    // Выбор чекбокса
    const chooseCheckbox = event => {
        const checkbox = event.target;
        const checked = checkbox.checked;
        const name = checkbox.name;

        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        workCharacteristicsCopy[name] = checked;

        updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
    };

    // Выбор радио
    const chooseRadio = event => {
        const radio = event.target;
        const name = radio.name;
        const value = radio.value;

        const otherRadios = radioBoxes[name].filter(radioBox => radioBox !== value);
        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        workCharacteristicsCopy[value] = true;

        otherRadios.forEach(otherRadio => workCharacteristicsCopy[otherRadio] = false);
        
        updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
    };

    const selectInputProps = {
        value: selectInputValue,
        tokens: selectTokens
    };

    return (
        <div className = 'WorkCharacteristics'>
            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('Genre', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent'>
                    <SelectMultiple inputProps = { selectInputProps }
                                    items = { preparedGenres }
                                    onInputChange = { handleSelectInput }
                                    onInputFocus = { handleSelectFocus }
                                    onDropdownItemClick = { chooseGenre } />
                </div>
            </div>

            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('Shows', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent WorkCharacteristics__sectionContent--with-choose-box'>
                    <ChooseBox name = 'isInternet' 
                               text = { translate('The internet', language) }
                               checked = { isInternet }
                               onChange = { chooseCheckbox } />

                    <ChooseBox name = 'isTv' 
                               text = { translate('TV', language) }
                               checked = { isTv }
                               onChange = { chooseCheckbox } />
                </div>
            </div>

            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('Additional', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent WorkCharacteristics__sectionContent--with-choose-box'>
                    <ChooseBox type = 'radio' 
                               name = 'additional' 
                               text = { translate('Not selected', language) }
                               value = 'extraNoSelect'
                               checked = { extraNoSelect }
                               onChange = { chooseRadio } />

                    <ChooseBox type = 'radio' 
                               name = 'additional' 
                               text = { translate('Record company', language) }
                               value = 'extraLabel'
                               checked = { extraLabel }
                               onChange = { chooseRadio } />
                    
                    <ChooseBox type = 'radio' 
                               name = 'additional' 
                               text = { translate('Independent group', language) }
                               value = 'extraIndependentGroup'
                               checked = { extraIndependentGroup }
                               onChange = { chooseRadio } />
                </div>
            </div>
        </div>
    )
}));

export { WorkCharacteristicsMusic };