import React, { useState } from 'react';

import { Modal } from 'components/containers';
import { Button, Alert, Input } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, request } from 'helpers';

import { BASE_URL } from 'constants/BASE_URL';
 
import './_NewCabinetEntityModal.scss';

const NewCabinetEntityModal = inject('LanguageStore', 'UserStore')(observer(({ type, onAdd, modalOpened, setModalOpened, LanguageStore }) => {
    const [entity, setEntity] = useState({ model: type, 'name-ru-RU': '', 'name-en-US': '', link: '' });

    const { language } = LanguageStore;

    const avaibleEnglishFields = ['name-ru-RU', 'abbreviation-ru-RU'];

    const title = {
        'guild': 'New guild',
        'university': 'New place of education',
        'company': 'New company'
    };

    const alertText = {
        'guild': 'If your guild is not in the list, you can add your own name, and after confirmation, it will appear in your profile.',
        'university': 'If your school is not in the list, you can add your name, and after confirmation, it will appear in your profile.',
        'company': 'If your company is not in the list, you can add your name, and after confirmation, it will appear in your profile.'
    };

    const nameLabel = {
        'guild': 'The name of the guild',
        'university': 'Name of the educational institution',
        'company': 'Company name'
    };

    const nameEnglishLabel = {
        'guild': 'Name of the guild in English',
        'university': 'Name of the educational institution in English',
        'company': 'Company name in English'
    };

    const linkLabel = {
        'guild': 'The website of the guild',
        'university': 'The website of the educational institution',
        'company': 'Company site'
    };

    // Отлов ввода
    const handleInputChange = event => {
        const input = event.target;
        const name = input.name;
        const value = input.value.trim();
        const updatedEntity = { ...entity, [name]: value };

        if (language === 'en-US' && avaibleEnglishFields.includes(name)) {
            updatedEntity[name.replace('ru-RU', 'en-US')] = value;
        }

        setEntity(updatedEntity);
    };

    // Создание сущности
    const addEntity = async() => {
        const options = { method: 'POST', body: JSON.stringify(entity) };
        const response = await request(`${BASE_URL}/api/user/handbook/add`, options);
        const data = await response.json();

        if (response.status === 200) {
            onAdd(data.result);
        }
    };

    return (
        <Modal title = { translate(title[type], language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'NewCabinetEntityModal'>
                <Alert text = { translate(alertText[type], language) } />

                <Input name = 'name-ru-RU' 
                       label = { translate(nameLabel[type], language) }
                       required
                       onInput = { handleInputChange } />
                
                { language === 'ru-RU' && (
                    <Input name = 'name-en-US' 
                           label = { translate(nameEnglishLabel[type], language) }
                           onInput = { handleInputChange } />
                ) }

                { type === 'university' && (
                    <Input name = 'abbreviation-ru-RU' 
                           label = { translate('Abbreviation', language) }
                           onInput = { handleInputChange } />
                ) }

                { (type === 'university' && language === 'ru-RU') && (
                    <Input name = 'abbreviation-en-US' 
                           label = { translate('Abbreviation (for English version)', language) }
                           onInput = { handleInputChange } />
                ) }

                <Input name = 'link' 
                       label = { translate(linkLabel[type], language) }
                       onInput = { handleInputChange } />
            </div>

            <div className = 'Modal__actions'>
                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: translate('Send', language) }}
                            disabled = { entity['name-ru-RU'] === '' }
                            onClick = { addEntity } />
                </div>
            </div>
        </Modal>
    )
}));

export { NewCabinetEntityModal };