import React from 'react';

import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_ReferralBenefit.scss';

const ReferralBenefit = inject('LanguageStore')(observer(({ benefit, LanguageStore }) => {
    const { language } = LanguageStore;

    const { title, text, statement, substatement, icon, hide } = benefit;

    return hide ? null : (
        <li className = 'ReferralBenefit'>
            <span className = 'ReferralBenefit__title'>
                { translate(title, language) }
            </span>

            <span className = 'ReferralBenefit__text'>
                { translate(text, language) }
            </span>

            <span className = 'ReferralBenefit__statement'>
                { translate(statement, language) }
            </span>

            <span className = 'ReferralBenefit__statement'>
                { translate(substatement, language) }
            </span>

            <Icon className = 'ReferralBenefit__icon' name = { icon } />
        </li>
    )
}));

export { ReferralBenefit };