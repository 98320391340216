// Хранилище языков, стран и т.д

import { observable, decorate, action, runInAction } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class GeolocationStore {
    geolocation = {}

    // Получение языка
    async findLanguage(name) {
        const response = await request(`${BASE_URL}/api/geolocation/language`, { method: 'POST', body: JSON.stringify({ find: name }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.geolocation.languages = data.result);
        }
    }

    // Получение страны
    async findCountry(name) {
        const response = await request(`${BASE_URL}/api/geolocation/find-country`, {  method: 'POST', body: JSON.stringify({ find: name }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.geolocation.countries = data.result);
        }

        return data.result;
    }

    // Получение города
    async findCity(name, countryId) {
        const response = await request(`${BASE_URL}/api/geolocation/find-city`, {  method: 'POST', body: JSON.stringify({ find: name, countryId: countryId }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.geolocation.cities = data.result);
        }

        return data.result;
    }
}

decorate(GeolocationStore, {
    geolocation: observable,
    findLanguage: action.bound,
    findCountry: action.bound,
    findCity: action.bound
});

export default new GeolocationStore();