import React, { useState, useCallback } from 'react';

import { Modal } from 'components/containers';
import { Button, Alert, SelectPure } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

import './_PayServiceModal.scss';

const PayServiceModal = inject('LanguageStore', 'CouponsStore')(observer(({ modalOpened, setModalOpened, coupon = {}, invoice, LanguageStore, CouponsStore }) => {
    const [selectInputValue, setSelectInputValue] = useState( (coupon.coupon && coupon.coupon.code) || undefined);
    const [currentCoupon, setCurrentCoupon] = useState({ discount: coupon.discount || 0, coupon: { code: coupon.coupon && coupon.coupon.code } });

    const { language } = LanguageStore;
    const { personalCoupons, checkCoupon, getPersonalCoupons } = CouponsStore;

    const selectInputProps = {
        label: translate('Promo code', language),
        value: selectInputValue
    };

    const preparedCoupons = personalCoupons.filter(couponItem => !couponItem.used) > 0 ? [{
        id: 0,
        items: personalCoupons.filter(couponItem => !couponItem.used).map((couponItem, index) => (
            {
                id: index,
                text: couponItem.code,
                selected: couponItem.code === currentCoupon.coupon.code,
                data: couponItem
            }
        ))
    }] : [];

    const payDomen = `${BASE_URL}/payed/invoice/`;
    const payLink = `${payDomen}${invoice.payedAlias}${currentCoupon.coupon.code ? `?coupon=${currentCoupon.coupon.code}` : ''}`;

    // Закрытие модального окна
    const closeModal = () => setModalOpened(false);

    // Проверка купона
    const checkCouponDebounced = useCallback(
        debounce(value => {
            const invoiceId = invoice.id;
            
            checkCoupon(invoiceId, value)
                .then(data => setCurrentCoupon(data))
                .catch(error => {
                    setCurrentCoupon(prevCoupon => {
                        return {    
                            ...prevCoupon,
                            coupon: {},
                            discount: 0,
                            error: value === '' ? null : error
                        }
                    });
                });
        }, 350)  
    , []);

    // Отлов ввода в поле выбора
    const handleSelectInput = event => {
        const input = event.target;
        const value = input.value;

        setSelectInputValue(value);
        checkCouponDebounced(value.trim());
    };

    // Отлов фокуса в поле выбора
    const handleSelectFocus = () => getPersonalCoupons();

    // Отлов клика в поле выбора
    const handleDropdownItemClick = event => {
        const item = event.currentTarget;
        const couponData = JSON.parse(item.dataset.data);
        const invoiceId = invoice.id;
        
        checkCoupon(invoiceId, couponData.code)
            .then(data => {
                setSelectInputValue(data.coupon.code);
                setCurrentCoupon(data);
            });
    };

    return (
        <Modal title = { translate('To pay for a service?', language) } titleAlign = 'center' opened = { modalOpened } setOpened = { closeModal }>
            <div className = 'PayServiceModal'>

                <div className = 'PayServiceModal__promoCode'>
                    <SelectPure inputProps = { selectInputProps }
                                items = { preparedCoupons }
                                error = { currentCoupon.error }
                                onInputFocus = { handleSelectFocus }
                                onInputChange = { handleSelectInput }
                                onDropdownItemClick = { handleDropdownItemClick } />
                </div>

                <div className = 'PayServiceModal__amount'>
                    <div className = 'PayServiceModal__amountItem'>
                        <span className = 'PayServiceModal__amountItemLeft'>{ translate('Price', language) }</span>

                        <span className = 'PayServiceModal__amountItemRight'>
                            { `${invoice.amount} ${translate('Rub', language)}` }
                        </span>
                    </div>

                    <div className = 'PayServiceModal__amountItem'>
                        <span className = 'PayServiceModal__amountItemLeft'>{ translate('Discount', language) }</span>

                        <span className = 'PayServiceModal__amountItemRight'>
                            { `${currentCoupon.discount} ${translate('Rub', language)}` }
                        </span>
                    </div>

                    <div className = 'PayServiceModal__amountItem PayServiceModal__amountItem--total'>
                        <span className = 'PayServiceModal__amountItemLeft'>{ translate('TotalPrice', language) }</span>

                        <span className = 'PayServiceModal__amountItemRight'>
                            { `${invoice.amount - currentCoupon.discount} ${translate('Rub', language)}` }
                        </span>
                    </div>
                </div>

                <div className = 'PayServiceModal__alert'>
                    <Alert text = { translate('You will be redirected to the payment page.', language) } />
                </div>
            </div>

            <div className = 'Modal__actions Modal__actions--fluid'>
                <div className = 'Modal__actionsSection Modal__actionsSection--left'>
                    <Button content = {{ text: translate('Cancel', language) }}
                            fluid = { true }
                            onClick = { closeModal } />
                </div>

                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <a href = { payLink }>
                        <Button content = {{ text: translate('Pay', language) }} fluid = { true } />
                    </a>
                </div>
            </div>
        </Modal>
    )
}));

export { PayServiceModal };