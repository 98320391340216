import React from 'react';

import { Input, ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const WorkCharacteristicsSerial = inject('WorksStore', 'LanguageStore')(observer(({ WorksStore, LanguageStore }) => {
    const { currentWork, updateCurrentWorkLocal } = WorksStore;
    const { language } = LanguageStore;

    const { link, isInternet, isTv, isWar } = currentWork.characteristics;
    const { formatUnder12, formatOver12 } = currentWork.characteristics;
    const { extraNoSelect, extraHistory, extraModern } = currentWork.characteristics;

    const radioBoxes = {
        'format': ['formatUnder12', 'formatOver12'],
        'additional': ['extraNoSelect', 'extraHistory', 'extraModern']
    };

    // Смена ссылки для просмотра
    const changeWatchLink = event => {
        const input = event.target;
        const value = input.value;

        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        workCharacteristicsCopy.link = value;

        updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
    };

    // Выбор чекбокса
    const chooseCheckbox = event => {
        const checkbox = event.target;
        const checked = checkbox.checked;
        const name = checkbox.name;

        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        workCharacteristicsCopy[name] = checked;

        updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
    };

    // Выбор радио
    const chooseRadio = event => {
        const radio = event.target;
        const name = radio.name;
        const value = radio.value;

        const otherRadios = radioBoxes[name].filter(radioBox => radioBox !== value);
        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        workCharacteristicsCopy[value] = true;

        otherRadios.forEach(otherRadio => workCharacteristicsCopy[otherRadio] = false);
        
        updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
    };

    return (
        <div className = 'WorkCharacteristics'>
            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('Official link to watch the series online', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent'>
                    <div className = 'WorkCharacteristics__linkInput'>
                        <Input label = { `${translate('Link', language)}...` } 
                               defaultValue = { link }
                               onInput = { changeWatchLink } />
                    </div>
                </div>
            </div>

            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('Shows', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent WorkCharacteristics__sectionContent--with-choose-box'>
                    <ChooseBox name = 'isInternet' 
                               text = { translate('The internet', language) }
                               checked = { isInternet }
                               onChange = { chooseCheckbox } />

                    <ChooseBox name = 'isTv' 
                               text = { translate('TV', language) }
                               checked = { isTv }
                               onChange = { chooseCheckbox } />
                </div>
            </div>

            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('Format', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent WorkCharacteristics__sectionContent--with-choose-box'>
                    <ChooseBox type = 'radio' 
                               name = 'format' 
                               text = { translate('TV series / series (up to 12 episodes)', language) }
                               value = 'formatUnder12'
                               checked = { formatUnder12 }
                               onChange = { chooseRadio } />

                    <ChooseBox type = 'radio' 
                               name = 'format' 
                               text = { translate('Multi-part series (12 + episodes)', language) }
                               value = 'formatOver12'
                               checked = { formatOver12 }
                               onChange = { chooseRadio } />
                </div>
            </div>

            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('Additional', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent WorkCharacteristics__sectionContent--with-choose-box'>
                    <ChooseBox type = 'radio' 
                               name = 'additional' 
                               text = { translate('Not selected', language) }
                               value = 'extraNoSelect'
                               checked = { extraNoSelect }
                               onChange = { chooseRadio } />

                    <ChooseBox type = 'radio' 
                               name = 'additional' 
                               text = { translate('Historical', language) }
                               value = 'extraHistory'
                               checked = { extraHistory }
                               onChange = { chooseRadio } />
                    
                    <ChooseBox type = 'radio' 
                               name = 'additional' 
                               text = { translate('Modern', language) }
                               value = 'extraModern'
                               checked = { extraModern }
                               onChange = { chooseRadio } />
                    
                    <ChooseBox name = 'isWar' 
                               text = { translate('Military', language) }
                               checked = { isWar }
                               onChange = { chooseCheckbox } />
                </div>
            </div>
        </div>
    )
}));

export { WorkCharacteristicsSerial };