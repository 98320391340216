// Хранилище универститетов

import { observable, decorate, action, runInAction } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class UniversityStore {
    universities = []

    // Получение университета
    async findUniversity(name) {
        const response = await request(`${BASE_URL}/api/university`, { method: 'POST', body: JSON.stringify({ find: name }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.universities = data.result);
        }
    }
}

decorate(UniversityStore, {
    universities: observable,
    findUniversity: action.bound
});

export default new UniversityStore();