// Парсинг инлайновых стилей и приведение их к объекту
function parseInlineStyles(inlineStyles) {
    const parsedObjectStyles = {};
    const splitedInlineStyles = inlineStyles.replace(/\s/g, '').split(';').filter(property => property !== '');

    splitedInlineStyles.forEach(property => {
        const [name, value] = property.split(':');
        parsedObjectStyles[name] = value;
    });

    return parsedObjectStyles
}

// Приведение стилей вида объекта к виду строки
function inlineObjectStyles(objectStyles) {
    const inlineStylesArr = [];
    const objectStylesKeys = Object.keys(objectStyles);

    objectStylesKeys.forEach(key => {
        inlineStylesArr.push(`${key}: ${objectStyles[key]};`);
    });

    const inlineStylesString = inlineStylesArr.join(' ');

    return inlineStylesString
}

// Изменение инлайновых стилей
function changeInlineStyles(node, styles) {
    const existInlineStyles = node.getAttribute('style') || '';
    const parsedExistStyles = parseInlineStyles(existInlineStyles);

    const newObjectStyles = Object.assign({}, parsedExistStyles);
    const stylesKeys = Object.keys(styles);

    stylesKeys.forEach(key => newObjectStyles[key] = styles[key]);

    const inlineNewStyles = inlineObjectStyles(newObjectStyles);

    node.setAttribute('style', inlineNewStyles);
}

export { changeInlineStyles as setInlineStyles };