import React, { useState, useEffect, useRef } from 'react';

import { SelectInput, SelectDropdown } from 'components/ui';

import classNames from 'classnames';

import './_SelectMultiple.scss';

const SelectMultiple = ({ items = [], onInputChange, onInputFocus, onDropdownItemClick, onWindowClick, disabled, children, inputProps, ...restProps }) => {
    const [active, setActive] = useState(false);

    const selectRef = useRef(null);
    const inputRef = useRef(null);

    const SelectMultipleClass = classNames('SelectMultiple', {
        'SelectMultiple--active': active && items.length > 0
    });

    // Отлов клика по элементу выпадающего списка
    const handleDropdownItemClick = event => {
        onDropdownItemClick && onDropdownItemClick(event);
    };

    // Отлов фокуса на поле ввода
    const handleInputFocus = event => {
        onInputFocus && onInputFocus(event);
        setActive(true);
    };

    // Отлов ввода в поле ввода
    const handleInputChange = event => {
        onInputChange && onInputChange(event);
    }

    // Отлов клика на окне браузера
    const handleWindowClick = event => {
        const select = event.target.closest('.SelectMultiple');

        if (!select || select !== selectRef.current ) {
            onWindowClick && onWindowClick(event);
            setActive(false);
        }
    };

    // Отлов клика по иконка в поле ввода
    const setSelectActive = () => {
        const newActive = !active;
        const input = inputRef.current;

        setActive(newActive);

        newActive ? input.focus() : input.blur();
    };

    // Добавление обработчиков событий и их сброс вместе с ссылкой на поле выбора
    useEffect(() => {
        window.addEventListener('click', handleWindowClick);

        return () => {
            window.removeEventListener('click', handleWindowClick);
            selectRef.current = null;
            inputRef.current = null;
        }
    }, []);

    return (
        <div className = { SelectMultipleClass } ref = { selectRef } { ...restProps }>
            <SelectInput icons = {['multiple']} 
                         disabled = { disabled }
                         handleFocus = { handleInputFocus } 
                         setSelectActive = { setSelectActive } 
                         onChange = { handleInputChange }
                         ref = { inputRef }
                         { ...inputProps }>
                { children }
            </SelectInput>

            <SelectDropdown type = 'multiple' items = { items } onItemClick = { handleDropdownItemClick } />
        </div>
    )
};

export { SelectMultiple };