import React from 'react';

import { FilmSchoolGeneral, FilmSchoolNav, FilmSchoolLocations,
         FilmSchoolMoreInfo, FilmSchoolSocialNetworks, FilmSchoolContacts } from 'components/pages';
import { CabinetContent, CabinetAlerts } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const FilmSchool = inject('LanguageStore', 'CabinetStore')(observer(({ LanguageStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { subscriptions } = CabinetStore;

    return (
        <div className = 'FilmSchool'>
            <FilmSchoolNav />

            <CabinetContent>
                { subscriptions.filmSchool && subscriptions.filmSchool[0].invoice[0].status === 'no_payed' && (
                    <CabinetAlerts>
                        <Alert text = { translate('To display on the site and add graduates, you need to pay for the first package (10 graduates). The first three months - 1 ruble', language) } 
                               type = 'warn' 
                               padding = { true } 
                               bordered = { true } />
                    </CabinetAlerts>
                ) }

                <FilmSchoolGeneral />
                <FilmSchoolContacts />
                <FilmSchoolLocations />
                <FilmSchoolMoreInfo />
                <FilmSchoolSocialNetworks />
            </CabinetContent>
        </div>
    )
}));

export { FilmSchool };