import React, { useState, useEffect } from 'react';

import { Icon } from 'components/ui';

import { generateId } from 'helpers';
import classNames from 'classnames';
import StringMask from 'string-mask';

import './_Input.scss';

const Input = ({ label, defaultValue, value, icons, required, error, disabled, mask, children, className, onChangeHandle, onFocusHandle, onBlurHandle, ...restProps }) => {
    const [inputValue, setInputValue] = useState(defaultValue || '');
    const [inputMask,] = useState(mask && new StringMask(mask));
    const [inputId, setInputId] = useState('');
    const [inputFocused, setInputFocused] = useState(false);

    const InputClass = classNames('Input', className, {
        'Input--with-icons': required || icons || children,
        'Input--focused': inputFocused,
        'Input--filled': (value && String(value).trim() !== '') || (inputValue && String(inputValue).trim() !== ''),
        'Input--error': error,
        'Input--disabled': disabled
    });

    // Отлов ввода на зоне ввода
    const handleInputChange = event => {
        const input = event.target;
        const value = input.value;

        if (inputMask) {
            const formatedValue = inputMask.apply(value.replace(/\s/g, '')).trim();

            if (onChangeHandle) {
                onChangeHandle(event, formatedValue);
            } else {
                setInputValue(formatedValue);
            }
        } else {
            if (onChangeHandle) {
                onChangeHandle(event);
            } else {
                setInputValue(value);
            }
        }
    };

    // Перехват фокуса на зоне ввода
    const handleInputFocus = event => {
        setInputFocused(true);
        onFocusHandle && onFocusHandle(event);
    };
    
    // Перехват блюра на зоне ввода
    const handleInputBlur = event => {
        setInputFocused(false);
        onBlurHandle && onBlurHandle(event);
    }

    // Генерирование id для поля ввода
    useEffect(() =>  setInputId(generateId()), []);
    
    return (
        <div className = { InputClass }>
            { label && <label className = 'Input__label' htmlFor = { inputId }>{ label }</label> }

            <div className = 'Input__areaWrap'>
                <input className = 'Input__area'
                       id = { inputId }
                       value = { value || inputValue }
                       onFocus = { handleInputFocus }
                       onBlur = { handleInputBlur }
                       onChange = { handleInputChange }
                       { ...restProps } />

                { (required || icons || children) && (
                    <div className = 'Input__icons'>
                        { required && <Icon name = 'asterisk' key = 'asterisk' className = 'Input__iconsIcon Input__iconsIcon--asterisk' /> }

                        { icons && icons.map(icon => (
                            <Icon name = { icon } key = { icon } className = { `Input__iconsIcon Input__iconsIcon--${icon}` } />
                        )) }

                        { children }
                    </div>
                ) }
            </div>

            <span className = 'Input__bar'></span>
            
            { typeof error === 'string' && (
                <span className = 'Input__error'>{ error }</span>
            ) }
        </div>
    )
};

export { Input };