import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { CabinetSection } from 'components/containers';
import { ChooseBox, Alert, FilterSelect, Input, ChooseIcon } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate, debounce } from 'helpers';
import { PHONE_CODES } from 'constants/constants';

import './_StudioContacts.scss';

const StudioContacts = inject('LanguageStore', 'CabinetStore')(observer(({ LanguageStore, CabinetStore }) => {
    const [inputs, setInputs] = useState({ 
        'phone': undefined,
        'email': undefined,
        'forButtonPhone': undefined,
        'forEmailPhone': undefined
    });

    const [phoneCodes, setPhoneCodes] = useState(PHONE_CODES);

    const { language } = LanguageStore;
    const { cabinet, updateCabinetSection } = CabinetStore;

    const contacts = cabinet.personalData ? cabinet.personalData.contacts : null;

    const phoneCode = contacts && contacts.phone ? phoneCodes.find(code => code.code === contacts.phone.match(/^\+\d+/)[0]) : phoneCodes.find(code => code.code === '+7');
    const forButtonPhone = contacts && contacts.forButtonPhone ? phoneCodes.find(code => code.code === contacts.forButtonPhone.match(/^\+\d+/)[0]) : phoneCodes.find(code => code.code === '+7');

    const phoneWithoutCode = (contacts && contacts.phone) && contacts.phone.split(' ').slice(1).join(' ');
    const forButtonPhoneWithoutCode = (contacts && contacts.forButtonPhone) && contacts.forButtonPhone.split(' ').slice(1).join(' ');

    // Изменение использования контактов
    const changeContacts = event => {
        const chooseBox = event.target;
        const name = chooseBox.name;
        const checked = chooseBox.checked;

        updateCabinetSection('contacts', { [name]: checked });
    };

    // Показывать информацию(телефон, почта) в основных контактах
    const showInfo = event => {
        const chooseBox = event.target;
        const checked = chooseBox.checked;
        const name = chooseBox.dataset.name;
        
        updateCabinetSection('contacts', { [name]: !checked });
    };

    // Изменение телефона
    const changePhoneItem = useCallback(
        debounce((value, name, phoneCodeItem) => {
            updateCabinetSection('contacts', { [name]: `${phoneCodeItem.code} ${value}` });
        }, 350)
    , []);

    // Изменение почты
    const changeEmailItem = useCallback(
        debounce((value, name) => {
            updateCabinetSection('contacts', { [name]: value });
        }, 350)
    , []);

    // Изменение телефона
    const changePhone = event => {
        const input = event.target;
        const value = input.value.replace(/\D/, '');
        const name = input.name;
        const phoneCodeId = JSON.parse(document.querySelector(`.FilterSelect[data-name="${name}"]`).dataset.id);
        const phoneCodeItem = phoneCodes.find(phoneCodeItem => phoneCodeItem.id === phoneCodeId);

        setInputs(prevInputs => ({ ...prevInputs, [name]: value }));

        changePhoneItem(value.trim(), name, phoneCodeItem);
    };

    // Изменение информации о пользователе
    const changeInfo = event => {
        const input = event.target;
        const value = input.value;
        const name = input.name;

        setInputs(prevInputs => ({ ...prevInputs, [name]: value }));

        changeEmailItem(value.trim(), name);
    };
    
    // Изменение кода страны для телефона
    const changePhoneCode = ({ parentName, item }) => {
        const phoneInput = document.querySelector(`input[name='${parentName}']`);
        const phoneInputValue = phoneInput.value.trim();

        const phoneCodeItem = phoneCodes.find(phoneCodeItem => phoneCodeItem.code === item.code);
        const phoneCodeItemIndex = phoneCodes.indexOf(phoneCodeItem);

        const phoneCodesCopy = Object.clone(phoneCodes);
        const prevCode = phoneCodesCopy.find(phoneCode => phoneCode[parentName]);

        prevCode && (prevCode[parentName] = false);
        phoneCodesCopy[phoneCodeItemIndex][parentName] = true;

        setPhoneCodes(phoneCodesCopy);

        updateCabinetSection('contacts', { [parentName]: `${item.code} ${phoneInputValue}` });
    };

    return contacts && (
        <CabinetSection id = 'contacts' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Contacts', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'StudioContacts'>
                    <CabinetSection sub = { true }>
                        <div className = 'CabinetSection__title'>
                            <span className = 'CabinetSection__titleText'>{ translate('Main', language) }</span>

                            <ChooseBox type = 'checkbox' 
                                       name = 'userMainContacts'
                                       checked = { contacts.userMainContacts } 
                                       text = { translate('Use account contacts', language) }
                                       onChange = { changeContacts } />
                        </div>

                        <div className = 'CabinetSection__content'>
                            <div className = 'StudioContacts__section'>
                                <div className = 'CabinetSection__container'>
                                    <div className = 'StudioContacts__sectionContent'>
                                        <div className = 'StudioContacts__inputs StudioContacts__inputs--general'>
                                            <div className = 'StudioContacts__phone'>
                                                <FilterSelect type = 'phone' 
                                                              items = { phoneCodes } 
                                                              current = { phoneCodes.find(code => code.code === phoneCode.code) }
                                                              name = 'phone'
                                                              id = { phoneCodes.find(code => code.code === phoneCode.code).id }
                                                              disabled = { contacts.userMainContacts }
                                                              onChoose = { changePhoneCode } />

                                                <Input label = { translate('Phone', language) } 
                                                       value = { inputs.phone !== undefined ? inputs.phone : phoneWithoutCode } 
                                                       name = 'phone'
                                                       disabled = { contacts.userMainContacts }
                                                       onChangeHandle = { changePhone }>
                                                    <ChooseIcon text = { translate('Show in profile', language) }  
                                                                changeText = { translate('Don`t show in profile', language) } 
                                                                tooltipDirection = 'right'
                                                                checked = { !contacts.phoneShow }
                                                                onChange = { showInfo }
                                                                data-name = 'phoneShow' />
                                                </Input>
                                            </div>

                                            <Input label = 'Email'
                                                   value = { inputs.email !== undefined ? inputs.email : contacts.email } 
                                                   name = 'email'
                                                   disabled = { contacts.userMainContacts }
                                                   onChangeHandle = { changeInfo }>
                                                <ChooseIcon text = { translate('Show in profile', language) } 
                                                            changeText = { translate('Don`t show in profile', language) } 
                                                            tooltipDirection = 'right'
                                                            checked = { !contacts.emailShow }
                                                            onChange = { showInfo }
                                                            data-name = 'emailShow' />
                                            </Input>
                                        </div>

                                        <div className = 'StudioContacts__personalPage'>
                                            <Input label = { translate('Personal page', language) } defaultValue = { contacts.site } name = 'site' onInput = { changeInfo } />
                                        </div>

                                        <div className = 'StudioContacts__alert'>
                                            <Alert text = { translate('When you turn off the phone and email display, you can be contacted via the "Contact" button, which can be configured separately', language) }
                                                   padding = { true }
                                                   bordered = { true } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CabinetSection>

                    <CabinetSection sub = { true }>
                        <div className = 'CabinetSection__title'>
                            <span className = 'CabinetSection__titleText'>{ translate('For the «Contact» button', language) }</span>

                            <ChooseBox type = 'checkbox' 
                                       name = 'forButtonContacts'
                                       checked = { contacts.forButtonContacts } 
                                       text = { translate('Use the main contacts', language) }
                                       onChange = { changeContacts } />
                        </div>

                        <div className = 'CabinetSection__content'>
                            <div className = 'StudioContacts__section'>
                                <div className = 'CabinetSection__container'>
                                    <div className = 'StudioContacts__sectionContent'>
                                        <div className = 'StudioContacts__inputs StudioContacts__inputs--button-contacts'>
                                            <div className = 'StudioContacts__phone'>
                                                <FilterSelect type = 'phone' 
                                                              items = { phoneCodes } 
                                                              current = { phoneCodes.find(code => code.forButtonPhone || code.code === forButtonPhone.code) }
                                                              name = 'forButtonPhone'
                                                              id = { phoneCodes.find(code => code.forButtonPhone || code.code === forButtonPhone.code).id }
                                                              disabled = { contacts.forButtonContacts }
                                                              onChoose = { changePhoneCode } />

                                                <Input label = { translate('Phone', language) } 
                                                       value = { inputs.forButtonPhone !== undefined ? inputs.forButtonPhone : forButtonPhoneWithoutCode } 
                                                       name = 'forButtonPhone'
                                                       disabled = { contacts.forButtonContacts }
                                                       onChangeHandle = { changePhone } />
                                            </div>

                                            <Input label = 'Email' 
                                                   value = {  inputs.forEmailPhone !== undefined ? inputs.forEmailPhone : contacts.forEmailPhone } 
                                                   name = 'forEmailPhone'
                                                   disabled = { contacts.forButtonContacts }
                                                   onInput = { changeInfo } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CabinetSection>
                </div>
            </div>
        </CabinetSection>
    )
}));

export { StudioContacts };