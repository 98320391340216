import React from 'react';
import { withRouter } from 'react-router-dom';

import { Container, HeaderNav, HeaderSearch, HeaderLanguage, HeaderUser } from 'components/containers';
import { Logo } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import { BASE_URL } from 'constants/BASE_URL';

import './_Header.scss';

const Header = inject('LanguageStore', 'UserStore', 'WorksStore', 'CabinetStore')(observer(withRouter(({ history, LanguageStore, UserStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { user } = UserStore;
    const { updateCurrentWorkLocal } = WorksStore;
    const { clearCabinetSearch } = CabinetStore;

    const { activeCabinet } = user;
    
    const addWorkCallback = () => {
        updateCurrentWorkLocal({ mode: 'add', errors: {}, updated: [] }, true);
        clearCabinetSearch();
    };

    const addButton = {
        studio: { text: 'Add work', link: '/works/add-work/general', callback: addWorkCallback },
        executor: { text: 'Add work', link: '/works/add-work/general', callback: addWorkCallback },
        filmSchool: { text: 'Add graduate', link: '/graduates?modal=true' },
        agent: { text: 'Add executor', link: '/contractors?modal=true' }
    };

    // Отлов клика по кнопке
    const handleAddButtonClick = () => {
        const { link, callback } = addButton[activeCabinet];

        callback && callback();
        history.push(link);
    };

    return (
        <header className = 'Header'>
            <Container>
                <div className = 'Header__wrap'>
                    <div className = 'Header__section Header__section--nav'>
                        <HeaderNav />
                    </div>

                    <div className = 'Header__section Header__section--logo'>
                        <a href = { BASE_URL }>
                            <Logo />
                        </a>
                    </div>

                    <div className = 'Header__section Header__section--search'>
                        <HeaderSearch />
                    </div>

                    <div className = 'Header__section Header__section--language'>
                        <HeaderLanguage />
                    </div>

                    <div className = 'Header__section Header__section--user'>
                        <HeaderUser />
                    </div>

                    { addButton[activeCabinet] && (
                        <div className = 'Header__section Header__section--add-work'>
                            <span className = 'Header__addWork' onClick = { handleAddButtonClick }>
                                { translate(addButton[activeCabinet].text, language) }
                            </span>
                        </div>
                    ) }
                </div>
            </Container>
        </header>
    )
})));

export { Header };