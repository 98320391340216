import React from 'react';

import { WorkActions, WorkImagePropertyInfo } from 'components/pages';
import { CabinetContent } from 'components/containers';

const WorkImageProperty = () => (
    <div className = 'WorkImageProperty'>
        <CabinetContent>
            <WorkImagePropertyInfo />
            <WorkActions />
        </CabinetContent>
    </div>
);

export { WorkImageProperty };