import React from 'react';

import { WorkActions, WorkBackstageImages, WorkBackstageVideos } from 'components/pages';

import { CabinetContent } from 'components/containers';

const WorkBackstage = () => (
    <div className = 'WorkBackstage'>
        <CabinetContent>
            <WorkBackstageImages />
            <WorkBackstageVideos />
            <WorkActions />
        </CabinetContent>
    </div>
);

export { WorkBackstage };