import React, { useState, useEffect } from 'react';

import { Modal } from 'components/containers';
import { Button, Input, ChooseBox, SelectFilter } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import classNames from 'classnames';

import './_NewProfessionDetailedModal.scss';

const NewProfessionDetailedModal = inject('LanguageStore', 'ProfessionsStore', 'CabinetStore', 'InvoicesStore')(observer(({ modalOpened, setModalOpened, InvoicesStore, LanguageStore, ProfessionsStore, CabinetStore }) => {
    const [currentClassifications, setCurrentClassifications] = useState([]);
    const [currentClassification, setCurrentClassification] = useState('');
    
    const { language } = LanguageStore;
    const { getInvoices } = InvoicesStore;
    const { departs, structs, currentProfession, findDepart, findStruct, updateCurrentProfessionLocal, clearCurrentProfession } = ProfessionsStore;
    const { cabinet, updateCabinetSection, updateCabinetSectionErrors, getAllSubscriptions } = CabinetStore;

    const { speciality } = cabinet.personalData;

    // Обновление даты у текущей работы
    const updateCurrentProfessionDate = event => {
        const input = event.target;
        const value = input.value.trim();

        updateCurrentProfessionLocal({ date: `${value}-01-01` });
        updateCabinetSectionErrors('speciality', { workStart: null });
    };

    // Выбор состава
    const chooseItemComposition = event => {
        const chooseBox = event.target;
        const compositionId = JSON.parse(chooseBox.dataset.id);

        findDepart(compositionId);
        updateCurrentProfessionLocal({}, true);
        setCurrentClassifications([]);
    };

    // Отлов клика по элементу списка в поле выбора
    const handleSelectDropdownItemClick = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const isChosen = currentProfession.id === data.id && currentProfession.departId === data.departId;
        const isExistProfession = Boolean(speciality.find(item => +item.professionId === data.id && item.departId === data.departId));
        
        if (!isExistProfession && !isChosen) {
            setCurrentClassifications(data.classification);
            setCurrentClassification(data.classification[0]);

            updateCurrentProfessionLocal(data);
        }
    };

    // Изменение классификации
    const changeClassification = event => {
        const radio = event.target;
        const classification = radio.value;

        setCurrentClassification(classification);
    };

    // Выбор профессии(radio)
    const chooseProfessionRadio = profession => {
        setCurrentClassifications(profession.classification);
        setCurrentClassification(profession.classification[0]);
      
        updateCurrentProfessionLocal(profession);
    };

    // Добавление профессии
    const addProfession = () => {
        const workStart = /\b\d{4}-01-01\b/.test(currentProfession.date) ? currentProfession.date : `${new Date().getFullYear()}-01-01`;

        updateCabinetSection('speciality', { 
            professionId: currentProfession.id,
            classification: currentClassification,
            departId: currentProfession.departId,
            workStart: workStart
        }).then(async () => {
            await getAllSubscriptions();
            await getInvoices();

            setModalOpened(false)
        })
          .catch(() => updateCurrentProfessionLocal({ date: undefined }));
    };

    // Очистка текущей профессии
    useEffect(() => {
        return () => {
            clearCurrentProfession();
            updateCabinetSectionErrors('speciality', { workStart: null });
        }
    }, []);

    // Получение составов
    useEffect(() => {
        findStruct('*');
    }, []);

    return (
        <Modal title = { translate('Choose speciality', language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'NewProfessionDetailedModal'>
                <div className = 'NewProfessionDetailedModal__info'>
                    <div className = 'NewProfessionDetailedModal__infoSection'>
                        <span className = 'NewProfessionDetailedModal__infoSectionTitle'>
                            { translate('Composition', language) }
                        </span>

                        <div className = 'NewProfessionDetailedModal__infoSectionContent'>
                            { structs.map(struct => (
                                <ChooseBox key = { struct.id }
                                           name = 'composition'
                                           data-id = { struct.id }
                                           type = 'radio'
                                           size = 'lg' 
                                           text = { struct[`name-${language}`] || struct['name-ru-RU'] }
                                           onChange = { chooseItemComposition } />
                            )) }
                        </div>
                    </div>

                    { departs.length > 0 && (
                        <div className = 'NewProfessionDetailedModal__infoSection'>
                            <span className = 'NewProfessionDetailedModal__infoSectionTitle'>
                                { translate('Profession', language) }
                            </span>

                            <div className = 'NewProfessionDetailedModal__infoSectionContent'>
                                { departs.map(depart => {
                                    const currentText = currentProfession.departId === depart.id ? currentProfession[`name-${language}`] : depart[`name-${language}`];

                                    const preparedProfessions = [{
                                        id: depart.id,
                                        title: depart[`name-${language}`],
                                        items: depart.profession.map(profession => {
                                            const isChosen = currentProfession.id === profession.id && currentProfession.departId === depart.id;
                                            const isExistProfession = Boolean(speciality.find(item => item.professionId === profession.id && item.departId === depart.id));

                                            return {
                                                id: profession.id,
                                                text: profession[`name-${language}`] || profession['name-ru-RU'],
                                                selected: isChosen || isExistProfession,
                                                data: { ...profession, departId: depart.id }
                                            }
                                        })
                                    }];
                                    
                                    return depart.id !== null ? (
                                        <SelectFilter key = { depart.id }
                                                      current = {currentText }
                                                      selected = { currentProfession.departId === depart.id }
                                                      items = { preparedProfessions }
                                                      onDropdownItemClick = { handleSelectDropdownItemClick } />
                                    ) : (
                                        ( depart.profession.map(profession => {
                                            const isExistProfession = Boolean(speciality.find(item => item.professionId === profession.id && item.departId === depart.id));

                                            return (
                                                <ChooseBox key = { profession.id }
                                                           type = 'radio'
                                                           name = 'profession'
                                                           size = 'lg'
                                                           disabled = { isExistProfession }
                                                           text = { profession[`name-${language}`] || profession['name-ru-RU'] }
                                                           onChange = { chooseProfessionRadio.bind(null, profession) } />
                                            )
                                        }) )
                                    )
                                }) }
                            </div>
                        </div>
                    ) }

                    { currentClassifications.filter(classification => classification === 'pro' || classification === 'new-blood').length > 0 && (
                        <div className = 'NewProfessionDetailedModal__infoSection'>
                            <span className = 'NewProfessionDetailedModal__infoSectionTitle'>
                                { translate('Classification', language) }
                            </span>

                            <div className = 'NewProfessionDetailedModal__infoSectionContent'>
                                { currentClassifications.length > 1 && currentClassifications.map(classification => (
                                    <div className = 'NewProfessionDetailedModal__classfication' key = { classification }>
                                        { ['pro', 'new-blood'].includes(classification) && (
                                            <ChooseBox type = 'radio'
                                                       name = 'classification'
                                                       value = { classification }
                                                       icon = { `${classification}-${language}` }
                                                       size = 'lg'
                                                       checked = { classification === currentClassification }
                                                       onChange = { changeClassification } />
                                        ) }   
                                        
                                        <div className = 'NewProfessionDetailedModal__classficationInfo'>
                                            { ['pro', 'new-blood'].includes(classification) && (
                                                <span className = 'NewProfessionDetailedModal__classficationText'>
                                                    { classification === 'pro' ? translate('Among the works, you must have 1 full-length film or 10 commercial commercials, or 10 music videos', language) : translate('Who starts, but has already made interesting projects', language) }
                                                </span>
                                            ) }
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    ) }

                    { currentClassifications.length > 0 && (
                        <div className = 'NewProfessionDetailedModal__infoSection'>
                            <span className = 'NewProfessionDetailedModal__infoSectionTitle'>
                                { translate('Cost', language) }
                            </span>

                            <div className = 'NewProfessionDetailedModal__infoSectionContent'>
                                { currentClassifications.map(classification => {
                                    const classificationCountsClass = classNames('NewProfessionDetailedModal__classficationCounts', {
                                        'NewProfessionDetailedModal__classficationCounts--selected': (['pro', 'new-blood'].includes(classification) && currentClassification === classification) || !['pro', 'new-blood'].includes(classification)
                                    });
                                    
                                    return (
                                        <div className = 'NewProfessionDetailedModal__classfication' key = { classification }>
                                            <div className = 'NewProfessionDetailedModal__classficationInfo'>
                                                <div className = { classificationCountsClass }>
                                                    <span className = 'NewProfessionDetailedModal__classficationCount'>
                                                        { Math.round(currentProfession.priceList[classification].rubYear / 12) } ₽/{ translate('MonthShort', language).toLowerCase() } —&nbsp;
                                                        { translate('When paying for a year', language).toLowerCase() }
                                                    </span>

                                                    <span className = 'NewProfessionDetailedModal__classficationCount'>
                                                        { currentProfession.priceList[classification].rubMonth } ₽/{ translate('MonthShort', language).toLowerCase() } —&nbsp;
                                                        { translate('When paying for a month', language).toLowerCase() }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) }
                            </div>
                        </div>
                    ) }
                </div>

                <Input label = { translate('Since what year', language) }
                       value = { currentProfession.date !== undefined ? currentProfession.date.split('-')[0] : '' }
                       error = { cabinet.errors.speciality && cabinet.errors.speciality.workStart }
                       onChangeHandle = { updateCurrentProfessionDate } />
            </div>

            <div className = 'Modal__actions'>
                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: translate('Add', language) }}
                            disabled = { !currentProfession.id }
                            onClick = { addProfession } />
                </div>
            </div>
        </Modal>
    )
}));

export { NewProfessionDetailedModal };