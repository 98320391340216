import React from 'react';

import { CabinetScreenNav } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const BookmarksScreenNav = inject('LanguageStore')(observer(({ counts, LanguageStore, ...restProps }) => {
    const { language } = LanguageStore;

    const screens = [
        { name: 'peopleFolders', title: `${translate('People', language)} (${counts[0]})` },
        { name: 'companiesFolders', title: `${translate('Companies', language)} (${counts[1]})` },
        { name: 'workFolders', title: `${translate('Works', language)} (${counts[2]})` }
    ];

    return (
        <div className = 'CabinetScreenNav__wrap'>
            <CabinetScreenNav screens = { screens } { ...restProps } />
        </div>
    )
}));

export { BookmarksScreenNav };