import React from 'react';

import { WorkActions, WorkBudgetsInfo } from 'components/pages';
import { CabinetContent } from 'components/containers';

const WorkBudgets = () => (
    <div className = 'WorkBudgets'>
        <CabinetContent>
            <WorkBudgetsInfo />
            <WorkActions />
        </CabinetContent>
    </div>
);

export { WorkBudgets };