import React, { useState, useEffect } from 'react';

import { FilmSchoolGraduateCard } from 'components/pages';
import { InviteGraduateFilmSchoolModal, EditSubscriptionModals } from 'components/containers';
import { Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, prepareSubscription } from 'helpers';
import Sortable from 'sortablejs';

import './_FilmSchoolGraduatesList.scss';

const FilmSchoolGraduatesList = inject('LanguageStore', 'CabinetStore')(observer(({ LanguageStore, CabinetStore }) => {
    const [inviteExecutorModalOpened, setInviteExecutorModalOpened] = useState(false);
    const [editSubscriptionModalsOpened, setEditSubscriptionModalsOpened] = useState(false);

    const { language } = LanguageStore;
    const { cabinet, updateCabinetSection, getAllSubscriptions, subscriptions } = CabinetStore;

    const subscription = subscriptions.filmSchool ? subscriptions.filmSchool[0] : {};
    const executors = cabinet.personalData ? cabinet.personalData.graduates || [] : [];

    // Приглашение исполнителя
    const inviteExecutor = (executor, profession, yearOfGraduation) => {
        updateCabinetSection('graduates', { 
            executorCabinetId: executor.cabinet_id,
            professionId: profession.id, 
            show: false,
            workEnd: `${yearOfGraduation}-01-01`
        }, { page: 'personalData' })
            .then(() => {
                getAllSubscriptions();
                setInviteExecutorModalOpened(false);
            });
    };

    // Отлов окончания dnd
    const handleDragEnd = ({ newIndex, oldIndex }) => {
        if (newIndex !== oldIndex) {
            const executorsCopy = Object.clone(executors);
            const executorsCards = [...document.querySelectorAll(`.FilmSchoolGraduateCard[data-executor-id]`)];
            
            executorsCopy.forEach(executor => {
                const executorsCard = executorsCards.find(card => Number(card.dataset.executorId) === executor.id);
                executor.sort = executorsCards.indexOf(executorsCard);
            });
        }
    };

    // Проверка кол-во пакетов
    const checkPackets = () => {
        if (subscription.userAttachCount >= subscription.maxUser || subscription.status !== 'active') {
            setEditSubscriptionModalsOpened(true);
        } else {
            setInviteExecutorModalOpened(true);
        }
    };

    // Добавление сортировки карточке исполнителей
    useEffect(() => {
        const sortableList = document.querySelector('.FilmSchoolGraduatesList__items');
        
        const sortable = new Sortable(sortableList, { 
            animation: 150, 
            handle: '.FilmSchoolGraduateCard__option--drag',
            onEnd: handleDragEnd
        });

        return () => {
            sortable.destroy();
        }
    }, [executors.length]);

    // Открытие модалки по нажатию на ссылку
    useEffect(() => {
        if (window.location.search === '?modal=true' && subscription.id) {
            checkPackets();
        }
    }, [subscription.id]);

    return (
        <div className = 'FilmSchoolGraduatesList'>
            <div className = 'FilmSchoolGraduatesList__actions'>
                <Button content = {{ icon: 'plus-in-circle', text: translate('Add graduate', language) }} 
                        pure = { true }
                        theme = 'grey'
                        onClick = { checkPackets } />
            </div>

            <div className = 'FilmSchoolGraduatesList__items' key = { executors.length }>
                { executors.map(executor => (
                    <FilmSchoolGraduateCard key = { executor.id } contractor = { executor } />
                )) }
            </div>

            { inviteExecutorModalOpened && (
                <InviteGraduateFilmSchoolModal modalOpened = { inviteExecutorModalOpened }
                                               setModalOpened = { setInviteExecutorModalOpened }
                                               graduates = { executors }
                                               onInvite = { inviteExecutor } />
            ) }

            { editSubscriptionModalsOpened && (
                <EditSubscriptionModals modalsOpened = { editSubscriptionModalsOpened } 
                                        setModalsOpened = { setEditSubscriptionModalsOpened } 
                                        cabinet = 'filmSchool'
                                        invoice = { { ...prepareSubscription(subscription, 'filmSchool').invoice, priceList: subscription.priceList } } />
            ) }
        </div>
    )
}));

export { FilmSchoolGraduatesList };