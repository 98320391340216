// Роутинг и навигация для разделов добавления и редактирования работ
import { WorkGeneral } from 'components/pages/Work/WorkGeneral/WorkGeneral';
import { WorkTeam } from 'components/pages/Work/WorkTeam/WorkTeam';
import { WorkCharacteristics } from 'components/pages/Work/WorkCharacteristics/WorkCharacteristics';
import { WorkPreparation } from 'components/pages/Work/WorkPreparation/WorkPreparation';
import { WorkBackstage } from 'components/pages/Work/WorkBackstage/WorkBackstage';
import { WorkFestivals } from 'components/pages/Work/WorkFestivals/WorkFestivals';
import { WorkSkills } from 'components/pages/Work/WorkSkills/WorkSkills';
import { WorkAdditionalEquipment } from 'components/pages/Work/WorkAdditionalEquipment/WorkAdditionalEquipment';
import { WorkLocationTimeWeather } from 'components/pages/Work/WorkLocationTimeWeather/WorkLocationTimeWeather';
import { WorkBudgets } from 'components/pages/Work/WorkBudgets/WorkBudgets';
import { WorkImageProperty } from 'components/pages/Work/WorkImageProperty/WorkImageProperty';
import { WorkLightingStyle } from 'components/pages/Work/WorkLightingStyle/WorkLightingStyle';

export const workRoutes = [
    {
        name: 'For quick search',
        color: 'orange',
        items: [
            { path: '/general', apiName: 'basic-information', component: WorkGeneral },
            { path: '/team', apiName: 'team', component: WorkTeam }
        ]
    },

    {
        name: 'For detailed search',
        color: 'green',
        items: [
            { path: '/characteristics', apiName: 'characteristics',  component: WorkCharacteristics },
            { path: '/preparation', apiName: 'tabs',  component: WorkPreparation },
            { path: '/backstage', apiName: 'backstage',  component: WorkBackstage },
            { path: '/festivals-and-awards', apiName: 'festivals-and-awards',  component: WorkFestivals }
        ]
    },

    {
        name: 'For professional search',
        color: 'purple',
        items: [
            { path: '/skills', apiName: 'skills',  component: WorkSkills },
            { path: '/image-property', apiName: 'image-property', component: WorkImageProperty },
            { path: '/lighting-and-style', apiName: 'lighting-and-style', component: WorkLightingStyle },
            { path: '/additional-equipment', apiName: 'additional-equipment',  component: WorkAdditionalEquipment },
            { path: '/locations-time-weather', apiName: 'locations-time-weather',  component: WorkLocationTimeWeather },
            { path: '/budgets', apiName: 'budgets', component: WorkBudgets }
        ]
    }
];