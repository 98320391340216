// Хранилище с поисками
import { action, observable, runInAction, decorate } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class SearchesStore {
    searches = []

    // Получение поисков
    async getSearches() {
        const response = await request(`${BASE_URL}/api/user/save-search`);
        const data = await response.json();

        runInAction(() => this.searches = data.data);
    
        return response
    }

    // Удаление поиска
    async deleteSearch(id) {
        const options = { method: 'DELETE', body: JSON.stringify({ id }) };
        const response = await request(`${BASE_URL}/api/user/save-search/remove`, options);
        const data = await response.json();

        if (data.success) {
            const search = this.searches.find(search => search.id === id);
            const searchIndex = this.searches.indexOf(search);

            runInAction(() => this.searches.splice(searchIndex, 1));
        }
    }

    // Очистка поисков
    cleanSearches() {
        runInAction(() => this.searches = []);
    }
}

decorate(SearchesStore, {
    searches: observable,
    getSearches: action.bound,
    deleteSearch: action.bound,
    cleanSearches: action.bound
});

export default new SearchesStore();