import React, { useState, useEffect } from 'react';

import { Icon } from 'components/ui';

import { generateId } from 'helpers';

import './_TextArea.scss';

const TextArea = ({ label, icons = [], placeholder = ' ', ...restProps }) => {
    const [textAreaId, setTextAreaId] = useState('');

    // Генерирование id для поля ввода
    useEffect(() => setTextAreaId(generateId()), []);

    return (
        <div className = 'TextArea'>
            <textarea className = 'TextArea__area' id = { textAreaId } placeholder = { placeholder } { ...restProps }></textarea>
            <label className = 'TextArea__label' htmlFor = { textAreaId }>{ label }</label>

            { icons.length > 0 && (
                <div className = 'TextArea__icons'>
                    { icons.map(icon => (
                        <Icon key = { icon } name = { icon } className = { `TextArea__icon TextArea__icon--${icon}` } />
                    )) }
                </div>
            ) }

            <span className = 'TextArea__bar'></span>
        </div>
    )
};

export { TextArea };