import React, { useState } from 'react';

import { Tooltip, Icon } from 'components/ui';

import classNames from 'classnames';

import './_CopyText.scss';

const CopyText = ({ className, text, copyText, copiedText }) => {
    const [isCopied, setIsCopied] = useState(false);

    const CopyTextClass = classNames('CopyText', className);

    function copy() {
        const clipboard = window.navigator.clipboard;
        
        clipboard.writeText(text)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 1000);
            });
    }

    return (
        <div className = { CopyTextClass }>
            <Tooltip text = { isCopied ? copiedText : copyText } direction = 'down' position = 'left'>
                <div className = 'CopyText__iconWrap' onClick = { copy }>
                    <Icon className = 'CopyText__icon' name = 'copy' />
                </div>
            </Tooltip>

            <div className = 'CopyText__text'>
                { text }
            </div>
        </div>
    )
};

export { CopyText };