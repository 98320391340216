import React, { useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamMovieSerialComputerGraphics = inject('LanguageStore', 'WorksStore', 'CabinetStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { currentWork } = WorksStore;
    const { searchInCabinet } = CabinetStore;

    const { graphics2_producer_vfx, graphics2_supervisor_vfx, graphics2_provisions, graphics2_concept_designer } = currentWork.team;
    const { graphics2_hero_dev, graphics2_art_director_vfx, graphics2_designer_vfx, graphics2_motion_designer } = currentWork.team;
    const { graphics2_riging_vfx, graphics2_compositing, graphics2_gudini } = currentWork.team;
    const { graphics2_titer_design, graphics2_3d_stereo_graphics } = currentWork.team;

    const { prepareGroup } = itemProps;

    const values = {
        'graphics2_producer_vfx': graphics2_producer_vfx.map(item => item.value),
        'graphics2_supervisor_vfx': graphics2_supervisor_vfx.map(item => item.value),
        'graphics2_provisions': graphics2_provisions.map(item => item.value),
        'graphics2_concept_designer': graphics2_concept_designer.map(item => item.value),
        'graphics2_hero_dev': graphics2_hero_dev.map(item => item.value),
        'graphics2_art_director_vfx': graphics2_art_director_vfx.map(item => item.value),
        'graphics2_designer_vfx': graphics2_designer_vfx.map(item => item.value),
        'graphics2_motion_designer': graphics2_motion_designer.map(item => item.value),
        'graphics2_riging_vfx': graphics2_riging_vfx.map(item => item.value),
        'graphics2_compositing': graphics2_compositing.map(item => item.value),
        'graphics2_gudini': graphics2_gudini.map(item => item.value),
        'graphics2_titer_design': graphics2_titer_design.map(item => item.value),
        'graphics2_3d_stereo_graphics': graphics2_3d_stereo_graphics.map(item => item.value)
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;
            const executorObj = { name: checkedValue, name_en: checkedValue, surname: checkedValue, surname_en: checkedValue };

            searchInCabinet('executor', 'personalData', 'profile', key, executorObj, result => {
                setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
            });
        }, 350)    
    , []);

    return (
        <div className = { WorkTeamSectionClass }>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'computerGraphics') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Computer graphics', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(graphics2_producer_vfx, 'graphics2_producer_vfx', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_supervisor_vfx, 'graphics2_supervisor_vfx', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_provisions, 'graphics2_provisions', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_concept_designer, 'graphics2_concept_designer', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_hero_dev, 'graphics2_hero_dev', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_art_director_vfx, 'graphics2_art_director_vfx', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_designer_vfx, 'graphics2_designer_vfx', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_motion_designer, 'graphics2_motion_designer', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_riging_vfx, 'graphics2_riging_vfx', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_compositing, 'graphics2_compositing', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_gudini, 'graphics2_gudini', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_titer_design, 'graphics2_titer_design', 'PersonName', findSelectItems, values) }
                            { prepareGroup(graphics2_3d_stereo_graphics, 'graphics2_3d_stereo_graphics', 'PersonName', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamMovieSerialComputerGraphics };