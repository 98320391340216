import React, { useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon, ChooseBox, Tooltip, Button, Input } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamMusicGroupInfo = inject('LanguageStore', 'WorksStore', 'MusicStore',  'CompanyStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, MusicStore, CompanyStore }) => {
    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal } = WorksStore;
    const { findMusicGroup, findMusicLabel } = MusicStore;
    const { findCompany } = CompanyStore;

    const { music_group, music_song_name, music_label } = currentWork.team;

    const { prepareGroup, addGroupItem, handleChooseBoxChange, handleRemoveClick } = itemProps;

    const values = {
        'music_group': music_group.map(item => item.value),
        'music_label': music_label.map(item => item.value)
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    const lastMusicNameValue = music_song_name[music_song_name.length - 1].value;

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;
            const companyObj = { name: checkedValue };

            switch(name) {
                case 'music_group': {
                    findMusicGroup(companyObj, result => {
                        setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
                    });

                    break;
                }

                case 'music_label': {
                    findMusicLabel(companyObj, result => {
                        setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
                    });

                    break;
                }

                default: break;
            }
        }, 350)    
    , []);

    // Изменение названия песни
    const changeMusicName = (key, event) => {
        const input = event.target;
        const value = input.value.trim();

        const [groupName, index] = key.split('-');

        const workTeamCopy = Object.clone(currentWork.team);
        const musicNameGroupCopy = workTeamCopy[groupName].map(item => Object.clone(item));

        musicNameGroupCopy[index].value = value;
        workTeamCopy[groupName] = musicNameGroupCopy;

        updateCurrentWorkLocal({ team: workTeamCopy });
    };

    return (
        <div className = { WorkTeamSectionClass } id = 'info'>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'info') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Music group', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(music_group, 'music_group', 'PersonName', findSelectItems, values) }

                            <div className = 'WorkTeam__group'>
                                { music_song_name.map(item => {
                                    const index = music_song_name.indexOf(item);
                                    const key = `music_song_name-${index}`;

                                    return (
                                        <div className = 'WorkTeamItem' key = { key } id = { key }>
                                            <div className = 'WorkTeamItem__title'>
                                                <span className = 'WorkTeamItem__titleText'>
                                                    { item[`name-${language}`] || item['name-ru-RU'] }
                                                </span>
                                            </div>
                                
                                            <div className = 'WorkTeamItem__content'>
                                                <Input label = { translate('PersonName', language) }
                                                       defaultValue = { item.value }
                                                       onInput = { changeMusicName.bind(null, key) } />
                                                
                                                { music_song_name.length > 1 && (
                                                    <Tooltip text = { translate('Remove', language) } position = 'left'>
                                                        <div className = 'WorkTeamItem__removeWrap' onClick = { handleRemoveClick.bind(null, key) }>
                                                            <Icon name = 'cross-tiny' className = 'WorkTeamItem__remove' />
                                                        </div>
                                                    </Tooltip>
                                                ) }
                                            </div>
                                        </div>
                                    )
                                }) }

                                <Button content = {{ icon: 'plus-in-circle', text: translate('Add', language) }}
                                        className = 'WorkTeam__groupAddButton'
                                        pure = { true }
                                        theme = 'grey'
                                        disabled = { !lastMusicNameValue }
                                        onClick = { addGroupItem.bind(null, 'music_song_name') } />
                            </div>

                            { prepareGroup(music_label, 'music_label', 'PersonName', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamMusicGroupInfo };