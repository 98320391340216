import React from 'react';

import './_CabinetContent.scss';

const CabinetContent = ({ children }) => (
    <div className = 'CabinetContent'>
        { children }
    </div>
);

export { CabinetContent };