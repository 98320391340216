import React, { useEffect } from 'react';

import { ChooseBox, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import Sortable from 'sortablejs';

import './_TabsListsAwards.scss';

const TabsListsAwards = inject('LanguageStore', 'TabsStore')(observer(({ items, LanguageStore, TabsStore }) => {
    const { language } = LanguageStore;
    const { changeTabShow, changeItemsSort } = TabsStore;

    // Окончание перетаскивания
    function handleDragEnd({ newIndex, oldIndex }) {
        if (newIndex !== oldIndex) {
            const itemsIds = [];
            const awardCards = [...document.querySelectorAll('.TabsListsAwards__item')];

            items.forEach(item => {
                const card = awardCards.find(card => +card.dataset.id === item.id);
                const cardIndex = awardCards.indexOf(card);

                itemsIds[cardIndex] = item.id;                
            });

            changeItemsSort('fest', itemsIds);
        }
    }

    // Добавление dnd разделам
    useEffect(() => {
        const list = document.querySelector('.TabsListsAwards');

        if (list) {
            const sortable = new Sortable(list, { 
                animation: 150, 
                handle: '.TabsListsAwards__itemDrag',
                onEnd: handleDragEnd
            });
    
            return () => {
                sortable.destroy();
            }
        }
    }, []);

    return items.length > 0 ? (
        <div className = 'TabsListsAwards'>
            { items.map(item => (
                <div key = { item.id } data-id = { item.id } className = 'TabsListsAwards__item'>
                    <div className = 'TabsListsAwards__itemInfo'>
                        <Icon name = { item.isWinner ? 'winner' : 'participant' } className = 'TabsListsAwards__itemIcon' />

                        <div className = 'TabsListsAwards__itemMain'>
                            <span className = 'TabsListsAwards__itemName'>
                                { item.awardsName }
                            </span>

                            <span className = 'TabsListsAwards__itemSubName'>
                                { item.fest.name } ({ item.year })
                            </span>
                        </div>
                    </div>

                    <div className = 'TabsListsAwards__itemOptions'>
                        <ChooseBox text = { translate('Show', language) }
                                   checked = { item.show }
                                   onChange = { changeTabShow.bind(null, { name: 'fest', equalFunc: award => award.id === item.id, id: item.id }) } />
                        
                        <div className = 'TabsListsAwards__itemDragWrap'>
                            <Icon name = 'drag-points' className = 'TabsListsAwards__itemDrag' />

                            <span className = 'TabsListsAwards__itemDragText'>
                                { translate('Drag', language) }
                            </span>
                        </div>
                    </div>
                </div>
            )) }
        </div>
    ) : (
        <div className = 'TabsLists__empty'>
            { translate('You don\'t have any awards', language) }
        </div>
    )
}));

export { TabsListsAwards };