// Хранилище с запросами
import { action, observable, runInAction, decorate } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class BookmarksStore {
    executorsFolders = []
    workFolders = []

    // Получение исполнителей
    async getExecutorsFolders() {
        const response = await request(`${BASE_URL}/api/user/favorites`);
        const data = await response.json();

        runInAction(() => this.executorsFolders = data.result);
    
        return response
    }

    // Получение работ
    async getWorksFolders() {
        const response = await request(`${BASE_URL}/api/user/favorites-work`);
        const data = await response.json();

        runInAction(() => this.workFolders = data.result);
    
        return response
    }

    // Удаление исполнителя
    async removeExecutor(cabinetId, cabinetType) {
        const options = { method: 'DELETE', body: JSON.stringify({ cabinetId, cabinetType }) };
        const response = await request(`${BASE_URL}/api/user/favorites/remove`, options);
        const data = await response.json();

        if (data.success) {
            this.getExecutorsFolders();
        }
    
        return response
    }

    // Удаление работы
    async removeWork(id) {
        const options = { method: 'DELETE', body: JSON.stringify({ id }) };
        const response = await request(`${BASE_URL}/api/user/favorites-work/remove`, options);
        const data = await response.json();

        if (data.success) {
            this.getWorksFolders();
        }
    
        return response
    }
}

decorate(BookmarksStore, {
    executorsFolders: observable,
    workFolders: observable,
    getExecutorsFolders: action.bound,
    getWorksFolders: action.bound,
    removeExecutor: action.bound,
    removeWork: action.bound
});

export default new BookmarksStore();