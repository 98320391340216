import React, { useState } from 'react';

import { CabinetSection } from 'components/containers';
import { ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_WorkGeneralAnimations.scss';

const WorkGeneralAnimations = inject('LanguageStore', 'WorksStore')(observer(({ LanguageStore, WorksStore }) => {
    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal } = WorksStore;

    const { fields } = currentWork;

    const { animation2d, animation3d, animationDrawing, animationSand, animationStopMotion, 
            animationGlass, animationPuppet, animationAnime, animationPlasticine } = fields;

    // Изменение настроек анимаций
    const changeAnimationAvaible = event => {
        const chooseBox = event.target;
        const checked = chooseBox.checked;
        const name = chooseBox.name;

        const workFieldsCopy = Object.clone(fields);
        workFieldsCopy[name] = checked;

        updateCurrentWorkLocal({ fields: workFieldsCopy });
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title Animation__title'>
                <ChooseBox name = 'animationEnabled'
                           checked = { fields.animationEnabled } 
                           text = { translate('With animation', language).toLowerCase() }
                           disabled = { currentWork.isOtherUserWork === true }
                           onChange = { changeAnimationAvaible } />
            </div>

            { fields.animationEnabled && (
                <div className = 'CabinetSection__content'>
                    <div className = 'WorkGeneralAnimations'>
                        <ChooseBox name = 'animation2d'
                                   checked = { animation2d }
                                   disabled = { currentWork.isOtherUserWork === true }
                                   size = 'lg'
                                   text = '2D'
                                   onChange = { changeAnimationAvaible } />

                        <ChooseBox name = 'animation3d'
                                   checked = { animation3d }
                                   disabled = { currentWork.isOtherUserWork === true }
                                   size = 'lg'
                                   text = '3D'
                                   onChange = { changeAnimationAvaible } />

                        <ChooseBox name = 'animationDrawing'
                                   checked = { animationDrawing }
                                   disabled = { currentWork.isOtherUserWork === true }
                                   size = 'lg'
                                   text = { translate('Hand drawn', language) }
                                   onChange = { changeAnimationAvaible } />

                        <ChooseBox name = 'animationAnime'
                                   checked = { animationAnime }
                                   disabled = { currentWork.isOtherUserWork === true }
                                   size = 'lg'
                                   text = { translate('Anime', language) }
                                   onChange = { changeAnimationAvaible } />

                        <ChooseBox name = 'animationStopMotion'
                                   checked = { animationStopMotion }
                                   disabled = { currentWork.isOtherUserWork === true }
                                   size = 'lg'
                                   text = 'Stop-motion'
                                   onChange = { changeAnimationAvaible } />

                        <ChooseBox name = 'animationGlass'
                                   checked = { animationGlass }
                                   disabled = { currentWork.isOtherUserWork === true }
                                   size = 'lg'
                                   text = { translate('On the glass', language) }
                                   onChange = { changeAnimationAvaible } />   

                        <ChooseBox name = 'animationSand'
                                   checked = { animationSand }
                                   disabled = { currentWork.isOtherUserWork === true }
                                   size = 'lg'
                                   text = { translate('Sand', language) }
                                   onChange = { changeAnimationAvaible } />

                        <ChooseBox name = 'animationPlasticine'
                                   checked = { animationPlasticine }
                                   disabled = { currentWork.isOtherUserWork === true }
                                   size = 'lg'
                                   text = { translate('Plasticine', language) }
                                   onChange = { changeAnimationAvaible } />

                        <ChooseBox name = 'animationPuppet'
                                   checked = { animationPuppet }
                                   disabled = { currentWork.isOtherUserWork === true }
                                   size = 'lg'
                                   text = { translate('Puppet', language) }
                                   onChange = { changeAnimationAvaible } />
                    </div>
                </div>
            ) }
        </CabinetSection> 
    )
}));

export { WorkGeneralAnimations };