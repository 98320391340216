import React, { useState } from 'react';

import { CabinetSection, ChangePhoneModal } from 'components/containers';
import { Alert, Input, FilterSelect, Button, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';
import { PHONE_CODES } from 'constants/constants';

import './_ManagementData.scss';

const ManagementData = inject('LanguageStore', 'UserStore')(observer(({ LanguageStore, UserStore }) => {
    const [openedChangePhoneModal, setOpenedChangePhoneModal] = useState(false);
    const [wasEmailSended, setWasEmailSended] = useState(false);

    const { language } = LanguageStore;
    const { user } = UserStore;

    const emailVerified = user.emailVerified;
    const phoneVerified = user.phoneVerified;

    const phoneCode = user.phone ? PHONE_CODES.find(code => code.code === user.phone.match(/^\+\d+/)[0]) : PHONE_CODES.find(code => code.code === '+7');
    const phoneWithoutCode = user.phone && user.phone.split(' ').slice(1).join(' ');

    // Получение письма
    const getEmail = async() => {
        const options = { method: 'POST', body: JSON.stringify({ phone: user.email }) };
        const response = await request(`${BASE_URL}/api/user/verification/email/send`, options);
        const { success } = await response.json();

        if (success) {
            setWasEmailSended(true);
        }
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Login details', language) }</span>
            </div>

            <div className = 'CabinteSection__content'>
                <div className = 'ManagementData'>
                    <div className = 'ManagementData__alerts'>
                        <Alert text = { translate('These contacts can be used to autocomplete your profiles', language) }
                               padding = { true }
                               bordered = { true } />
                    </div>

                    <div className = 'CabinetSection__container'>
                        <div className = 'ManagementData__inputs'>
                            <div className = 'ManagementData__phone'>
                                <FilterSelect type = 'phone' 
                                              items = { PHONE_CODES } 
                                              current = { PHONE_CODES.find(code => code.phoneCode || code.code === phoneCode.code) }
                                              name = 'phone'
                                              disabled = { true }
                                              id = { PHONE_CODES.find(code => code.phoneCode || code.code === phoneCode.code).id } />

                                <Input label = { translate('Phone', language) } 
                                       value = { phoneWithoutCode }
                                       disabled = { true }
                                       name = 'phone' />

                                { phoneVerified && (
                                    <div className = 'ManagementData__phoneConfirm'>
                                        <Icon className = 'ManagementData__phoneConfirmIcon' name = 'tick' />

                                        <span className = 'ManagementData__phoneConfirmText'>
                                            { translate('Confirmed', language) }
                                        </span>
                                    </div>
                                ) }

                                <Button className = 'ManagementData__phoneButton' 
                                        content = {{ text: translate('Change', language) }}
                                        onClick = { setOpenedChangePhoneModal.bind(null, true) } />
                            </div>
                            
                            <div className = 'ManagementData__email'>
                                <Input label = 'Email' 
                                       name = 'email'
                                       disabled = { true }
                                       value = { user.email } />

                                { emailVerified && (
                                    <div className = 'ManagementData__emailConfirm ManagementData__emailConfirm--confirmed'>
                                        <Icon className = 'ManagementData__emailConfirmIcon' name = { 'tick' } />

                                        <span className = 'ManagementData__emailConfirmText'>
                                            { translate('Confirmed', language) }
                                        </span>
                                    </div>
                                ) }

                                { !emailVerified && (
                                    <div className = 'ManagementData__emailConfirm'>
                                        <Icon className = 'ManagementData__emailConfirmIcon' name = { wasEmailSended ? 'circle' : 'cross-small' } />

                                        <span className = 'ManagementData__emailConfirmText'>
                                            { translate(wasEmailSended ? 'Confirmation is expected' : 'Email not confirmed', language) }.
                                        </span>
                                        
                                        <div className = 'ManagementData__emailConfirmLink' onClick = { getEmail }>
                                            <span className = 'ManagementData__emailConfirmText ManagementData__emailConfirmAction'>
                                                { translate(wasEmailSended ? 'Send email again' : 'Send a confirmation email', language) }
                                            </span>
                                            &nbsp;
                                            <span className = 'ManagementData__emailConfirmText'>
                                                →
                                            </span>
                                        </div>
                                    </div>
                                ) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            { openedChangePhoneModal && (
                <ChangePhoneModal modalOpened = { openedChangePhoneModal } 
                                  setModalOpened = { setOpenedChangePhoneModal } />
            ) }
        </CabinetSection>
    )
}));

export { ManagementData };