import React from 'react';

import { ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const WorkCharacteristicsInfoProductVideo = inject('WorksStore', 'LanguageStore')(observer(({ WorksStore, LanguageStore }) => {
    const { currentWork, updateCurrentWorkLocal } = WorksStore;
    const { language } = LanguageStore;

    const { isApp, isSocial } = currentWork.characteristics;

    // Выбор чекбокса
    const chooseCheckbox = event => {
        const checkbox = event.target;
        const checked = checkbox.checked;
        const name = checkbox.name;

        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        workCharacteristicsCopy[name] = checked;

        updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
    };

    return (
        <div className = 'WorkCharacteristics'>
            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('Additional', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent WorkCharacteristics__sectionContent--with-choose-box'>
                    <ChooseBox name = 'isApp' 
                               text = { translate('Apps', language) }
                               checked = { isApp }
                               onChange = { chooseCheckbox } />
                    
                    <ChooseBox name = 'isSocial' 
                               text = { translate('Social networks', language) }
                               checked = { isSocial }
                               onChange = { chooseCheckbox } />
                </div>
            </div>
        </div>
    )
}));

export { WorkCharacteristicsInfoProductVideo };