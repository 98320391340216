import React from 'react';

import { ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const WorkCharacteristicsAds = inject('WorksStore', 'LanguageStore')(observer(({ WorksStore, LanguageStore }) => {
    const { currentWork, updateCurrentWorkLocal } = WorksStore;
    const { language } = LanguageStore;

    const { isInternet, isTv } = currentWork.characteristics;
    const { extraNoSelect, extraVirusAdv, extra360, extraManifest } = currentWork.characteristics;

    const radioBoxes = {
        'additional': ['extraNoSelect', 'extraVirusAdv', 'extra360', 'extraManifest']
    };

    // Выбор чекбокса
    const chooseCheckbox = event => {
        const checkbox = event.target;
        const checked = checkbox.checked;
        const name = checkbox.name;

        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        workCharacteristicsCopy[name] = checked;

        updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
    };

    // Выбор радио
    const chooseRadio = event => {
        const radio = event.target;
        const name = radio.name;
        const value = radio.value;

        const otherRadios = radioBoxes[name].filter(radioBox => radioBox !== value);
        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        workCharacteristicsCopy[value] = true;

        otherRadios.forEach(otherRadio => workCharacteristicsCopy[otherRadio] = false);
        
        updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
    };

    return (
        <div className = 'WorkCharacteristics'>
            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('Shows', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent WorkCharacteristics__sectionContent--with-choose-box'>
                    <ChooseBox name = 'isInternet' 
                               text = { translate('The internet', language) }
                               checked = { isInternet }
                               onChange = { chooseCheckbox } />

                    <ChooseBox name = 'isTv' 
                               text = { translate('TV', language) }
                               checked = { isTv }
                               onChange = { chooseCheckbox } />
                </div>
            </div>

            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('Additional', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent WorkCharacteristics__sectionContent--with-choose-box'>
                    <ChooseBox type = 'radio' 
                               name = 'additional' 
                               text = { translate('Not selected', language) }
                               value = 'extraNoSelect'
                               checked = { extraNoSelect }
                               onChange = { chooseRadio } />

                    <ChooseBox type = 'radio' 
                               name = 'additional' 
                               text = { translate('Viral advertising', language) }
                               value = 'extraVirusAdv'
                               checked = { extraVirusAdv }
                               onChange = { chooseRadio } />
                    
                    <ChooseBox type = 'radio' 
                               name = 'additional' 
                               text = '360'
                               value = 'extra360'
                               checked = { extra360 }
                               onChange = { chooseRadio } />
                    
                    <ChooseBox type = 'radio'
                               name = 'additional' 
                               text = { translate('Manifest', language) }
                               value = 'extraManifest'
                               checked = { extraManifest }
                               onChange = { chooseRadio } />
                </div>
            </div>
        </div>
    )
}));

export { WorkCharacteristicsAds };