import React, { useState } from 'react';

import { EditSubscriptionModal, PaySubscriptionModal } from 'components/containers';

import { observer, inject } from 'mobx-react';
import { prepareSubscription } from 'helpers';

const EditSubscriptionModals = inject('CabinetStore', 'InvoicesStore')(observer(({ cabinet, setModalsOpened, invoice, CabinetStore, InvoicesStore }) => {
    const { subscriptions, getAllSubscriptions } = CabinetStore;
    const { updateInvoicePeriod } = InvoicesStore;

    const [openedEditModal, setOpenedEditModal] = useState(true);
    const [openedPaySubscriptionModal, setOpenedPaySubscriptionModal] = useState(false);

    const currentSubscription = subscriptions[cabinet].find(subscription => {
        return subscription.invoice.find(invoiceItem => invoiceItem.id === invoice.id);
    });

    const preparedSubscription = currentSubscription ? prepareSubscription(currentSubscription, cabinet) : null;

    const openPayModal = async(chosenTime) => {
        await getAllSubscriptions();

        if (preparedSubscription.status !== 'active') {
            await updateInvoicePeriod(invoice.id, chosenTime);
        }

        setOpenedEditModal(false);
        setOpenedPaySubscriptionModal(true);
    };

    return preparedSubscription && (
        <>
            { openedEditModal && (
                <EditSubscriptionModal modalOpened = { openedEditModal }
                                       setModalOpened = { setModalsOpened }
                                       openPayModal = { openPayModal }
                                       subscription = { preparedSubscription } />
            ) }

            { openedPaySubscriptionModal && (
                <PaySubscriptionModal modalOpened = { openedPaySubscriptionModal }
                                      setModalOpened = { setModalsOpened }
                                      subscription = { preparedSubscription } />
            ) }
        </>
    )
}));

export { EditSubscriptionModals }
