// Хранилище c профессиями

import { decorate, action, observable, runInAction } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class ProfessionsStore {
    professions = []
    directions = []
    departs = []
    structs = []

    currentProfession = {}
    currentDirection = {}

    // Поиск направления
    async findDirection(name) {
        const response = await request(`${BASE_URL}/api/professions/find-studio-profession`, { method: 'POST', body: JSON.stringify({ name }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.directions = data.result);
        }
    }

    // Поиск работы
    async findProfession(name) {
        const response = await request(`${BASE_URL}/api/professions/find-profession`, { method: 'POST', body: JSON.stringify({ name }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.professions = data.result);
        }
    }

    // Поиск цеха
    async findDepart(compositionId) {
        const response = await request(`${BASE_URL}/api/professions/find-depart`, { method: 'POST', body: JSON.stringify({ struct_id: compositionId }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.departs = data.result);
        }
    }

    // Поиск состава
    async findStruct(name) {
        const response = await request(`${BASE_URL}/api/professions/find-struct`, { method: 'POST', body: JSON.stringify({ name }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.structs = data.result);
        }
    }

    // Обновление текущей профессии локально
    updateCurrentProfessionLocal(obj, reset = false) {
        this.currentProfession = reset ? obj : { ...this.currentProfession, ...obj };
    }

    // Обновление текущего направления локально
    updateCurrentDirectionLocal(obj, reset = false) {
        this.currentDirection = reset ? obj : { ...this.currentDirection, ...obj };
    }

    // Очистка текущей профессии
    clearCurrentProfession() {
        this.currentProfession = {};
        this.departs = []
        this.structs = []
        this.professions = []
    }
}

decorate(ProfessionsStore, {
    professions: observable,
    directions: observable,
    departs: observable,
    structs: observable,
    currentProfession: observable,
    currentDirection: observable,
    findDirection: action.bound,
    findProfession: action.bound,
    findDepart: action.bound,
    findStruct: action.bound,
    updateCurrentProfessionLocal: action.bound,
    updateCurrentDirectionLocal: action.bound,
    clearCurrentProfession: action.bound
});

export default new ProfessionsStore();