import React from 'react';

import { ReferralPromocode } from 'components/pages';
import { CabinetSection } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate, getDeclination } from 'helpers';

import './_ReferralPromocodes.scss';

const ReferralPromocodes = inject('LanguageStore')(observer(({ promocodes, invited, LanguageStore }) => {
    const { language } = LanguageStore;
    
    const preparedPromocodes = preparePromocodes();
    const needInvite = getNeedInviteCount();
    
    const needInviteText = {
        'ru-RU': ['человек', 'человека', 'человек'],
        'en-US': ['person', 'people', 'people']
    };

    function preparePromocodes() {
        return promocodes.map(promocode => (
            {
                id: promocode.code,
                code: promocode.code,
                text: preparePromocodeText(promocode),
                expiresAt: preparePromocodeDate(promocode.expires_at),
                used: promocode.used
            }
        ));
    }

    function preparePromocodeDate(timestamp) {
        if (timestamp === null) return null;

        const date = new Date(timestamp * 1000);
        const day = `0${date.getDate()}`.slice(-2);
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const year = `000${date.getFullYear()}`.slice(-4);
        
        return `${day}.${month}.${year}`;
    }

    function preparePromocodeText({ discountValue, discountType }) {
        const value = +discountValue;
        const type = discountType;

        const types = {
            days: {
                'ru-RU': [' день', ' дня', ' дней'],
                'en-US': [' day', ' days', ' days']
            },

            money: {
                'ru-RU': [' рубль', ' рубля', ' рублей'],
                'en-US': [' ruble', ' rubles', ' rubles']
            },

            percent: {
                'ru-RU': ['%', '%', '%'],
                'en-US': ['%', '%', '%']
            }
        };

        const start = {
            'ru-RU': 'Скидка',
            'en-US': 'Discount'
        };

        const end = {
            'ru-RU': 'по промокоду',
            'en-US': 'by promo code'
        };

        return `${start[language]} ${value}${getDeclination(value, types[type][language])} ${end[language]}`;
    }

    function getNeedInviteCount() {
        const confirmedCount = invited.filter(item => item.status === 'confirmed').length;
        const step = 5;
        const goal = Math.ceil((confirmedCount + 1) / step) * step;

        return goal - confirmedCount;
    }

    return (
        <div className = 'Referral__section'>
            <div className = 'Referral__sectionTitle'>
                <span className = 'Referral__sectionTitleText'>
                    { translate('Promo codes', language) }
                </span>
            </div>

            <div className = 'Referral__sectionContent'>
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinetSection__content'>
                        <div className = 'ReferralPromocodes'>
                            <span className = 'ReferralPromocodes__text'>
                                { translate('You can use the promo code when paying for a new account. To prevent the free period from being burned out, we recommend using the free months received in advance', language) }
                            </span>

                            <ul className = 'ReferralPromocodes__list'>
                                { preparedPromocodes.map(promocode => (
                                    <ReferralPromocode
                                        key = { promocode.id }
                                        promocode = { promocode }
                                    />
                                )) }

                                <li className = 'ReferralPromocodes__need'>
                                    { translate('For the next promo code it remains to invite', language) }: { needInvite } { getDeclination(needInvite, needInviteText[language]) }
                                </li>
                            </ul>
                        </div>
                    </div>
                </CabinetSection>
            </div>
        </div>
    )
}));

export { ReferralPromocodes };