import React, { useState, useEffect } from 'react';

import { PaySubscriptionModal, SubscriptionModals, PayServiceModal } from 'components/containers';

import { observer, inject } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_PaymentExpensesTable.scss';

const PaymentExpensesTable = inject('LanguageStore', 'CabinetStore')(observer(({ items, prepareSubscription, LanguageStore, CabinetStore }) => {
    const [currentSubscription, setCurrentSubscription] = useState({});
    const [payServiceInvoice, setPayServiceInvoice] = useState({});
    const [openedSubscriptionModals, setOpenedSubscriptionModals] = useState(false);
    const [openedPayModal, setOpenedPayModal] = useState(false);
    const [openedPayServiceModal, setPayServiceModalOpened] = useState(false);

    const { language } = LanguageStore;
    const { subscriptions } = CabinetStore;

    const preparedInvoice = { 
        ...currentSubscription.invoice, 
        priceList: currentSubscription.priceList 
    };

    // Отлов клика по действию счета
    const handleActionClick = invoice => {
        if (invoice.status === 'no_payed') {
            if (invoice.period === 'now') {
                setPayServiceInvoice(invoice);
                return setPayServiceModalOpened(true);
            }

            const invoiceCabinet = invoice.subjectCabinetType;

            const subscriptionsList = subscriptions[invoiceCabinet].map(subscription => {
                return prepareSubscription(subscription, invoiceCabinet);
            });

            const subscriptionItem = subscriptionsList.find(subscription => subscription.invoice.id === invoice.id);

            setCurrentSubscription(subscriptionItem);

            if (['agent', 'filmSchool'].includes(subscriptionItem.cabinet)) {
                setOpenedPayModal(true);
            } else {
                setOpenedSubscriptionModals(true);
            }
        }
    };

    useEffect(() => {
        if (window.location.search === '?service=true' && items.length > 0) {
            const noPayedServiceInvoice = (items.filter(invoice => invoice.status === 'no_payed' && invoice.period === 'now'))[0];

            if (noPayedServiceInvoice) {
                handleActionClick(noPayedServiceInvoice);
            }
        }
    }, [items.length]);

    return (
        <div className = 'PaymentExpensesTable'>
            <div className = 'PaymentExpensesTable__row PaymentExpensesTable__row--head'>
                <div className = 'PaymentExpensesTable__headItem'>
                    <span className = 'PaymentExpensesTable__headItemText'>{ translate('ObjectName', language) }</span>
                </div>

                <div className = 'PaymentExpensesTable__headItem'>
                    <span className = 'PaymentExpensesTable__headItemText'>{ translate('Date of the deduction', language) }</span>
                </div>

                <div className = 'PaymentExpensesTable__headItem'>
                    <span className = 'PaymentExpensesTable__headItemText'>{ translate('The amount', language) }</span>
                </div>

                <div className = 'PaymentExpensesTable__headItem'>
                    <span className = 'PaymentExpensesTable__headItemText'>{ translate('Status', language) }</span>
                </div>
            </div>

            { items.length > 0 ? (
                ( items.map(item => {
                    const statusBodyItemClass = classNames('PaymentExpensesTable__bodyItemText', {
                        'PaymentExpensesTable__bodyItemText--color-red': item.status === 'no_payed'
                    });
    
                    return (
                        <div className = 'PaymentExpensesTable__row PaymentExpensesTable__row--body' key = { item.id }>
                            <div className = 'PaymentExpensesTable__bodyItem PaymentExpensesTable__bodyItem--name' title = { item.description }>
                                <span className = 'PaymentExpensesTable__bodyItemText PaymentExpensesTable__bodyItemText--weight-bold'>
                                    { item.description }
                                </span>
                            </div>
    
                            <div className = 'PaymentExpensesTable__bodyItem'>
                                <span className = 'PaymentExpensesTable__bodyItemText'>
                                    { item.payedData || '-' }
                                </span>
                            </div>
    
                            <div className = 'PaymentExpensesTable__bodyItem'>
                                <span className = 'PaymentExpensesTable__bodyItemText'>
                                    { item.amount } { translate('Rub', language) }
                                </span>
                            </div>
    
                            <div className = 'PaymentExpensesTable__bodyItem PaymentExpensesTable__bodyItem--status'>
                                <span className = { statusBodyItemClass }>
                                    { translate(item.status === 'no_payed' ? 'Not paid' : 'Paid', language) }
                                </span>
    
                                <span className = 'PaymentExpensesTable__bodyItemText PaymentExpensesTable__bodyItemText--action' onClick = { handleActionClick.bind(null, item) }>
                                    { translate(item.status === 'no_payed' ? 'Pay' : 'Print out', language) } →
                                </span>
                            </div>
                        </div>
                    )
                }) )
            ) : (
                <div className = 'PaymentExpensesTable__notFound'>
                    { translate('Expenses not found', language) }.
                </div>
            ) }

            { openedSubscriptionModals && (
                <SubscriptionModals cabinet = { currentSubscription.cabinet }
                                    setModalsOpened = { setOpenedSubscriptionModals }
                                    invoice = { preparedInvoice } />
            ) }

            { openedPayModal && (
                <PaySubscriptionModal modalOpened = { openedPayModal } 
                                      setModalOpened = { setOpenedPayModal }
                                      subscription = { currentSubscription } />
            ) }

            { openedPayServiceModal && (
                <PayServiceModal modalOpened = { openedPayServiceModal } 
                                 setModalOpened = { setPayServiceModalOpened }
                                 invoice = { payServiceInvoice } />
            ) }
        </div>
    )
}));

export { PaymentExpensesTable };