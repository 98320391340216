import React from 'react';

import { PaymentCard } from 'components/pages';
import { CabinetSection } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_PaymentCurrent.scss';

const PaymentCurrent = inject('LanguageStore', 'CabinetStore', 'UserStore')(observer(({ titles, prepareSubscription, LanguageStore, CabinetStore, UserStore }) => {
    const { language } = LanguageStore;
    const { subscriptions } = CabinetStore;
    const { user } = UserStore;
   
    return (
        <div className = 'Payment__section'>
            <div className = 'Payment__sectionTitle'>
                <span className = 'Payment__sectionTitleText'>{ translate('Subscription', language) }</span>
            </div>

            <div className = 'Payment__sectionContent'>
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    {/* <div className = 'CabinetSection__title' style = {{ gridAutoColumns: 'auto' }}>
                        <span className = 'CabinetSection__titleText'>{ translate(titles[user.activeCabinet], language) }</span>
                    </div> */}

                    { subscriptions[user.activeCabinet] && (
                        <div className = 'CabinetSection__content'>
                            <div className = 'PaymentCurrent'>
                                { subscriptions[user.activeCabinet].map(subscription => (
                                    <PaymentCard key = { subscription.id } subscription = { prepareSubscription(subscription, user.activeCabinet) } />
                                )) }
                            </div>
                        </div>
                    ) }
                </CabinetSection>
            </div>
        </div>
    )
}));

export { PaymentCurrent };