import React from 'react';

import { ReferralBenefit } from 'components/pages';

import { inject, observer } from 'mobx-react';

import './_ReferralBenefits.scss';

const ReferralBenefits = inject('UserStore')(observer((({ UserStore }) => {
    const { user } = UserStore;

    const benefits = [
        {
            title: 'Invite to the team',
            text: 'So that you have one project for all, that you were working on',
            statement: 'For every 5 subscribers - 1 month for free',
            substatement: 'Project colleagues have a 120-day trial period',
            icon: 'waves',
            hide: user.activeCabinet === 'agent' || user.activeCabinet === 'filmSchool'
        },

        {
            title: 'Share a link',
            text: 'In Facebook, Vkontakte, chats and conversations',
            statement: 'For every 5 subscribers - 1 month for free',
            substatement: 'Friends 10 bonus days',
            icon: 'chat'
        }
    ];

    return (
        <ul className = 'ReferralBenefits'>
            { benefits.map((benefit, index) => (
                <ReferralBenefit key = { index } benefit = { benefit } />
            )) }
        </ul>
    )
})));

export { ReferralBenefits };