import React, { useEffect, useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamMovieSerialCompound = inject('LanguageStore', 'WorksStore', 'CabinetStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { currentWork } = WorksStore;
    const { searchInCabinet } = CabinetStore;

    const { main_cost_screenwriter, main_cost_director, main_cast_operator, main_cast_production_designer } = currentWork.team;
    const { main_cast_makeup_designer, main_cast_suit_designer, main_cast_composer, main_cast_installation } = currentWork.team;
    const { main_cast_executive_producer, main_cost_creative_producer } = currentWork.team;

    const { prepareGroup } = itemProps;

    const values = {
        'main_cost_screenwriter': main_cost_screenwriter.map(item => item.value),
        'main_cost_director': main_cost_director.map(item => item.value),
        'main_cast_operator': main_cast_operator.map(item => item.value),
        'main_cast_production_designer': main_cast_production_designer.map(item => item.value),
        'main_cast_makeup_designer': main_cast_makeup_designer.map(item => item.value),
        'main_cast_suit_designer': main_cast_suit_designer.map(item => item.value),
        'main_cast_composer': main_cast_composer.map(item => item.value),
        'main_cast_installation': main_cast_installation.map(item => item.value),
        'main_cast_executive_producer': main_cast_executive_producer.map(item => item.value),
        'main_cost_creative_producer':main_cost_creative_producer.map(item => item.value),
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;
            const executorObj = { name: checkedValue, name_en: checkedValue, surname: checkedValue, surname_en: checkedValue };

            searchInCabinet('executor', 'personalData', 'profile', key, executorObj, result => {
                setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
            });
        }, 350)    
    , []);

    // Открытие секции
    useEffect(() => {
        handleTitleClick('compound', true);

        return () => {
            handleTitleClick('compound', false);
        }
    }, []);

    return (
        <div className = { WorkTeamSectionClass }>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'compound') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Compound', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(main_cost_screenwriter, 'main_cost_screenwriter', 'PersonName', findSelectItems, values) }

                            { currentWork.workType === 'serial' && (
                                prepareGroup(main_cost_creative_producer, 'main_cost_creative_producer', 'PersonName', findSelectItems, values)
                            )}

                            { prepareGroup(main_cost_director, 'main_cost_director', 'PersonName', findSelectItems, values) }
                            { prepareGroup(main_cast_operator, 'main_cast_operator', 'PersonName', findSelectItems, values) }
                            { prepareGroup(main_cast_production_designer, 'main_cast_production_designer', 'PersonName', findSelectItems, values) }
                            { prepareGroup(main_cast_makeup_designer, 'main_cast_makeup_designer', 'PersonName', findSelectItems, values) }
                            { prepareGroup(main_cast_suit_designer, 'main_cast_suit_designer', 'PersonName', findSelectItems, values) }
                            { prepareGroup(main_cast_composer, 'main_cast_composer', 'PersonName', findSelectItems, values) }
                            { prepareGroup(main_cast_installation, 'main_cast_installation', 'PersonName', findSelectItems, values) }
                            { prepareGroup(main_cast_executive_producer, 'main_cast_executive_producer', 'PersonName', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamMovieSerialCompound };