import React, { useState, useEffect } from 'react';

import { AgentContractorCard } from 'components/pages';
import { InviteExecutorAgentModal, EditSubscriptionModals } from 'components/containers';
import { Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, prepareSubscription } from 'helpers';
import Sortable from 'sortablejs';

import './_AgentContractorsList.scss';

const AgentContractorsList = inject('LanguageStore', 'AgentExecutorsStore', 'CabinetStore')(observer(({ LanguageStore, AgentExecutorsStore, CabinetStore }) => {
    const [inviteExecutorModalOpened, setInviteExecutorModalOpened] = useState(false);
    const [editSubscriptionModalsOpened, setEditSubscriptionModalsOpened] = useState(false);

    const { language } = LanguageStore;
    const { getAllSubscriptions, subscriptions } = CabinetStore;
    const { executors, updateExecutor, updateExecutorSort } = AgentExecutorsStore;

    const subscription = subscriptions.agent ? subscriptions.agent[0] : {};

    // Приглашение исполнителя
    const inviteExecutor = (executor, classification, profession) => {
        updateExecutor({ executorCabinetId: executor.cabinet_id, professionId: profession.id, classification })
            .then(() => {
                getAllSubscriptions();
                setInviteExecutorModalOpened(false);
            });
    };

    // Отлов окончания dnd
    const handleDragEnd = ({ newIndex, oldIndex }) => {
        if (newIndex !== oldIndex) {
            const executorsCopy = Object.clone(executors);
            const executorsCards = [...document.querySelectorAll(`.AgentContractorCard[data-executor-id]`)];
            
            executorsCopy.forEach(executor => {
                const executorsCard = executorsCards.find(card => Number(card.dataset.executorId) === executor.id);
                executor.sort = executorsCards.indexOf(executorsCard);
            });

            updateExecutorSort(executorsCopy);
        }
    };

    // Проверка кол-во пакетов
    const checkPackets = () => {
        if (subscription.userAttachCount >= subscription.maxUser || subscription.status !== 'active') {
            setEditSubscriptionModalsOpened(true);
        } else {
            setInviteExecutorModalOpened(true);
        }
    };

    // Добавление сортировки карточке исполнителей
    useEffect(() => {
        const sortableList = document.querySelector('.AgentContractorsList__items');
        
        const sortable = new Sortable(sortableList, { 
            animation: 150, 
            handle: '.AgentContractorCard__option--drag',
            onEnd: handleDragEnd
        });

        return () => {
            sortable.destroy();
        };
    }, [executors.length]);

    // Открытие модалки по нажатию на ссылку
    useEffect(() => {
        if (window.location.search === '?modal=true' && subscription.id) {
            checkPackets();
        }
    }, [subscription.id]);

    return (
        <div className = 'AgentContractorsList'>
            <div className = 'AgentContractorsList__actions'>
                <Button content = {{ icon: 'plus-in-circle', text: translate('Add executor', language) }} 
                        pure = { true }
                        theme = 'grey'
                        onClick = { checkPackets } />
            </div>

            <div className = 'AgentContractorsList__items' key = { executors.length }>
                { executors.map((executor, index) => (
                    <AgentContractorCard key = { executor.id } contractor = { executor } />
                )) }
            </div>

            { inviteExecutorModalOpened && (
                <InviteExecutorAgentModal modalOpened = { inviteExecutorModalOpened }
                                          setModalOpened = { setInviteExecutorModalOpened }
                                          agentExecutors = { executors }
                                          onInvite = { inviteExecutor } />
            ) }

            { editSubscriptionModalsOpened && (
                <EditSubscriptionModals modalsOpened = { editSubscriptionModalsOpened } 
                                        setModalsOpened = { setEditSubscriptionModalsOpened } 
                                        cabinet = 'agent'
                                        invoice = { { ...prepareSubscription(subscription, 'agent').invoice, priceList: subscription.priceList } } />
            ) }
        </div>
    )
}));

export { AgentContractorsList };