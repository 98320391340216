import React from 'react';

import { ClientGeneral } from 'components/pages';
import { CabinetContent } from 'components/containers';

const Client = () => {
    return (
        <div className = 'Client'>           
            <CabinetContent>
                <ClientGeneral />
            </CabinetContent>
        </div>
    )
};

export { Client };