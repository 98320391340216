// Хранилище с купонами
import { decorate, action, runInAction, observable } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class CouponsStore {
    personalCoupons = []

    // Получение персональных купонов
    async getPersonalCoupons() {
        const response = await request(`${BASE_URL}/api/user/coupons/get`);
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.personalCoupons = data.data);
        }
    }

    // Проверка купона
    async checkCoupon(invoiceId, code) {
        const response = await request(`${BASE_URL}/api/user/coupons/check`, { method: 'POST', body: JSON.stringify({ invoiceId, code }) });
        const data = await response.json();

        if (response.status === 200) {
            return data.data
        } else if (response.status === 422) {
            throw data.errors.code
        }
    }
}

decorate(CouponsStore, {
    personalCoupons: observable,
    checkCoupon: action.bound,
    getPersonalCoupons: action.bound
});

export default new CouponsStore();
