// Подготовка pathname для редактирония и добавления работ

const prepareWorkModePath = (location, route) => {
    const mode = location.pathname.match(/\w+-work/)[0];
    let preparedPath = '';

    if (mode === 'edit-work') {
        const workId = location.pathname.match(/\d+/);

        if (workId) {
            preparedPath = `/works/${mode}${route.path}${route.path === '/' ? workId[0] : `/${workId[0]}`}`;
        }
    } else {
        preparedPath = `/works/${mode}${route.path}`;
    }

    return preparedPath
};

export { prepareWorkModePath };