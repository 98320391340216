import React from 'react';

import { SelectPure, Tooltip, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_WorkTeamItem.scss';

const WorkTeamItem = inject('LanguageStore')(observer(({ item, selectProps, canDelete, handleRemoveClick, handleChooseBoxChange, LanguageStore, ...restProps }) => {
    const { language } = LanguageStore;

    const { canChange } = item;
    
    return (
        <div className = 'WorkTeamItem' { ...restProps }>
            <div className = 'WorkTeamItem__content'>
                <SelectPure disabled = { !canChange } { ...selectProps } />
                
                { (canChange && canDelete) && (
                    <Tooltip text = { translate('Remove', language) } position = 'left'>
                        <div className = 'WorkTeamItem__removeWrap' onClick = { handleRemoveClick }>
                            <Icon name = 'cross-tiny' className = 'WorkTeamItem__remove' />
                        </div>
                    </Tooltip>
                ) }
            </div>
        </div>
    )
}));

export { WorkTeamItem };