// Добавление или переопределение стандартных методов JS

String.prototype.toTitleCase = function () {
    return this.replace(/\w/, this.split('')[0].toUpperCase())
}

Object.clone = function(obj, ...props) {
    if (typeof obj === 'object' && obj !== null) {
        const clonedObj = Array.isArray(obj) ? []: {};
        const clonedProps = props.length !== 0 ? Object.clone(...props) : null;
        const keys = Object.keys(obj);

        for (let key of keys) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                clonedObj[key] = Object.assign(Array.isArray(obj[key]) ? []: {}, obj[key]);
            } else {
                clonedObj[key] = obj[key];
            }
        }

        return clonedProps ? Array.isArray(obj) ? [ ...clonedObj, ...clonedProps ] :  { ...clonedObj, ...clonedProps } : clonedObj
    }

    throw new TypeError(`This is not object: ${obj}`);
}