import React, { useState } from 'react';

import { RemoveSureModal, CabinetCard } from 'components/containers';
import { Button, ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_BookmarksCompaniesItem.scss';

const BookmarksCompaniesItem = inject('LanguageStore', 'BookmarksStore')(observer(({ cabinet, LanguageStore, BookmarksStore }) => {
    const [removeModalOpened, setRemoveModalOpened] = useState(false);

    const { language } = LanguageStore;
    const { removeExecutor } = BookmarksStore;

    const removeExecutorBinded = removeExecutor.bind(null, cabinet.id, 'studio');

    // Отлов нажатия по кнопке удалить
    function openRemoveModal() {
        setRemoveModalOpened(true);
    }

    return (
        <div className = 'BookmarksCompaniesItem'>
            <CabinetCard cabinet = { cabinet } />

            <div className = 'BookmarksCompaniesItem__options'>
                <Button content = {{ text: translate('Remove', language) }} 
                        size = 'sm'
                        onClick = { openRemoveModal } />

                <ChooseBox text = { translate('Subscription to works', language) } disabled = { true } />
            </div>

            { removeModalOpened && (
                <RemoveSureModal title = { translate('Do you really want to delete the company from bookmarks?', language) }
                                 modalOpened = { removeModalOpened }
                                 setModalOpened = { setRemoveModalOpened }
                                 onRemoveClick = { removeExecutorBinded } />
            ) }
        </div>
    )
}));

export { BookmarksCompaniesItem };