import React, { useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamMovieSerialSoundMusiс = inject('LanguageStore', 'WorksStore', 'CabinetStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { currentWork } = WorksStore;
    const { searchInCabinet } = CabinetStore;

    const { sound2_sound_director, sound2_music, sound2_music_producer } = currentWork.team;
    const { sound2_music_editor, sound2_music_designer, sound2_noise } = currentWork.team;

    const { prepareGroup } = itemProps;

    const values = {
        'sound2_sound_director': sound2_sound_director.map(item => item.value),
        'sound2_music': sound2_music.map(item => item.value),
        'sound2_music_producer': sound2_music_producer.map(item => item.value),
        'sound2_music_editor': sound2_music_editor.map(item => item.value),
        'sound2_music_designer': sound2_music_designer.map(item => item.value),
        'sound2_noise': sound2_noise.map(item => item.value)
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;

            const studioObj = { name: checkedValue, name_en: checkedValue };
            const executorObj = { name: checkedValue, name_en: checkedValue, surname: checkedValue, surname_en: checkedValue };

            switch(name) {
                case 'sound2_sound_director':
                case 'sound2_music_producer':
                case 'sound2_music_editor':
                case 'sound2_music_designer':
                case 'sound2_noise': {
                    searchInCabinet('executor', 'personalData', 'profile', key, executorObj, result => {
                        setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
                    });

                    break;
                }

                case 'sound2_music': {
                    searchInCabinet('studio', 'personalData', 'profile', key, studioObj, result => {
                        setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
                    });

                    break;
                }
            }
        }, 350)    
    , []);

    return (
        <div className = { WorkTeamSectionClass }>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'soundMusic') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Sound and music', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(sound2_sound_director, 'sound2_sound_director', 'PersonName', findSelectItems, values) }
                            { prepareGroup(sound2_music, 'sound2_music', 'Name', findSelectItems, values) }
                            { prepareGroup(sound2_music_producer, 'sound2_music_producer', 'PersonName', findSelectItems, values) }
                            { prepareGroup(sound2_music_editor, 'sound2_music_editor', 'PersonName', findSelectItems, values) }
                            { prepareGroup(sound2_music_designer, 'sound2_music_designer', 'PersonName', findSelectItems, values) }
                            { prepareGroup(sound2_noise, 'sound2_noise', 'PersonName', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamMovieSerialSoundMusiс };