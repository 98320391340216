import React from 'react';

import classNames from 'classnames';

import './_CabinetPreloader.scss';

const CabinetPreloader = ({ loading }) => {
    const CabinetPreloaderClass = classNames('CabinetPreloader', {
        'CabinetPreloader--hide': !loading
    });

    return (
        <div className = { CabinetPreloaderClass }>
            <svg className = 'CabinetPreloader__spinner' width = '40' height = '40'>
                <circle className = 'CabinetPreloader__spinnerCircle' strokeWidth = '3' strokeLinecap = 'round' cx = '20' cy = '20' r = '17'></circle>
            </svg>
        </div>
    )
};

export { CabinetPreloader };