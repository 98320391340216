import React, { useState, useEffect, useCallback } from 'react';

import { CabinetSection, NewCabinetEntityModal } from 'components/containers';
import { Button, ChooseIcon, Icon, Input, SelectPure, ChooseBox, Tooltip } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate, debounce } from 'helpers';

import './_AgentEducation.scss';

const AgentEducation = inject('LanguageStore', 'CabinetStore', 'UniversityStore')(observer(({ LanguageStore, CabinetStore, UniversityStore }) => {
    const [selectInputs, setSelectInputs] = useState({ 'university-name': {}, 'year-of-graduation': {} });
    const [openedEntityModal, setOpenedEntityModal] = useState(false);

    const { language } = LanguageStore;
    const { cabinet, updateCabinetSection, updateCabinetSectionLocal, deleteFromCabinetSection } = CabinetStore;
    const { universities, findUniversity } = UniversityStore;

    const educations = cabinet.personalData ? cabinet.personalData.educations : null;

    const universityActionButton = {
        text: translate('Add an new educational institution', language),
        restProps: {
            onClick: setOpenedEntityModal.bind(null, true)
        }
    };

    const findUniversityName = useCallback(
        debounce(value => findUniversity(value === '' ? '*' : value), 350)
    , []);

    const changeSpecialityDebounced = useCallback(
        debounce((id, value) => updateCabinetSection('educations', { id, speciality: value }), 350)
    , []);

    // Отлов фокуса на поле ввода(имя учебного заведения)
    const handleInputUniversityFocus = () => findUniversity('*');

    // Отлов изменения на поле выбора
    const showEducation = event => {
        const chooseIcon = event.target;
        const educationIndex = JSON.parse(chooseIcon.closest('.AgentEducation__selects').dataset.educationIndex);
        const checked = chooseIcon.checked;
        const education = educations[educationIndex];

        updateCabinetSection('educations', { id: education.id, show: checked });
    };

    // Добавление образования
    const addEducation = () => {
        updateCabinetSectionLocal('educations', { data: { id: educations[educations.length - 1].id + 1, show: false, fake: true } });
    };

    // Удаление образования
    const deleteEducation = event => {
        const removeWrap = event.currentTarget;
        const educationIndex = JSON.parse(removeWrap.closest('.AgentEducation__selects').dataset.educationIndex);
        const education = educations[educationIndex];
        
        setSelectInputs(prevSelectInputs => {
            return {
                'year-of-graduation': {
                    ...prevSelectInputs['year-of-graduation'],
                    [education.id]: undefined
                },

                'university-name': {
                    ...prevSelectInputs['university-name'],
                    [education.id]: undefined
                }
            }
        });

        deleteFromCabinetSection('educations', education.id, educationIndex)
            .then(() => {
                if (educations.length === 0) {
                    updateCabinetSectionLocal('educations', { data: { id: 0, show: false, fake: true } });
                }
            });
    };

    // Добавление элемента в образование
    const addItemToEducation = event => {
        const selectDropdownItem = event.currentTarget;
        const data = JSON.parse(selectDropdownItem.dataset.data);
        const selectPure = selectDropdownItem.closest('.SelectPure')
        const executorSelects = selectDropdownItem.closest('.AgentEducation__selects');
        const educationIndex = JSON.parse(executorSelects.dataset.educationIndex);
        const education = educations[educationIndex];
        const isFake = JSON.parse(executorSelects.dataset.fake);
        const type = selectPure.dataset.type;

        if (!isFake) {
            setSelectInputs(prevSelectInputs => {
                return {
                    ...prevSelectInputs,
                    [type]: {
                        ...prevSelectInputs[type],
                        [education.id]: type === 'university-name' ? `${data[`abbreviation-${language}`] ? `${data[`abbreviation-${language}`]} - ` : ''}${data[`name-${language}`]}` : `${data.year}`
                    }
                }
            });
        }

        if (isFake) {
            updateCabinetSection('educations', {
                universityId: data.id,
                show: education.show,
                educationEnd: data.year
            }).then(({ educations }) => {
                setSelectInputs(prevSelectInputs => {
                    return {
                        ...prevSelectInputs,
                        [type]: {
                            ...prevSelectInputs[type],
                            [educations[educationIndex].id]: type === 'university-name' ? `${data[`abbreviation-${language}`] ? `${data[`abbreviation-${language}`]} - ` : ''}${data[`name-${language}`]}` : `${data.year}`
                        }
                    }
                });
            });
        } else {
            if (type === 'year-of-graduation') {
                updateCabinetSection('educations', { id: education.id, educationEnd: `${data.year}` });
            } else {
                updateCabinetSection('educations', { id: education.id, universityId: data.id });   
            }
        }
    };

    // Отлов ввода в поле ввода
    const handleInputChange = event => { 
        const input = event.target;
        let value = input.value;
        const educationIndex = JSON.parse(input.closest('.AgentEducation__selects').dataset.educationIndex);
        const education = educations[educationIndex];
        const group = input.dataset.group;

        if (group === 'year-of-graduation') {
            value = value.match(/\d{0,4}/)[0];
        }
       
        setSelectInputs(prevSelectInputs => {
            return {
                ...prevSelectInputs,
                [group]: {
                    ...prevSelectInputs[group],
                    [education.id]: value
                }
            }
        });

        if (group === 'university-name') {
            findUniversityName(value.trim());
        }
    };

    // Отлов блюра в поле ввода
    const handleInputBlur = educationId => {
        const educationEnd = educations[educations.indexOf(educations.find(education => education.id === educationId))].educationEnd;
        const selectYear = selectInputs['year-of-graduation'][educationId];
    
        setSelectInputs(prevSelectInputs => {
            return {
                ...prevSelectInputs,
                'year-of-graduation': {
                    ...prevSelectInputs['year-of-graduation'],
                    [educationId]: selectYear && selectYear.length === 4 ? selectYear : educationEnd || undefined
                }
            }
        });
    };

    // Включение кросс-ссылки
    const enableCrossLink = event => {
        const checkbox = event.target;
        const value = checkbox.checked;

        const executorSelects = checkbox.closest('.AgentEducation__selects');
        const educationIndex = JSON.parse(executorSelects.dataset.educationIndex);
        const education = educations[educationIndex];

        updateCabinetSection('educations', { id: education.id, showCrossLink: value });
    };

    // Изменение специальности
    const changeSpeciality = event => {
        const input = event.target;
        const value = input.value;

        const executorSelects = input.closest('.AgentEducation__selects');
        const educationIndex = JSON.parse(executorSelects.dataset.educationIndex);
        const education = educations[educationIndex];
        
        changeSpecialityDebounced(education.id, value);
    };

    // Добавление нового университета
    const addNewUniversity = university => {
        const newUniversity = {
            universityId: university.id,
            show: false
        };

        updateCabinetSection('educations', newUniversity);
        setOpenedEntityModal(false);
    };

    // Создание образования, если его нету у пользователя
    useEffect(() => {
        if (educations && educations.length === 0) {
            updateCabinetSectionLocal('educations', { data: { id: 0, show: false, fake: true } });
        }
    }, [educations]);

    return educations && (
        <CabinetSection id = 'education' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Education', language) }</span>
            </div>
            
            <div className = 'CabinetSection__content'>
                <div className = 'CabinetSection__container'>
                    <div className = 'AgentEducation'>
                        { educations.map((education, index) => {
                            const preparedUniversities = universities && [{
                                id: 0,
                                items: universities.map(university => ({
                                    id: university.id,
                                    text: `${university[`abbreviation-${language}`] ? `${university[`abbreviation-${language}`]} - ` : ''}${university[`name-${language}`]}`,
                                    selected: educations.find(education => education.universityId === university.id),
                                    data: university
                                }))
                            }];

                            const preparedYears = [{
                                id: 0,
                                items: (new Array(50).fill(null)).map((year, index) => {
                                    const currentYear = new Date().getFullYear();

                                    return {
                                        id: index,
                                        text: currentYear - index,
                                        data: { year: currentYear - index }
                                    }
                                })
                            }];
                     
                            const educationIndex = index;
                            const universityName = [education[`abbreviation-${language}`], education[`name-${language}`]].filter(item => item).join(' - ');
                            
                            preparedYears && ((preparedYears[0].items.find(year => year.text === +education.educationEnd) || {}).selected = true);

                            if (selectInputs['year-of-graduation'][education.id] !== undefined) {
                                preparedYears[0].items = preparedYears[0].items.filter(item => {
                                    return Number(item.text) <= Number(selectInputs['year-of-graduation'][education.id].trim());
                                });
                            }
                            
                            return (
                                <div className = 'AgentEducation__selects' key = { education.id } data-education-index = { educationIndex } data-fake = { education.fake || 'false' }>
                                    <SelectPure inputProps = {{
                                                    'data-group': 'university-name',
                                                    label: translate('Abbreviation of educational institution', language),
                                                    value: selectInputs['university-name'][education.id] !== undefined ? selectInputs['university-name'][education.id] : universityName
                                                }}
                                                items = { preparedUniversities }
                                                actionButton = { universityActionButton }
                                                onInputChange = { handleInputChange }
                                                onInputFocus = { handleInputUniversityFocus }
                                                onDropdownItemClick = { addItemToEducation }
                                                data-type = 'university-name' />

                                    <SelectPure inputProps = {{
                                                    'data-group': 'year-of-graduation',
                                                    label: translate('Year of graduation', language), 
                                                    value: selectInputs['year-of-graduation'][education.id] !== undefined ? selectInputs['year-of-graduation'][education.id] : education.educationEnd
                                                }}
                                                items = { preparedYears }
                                                onInputChange = { handleInputChange }
                                                onInputBlur = { handleInputBlur.bind(null, education.id) }
                                                onDropdownItemClick = { addItemToEducation }
                                                disabled = { !education.universityId }
                                                data-type = 'year-of-graduation' />

                                    <Input label = { translate('Major', language) }
                                           className = 'AgentEducation__speciality'
                                           disabled = { !education.universityId }
                                           defaultValue = { education.speciality }
                                           onInput = { changeSpeciality } />

                                    <ChooseIcon text = { translate('Show in profile', language) } 
                                                changeText = { translate('Don`t show in profile', language) }
                                                className = 'AgentEducation__show'
                                                tooltipDirection = 'right'
                                                disabled = { !education.universityId }
                                                checked = { education.show }
                                                onChange = { showEducation } />
                                    
                                    <div className = 'AgentEducation__crossLink'>
                                        <ChooseBox type = 'checkbox' 
                                                   text = { translate('Enable cross-reference', language) }
                                                   checked = { education.showCrossLink }
                                                   onChange = { enableCrossLink }
                                                   disabled = { !education.universityId || !education.linkProfile }>
                                            <Tooltip wrapClassName = 'AgentEducation__crossLinkTooltipWrap' text = { translate('You can include a link to go to your film school profile', language) } direction = 'down' position = 'right'>
                                                <Icon name = 'question' className = 'ChooseBox__icon ChooseBox__icon--question' />
                                            </Tooltip>
                                        </ChooseBox>
                                    </div>

                                    { !education.fake && (
                                        <Tooltip wrapClassName = 'AgentEducation__removeTooltipWrap' text = { translate('Remove', language) } direction = 'down'>
                                            <div className = 'AgentEducation__removeWrap' onClick = { deleteEducation }>
                                                <Icon name = 'cross-tiny' className = 'AgentEducation__remove' />
                                            </div>
                                        </Tooltip>
                                    ) }
                                </div>
                            )
                        }) }
                    </div>
                </div>
            </div>
           
            <div className = 'CabinetSection__actions'>
                <Button content = {{ icon: 'plus-in-circle', text: translate('Add', language) }} 
                        theme = 'grey'
                        disabled = { (educations[educations.length - 1] || {}).fake }
                        pure = { true } 
                        onClick = { addEducation } />
            </div>

            { openedEntityModal && (
                <NewCabinetEntityModal type = 'university'
                                       modalOpened = { openedEntityModal }
                                       setModalOpened = { setOpenedEntityModal }
                                       onAdd = { addNewUniversity } />
            ) }
        </CabinetSection>
    )
}));

export { AgentEducation };