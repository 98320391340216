import React, { memo } from 'react';

import classNames from 'classnames';

import './_MainPreloader.scss';

const MainPreloader = memo(({ loading }) => {
    const MainPreloaderClass = classNames('MainPreloader', {
        'MainPreloader--hide': !loading
    });

    return (
        <div className = { MainPreloaderClass }>
            <svg className = 'MainPreloader__spinner' width = '40' height = '40'>
                <circle className = 'MainPreloader__spinnerCircle' strokeWidth = '3' strokeLinecap = 'round' cx = '20' cy = '20' r = '17'></circle>
            </svg>
        </div>
    )
});

export { MainPreloader };