// Хранилище гильдий и профсоюзов

import { observable, decorate, action, runInAction } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class GuildsStore {
    guilds = []

    // Получение гильдии
    async findGuild(name) {
        const response = await request(`${BASE_URL}/api/guild`, { method: 'POST', body: JSON.stringify({ find: name }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.guilds = data.result);
        }
    }
}

decorate(GuildsStore, {
    guilds: observable,
    findGuild: action.bound
});

export default new GuildsStore();