import React from 'react';

import classNames from 'classnames';

import './_CabinetAlerts.scss';

const CabinetAlerts = ({ className, children }) => {
    const CabinetAlertsClass = classNames('CabinetAlerts', className);

    return (
        <div className = { CabinetAlertsClass }>
            { children }
        </div>
    )
};

export { CabinetAlerts };
