import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Icon } from 'components/ui';

import classNames from 'classnames';

import './_Modal.scss';

const Modal = ({ title, titleAlign, opened, setOpened, children }) => {
    const ModalClass = classNames('Modal', {
        'Modal--title-align-center': titleAlign === 'center'
    });

    // Отлови клика по обертке модального окна
    const handleModalWrapClick = event => {
        const target = event.target;
        
        if (target.classList.contains('Modal__wrap')) {
            setOpened(false);
        }
    };

    // Отлови клика по крестику
    const handleModalCloseClick = event => {
        const target = event.target;
        
        if (target.closest('.Modal__closeWrap')) {
            setOpened(false);
        }
    };

    // Блокировка прокрутки страницы
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = null;
        };
    }, [opened]);

    return opened && (
        ReactDOM.createPortal((
            <div className = 'Modal__wrap' onMouseDown = { handleModalWrapClick }>
                <div className = { ModalClass }>
                    <div className = 'Modal__title'>{ title }</div>

                    <div className = 'Modal__content'>
                        { children }
                    </div>

                    <div className = 'Modal__closeWrap' onMouseDown = { handleModalCloseClick }>
                        <Icon name = 'cross' className = 'Modal__close' />
                    </div>
                </div>
            </div>
        ), document.querySelector('#Modals'))
    )
};

export { Modal };