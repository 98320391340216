import React from 'react';

import { CabinetSection } from 'components/containers';
import { ChooseBox, Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_ManagementSettings.scss';

const ManagementSettings = inject('LanguageStore', 'UserStore')(observer(({ UserStore, LanguageStore }) => {
    const { user, updateUser } = UserStore;
    const { language } = LanguageStore;

    const { emailSubscribeNews, emailSubscribeStatistic, emailSubscribeNotification, privacyHiddenInSearch } = user;

    // Изменение настроек пользователя
    const changeUserSettings = event => {
        event.preventDefault();

        const form = event.target;
        
        const { emailSubscribeNews, emailSubscribeStatistic } = form.elements;
        const { emailSubscribeNotification, privacyHiddenInSearch } = form.elements;

        updateUser({
            emailSubscribeNews: emailSubscribeNews.checked,
            emailSubscribeStatistic: emailSubscribeStatistic.checked,
            emailSubscribeNotification: emailSubscribeNotification.checked,
            privacyHiddenInSearch: privacyHiddenInSearch.checked
        });
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>
                    { translate('Account settings', language) }
                </span>
            </div>

            <div className = 'CabinteSection__content'>
                <form className = 'ManagementSettings__form' onSubmit = { changeUserSettings }>
                    <div className = 'ManagementSettings'>
                        <div className = 'ManagementSettings__section'>
                            <span className = 'ManagementSettings__sectionTitle'>{ translate('Email-subscription', language) }</span>

                            <div className = 'ManagementSettings__sectionContent'>
                                <ChooseBox type = 'checkbox' 
                                           size = 'lg' 
                                           fluid = { true } 
                                           text = { translate('Portal news', language) } 
                                           name = 'emailSubscribeNews' 
                                           defaultChecked = { emailSubscribeNews } />

                                <ChooseBox type = 'checkbox' 
                                           size = 'lg' 
                                           fluid = { true }
                                           text = { translate('Monthly statistics', language) } 
                                           name = 'emailSubscribeStatistic' 
                                           defaultChecked = { emailSubscribeStatistic } />

                                <ChooseBox type = 'checkbox' 
                                           size = 'lg' 
                                           fluid = { true } 
                                           text = { translate('Notifications', language) } 
                                           name = 'emailSubscribeNotification' 
                                           defaultChecked = { emailSubscribeNotification } />
                            </div>
                        </div>

                        <div className = 'ManagementSettings__section'>
                            <span className = 'ManagementSettings__sectionTitle'>
                                { translate('Privacy', language) }
                            </span>

                            <div className = 'ManagementSettings__sectionContent'>
                                <ChooseBox type = 'checkbox' 
                                           size = 'lg' 
                                           fluid = { true } 
                                           text = { translate('Hide me in the search', language) } 
                                           name = 'privacyHiddenInSearch' 
                                           defaultChecked = { privacyHiddenInSearch } />
                            </div>
                        </div>
                    </div>

                    <div className = 'CabinetSection__actions'>
                        <Button content = {{ text: translate('Save', language) }} />
                    </div>
                </form>
            </div>
        </CabinetSection>
    )
}));

export { ManagementSettings };