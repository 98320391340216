import React, { useState } from 'react';

import { CabinetNav } from 'components/containers';

const StudioNav = () => {
    const [navItems, setNavItems] = useState({
        general: { text: 'Основное', translationKey: 'General', active: false },
        contacts: { text: 'Контакты', translationKey: 'Contacts', active: false },
        locations: { text: 'Локации', translationKey: 'Locations', active: false },
        extraInfo: { text: 'Дополнительная информация', translationKey: 'Extra info', active: false },
        socialNetworks: { text: 'Соцсети', translationKey: 'Social networks', active: false },
        filmography: { text: 'Фильмография', translationKey: 'Filmography', active: false }
    });

    return (
        <div className = 'StudioNav CabinetNav__wrap'>
            <CabinetNav items = { navItems } updateItems = { setNavItems } />
        </div>
    )
};

export { StudioNav };