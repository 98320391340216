export const PHONE_CODES = [
    { id: 0, country: 'Австралия', country_en: 'Australia', code: '+61' },
    { id: 1, country: 'Австрия', country_en: 'Austria', code: '+43' },
    { id: 2, country: 'Азербайджан', country_en: 'Azerbaijan', code: '+994' },
    { id: 3, country: 'Албания', country_en: 'Albania', code: '+355' },
    { id: 4, country: 'Алжир', country_en: 'Algeria', code: '+213' },
    { id: 5, country: 'Ангола', country_en: 'Angola', code: '+244' },
    { id: 6, country: 'Андорра', country_en: 'Andorra', code: '+376' },
    { id: 7, country: 'Антигуа и Барбуда', country_en: 'Antigua and Barbuda', code: '+1268' },
    { id: 8, country: 'Армения', country_en: 'Armenia', code: '+374' },
    { id: 9, country: 'Афганистан', country_en: 'Afghanistan', code: '+93' },

    { id: 10, country: 'Багамы', country_en: 'Bahamas', code: '+1242' },
    { id: 11, country: 'Бангладеш', country_en: 'Bangladesh', code: '+880' },
    { id: 12, country: 'Барбадос', country_en: 'Barbados', code: '+1246' },
    { id: 13, country: 'Бахрейн', country_en: 'Bahrain', code: '+973' },
    { id: 14, country: 'Беларусь', country_en: 'Belarus', code: '+375' },
    { id: 15, country: 'Белиз', country_en: 'Belize', code: '+501' },
    { id: 16, country: 'Бельгия', country_en: 'Belgium', code: '+32' },
    { id: 17, country: 'Бенин', country_en: 'Benin', code: '+229' },
    { id: 18, country: 'Болгария', country_en: 'Bulgaria', code: '+359' },
    { id: 19, country: 'Боливия', country_en: 'Bolivia', code: '+591' },

    { id: 20, country: 'Босния и Герцеговина', country_en: 'Bosnia and Herzegovina', code: '+387' },
    { id: 21, country: 'Ботсвана', country_en: 'Botswana', code: '+267' },
    { id: 22, country: 'Бразилия', country_en: 'Brazil', code: '+55' },
    { id: 23, country: 'Бруней', country_en: 'Brunei', code: '+673' },
    { id: 24, country: 'Буркина Фасо', country_en: 'Burkina Faso', code: '+226' },
    { id: 25, country: 'Бурунди', country_en: 'Burundi', code: '+257' },
    { id: 26, country: 'Бутан', country_en: 'Bhutan', code: '+975' },
    { id: 27, country: 'Вануату', country_en: 'Vanuatu', code: '+678' },
    { id: 28, country: 'Ватикан', country_en: 'Vatican', code: '+39' },
    { id: 29, country: 'Великобритания', country_en: 'Great Britain', code: '+44' },

    { id: 30, country: 'Венгрия', country_en: 'Hungary', code: '+36' },
    { id: 31, country: 'Венесуэла', country_en: 'Venezuela', code: '+58' },
    { id: 32, country: 'Восточный Тимор', country_en: 'East Timor', code: '+670' },
    { id: 33, country: 'Вьетнам', country_en: 'Vietnam', code: '+84' },
    { id: 34, country: 'Габон', country_en: 'Gabon', code: '+241' },
    { id: 35, country: 'Гаити', country_en: 'Haiti', code: '+509' },
    { id: 36, country: 'Гайана', country_en: 'Guyana', code: '+592' },
    { id: 37, country: 'Гамбия', country_en: 'Gambia', code: '+220' },
    { id: 38, country: 'Гана', country_en: 'Ghana', code: '+233' },
    { id: 39, country: 'Гватемала', country_en: 'Guatemala', code: '+502' },

    { id: 40, country: 'Гвинея', country_en: 'Guinea', code: '+224' },
    { id: 41, country: 'Гвинея-Бисау', country_en: 'Guinea Bissau', code: '+245' },
    { id: 42, country: 'Германия', country_en: 'Germany', code: '+49' },
    { id: 43, country: 'Гондурас', country_en: 'Honduras', code: '+504' },
    { id: 44, country: 'Гренада', country_en: 'Grenada', code: '+1473' },
    { id: 45, country: 'Греция', country_en: 'Greece', code: '+30' },
    { id: 46, country: 'Грузия', country_en: 'Georgia', code: '+995' },
    { id: 47, country: 'Дания', country_en: 'Denmark', code: '+45' },
    { id: 48, country: 'Джибути', country_en: 'Djibouti', code: '+253' },
    { id: 49, country: 'Доминика', country_en: 'Dominica', code: '+1767' },
    
    { id: 50, country: 'Доминиканская Республика', country_en: 'Dominican Republic', code: '+1809' },
    { id: 51, country: 'Египет', country_en: 'Egypt', code: '+20' },
    { id: 52, country: 'Замбия', country_en: 'Zambia', code: '+260' },
    { id: 53, country: 'Зимбабве', country_en: 'Zimbabwe', code: '+263' },
    { id: 54, country: 'Израиль', country_en: 'Israel', code: '+972' },
    { id: 55, country: 'Индия', country_en: 'India', code: '+91' },
    { id: 56, country: 'Индонезия', country_en: 'Indonesia', code: '+62' },
    { id: 57, country: 'Иордания', country_en: 'Jordan', code: '+962' },
    { id: 58, country: 'Ирак', country_en: 'Iraq', code: '+964' },
    { id: 59, country: 'Иран', country_en: 'Iran', code: '+98' },
    
    { id: 60, country: 'Ирландия', country_en: 'Irish', code: '+353' },
    { id: 61, country: 'Исландия', country_en: 'Iceland', code: '+354' },
    { id: 62, country: 'Испания', country_en: 'Spain', code: '+34' },
    { id: 63, country: 'Италия', country_en: 'Italy', code: '+39' },
    { id: 64, country: 'Йемен', country_en: 'Yemen', code: '+967' },
    { id: 65, country: 'Кабо-Верде', country_en: 'Cape Verde', code: '+238' },
    { id: 66, country: 'Казахстан', country_en: 'Kazakhstan', code: '+77' },
    { id: 67, country: 'Камбоджа', country_en: 'Cambodia', code: '+855' },
    { id: 68, country: 'Камерун', country_en: 'Cameroon', code: '+237' },
    { id: 69, country: 'Канада', country_en: 'Canada', code: '+1' },

    { id: 70, country: 'Катар', country_en: 'Qatar', code: '+974' },
    { id: 71, country: 'Кения', country_en: 'Kenya', code: '+254' },
    { id: 72, country: 'Кипр', country_en: 'Cyprus', code: '+357' },
    { id: 73, country: 'Киргизия', country_en: 'Kyrgyzstan', code: '+996' },
    { id: 74, country: 'Кирибати', country_en: 'Kiribati', code: '+686' },
    { id: 75, country: 'Китай', country_en: 'China', code: '+86' },
    { id: 76, country: 'Колумбия', country_en: 'Colombia', code: '+57' },
    { id: 77, country: 'Коморы', country_en: 'Comoros', code: '+269' },
    { id: 78, country: 'Конго, демократическая республика', country_en: 'Congo, democratic Republic', code: '+243' },
    { id: 79, country: 'Конго, республика', country_en: 'Congo, Republic of', code: '+242' },
    
    { id: 80, country: 'Коста Рика', country_en: 'Costa Rica', code: '+506' },
    { id: 82, country: 'Кот-д’Ивуар', country_en: 'Ivory coast', code: '+225' },
    { id: 83, country: 'Куба', country_en: 'Cuba', code: '+53' },
    { id: 84, country: 'Кувейт', country_en: 'Kuwait', code: '+965' },
    { id: 85, country: 'Лаос', country_en: 'Laos', code: '+856' },
    { id: 86, country: 'Латвия', country_en: 'Latvian', code: '+371' },
    { id: 87, country: 'Лесото', country_en: 'Lesotho', code: '+266' },
    { id: 88, country: 'Либерия', country_en: 'Liberia', code: '+231' },
    { id: 89, country: 'Ливан', country_en: 'Lebanon', code: '+961' },

    { id: 90, country: 'Ливия', country_en: 'Libya', code: '+218' },
    { id: 91, country: 'Литва', country_en: 'Lithuania', code: '+370' },
    { id: 92, country: 'Лихтенштейн', country_en: 'Liechtenstein', code: '+423' },
    { id: 93, country: 'Люксембург', country_en: 'Luxembourg', code: '+352' },
    { id: 94, country: 'Маврикий', country_en: 'Mauritius', code: '+230' },
    { id: 95, country: 'Мавритания', country_en: 'Mauritania', code: '+222' },
    { id: 96, country: 'Мадагаскар', country_en: 'Madagascar', code: '+261' },
    { id: 97, country: 'Македония', country_en: 'Macedonia', code: '+389' },
    { id: 98, country: 'Малави', country_en: 'Malawi', code: '+265' },
    { id: 99, country: 'Малайзия', country_en: 'Malaysia', code: '+60' },

    { id: 100, country: 'Мали', country_en: 'Mali', code: '+223' },
    { id: 101, country: 'Мальдивы', country_en: 'Maldives', code: '+960' },
    { id: 102, country: 'Мальта', country_en: 'Malta', code: '+356' },
    { id: 103, country: 'Марокко', country_en: 'Morocco', code: '+212' },
    { id: 104, country: 'Маршалловы Острова', country_en: 'Marshall islands', code: '+692' },
    { id: 105, country: 'Мексика', country_en: 'Mexico', code: '+52' },
    { id: 106, country: 'Мозамбик', country_en: 'Mozambique', code: '+259' },
    { id: 107, country: 'Молдавия', country_en: 'Moldavia', code: '+373' },
    { id: 108, country: 'Монако', country_en: 'Monaco', code: '+377' },
    { id: 109, country: 'Монголия', country_en: 'Mongolia', code: '+976' },

    { id: 110, country: 'Мьянма', country_en: 'Myanmar', code: '+95' },
    { id: 111, country: 'Намибия', country_en: 'Namibia', code: '+264' },
    { id: 112, country: 'Науру', country_en: 'Nauru', code: '+674' },
    { id: 113, country: 'Непал', country_en: 'Nepal', code: '+977' },
    { id: 114, country: 'Нигер', country_en: 'Niger', code: '+227' },
    { id: 115, country: 'Нигерия', country_en: 'Nigeria', code: '+234' },
    { id: 116, country: 'Нидерланды', country_en: 'Netherlands', code: '+31' },
    { id: 117, country: 'Никарагуа', country_en: 'Nicaragua', code: '+505' },
    { id: 118, country: 'Новая Зеландия', country_en: 'new Zealand', code: '+64' },
    { id: 119, country: 'Объединенные Арабские Эмираты', country_en: 'United Arab Emirates', code: '+971' },

    { id: 120, country: 'Оман', country_en: 'Oman', code: '+968' },
    { id: 121, country: 'Пакистан', country_en: 'Pakistan', code: '+92' },
    { id: 122, country: 'Палау', country_en: 'Palau', code: '+680' },
    { id: 123, country: 'Панама', country_en: 'Panama', code: '+507' },
    { id: 124, country: 'Папуа - Новая Гвинея', country_en: 'Papua New Guinea', code: '+675' },
    { id: 125, country: 'Парагвай', country_en: 'Paraguay', code: '+595' },
    { id: 126, country: 'Перу', country_en: 'Peru', code: '+51' },
    { id: 127, country: 'Польша', country_en: 'Poland', code: '+48' },
    { id: 128, country: 'Португалия', country_en: 'Portugal', code: '+351' },
    { id: 129, country: 'Россия', country_en: 'Russia', code: '+7' },

    { id: 130, country: 'Руанда', country_en: 'Rwanda', code: '+250' },
    { id: 131, country: 'Румыния', country_en: 'Romania', code: '+40' },
    { id: 132, country: 'Сальвадор', country_en: 'Salvador', code: '+503' },
    { id: 133, country: 'Самоа', country_en: 'Samoa', code: '+685' },
    { id: 134, country: 'Сан-Марино', country_en: 'San Marino', code: '+378' },
    { id: 135, country: 'Сан-Томе и Принсипи', country_en: 'Sao Tome and Principe', code: '+239' },
    { id: 136, country: 'Саудовская Аравия', country_en: 'Saudi Arabia', code: '+966' },
    { id: 137, country: 'Свазиленд', country_en: 'Swaziland', code: '+268' },
    { id: 138, country: 'Северная Корея', country_en: 'North Korea', code: '+850' },
    { id: 139, country: 'Сейшелы', country_en: 'Seychelles', code: '+248' },

    { id: 140, country: 'Сенегал', country_en: 'Senegal', code: '+221' },
    { id: 141, country: 'Сент-Винсент и Гренадины', country_en: 'Saint Vincent and the Grenadines', code: '+1784' },
    { id: 142, country: 'Сент-Китс и Невис', country_en: 'Saint Kitts and Nevis', code: '+1869' },
    { id: 143, country: 'Сент-Люсия', country_en: 'Saint Lucia', code: '+1758' },
    { id: 144, country: 'Сербия', country_en: 'Serbia', code: '+381' },
    { id: 145, country: 'Сингапур', country_en: 'Singapore', code: '+65' },
    { id: 146, country: 'Сирия', country_en: 'Syria', code: '+963' },
    { id: 147, country: 'Словакия', country_en: 'Slovakia', code: '+421' },
    { id: 148, country: 'Словения', country_en: 'Slovenia', code: '+986' },
    { id: 149, country: 'США', country_en: 'USA', code: '+1' },

    { id: 150, country: 'Соломоновы Острова', country_en: 'Solomon islands', code: '+677' },
    { id: 151, country: 'Сомали', country_en: 'Somalia', code: '+252' },
    { id: 152, country: 'Судан', country_en: 'Sudan', code: '+249' },
    { id: 153, country: 'Суринам', country_en: 'Suriname', code: '+597' },
    { id: 154, country: 'Сьерра-Леоне', country_en: 'Sierra Leone', code: '+232' },
    { id: 155, country: 'Таджикистан', country_en: 'Tajikistan', code: '+992' },
    { id: 156, country: 'Таиланд', country_en: 'Thailand', code: '+66' },
    { id: 157, country: 'Танзания', country_en: 'Tanzania', code: '+255' },
    { id: 158, country: 'Того', country_en: 'Togo', code: '+228' },
    { id: 159, country: 'Тонга', country_en: 'Tonga', code: '+676' },

    { id: 160, country: 'Тринидад и Тобаго', country_en: 'Trinidad and Tobago', code: '+1868' },
    { id: 161, country: 'Тувалу', country_en: 'Tuvalu', code: '+688' },
    { id: 162, country: 'Тунис', country_en: 'Tunisia', code: '+216' },
    { id: 163, country: 'Туркмения', country_en: 'Turkmenia', code: '+993' },
    { id: 164, country: 'Турция', country_en: 'Turkey', code: '+90' },
    { id: 165, country: 'Уганда', country_en: 'Uganda', code: '+256' },
    { id: 166, country: 'Узбекистан', country_en: 'Uzbekistan', code: '+998' },
    { id: 167, country: 'Украина', country_en: 'Ukraine', code: '+380' },
    { id: 168, country: 'Уругвай', country_en: 'Uruguay', code: '+598' },
    { id: 169, country: 'Федеративные штаты Микронезии', country_en: 'Federated States of Micronesia', code: '+691' },

    { id: 170, country: 'Фиджи', country_en: 'Fiji', code: '+679' },
    { id: 171, country: 'Филиппины', country_en: 'Philippines', code: '+63' },
    { id: 172, country: 'Финляндия', country_en: 'Finland', code: '+358' },
    { id: 173, country: 'Франция', country_en: 'France', code: '+33' },
    { id: 174, country: 'Хорватия', country_en: 'Croatia', code: '+385' },
    { id: 175, country: 'Центрально-Африканская Республика', country_en: 'Central African Republic', code: '+236' },
    { id: 176, country: 'Чад', country_en: 'Chad', code: '+235' },
    { id: 177, country: 'Черногория', country_en: 'Montenegro', code: '+381' },
    { id: 178, country: 'Чехия', country_en: 'Czech', code: '+420' },
    { id: 179, country: 'Чили', country_en: 'Chile', code: '+56' },

    { id: 180, country: 'Швейцария', country_en: 'Switzerland', code: '+41' },
    { id: 181, country: 'Швеция', country_en: 'Sweden', code: '+46' },
    { id: 182, country: 'Шри-Ланка', country_en: 'Sri Lanka', code: '+94' },
    { id: 183, country: 'Эквадор', country_en: 'Ecuador', code: '+593' },
    { id: 184, country: 'Экваториальная Гвинея', country_en: 'Equatorial Guinea', code: '+240' },
    { id: 185, country: 'Эритрея', country_en: 'Eritrea', code: '+291' },
    { id: 186, country: 'Эстония', country_en: 'Estonia', code: '+372' },
    { id: 187, country: 'Эфиопия', country_en: 'Ethiopia', code: '+251' },

    { id: 188, country: 'Южная Корея', country_en: 'South Korea', code: '+82' },
    { id: 189, country: 'Южно-Африканская Республика', country_en: 'South Africa', code: '+27' },
    { id: 190, country: 'Ямайка', country_en: 'Jamaica', code: '+1876' },
    { id: 191, country: 'Япония', country_en: 'Japan', code: '+81' }
];