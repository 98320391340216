import React from 'react';

import { Input, ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const WorkCharacteristicsDocumentary = inject('WorksStore', 'LanguageStore')(observer(({ WorksStore, LanguageStore }) => {
    const { currentWork, updateCurrentWorkLocal } = WorksStore;
    const { language } = LanguageStore;

    const { link, isFilm, isInternet, isTv } = currentWork.characteristics;

    // Смена ссылки для просмотра
    const changeWatchLink = event => {
        const input = event.target;
        const value = input.value;

        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        workCharacteristicsCopy.link = value;

        updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
    };

    // Выбор чекбокса
    const chooseCheckbox = event => {
        const checkbox = event.target;
        const checked = checkbox.checked;
        const name = checkbox.name;

        const workCharacteristicsCopy = Object.clone(currentWork.characteristics);
        workCharacteristicsCopy[name] = checked;

        updateCurrentWorkLocal({ characteristics: workCharacteristicsCopy });
    };

    return (
        <div className = 'WorkCharacteristics'>
            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('Official link to watch the movie online', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent'>
                    <div className = 'WorkCharacteristics__linkInput'>
                        <Input label = { `${translate('Link', language)}...` } 
                               defaultValue = { link }
                               onInput = { changeWatchLink } />
                    </div>
                </div>
            </div>

            <div className = 'WorkCharacteristics__section'>
                <span className = 'WorkCharacteristics__sectionTitle'>{ translate('FilmRental', language) }</span>

                <div className = 'WorkCharacteristics__sectionContent WorkCharacteristics__sectionContent--with-choose-box'>
                    <ChooseBox name = 'isFilm' 
                               text = { translate('Film-2', language) }
                               checked = { isFilm }
                               onChange = { chooseCheckbox } />

                    <ChooseBox name = 'isInternet' 
                               text = { translate('The internet', language) }
                               checked = { isInternet }
                               onChange = { chooseCheckbox } />
                    
                    <ChooseBox name = 'isTv' 
                               text = { translate('TV', language) }
                               checked = { isTv }
                               onChange = { chooseCheckbox } />
                </div>
            </div>
        </div>
    )
}));

export { WorkCharacteristicsDocumentary };