import React from 'react';

import { ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, getStudioName, getExecutorName, getImageFullPath } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';
import classNames from 'classnames';

import NoPhotoLink from 'static/images/no-avatar.jpg';

import './_TabsListsCommunication.scss';

const TabsListsCommunication = inject('LanguageStore', 'TabsStore')(observer(({ items, LanguageStore, TabsStore }) => {
    const { language } = LanguageStore;
    const { changeTabShow } = TabsStore;

    const categories = {};

    for (let item of items) {
        const categoryName = item[`category-${language}`] || item['category-ru-RU'];

        if (!categories[categoryName]) {
            categories[categoryName] = [];
        }

        categories[categoryName].push(item);
    }

    const categoriesNames = Object.keys(categories);

    return items.length > 0 ? (
        <div className = 'TabsListsCommunication'>
            { categoriesNames.map((name, categoryIndex) => (
                <div key = { categoryIndex } className = 'TabsListsCommunication__group'>
                    <span className = 'TabsListsCommunication__groupName'>
                        { name }
                    </span>

                    <div className = 'TabsListsCommunication__groupItems'>
                        { categories[name].map(user => {
                            const { id, photo, cabinetType, category, userObj } = user;
                            const { isInvited } = userObj;

                            const TabsListsCommunicationUserInfoClass = classNames('TabsListsCommunication__userInfo', {
                                'TabsListsCommunication__userInfo--disabled': isInvited === 1
                            });
                            
                            const userName = cabinetType === 'executor' ? getExecutorName(user, language) : getStudioName(user, language);
                            const linkType = cabinetType === 'executor' ? 'crew' : 'company';
                            const photoLink = photo ? getImageFullPath(photo) : NoPhotoLink;

                            const equalFunc = item => item.id === id && item.category === category;
                            const changeTabOptions = { name: 'links', id, equalFunc, options: { additional: cabinetType, category } };
                            const changeTabShowBinded = changeTabShow.bind(null, changeTabOptions);

                            return (
                                <div key = { id } className = 'TabsListsCommunication__user'>
                                    <a className = { TabsListsCommunicationUserInfoClass } href = { `${BASE_URL}/${linkType}/${id}` } target = '_blank' rel = 'noopener noreferrer'>
                                        <img className = 'TabsListsCommunication__userLogo' src = { photoLink } alt = 'Аватарка' />

                                        <span className = 'TabsListsCommunication__userName'>
                                            { userName }
                                        </span>
                                    </a>

                                    <ChooseBox text = { translate('Show', language) }
                                               checked = { user.show }
                                               onChange = { changeTabShowBinded } />
                                </div>
                            )
                        } ) }
                    </div>
                </div>
            )) }
        </div>
    ) : (
        <div className = 'TabsLists__empty'>
            { translate('You have no communications', language) }
        </div>
    )
}));

export { TabsListsCommunication };