import React from 'react';

import './_Container.scss';

const Container = ({ children }) => (
    <div className = 'Container'>
        { children }
    </div>
);

export { Container };