import React from 'react';

import './_StatisticsItem.scss';

const StatisticsItem = ({ label, value }) => (
    <div className = 'StatisticsItem'>
        <span className = 'StatisticsItem__label'>
            { label }:
        </span>

        <span className = 'StatisticsItem__value'>
            { value }
        </span>
    </div>
);

export { StatisticsItem };