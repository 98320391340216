import React, { useState, useEffect, useCallback } from 'react';

import { Modal } from 'components/containers';
import { Button, Input, SelectPure } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';

import './_NewDirectionModal.scss';

const NewDirectionModal = inject('LanguageStore', 'ProfessionsStore', 'CabinetStore', 'InvoicesStore')(observer(({ modalOpened, setModalOpened, InvoicesStore, LanguageStore, ProfessionsStore, CabinetStore }) => {
    const [selectInputValue, setSelectInputValue] = useState(undefined);

    const { language } = LanguageStore;
    const { getInvoices } = InvoicesStore;
    const { directions, currentDirection, findDirection, updateCurrentDirectionLocal } = ProfessionsStore;
    const { cabinet, updateCabinetSection, updateCabinetSectionErrors, getAllSubscriptions } = CabinetStore;

    const { direction } = cabinet.personalData;

    const preparedDirections = directions.length > 0 && directions.map(struct => ({
        id: struct.id,
        title: struct[`name-${language}`] || struct['name-ru-RU'],
        items: struct.profession.map(directionItem => {
            const isChosen = currentDirection.id === directionItem.id && currentDirection.structId === struct.id;
            const isExistDirection = Boolean(direction.find(item => item.professionId === directionItem.id && item.structId === struct.id));

            return {
                id: directionItem.id,
                text: directionItem[`name-${language}`] || directionItem['name-ru-RU'],
                selected: isChosen || isExistDirection,
                data: { ...directionItem, structId: struct.id }
            }
        })
    }));

    const selectInputsProps = {
        label: translate('Name', language),
        value: selectInputValue !== undefined ? selectInputValue : currentDirection[`name-${language}`]
    };

    // Поиск профессия с задержкой
    const findDirectionDebounced = useCallback(
        debounce(value => findDirection(value === '' ? '*' : value), 350)    
    , []);

    // Отлов фокуса на поле ввода
    const handleSelectInputFocus = () => {
        findDirection('*');
    };

    // Отлов ввода в поле ввода
    const handleSelectInputChange = event => {
        const input = event.target;
        const value = input.value;

        setSelectInputValue(value);

        findDirectionDebounced(value.trim());
    };

    // Отлов клика по элементу в поле выбора
    const handleSelectDropdownItemClick = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const isChosen = currentDirection.id === data.id && currentDirection.structId === data.structId;
        const isExistDirection = Boolean(direction.find(item => item.professionId === data.id && item.structId === data.structId));

        if (!isExistDirection && !isChosen) {
            setSelectInputValue(undefined);
            updateCurrentDirectionLocal(data);
        }
    };

    // Обновление даты у текущей работы
    const updateCurrentDirectionDate = event => {
        const input = event.target;
        const value = input.value.trim();

        updateCurrentDirectionLocal({ date: `${value}-01-01` });
        updateCabinetSectionErrors('direction', { workStart: null });
    };

    // Добавление профессии
    const addProfession = () => {
        const workStart = /\b\d{4}-01-01\b/.test(currentDirection.date) ? currentDirection.date : `${new Date().getFullYear()}-01-01`;

        updateCabinetSection('direction', { 
            professionId: currentDirection.id, 
            structId: currentDirection.structId,
            workStart: workStart
        }).then(async() => {
            await getAllSubscriptions();
            await getInvoices();
    
            setModalOpened(false);
        })
          .catch(() => {});
    };

    // Очистка текущей работы
    useEffect(() => {
        return () => {
            updateCurrentDirectionLocal({}, true);
            updateCabinetSectionErrors('direction', { workStart: null });
        }
    }, []);

    return (
        <Modal title = { translate('Choose direction', language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'NewDirectionModal'>
                <SelectPure inputProps = { selectInputsProps }
                            items = { preparedDirections || [] }
                            onInputFocus = { handleSelectInputFocus }
                            onInputChange = { handleSelectInputChange }
                            onDropdownItemClick = { handleSelectDropdownItemClick } />

                <Input label = { translate('Since what year', language) }
                       value = { currentDirection.date !== undefined ? currentDirection.date.split('-')[0] : '' }
                       error = { cabinet.errors.direction && cabinet.errors.direction.workStart }
                       onChangeHandle = { updateCurrentDirectionDate } />
            </div>

            <div className = 'Modal__actions'>
                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: translate('Add', language) }}
                            disabled = { !currentDirection.id }
                            onClick = { addProfession } />
                </div>
            </div>
        </Modal>
    )
}));

export { NewDirectionModal };