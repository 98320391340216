import React from 'react';

import { ChooseBox, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, getExecutorName } from 'helpers';
import classNames from 'classnames';

import './_AgentContractorCard.scss';

const AgentContractorCard = inject('LanguageStore', 'AgentExecutorsStore', 'CabinetStore')(observer(({ contractor, LanguageStore, AgentExecutorsStore, CabinetStore, ...restProps }) => {
    const { language } = LanguageStore;
    const { updateExecutor, removeExecutor } = AgentExecutorsStore;
    const { getAllSubscriptions } = CabinetStore;
    
    const { executor, id, show, executorAgree } = contractor;

    const removeExecutorBinded = () => {
        removeExecutor.call(null, id)
            .then(() => getAllSubscriptions());
    };

    const AgentContractorCardClass = classNames('AgentContractorCard', {
        'AgentContractorCard--no-photo': !executor.photo,
        'AgentContractorCard--no-agree': !executorAgree,
    });

    // Показ исполнителя
    const showExecutor = (id, event) => {
        const checkbox = event.target;
        const checked = checkbox.checked;

        updateExecutor({ id, show: checked });
    };

    return (
        <div className = { AgentContractorCardClass } data-executor-id = { id } { ...restProps }>
            { executor.photo && (
                <img className = 'AgentContractorCard__preview' src = { executor.photo } />
            ) }

            <div className = 'AgentContractorCard__info'>
                <span className = 'AgentContractorCard__name'>{ getExecutorName(executor, language) }</span>
                
                { !executorAgree && (
                    <span className = 'AgentContractorCard__text'>{ translate('Request sent', language).toLowerCase() }</span>
                ) }
            </div>

            { executorAgree && (
                <div className = 'AgentContractorCard__options'>
                    <div className = 'AgentContractorCard__option AgentContractorCard__option--data'>
                        { translate('Login details', language) }
                    </div>

                    <div className = 'AgentContractorCard__chooseBox'>
                        <ChooseBox type = 'checkbox'
                                   checked = { show }
                                   text = { translate('Show', language) }
                                   theme = 'white-blur'
                                   onChange = { showExecutor.bind(null, id) } />
                    </div>

                    <div className = 'AgentContractorCard__option AgentContractorCard__option--drag'>
                        <Icon name = 'drag-points' className = 'AgentContractorCard__optionDragIcon' />
                    </div>

                    <div className = 'AgentContractorCard__option AgentContractorCard__option--remove' onClick = { removeExecutorBinded }>
                        { translate('Unpin', language) }
                    </div>
                </div>
            ) }

            { !executorAgree && (
                <div className = 'AgentContractorCard__options'>
                    <div className = 'AgentContractorCard__option AgentContractorCard__option--pure AgentContractorCard__option--cancel' onClick = { removeExecutorBinded }>
                        { translate('Cancel', language) }
                    </div>

                    <div className = 'AgentContractorCard__option AgentContractorCard__option--pure AgentContractorCard__option--send-again'>
                        { translate('Send again', language) } →
                    </div>
                </div>
            ) }
        </div>
    )
}));

export { AgentContractorCard };