import React, { useState, useCallback, useEffect } from 'react';

import { CabinetSection, ImageWithCropper, SubscriptionModals } from 'components/containers';
import { Input, SelectPure, SelectMultiple, ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce, getYouTubeVideoId, getImageFullPath } from 'helpers';

import './_WorkGeneralMovieSerial.scss';

const WorkGeneralMovieSerial = inject('LanguageStore', 'WorksStore', 'CabinetStore', 'UserStore', 'GenresStore')(observer(({ LanguageStore, UserStore, WorksStore, CabinetStore, GenresStore }) => {
    const [imageUploadModalOpened, setImageUploadModalOpened] = useState(false); 
    const [posterUploadModalOpened, setPosterUploadModalOpened] = useState(false);
    const [openedSubscriptionModals, setOpenedSubscriptionModals] = useState(false);
    const [currentInvoice, setCurrentInvoice] = useState(null);

    const [selectInput, setSelectInput] = useState(undefined);
    const [genreSelectInput, setGenreSelectInput] = useState(undefined);

    const { language } = LanguageStore;
    const { works, currentWork, updateCurrentWorkLocal } = WorksStore;
    const { cabinet, subscriptions } = CabinetStore;
    const { user } = UserStore;
    const { movieGenres, findMovieGenre } = GenresStore;

    const workGenres = currentWork.fields.genresObj || [];

    const preparedSections = [{
        id: 0,
        items: works.filter(workGroup => !workGroup.isOtherUser).map(workGroup => ({
            id: workGroup.id,
            text: workGroup.name,
            selected: workGroup.id === +currentWork.fields.groupId,
            data: workGroup
        }))
    }];

    // Открытие модального окна для выбора срока подписки
    const openSubscriptionTimeModal = specialization => {
        const subscription = subscriptions[user.activeCabinet].find(subscription => subscription.professionId === specialization.professionId);
        const priceList = user.activeCabinet === 'executor' ? subscription.profession.priceList[subscription.classification] : subscription.profession.priceList;
        const invoice = subscription.invoice.find(invoice => invoice.status === 'no_payed');
        
        setCurrentInvoice({ ...invoice, priceList: priceList });
        setOpenedSubscriptionModals(true);
    };

    // Удаление жанра
    const removeGenre = event => {
        const crossIcon = event.currentTarget;
        const token = crossIcon.closest('.Token');
        const data = JSON.parse(token.dataset.data);

        const workFieldsCopy = Object.clone(currentWork.fields);
        const genreIndex = workFieldsCopy.genresObj.indexOf(workFieldsCopy.genresObj.find(genre => genre.id === data.id));
        const genreIdIndex = workFieldsCopy.genres.indexOf(data.id);

        workFieldsCopy.genresObj.splice(genreIndex, 1);
        workFieldsCopy.genres.splice(genreIdIndex, 1);

        updateCurrentWorkLocal({ fields: workFieldsCopy });
    };

    const preparedGenreTokens = workGenres.map(genre => ({
        id: genre.id,
        text: genre.name,
        data: genre,
        canDelete: true,
        handlers: {
            handleRemoveIconClick: removeGenre
        }
    }))

    const [filteredSections, setFilteredSections] = useState(preparedSections);

    const currentWorkGroupName = (works.find(workGroup => workGroup.id === +currentWork.fields.groupId) || {}).name;
    const fieldsErrors = currentWork.errors.fields;
    const specializations = user.activeCabinet === 'executor' ? cabinet.personalData.speciality : cabinet.personalData.direction;

    const genresSelectInputProps = {
        label: translate('Genres', language),
        value: genreSelectInput,
        tokens: preparedGenreTokens
    };

    // Поиск жанров для кино и сериала с задержкой
    const findMovieGenreDebounced = useCallback(
        debounce(value => findMovieGenre(value === '' ? '*' : value), 350), 
    []);
    
    // Отлов ввода в поле ввода в поле выбора
    const handleSelectInput = event => {
        const input = event.target;
        const value = input.value;

        const filteredSectionsCopy = [...filteredSections];
        filteredSectionsCopy[0].items = filteredSectionsCopy[0].items.filter(item => item.text.includes(value.trim()));

        setSelectInput(value);
        setFilteredSections(value.trim() === '' ? preparedSections : filteredSectionsCopy);
    };

    // Отлов фокуса
    const handleSelectFocus = () => {
        setFilteredSections(preparedSections);
    };

    // Отлов ввода в поле ввода в поле выбора
    const handleGenreSelectInput = event => {
        const input = event.target;
        const value = input.value;

        setGenreSelectInput(value);
        findMovieGenreDebounced(value.trim());
    };

    // Отлов окончания обрезания фото
    const handleImageCrop = link => {
        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy.customPreview = link;

        updateCurrentWorkLocal({ fields: workFieldsCopy });
        setImageUploadModalOpened(false);
    }

    // Отлов окончания обрезания постера
    const handlePosterCrop = link => {
        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy.customPoster = link;

        updateCurrentWorkLocal({ fields: workFieldsCopy });
        setPosterUploadModalOpened(false);
    }

    // Отлов ввода в поле ввода
    const handleInputChange = event => {
        const input = event.target;
        const value = input.value.trim();
        const name = input.name;

        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy[name] = value;

        if (language === 'en-US' && name === 'name-ru-RU') {
            workFieldsCopy[`name-en-US`] = value;
        }

        updateCurrentWorkLocal({ fields: workFieldsCopy });
    };

    // Отлов фокуса в поле выбора
    const handleGenresSelectFocus = () => {
        findMovieGenre('*');
    };

    // Выбор раздела
    const chooseSection = event => {
        const dropdownItem = event.currentTarget;
        const data = JSON.parse(dropdownItem.dataset.data);

        const filteredSectionsCopy = [...filteredSections];
        (filteredSectionsCopy[0].items.find(item => item.selected) || {}).selected = false;
        filteredSectionsCopy[0].items.find(item => item.id === data.id).selected = true;

        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy.groupId = data.id;

        updateCurrentWorkLocal({ fields: workFieldsCopy });

        setFilteredSections(filteredSectionsCopy);
        setSelectInput(undefined);
    };

    // Удаление своего превью у видео
    const removeCustomPreview = () => {
        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy.customPreview = null;

        updateCurrentWorkLocal({ fields: workFieldsCopy });
    };

    // Удаление своего постера у видео
    const removeCustomPoster = () => {
        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy.customPoster = null;

        updateCurrentWorkLocal({ fields: workFieldsCopy });
    };

    // Выбор специализации для работы
    const chooseSpecialization = event => {
        const chooseBox = event.target;
        const checked = chooseBox.checked;
        const specializationId = JSON.parse(chooseBox.dataset.specializationId);
        const workFieldsCopy = Object.clone(currentWork.fields);
        const specializationName = 'profession';

        if (checked) {
            !workFieldsCopy[specializationName] && (workFieldsCopy[specializationName] = []);
            workFieldsCopy[specializationName].push(specializationId);
        } else {
            const specializationIndex = workFieldsCopy[specializationName].indexOf(specializationId);

            workFieldsCopy[specializationName].splice(specializationIndex, 1);
        }

        updateCurrentWorkLocal({ fields: workFieldsCopy });
    };

    // Выбор жанра
    const chooseGenre = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const workFieldsCopy = Object.clone(currentWork.fields);
        const sameGenre = workFieldsCopy.genresObj && workFieldsCopy.genresObj.find(genre => genre.id === data.id);

        workFieldsCopy.genres = workFieldsCopy.genres ? workFieldsCopy.genres : [];

        if (!sameGenre && workFieldsCopy.genres.length < 3) {
            !workFieldsCopy.genres && (workFieldsCopy.genres = []);
            workFieldsCopy.genres.push(data.id);

            !workFieldsCopy.genresObj && (workFieldsCopy.genresObj = []);
            workFieldsCopy.genresObj.push(data);

            setGenreSelectInput(undefined);
            updateCurrentWorkLocal({ fields: workFieldsCopy });
        }
    };

    // Выбор профессии, если в списке она одна
    useEffect(() => {
        if (currentWork.mode === 'add' && specializations && specializations.length > 0) {
            const specializationId = specializations[0].profession.id;
            const workFieldsCopy = Object.clone(currentWork.fields);

            if (!workFieldsCopy.profession) {
                workFieldsCopy.profession = [];
            }

            workFieldsCopy.profession.push(specializationId);

            updateCurrentWorkLocal({ fields: workFieldsCopy });
        }
    }, []);
    
    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Description', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'WorkGeneralMovieSerial'>
                    <div className = 'WorkGeneralMovieSerial__timeInputs'>
                        <Input label = { translate('Link to the video (YouTube / Vimeo)', language) }
                               value = { currentWork.fields.link }
                               disabled = { true }
                               onChange = { () => {} } />

                        <Input label = { translate('Minutes', language) }
                               defaultValue = { currentWork.fields.minutesLength }
                               name = 'minutesLength'
                               error = { Boolean(fieldsErrors && fieldsErrors.minutesLength) }
                               disabled = { currentWork.isOtherUserWork === true }
                               onInput = { handleInputChange } />

                        <Input label = { translate('Year', language) }
                               defaultValue = { currentWork.fields.years }
                               name = 'years'
                               error = { Boolean(fieldsErrors && fieldsErrors.years) }
                               disabled = { currentWork.isOtherUserWork === true }
                               onInput = { handleInputChange } />
                    </div>

                    <div className = 'WorkGeneralMovieSerial__video'>
                        <div className = 'WorkGeneralMovieSerial__videoItem'>
                            <span className = 'WorkGeneralMovieSerial__videoItemTitle'>{ translate('Video', language) }:</span>

                            {  /vimeo.com/.test(currentWork.fields.link) 
                                ? (
                                    <iframe width = '100%' height = '245'  src = { currentWork.fields.link.replace(/vimeo.com\//, 'player.vimeo.com/video/') } frameBorder = '0' allow = 'autoplay; fullscreen' allowFullScreen>
                                    </iframe>
                                ) 
                                : /rutube.ru/.test(currentWork.fields.link)     
                                    ? (
                                        <iframe width = '100%' height = '245'  src = { currentWork.fields.link.replace(/video\//, '/embed/') } frameBorder = '0' allow = 'autoplay; fullscreen' allowFullScreen>
                                        </iframe>
                                    )
                                    : (
                                        <iframe width = '100%' height = '245' src = { `https://www.youtube.com/embed/${getYouTubeVideoId(currentWork.fields.link)}` } frameBorder = '0' allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen>
                                        </iframe>
                                    )
                            }
                        </div>

                        <div className = 'WorkGeneralMovieSerial__videoItem'>
                            <span className = 'WorkGeneralMovieSerial__videoItemTitle'>{ translate('Preview', language) }:</span>
                                
                            <ImageWithCropper src = { currentWork.fields.customPreview ? getImageFullPath(currentWork.fields.customPreview) : getImageFullPath(currentWork.fields.preview) }
                                              modalOpened = { imageUploadModalOpened }
                                              setModalOpened = { setImageUploadModalOpened }
                                              size = 'md'
                                              requirements = {{ minWidth: 960, minHeight: 540 }}
                                              settings = {{ minCropBoxWidth: 88, minCropBoxHeight: 50, aspectRatio: 16 / 9 }}
                                              bgImg = 'mountain'
                                              disabled = { currentWork.isOtherUserWork === true }
                                              buttonText = { translate('Change preview', language) }
                                              onImageLoadOnServer = { handleImageCrop }
                                              onRemoveClick = { currentWork.fields.customPreview && removeCustomPreview } />
                        </div>
                    </div>

                    <div className = 'WorkGeneralMovieSerial__sectionAndProfession'>
                        <SelectPure inputProps = {{
                                        label: translate('Display in the profile', language),
                                        value: selectInput !== undefined ? selectInput : currentWorkGroupName
                                    }}
                                    items = { filteredSections }
                                    onInputChange = { handleSelectInput }
                                    onInputFocus = { handleSelectFocus }
                                    onDropdownItemClick = { chooseSection } />
                        
                        <div className = 'WorkGeneralMovieSerial__specializations'>
                            <span className = 'WorkGeneralMovieSerial__specializationsTitle'>
                                { user.activeCabinet === 'executor' ? translate('Profession in the project', language) : translate('Direction in the project', language) }:
                            </span>
                            
                            { specializations && specializations.length > 0 ? (
                                <div className = 'WorkGeneralMovieSerial__specializationsList'>
                                    { specializations.map(specialization => {
                                        const specializationItem = specialization.profession;
                                        const currentWorkSpecializationList = currentWork.fields.profession;
                                        
                                        return (
                                            <div key = { specializationItem.id } className = 'WorkGeneralMovieSerial__specializationsListItem'>
                                                <ChooseBox key = { specializationItem.id }
                                                           text = { specializationItem[`name-${language}`] }
                                                           checked = { currentWorkSpecializationList && currentWorkSpecializationList.includes(specializationItem.id) }
                                                           data-specialization-id = { specializationItem.id }
                                                           onChange = { chooseSpecialization } />

                                                { specialization.status !== 'active' && specialization.status !== 'delete' && !['agent', 'filmschool'].includes(specialization.addBy) && (
                                                    <span className = 'WorkGeneralMovieSerial__specializationsListItemText' onClick = { openSubscriptionTimeModal.bind(null, specialization) }>
                                                        ({ translate('Not paid', language) })
                                                    </span>
                                                ) }
                                            </div>
                                        )
                                    }) }
                                </div>
                            ) : (
                                <div className = 'WorkGeneralMovieSerial__specializationsEmpty'>
                                    { translate(user.activeCabinet === 'executor' ? 'You don\'t have any specializations' : 'You don\'t have directions', language) }
                                </div>
                            ) }
                        </div>
                    </div>

                    <div className = 'WorkGeneralMovieSerial__info'>
                        <ImageWithCropper src = { currentWork.fields.customPoster ? getImageFullPath(currentWork.fields.customPoster) : getImageFullPath(currentWork.fields.poster) }
                                          modalOpened = { posterUploadModalOpened }
                                          setModalOpened = { setPosterUploadModalOpened }
                                          size = 'md'
                                          requirements = {{ minWidth: 308, minHeight: 462 }}
                                          settings = {{ minCropBoxWidth: 33, minCropBoxHeight: 50, aspectRatio: 2 / 3 }}
                                          bgImg = 'star'
                                          disabled = { currentWork.isOtherUserWork === true }
                                          buttonText = { translate('Add poster', language) }
                                          onImageLoadOnServer = { handlePosterCrop }
                                          onRemoveClick = { currentWork.fields.customPoster && removeCustomPoster } />        

                        <div className = 'WorkGeneralMovieSerial__infoInputs'>
                            <Input label = { translate('ObjectNameOriginal', language) } 
                                   defaultValue = { currentWork.fields['name-ru-RU'] } 
                                   name = 'name-ru-RU' 
                                   required = { true }
                                   disabled = { currentWork.isOtherUserWork === true }
                                   error = { fieldsErrors && fieldsErrors['name-ru-RU'] }
                                   onInput = { handleInputChange } />
                            
                            { language !== 'en-US' && (
                                <Input label = { translate('ObjectNameEnglish', language) } 
                                       defaultValue = { currentWork.fields['name-en-US'] } 
                                       name = 'name-en-US'
                                       disabled = { currentWork.isOtherUserWork === true }
                                       onInput = { handleInputChange } />
                            ) }

                            { !['fullLengthDocumentaryMovie', 'shortDocumentaryMovie', 'serialDoc'].includes(currentWork.workType) && (
                                <SelectMultiple inputProps = { genresSelectInputProps }
                                                items = { [{
                                                    id: 0,
                                                    items: movieGenres.map(genre => ({
                                                        id: genre.id,
                                                        text: genre[`name-${language}`],
                                                        selected: Boolean(workGenres.find(genreItem => genreItem.id === genre.id)),
                                                        data: genre
                                                    }))
                                                }] }
                                                disabled = { currentWork.isOtherUserWork === true }
                                                onInputFocus = { handleGenresSelectFocus }
                                                onInputChange = { handleGenreSelectInput }
                                                onDropdownItemClick = { chooseGenre } />
                            ) }
                            
                            <Input label = { `${translate('Link to kinopoisk', language)} (https://www.kinopoisk.ru/film/1048334/)` } 
                                   defaultValue = { currentWork.fields.kinopoiskLink } 
                                   name = 'kinopoiskLink'
                                   disabled = { currentWork.isOtherUserWork === true }
                                   onInput = { handleInputChange } />
                            
                            <Input label = { `${translate('Link to IMDb', language)} (https://www.imdb.com/title/tt7286456/)` } 
                                   defaultValue = { currentWork.fields.imdbLink } 
                                   name = 'imdbLink'
                                   disabled = { currentWork.isOtherUserWork === true }
                                   onInput = { handleInputChange } />
                        </div>
                    </div>
                </div>
            </div>

            { openedSubscriptionModals && (
                <SubscriptionModals cabinet = { user.activeCabinet }
                                    setModalsOpened = { setOpenedSubscriptionModals }
                                    invoice = { currentInvoice } />
            ) }
        </CabinetSection>
    )
}));

export { WorkGeneralMovieSerial };