import React from 'react';

import { CabinetCard } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import { BASE_URL } from 'constants/BASE_URL';

import './_RequestsListItemResult.scss';

const RequestsListItemResult = inject('LanguageStore')(observer(({ result, cabinetType, LanguageStore }) => {
    const { language } = LanguageStore;
    
    const cabinetId = result.id;
    const link = `${BASE_URL}/${cabinetType === 'executor' ? 'crew' : 'studio'}/${cabinetId}`;

    return (
        <div className = 'RequestsListItemResult'>
            <CabinetCard cabinet = { result } />

            <a href = { link } target = '_blank' rel = 'noopener noreferrer'>
                <span className = 'RequestsListItemResult__view'>
                    { translate('View', language) } →
                </span>
            </a>
        </div>
    )
}));

export { RequestsListItemResult };