import React, { useState, useEffect, useCallback } from 'react';

import { CabinetSection, ImageWithCropper, SubscriptionModals } from 'components/containers';
import { Input, SelectPure, ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce, getYouTubeVideoId, getImageFullPath } from 'helpers';

import './_WorkGeneralCommercialAds.scss';

const WorkGeneralCommercialAds = inject('LanguageStore', 'WorksStore', 'CabinetStore', 'UserStore', 'AdDirectionsStore')(observer(({ LanguageStore, UserStore, WorksStore, CabinetStore, AdDirectionsStore }) => {
    const [imageUploadModalOpened, setImageUploadModalOpened] = useState(false);
    const [sectionSelectInput, setSectionSelectInput] = useState(undefined);
    const [adSelectInput, setAdSelectInput] = useState(undefined);
    const [openedSubscriptionModals, setOpenedSubscriptionModals] = useState(false);
    const [currentInvoice, setCurrentInvoice] = useState(null);

    const { language } = LanguageStore;
    const { works, currentWork, updateCurrentWorkLocal } = WorksStore;
    const { cabinet, subscriptions } = CabinetStore;
    const { user } = UserStore;
    const { adDirections, findAdDirection } = AdDirectionsStore;

    const preparedSections = [{
        id: 0,
        items: works.filter(workGroup => !workGroup.isOtherUser).map(workGroup => ({
            id: workGroup.id,
            text: workGroup.name,
            selected: workGroup.id === +currentWork.fields.groupId,
            data: workGroup
        }))
    }];

    const preparedDirections = [{
        id: 0,
        items: adDirections.map(direction => ({
            id: direction.id,
            text: direction.name,
            selected: (direction.id === (currentWork.fields.directComercAdv || {}).id) || (currentWork.fields.directComercAdvId === direction.id),
            data: direction
        }))
    }];

    const [filteredSections, setFilteredSections] = useState(preparedSections);

    const currentWorkGroupName = (works.find(workGroup => workGroup.id === +currentWork.fields.groupId) || {}).name;
    const fieldsErrors = currentWork.errors.fields;
    const specializations = user.activeCabinet === 'executor' ? cabinet.personalData.speciality : cabinet.personalData.direction;

    const adDirectionSelectInputProps = {
        label: translate('Direction', language),
        value: adSelectInput !== undefined 
            ? adSelectInput 
            : (currentWork.fields.directComercAdv || {})[`name-${language}`]
                || (currentWork.fields.directComercAdv || {}).name
    };

    // Открытие модального окна для выбора срока подписки
    const openSubscriptionTimeModal = specialization => {
        const subscription = subscriptions[user.activeCabinet].find(subscription => subscription.professionId === specialization.professionId);
        const priceList = user.activeCabinet === 'executor' ? subscription.profession.priceList[subscription.classification] : subscription.profession.priceList;
        const invoice = subscription.invoice.find(invoice => invoice.status === 'no_payed');
        
        setCurrentInvoice({ ...invoice, priceList: priceList });
        setOpenedSubscriptionModals(true);
    };
    
    // Отлов ввода в поле ввода в поле выбора
    const handleSectionSelectInput = event => {
        const input = event.target;
        const value = input.value;

        const filteredSectionsCopy = [...filteredSections];
        filteredSectionsCopy[0].items = filteredSectionsCopy[0].items.filter(item => item.text.includes(value.trim()));

        setSectionSelectInput(value);
        setFilteredSections(value.trim() === '' ? preparedSections : filteredSectionsCopy);
    };

    // Поиск направления для рекламы
    const findAdDirectionDebounced = useCallback(
        debounce(value => findAdDirection(value === '' ? '*' : value), 350), 
    []);

    // Отлов ввода в поле ввода в поле выбора
    const handleAdSelectInput = event => {
        const input = event.target;
        const value = input.value;

        setAdSelectInput(value);
        findAdDirectionDebounced(value.trim());
    };

    // Отлов фокуса
    const handleSelectFocus = () => {
        setFilteredSections(preparedSections);
    };

    // Отлов фокуса в поле выбора
    const handleAdSelectFocus = () => {
        findAdDirection('*');
    }

    // Отлов окончания обрезания фото
    const handleImageCrop = link => {
        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy.customPreview = link;

        updateCurrentWorkLocal({ fields: workFieldsCopy });
        setImageUploadModalOpened(false);
    }

    // Отлов ввода в поле ввода
    const handleInputChange = event => {
        const input = event.target;
        const value = input.value.trim();
        const name = input.name;

        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy[name] = value;

        if (language === 'en-US' && name === 'name-ru-RU') {
            workFieldsCopy[`name-en-US`] = value;
        }

        updateCurrentWorkLocal({ fields: workFieldsCopy });
    };

    // Выбор раздела
    const chooseSection = event => {
        const dropdownItem = event.currentTarget;
        const data = JSON.parse(dropdownItem.dataset.data);

        const filteredSectionsCopy = [...filteredSections];
        (filteredSectionsCopy[0].items.find(item => item.selected) || {}).selected = false;
        filteredSectionsCopy[0].items.find(item => item.id === data.id).selected = true;

        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy.groupId = data.id;

        updateCurrentWorkLocal({ fields: workFieldsCopy });

        setFilteredSections(filteredSectionsCopy);
        setSectionSelectInput(undefined);
    };

    // Удаление своего превью у видео
    const removeCustomPreview = () => {
        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy.customPreview = null;

        updateCurrentWorkLocal({ fields: workFieldsCopy });
    };

    // Выбор специализации для работы
    const chooseSpecialization = event => {
        const chooseBox = event.target;
        const checked = chooseBox.checked;
        const specializationId = JSON.parse(chooseBox.dataset.specializationId);
        const workFieldsCopy = Object.clone(currentWork.fields);
        const specializationName = 'profession';

        if (checked) {
            !workFieldsCopy[specializationName] && (workFieldsCopy[specializationName] = []);
            workFieldsCopy[specializationName].push(specializationId);
        } else {
            const specializationIndex = workFieldsCopy[specializationName].indexOf(specializationId);

            workFieldsCopy[specializationName].splice(specializationIndex, 1);
        }

        updateCurrentWorkLocal({ fields: workFieldsCopy });
    };

    // Выбор рекламного направления
    const chooseAdDirection = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const workFieldsCopy = Object.clone(currentWork.fields);
        workFieldsCopy.directComercAdvId = data.id;

        updateCurrentWorkLocal({ fields: workFieldsCopy });
        setAdSelectInput(data.name);
    };

    // Выбор профессии, если в списке она одна
    useEffect(() => {
        if (currentWork.mode === 'add' && specializations && specializations.length > 0) {
            const specializationId = specializations[0].profession.id;
            const workFieldsCopy = Object.clone(currentWork.fields);

            if (!workFieldsCopy.profession) {
                workFieldsCopy.profession = [];
            }

            workFieldsCopy.profession.push(specializationId);

            updateCurrentWorkLocal({ fields: workFieldsCopy });
        }
    }, []);

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Description', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'WorkGeneralCommercialAds'>
                    <div className = 'WorkGeneralCommercialAds__timeInputs'>
                        <Input label = { translate('Link to the video (YouTube / Vimeo)', language) }
                               value = { currentWork.fields.link }
                               disabled = { true }
                               onChange = { () => {} } />

                        <Input label = { translate('Minutes', language) }
                               defaultValue = { currentWork.fields.minutesLength }
                               name = 'minutesLength'
                               error = { Boolean(fieldsErrors && fieldsErrors.minutesLength) }
                               disabled = { currentWork.isOtherUserWork === true }
                               onInput = { handleInputChange } />

                        <Input label = { translate('Seconds', language) }
                               defaultValue = { currentWork.fields.secondsLength }
                               name = 'secondsLength'
                               error = { Boolean(fieldsErrors && fieldsErrors.secondsLength) }
                               disabled = { currentWork.isOtherUserWork === true }
                               onInput = { handleInputChange } />

                        <Input label = { translate('Year', language) }
                               defaultValue = { currentWork.fields.years }
                               name = 'years'
                               error = { Boolean(fieldsErrors && fieldsErrors.years) }
                               disabled = { currentWork.isOtherUserWork === true }
                               onInput = { handleInputChange } />
                    </div>

                    <div className = 'WorkGeneralCommercialAds__video'>
                        <div className = 'WorkGeneralCommercialAds__videoItem'>
                            <span className = 'WorkGeneralCommercialAds__videoItemTitle'>{ translate('Video', language) }:</span>

                            {  /vimeo.com/.test(currentWork.fields.link) 
                                ? (
                                    <iframe width = '100%' height = '245'  src = { currentWork.fields.link.replace(/vimeo.com\//, 'player.vimeo.com/video/') } frameBorder = '0' allow = 'autoplay; fullscreen' allowFullScreen>
                                    </iframe>
                                ) 
                                : /rutube.ru/.test(currentWork.fields.link)     
                                    ? (
                                        <iframe width = '100%' height = '245'  src = { currentWork.fields.link.replace(/video\//, '/embed/') } frameBorder = '0' allow = 'autoplay; fullscreen' allowFullScreen>
                                        </iframe>
                                    )
                                    : (
                                        <iframe width = '100%' height = '245' src = { `https://www.youtube.com/embed/${getYouTubeVideoId(currentWork.fields.link)}` } frameBorder = '0' allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen>
                                        </iframe>
                                    )
                            }
                        </div>

                        <div className = 'WorkGeneralCommercialAds__videoItem'>
                            <span className = 'WorkGeneralCommercialAds__videoItemTitle'>{ translate('Preview', language) }:</span>
                                
                            <ImageWithCropper src = { currentWork.fields.customPreview ? getImageFullPath(currentWork.fields.customPreview) : getImageFullPath(currentWork.fields.preview) }
                                              modalOpened = { imageUploadModalOpened }
                                              setModalOpened = { setImageUploadModalOpened }
                                              size = 'md'
                                              requirements = {{ minWidth: 960, minHeight: 540 }}
                                              settings = {{ minCropBoxWidth: 88, minCropBoxHeight: 50, aspectRatio: 16 / 9 }}
                                              bgImg = 'mountain'
                                              disabled = { currentWork.isOtherUserWork === true }
                                              buttonText = { translate('Change preview', language) }
                                              onImageLoadOnServer = { handleImageCrop }
                                              onRemoveClick = { currentWork.fields.customPreview && removeCustomPreview } />
                        </div>
                    </div>
                                
                    <div className = 'WorkGeneralCommercialAds__sectionAndProfession'>
                        <SelectPure inputProps = {{
                                        label: translate('Display in the profile', language),
                                        value: sectionSelectInput !== undefined ? sectionSelectInput : currentWorkGroupName
                                    }}
                                    items = { filteredSections }
                                    onInputChange = { handleSectionSelectInput }
                                    onInputFocus = { handleSelectFocus }
                                    onDropdownItemClick = { chooseSection } />
                        
                        <div className = 'WorkGeneralCommercialAds__specializations'>
                            <span className = 'WorkGeneralCommercialAds__specializationsTitle'>
                                { user.activeCabinet === 'executor' ? translate('Profession in the project', language) : translate('Direction in the project', language) }:
                            </span>

                            { specializations && specializations.length > 0 ? (
                                <div className = 'WorkGeneralCommercialAds__specializationsList'>
                                    { specializations.map(specialization => {
                                        const specializationItem = specialization.profession;
                                        const currentWorkSpecializationList = currentWork.fields.profession;
                                        
                                        return (
                                            <div key = { specializationItem.id } className = 'WorkGeneralCommercialAds__specializationsListItem'>
                                                <ChooseBox key = { specializationItem.id }
                                                           text = { specializationItem[`name-${language}`] }
                                                           checked = { currentWorkSpecializationList && currentWorkSpecializationList.includes(specializationItem.id) }
                                                           data-specialization-id = { specializationItem.id }
                                                           onChange = { chooseSpecialization } />

                                                { specialization.status !== 'active' && specialization.status !== 'delete' && !['agent', 'filmschool'].includes(specialization.addBy) && (
                                                    <span className = 'WorkGeneralCommercialAds__specializationsListItemText' onClick = { openSubscriptionTimeModal.bind(null, specialization) }>
                                                        ({ translate('Not paid', language) })
                                                    </span>
                                                ) }
                                            </div>
                                        )
                                    }) }
                                </div>
                            ) : (
                                <div className = 'WorkGeneralCommercialAds__specializationsEmpty'>
                                    { translate(user.activeCabinet === 'executor' ? 'You don\'t have any specializations' : 'You don\'t have directions', language) }
                                </div>
                            ) }
                        </div>
                    </div>

                    <div className = 'WorkGeneralCommercialAds__inputs'>
                        <Input label = { translate('ObjectNameOriginal', language) } 
                               defaultValue = { currentWork.fields['name-ru-RU'] } 
                               name = 'name-ru-RU' required = { true }
                               error = { fieldsErrors && fieldsErrors['name-ru-RU'] }
                               disabled = { currentWork.isOtherUserWork === true }
                               onInput = { handleInputChange } />

                        { language !== 'en-US' && (
                            <Input label = { translate('ObjectNameEnglish', language) } 
                                   defaultValue = { currentWork.fields['name-en-US'] } 
                                   name = 'name-en-US'
                                   disabled = { currentWork.isOtherUserWork === true }
                                   onInput = { handleInputChange } />
                        ) }    

                        <SelectPure inputProps = { adDirectionSelectInputProps }
                                    items = { preparedDirections }
                                    onInputChange = { handleAdSelectInput }
                                    onInputFocus = { handleAdSelectFocus }
                                    disabled = { currentWork.isOtherUserWork === true }
                                    onDropdownItemClick = { chooseAdDirection } />
                    </div>
                </div>
            </div>

            { openedSubscriptionModals && (
                <SubscriptionModals cabinet = { user.activeCabinet }
                                    setModalsOpened = { setOpenedSubscriptionModals }
                                    invoice = { currentInvoice } />
            ) }
        </CabinetSection> 
    )
}));

export { WorkGeneralCommercialAds };