import React from 'react';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_ReferralPromocode.scss';

const ReferralPromocode = inject('LanguageStore')(observer(({ promocode, LanguageStore }) => {
    const { language } = LanguageStore;

    const { code, text, expiresAt, used } = promocode;

    const ReferralPromocodeClass = classNames('ReferralPromocode', {
        'ReferralPromocode--used': used
    });

    return (
        <li className = { ReferralPromocodeClass }>
            <div className = 'ReferralPromocode__info'>
                <span className = 'ReferralPromocode__code'>
                    { code }
                </span>

                { used && (
                    <span className = 'ReferralPromocode__used'>
                        { translate('Used', language) }
                    </span>
                ) }
            </div>

            <span className = 'ReferralPromocode__text'>
                { expiresAt === null ? translate('Perpetual', language) : text }
            </span>

            { expiresAt && (
                <span className = 'ReferralPromocode__date'>
                    { translate('Valid until', language) }
                    &nbsp;
                    <span className = 'ReferralPromocode__date ReferralPromocode__date--weight-bold'>
                        { expiresAt }
                    </span>
                </span>
            ) }
        </li>
    )
}));

export { ReferralPromocode };