import React, { useEffect } from 'react';

import { CabinetSection } from 'components/containers';
import { Input, Button, FilterSelect, ChooseIcon, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounceEvent } from 'helpers';

import './_FilmSchoolSocialNetworks.scss';

const FilmSchoolSocialNetworks = inject('LanguageStore', 'CabinetStore')(observer(({ CabinetStore, LanguageStore }) => {
    const { cabinet, updateCabinetSection, updateCabinetSectionLocal, deleteFromCabinetSection, deleteFromCabinetSectionLocal } = CabinetStore;
    const { language } = LanguageStore;

    const social = cabinet.personalData ? cabinet.personalData.social : null;
   
    const socialTypes = [
        { id: 0, type: 'vimeo', used: false },
        { id: 1, type: 'facebook', used: false },
        { id: 2, type: 'vk', used: false },
        { id: 3, type: 'instagram', used: false },
        { id: 4, type: 'linkedIn', used: false },
        { id: 5, type: 'pinterest', used: false },
        { id: 6, type: 'youtube', used: false },
        { id: 7, type: 'behance', used: false },
        { id: 8, type: 'flickr', used: false },
        { id: 9, type: 'tumblr', used: false },
        { id: 10, type: 'myspace', used: false },
        { id: 11, type: 'angellist', used: false },
        { id: 12, type: 'aboutme', used: false },
        { id: 13, type: 'dribbble', used: false },
        { id: 14, type: 'xing', used: false },
        { id: 15, type: 'yuoku', used: false },
        { id: 16, type: 'douban', used: false },
        { id: 17, type: 'weibo', used: false }
    ].map(socialType => {
        if (social) {
            const isSocialUsed = Boolean(social.find(network => network.type === socialType.type));
        
            if (isSocialUsed) {
                return { ...socialType, used: true }
            }

            return socialType
        }
    });

    // Изменение социальной сети
    const changeSocial = ({ parentId, item }) => {
        const network = social[parentId];
        
        if (network.type !== item.type) {
            if (network.fake) {
                updateCabinetSectionLocal('social', { index: parentId, data: { type: item.type } });
            } else {
                updateCabinetSection('social', { id: network.id, type: item.type });
            }
        }
    };

    // Изменение ссылки социальной сети
    const changeSocialLink = debounceEvent(event => {
        const input = event.target;
        const value = input.value.trim();

        const socialItem = input.closest('.FilmSchoolSocialNetworks__item');
        const socialFake = JSON.parse(socialItem.dataset.fake);

        const networkIndex = socialItem.dataset.networkIndex;
        const network = social[networkIndex];
        
        if (socialFake) {
            const socialType = socialItem.dataset.type;
            const { id, ...socialContent } = network;
            
            updateCabinetSectionLocal('social', { index: networkIndex, data: { fake: false } });
            updateCabinetSection('social', { type: socialType, ...socialContent, link: value });
        } else {
            updateCabinetSection('social', { id: network.id, type: network.type, link: value });
        }
    }, 350);

    // Изменение видимости социальной сети
    const changeSocialShow = event => {
        const chooseIcon = event.target;
        const checked = chooseIcon.checked;

        const socialItem = chooseIcon.closest('.FilmSchoolSocialNetworks__item');
        const socialFake = JSON.parse(socialItem.dataset.fake);

        const networkIndex = socialItem.dataset.networkIndex;
        const network = social[networkIndex];

        if (socialFake) {
            updateCabinetSectionLocal('social', { index: networkIndex, data: { show: checked } });
        } else { 
            updateCabinetSection('social', { id: network.id, show: checked });
        }
    };

    // Добавление социальной сети
    const addSocial = () => {
        const noUsedSocials = socialTypes.filter(socialType => !socialType.used);

        if (noUsedSocials.length > 0) {
            updateCabinetSectionLocal('social', { data: { type: noUsedSocials[0].type, link: '', show: false, fake: true } });
        }
    };

    // Удаление социальной сети
    const deleteSocial = event => {
        const removeWrap = event.currentTarget;
        const networkIndex = removeWrap.closest('.FilmSchoolSocialNetworks__item').dataset.networkIndex;
        const network = social[networkIndex];

        deleteFromCabinetSectionLocal('social', networkIndex);
        deleteFromCabinetSection('social', network.id);
    };

    // Создание vimeo, если нету социальных сетей
    useEffect(() => {
        if (social && Object.entries(social).length === 0) {
            updateCabinetSectionLocal('social', { index: 0, data: { type: 'vimeo', link: '', show: false, fake: true } });
        }
    }, [social]);

    return social && (
        <CabinetSection id = 'socialNetworks' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Social networks', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'CabinetSection__container'>
                    <div className = 'FilmSchoolSocialNetworks'>
                        { social.map(network => {
                            const currentItem = socialTypes.find(currentNetwork => currentNetwork.type === network.type);
                            const filteredSocialTypes = [currentItem, ...(socialTypes.filter(socialType => !socialType.used && socialType.type !== currentItem.type))];
                            const networkIndex = social.indexOf(network);

                            return network && (
                                <div className = 'FilmSchoolSocialNetworks__item' key = { network.id || networkIndex } data-network-index = { networkIndex } data-fake = { network.fake || false } data-type = { currentItem.type }>
                                    <FilterSelect type = 'social' current = { currentItem } items = { filteredSocialTypes } id = { networkIndex } onChoose = { changeSocial } />

                                    <Input label = { `${network.type.toTitleCase()} (${translate('You must specify the full page address', language)})` } defaultValue = { network.link } onInput = { changeSocialLink }>
                                        <ChooseIcon text = { translate('Show in profile', language) } 
                                                    changeText = { translate('Don`t show in profile', language) }
                                                    tooltipDirection = 'right'
                                                    checked = { network.show }
                                                    onChange = { changeSocialShow } />
                                    </Input>

                                    { (social.length > 1 && !network.fake) && (
                                        <div className = 'FilmSchoolSocialNetworks__itemRemoveWrap' onClick = { deleteSocial }>
                                            <Icon name = 'cross-tiny' className = 'FilmSchoolSocialNetworks__itemRemove' />
                                        </div>
                                    ) }
                                </div>
                            )
                        }) }
                    </div>
                </div>
            </div>
            
            { social.length < 5 && (
                <div className = 'CabinetSection__actions'>
                    <Button content = {{ icon: 'plus-in-circle', text: translate('Add', language) }}
                            disabled = { (social[social.length - 1] || {}).fake }
                            theme = 'grey' 
                            pure = { true } 
                            onClick = { addSocial } />
                </div>
            ) }
        </CabinetSection> 
    )
}));

export { FilmSchoolSocialNetworks };