// Роутинг и навигация
import { Executor } from 'components/pages/Executor/Executor';
import { Client } from 'components/pages/Client/Client';

import { Agent } from 'components/pages/Agent/AgentPersonalData/Agent';
import { AgentContractors } from 'components/pages/Agent/AgentContractors/AgentContractors';

import { FilmSchool } from 'components/pages/FilmSchool/FilmSchoolPersonalData/FilmSchool';
import { FilmSchoolGraduates } from 'components/pages/FilmSchool/FilmSchoolGraduates/FilmSchoolGraduates';

import { Studio } from 'components/pages/Studio/Studio';
import { Management } from 'components/pages/Management/Management';
import { Payment } from 'components/pages/Payment/Payment';
import { Works } from 'components/pages/Works/Works';
import { Requests } from 'components/pages/Requests/Requests';
import { Searches } from 'components/pages/Searches/Searches';
import { Bookmarks } from 'components/pages/Bookmarks/Bookmarks';
import { Tabs } from 'components/pages/Tabs/Tabs';
import { Referral } from 'components/pages/Referral/Referral';
import { Statistics } from 'components/pages/Statistics/Statistics';

export const routes = {
    executor: [
        { path: '/', apiName: 'personalData', component: Executor },
        { path: '/works',  apiName: 'works', component: Works },
        { path: '/tabs',  apiName: 'tabs', component: Tabs },
        { path: '/statistics', apiName: 'statistics', component: Statistics },
        { path: '/bookmarks', apiName: 'bookmarks', component: Bookmarks },
        { path: '/searches', apiName: 'searches', component: Searches },
        { path: '/requests', apiName: 'requests', component: Requests },
        { path: '/payment', apiName: 'payment', component: Payment },
        { path: '/month-free', apiName: 'month-free', component: Referral },
        { path: '/settings', apiName: 'management', component: Management, show: false }
    ],

    studio: [
        { path: '/', apiName: 'personalData', component: Studio },
        { path: '/works',  apiName: 'works', component: Works },
        { path: '/tabs',  apiName: 'tabs', component: Tabs },
        { path: '/statistics', apiName: 'statistics', component: Statistics },
        { path: '/bookmarks', apiName: 'bookmarks', component: Bookmarks },
        { path: '/searches', apiName: 'searches', component: Searches },
        { path: '/requests', apiName: 'requests', component: Requests },
        { path: '/payment', apiName: 'payment', component: Payment },
        { path: '/month-free', apiName: 'month-free', component: Referral },
        { path: '/settings', apiName: 'management', component: Management, show: false }
    ],

    agent: [
        { path: '/', apiName: 'personalData', component: Agent },
        { path: '/contractors', apiName: 'contractors', component: AgentContractors },
        { path: '/statistics', apiName: 'statistics', component: Statistics },
        { path: '/bookmarks', apiName: 'bookmarks', component: Bookmarks },
        { path: '/searches', apiName: 'searches', component: Searches },
        { path: '/requests', apiName: 'requests', component: Requests },
        { path: '/payment', apiName: 'payment', component: Payment },
        { path: '/month-free', apiName: 'month-free', component: Referral },
        { path: '/settings', apiName: 'management', component: Management, show: false }
    ],

    client: [
        { path: '/', apiName: 'personalData', component: Client },
        { path: '/bookmarks', apiName: 'bookmarks', component: Bookmarks },
        { path: '/searches', apiName: 'searches', component: Searches },
        { path: '/payment', apiName: 'payment', component: Payment },
        { path: '/settings', apiName: 'management', component: Management, show: false }
    ],

    filmSchool: [
        { path: '/', apiName: 'personalData', component: FilmSchool },
        { path: '/graduates', apiName: 'graduates', component: FilmSchoolGraduates },
        { path: '/statistics', apiName: 'statistics', component: Statistics },
        { path: '/bookmarks', apiName: 'bookmarks', component: Bookmarks },
        { path: '/searches', apiName: 'searches', component: Searches },
        { path: '/requests', apiName: 'requests', component: Requests },
        { path: '/payment', apiName: 'payment', component: Payment },
        { path: '/month-free', apiName: 'month-free', component: Referral },
        { path: '/settings', apiName: 'management', component: Management, show: false }
    ]
};