import React from 'react';

import { AgentGeneral, AgentNav, AgentLocations, 
         AgentEducation, AgentLanguages, AgentGuilds, 
         AgentMoreInfo, AgentSocialNetworks, AgentContacts,
         AgentShowAs } from 'components/pages';
import { CabinetContent, CabinetAlerts } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const Agent = inject('LanguageStore', 'CabinetStore')(observer(({ LanguageStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { subscriptions } = CabinetStore;

    return (
        <div className = 'Agent'>
            <AgentNav />

            <CabinetContent>
                { subscriptions.agent && subscriptions.agent[0].invoice[0].status !== 'payed' && (
                    <CabinetAlerts>
                        <Alert text = { translate('To display on the site and add performers, you need to pay for the first package (5 performers). The first two months - 1 ruble', language) } 
                               type = 'warn' />
                    </CabinetAlerts>
                ) }

                <AgentShowAs />
                <AgentGeneral />
                <AgentContacts />
                <AgentLocations />
                <AgentEducation />
                <AgentLanguages />
                <AgentGuilds />
                <AgentMoreInfo />
                <AgentSocialNetworks />
            </CabinetContent>
        </div>
    )
}));

export { Agent };