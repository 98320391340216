import React from 'react';

import { CabinetSection } from 'components/containers';
import { FilterSelect, Input, Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_WorkGeneralType.scss';

const WorkGeneralType = inject('LanguageStore', 'WorksStore', 'ToolsStore')(observer(({ LanguageStore, WorksStore, ToolsStore }) => {
    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal, updateCurrentWorkErrors } = WorksStore;
    const { getPreviewFromLink } = ToolsStore;

    const { workType, fields = {}, mode, isPreviewLoaded } = currentWork;
    
    const movieTypes = [
        { id: 0, type: 'fullLengthArtMovie', value: 'Full-length art' },
        { id: 1, type: 'shortArtMovie', value: 'Short fiction' },
        { id: 2, type: 'fullLengthDocumentaryMovie', value: 'Full-length documentary' },
        { id: 3, type: 'shortDocumentaryMovie', value: 'Short documentary' }
    ];

    const serialTypes = [
        { id: 0, type: 'serialArt', value: 'Art serial' },
        { id: 1, type: 'serialDoc', value: 'Documentary serial' }
    ];

    const adsTypes = [
        { id: 0, type: 'commercialAdvertising', value: 'Commercial' },
        { id: 1, type: 'socialAdvertisement', value: 'Social' }
    ];

    const otherTypes = [
        { id: 0, type: 'fashionFilm', value: 'Fashion-film' },
        { id: 1, type: 'imageVideo', value: 'Image video' },
        { id: 3, type: 'corporateMovie', value: 'Corporate film' },
        { id: 4, type: 'screensaverIdMovie', value: 'The intro / id video' },
        { id: 5, type: 'infoProductVideo', value: 'Info / grocery video' },
        { id: 6, type: 'trainingVideo', value: 'Training video' },
        { id: 7, type: 'videoAndInfographics', value: 'Video with infographics' },
        { id: 8, type: 'backstageShooting', value: 'Shooting backstage' }
    ];

    const movieChosen = movieTypes.find(item => item.type === workType);
    const adsChosen = adsTypes.find(item => item.type === workType);
    const otherChosen = otherTypes.find(item => item.type === workType);
    const serialChosen = serialTypes.find(item => item.type === workType);
    const musicVideoChosen = workType === 'musicVideo';
    const showreelChosen = workType === 'showreel';

    const addPreviewButtonDisabled = !workType || fields.preview || isPreviewLoaded || (fields.link && fields.link.trim() === '');

    // Отлов ввода в поле ввода
    const handleInputChange = event => {
        const input = event.target;
        const value = input.value;

        updateCurrentWorkErrors('fields', { link: null });
        updateCurrentWorkLocal({ fields: { ...fields, link: value, preview: fields.preview || '' }});
    };

    // Отлов клика по кнопке
    const handleButtonClick = () => {
        getPreviewFromLink(fields.link, link => {
            const workFieldsCopy = Object.clone(fields);
            workFieldsCopy.link = fields.link.trim();
            workFieldsCopy.preview = link;

            updateCurrentWorkLocal({ fields: workFieldsCopy, isPreviewLoaded: true });
        }).catch(data => {
            updateCurrentWorkErrors('fields', { link: data.errors.link });
        });
    };

    // Выбор типа работы
    const chooseType = ({ parentName, item }) => {
        const newWorkType = item.type ? item.type : parentName;

        if (workType !== newWorkType) {
            updateCurrentWorkLocal({ workType: item.type ? item.type : parentName, errors: {} });
        }
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>
                    { translate(currentWork.mode === 'add' ? 'Select the type of work to add and specify the link' : 'Work type', language) }
                </span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'WorkGeneralType'>
                    <div className = 'WorkGeneralType__filters'>
                        <FilterSelect name = 'movie' 
                                      current = { movieChosen || { value: translate('FilmSelect', language) } } 
                                      items = { movieTypes } 
                                      size = 'lg'
                                      disabled = { mode === 'edit' || isPreviewLoaded }
                                      isChosen = { Boolean(movieChosen) }
                                      onChoose = { chooseType } />

                        <FilterSelect name = 'serial'
                                      current = { serialChosen || { value: translate('Serial', language) } }
                                      items = { serialTypes } 
                                      size = 'lg'
                                      disabled = { mode === 'edit'|| isPreviewLoaded }
                                      isChosen = { Boolean(serialChosen) }
                                      onChoose = { chooseType } />

                        <FilterSelect name = 'adv'
                                      current = { adsChosen || { value: translate('Advertising', language) } }
                                      items = { adsTypes } 
                                      size = 'lg'
                                      disabled = { mode === 'edit' || isPreviewLoaded }
                                      isChosen = { Boolean(adsChosen) }
                                      onChoose = { chooseType } />

                        <FilterSelect name = 'musicVideo'
                                      single = { true }
                                      current = { translate('MusicVideoShort', language) } 
                                      size = 'lg'
                                      disabled = { mode === 'edit' || isPreviewLoaded }
                                      isChosen = { musicVideoChosen }
                                      onChoose = { chooseType } />

                        <FilterSelect name = 'other'
                                      current = { otherChosen || { value: translate('Other', language) } }
                                      items = { otherTypes } 
                                      size = 'lg'
                                      disabled = { mode === 'edit' || isPreviewLoaded }
                                      isChosen = { Boolean(otherChosen) }
                                      onChoose = { chooseType } />
                        
                        <FilterSelect name = 'showreel'
                                      single = { true }
                                      current = 'Showreel' 
                                      size = 'lg'
                                      disabled = { mode === 'edit' || isPreviewLoaded }
                                      isChosen = { Boolean(showreelChosen) }
                                      onChoose = { chooseType } />
                    </div>

                    <div className = 'WorkGeneralType__videoLink'>
                        <Input label = { translate('Link to the video (YouTube / Vimeo)', language) }
                               value = { fields && fields.link }
                               error = { currentWork.errors.fields && currentWork.errors.fields.link }
                               disabled = { !workType || currentWork.isOtherUserWork === true }
                               onChangeHandle = { handleInputChange } />

                        <Button content = {{ text: translate('Upload', language) }} 
                                disabled = { addPreviewButtonDisabled }
                                onClick = { handleButtonClick } />
                    </div>
                </div>
            </div>
        </CabinetSection> 
    )
}));

export { WorkGeneralType };