import React from 'react';

import { CabinetSection } from 'components/containers';
import { ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_WorkAdditionalEquipmentList.scss';

const WorkAdditionalEquipmentList = inject('LanguageStore', 'WorksStore')(observer(({ LanguageStore, WorksStore }) => {
    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal } = WorksStore;

    const { optionalEquip } = currentWork;

    // Изменение техники
    const changeEquipment = event => {
        const chooseBox = event.target;
        const name = chooseBox.name;
        const checked = chooseBox.checked;

        const workOptionalEquipCopy = Object.clone(optionalEquip);
        const equipment = workOptionalEquipCopy.find(equipment => equipment.name === name);
        const equipmentIndex = workOptionalEquipCopy.indexOf(equipment);
        
        workOptionalEquipCopy[equipmentIndex].checked = checked;

        updateCurrentWorkLocal({ optionalEquip: workOptionalEquipCopy });
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Additional equipment', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'WorkAdditionalEquipmentList'>
                    { optionalEquip.map(({ id, name, checked }) => (
                        <ChooseBox key = { id }
                                   name = { name }
                                   text = { name }
                                   checked = { checked }
                                   size = 'lg'
                                   onChange = { changeEquipment } />
                    )) }
                </div>
            </div>
        </CabinetSection> 
    )
}));

export { WorkAdditionalEquipmentList };