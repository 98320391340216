import React, { useState, useEffect } from 'react';

import { ReferralBenefits, ReferralLink } from 'components/pages';
import { ReferralPromocodes, ReferralInvited } from 'components/pages';
import { CabinetContent } from 'components/containers';

import { inject, observer } from 'mobx-react';

import './_Referral.scss';

const Referral = inject('LoadingStore', 'CouponsStore', 'ReferralsStore')(observer((({ LoadingStore, CouponsStore, ReferralsStore }) => {
    const { setLoading } = LoadingStore;
    const { personalCoupons, getPersonalCoupons } = CouponsStore;
    const { referrals, getReferrals } = ReferralsStore;
    
    const [isLoading, setIsLoading] = useState(personalCoupons.length === 0 || referrals.length === 0);

    useEffect(() => {
        const promise = Promise.all([getPersonalCoupons(), getReferrals()]);

        if (personalCoupons.length === 0 || referrals.length === 0) {
            setLoading('mainContent', true);

            promise
                .then(() => {
                    setIsLoading(false);
                    setLoading('mainContent', false);
                })

            return () => {
                setLoading('mainContent', false);
            };
        }
    }, []);

    return (
        <div className = 'Referral'>
            { !isLoading && (
                <CabinetContent>
                    <ReferralBenefits />
                    <ReferralLink />
                    <ReferralPromocodes promocodes = { personalCoupons } invited = { referrals } />
                    <ReferralInvited invited = { referrals } />
                </CabinetContent>
            ) }
        </div>
    )
})));

export { Referral };