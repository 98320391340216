import React, { useState, useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Input, Button, SelectPure, ChooseBox, Tooltip, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';

import './_WorkFestivalsItem.scss';

const WorkFestivalsItem = inject('LanguageStore', 'WorksStore', 'FestivalStore')(observer(({ festival, festivalIndex, LanguageStore, WorksStore, FestivalStore }) => {
    const [festivalSelectInput, setFestivalSelectInput] = useState(undefined);
    const [yearsSelectInput, setYearsSelectInput] = useState(undefined);

    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal } = WorksStore;
    const { festivals, findFestival } = FestivalStore;

    const { awards } = festival;

    const festivalSelectInputProps = {
        label: translate('Name', language),
        value: festivalSelectInput !== undefined 
            ? festivalSelectInput 
            : festival.fest.name?.[`${language}`] || festival.fest.name
    };

    const yearsSelectInputProps = {
        label: translate('Year', language),
        value: yearsSelectInput !== undefined ? yearsSelectInput : festival.year
    };

    const preparedFestivals = [{
        id: 0,
        items: festivals.map(festivalItem => ({
            id: festivalItem.id,
            text: language === 'en-US' ? festivalItem.name_en : festivalItem.name,
            selected: festival.festId === festivalItem.id,
            data: { type: 'festival', ...festivalItem }
        }))
    }];

    const preparedYears = [{
        id: 0,
        items: (new Array(50).fill(null)).map((year, index) => {
            const currentYear = new Date().getFullYear();
            const yearItem = String(currentYear - index); 

            return {
                id: index,
                text: currentYear - index,
                selected: yearItem === String(festival.year),
                data: { type: 'year', year: yearItem }
            }
        })
    }];
    
    const [filteredYears, setFilteredYears] = useState(preparedYears);

    const radioBoxes = {
        'role': ['isParticipant', 'isWinner']
    };

    // Поиск фестиваля с задержкой
    const findFestivalDebounced = useCallback(
        debounce(value => findFestival(value === '' ? '*' : value), 350), 
    []);

    // Отлов ввода в поле выбора(фестиваль)
    const handleFestivalSelectInput = event => {
        const input = event.target;
        const value = input.value;

        setFestivalSelectInput(value);
        findFestivalDebounced(value.trim());
    };

    // Отлов ввода в поле выбора(год)
    const handleYearSelectInput = event => {
        const input = event.target;
        const value = input.value;

        const newFilteredYears = preparedYears[0].items.filter(year => {
            return Number(year.text) <= Number(value.trim())
        });

        setYearsSelectInput(value);
        setFilteredYears([{ id: 0, items: newFilteredYears }]);
    };

    // Отлов фокуса в поле выбора(фестиваль)
    const handleFestivalSelectFocus = () => findFestival('*');

    // Отлов фокуса в поле выбора(год)
    const handleYearSelectFocus = () => setFilteredYears(preparedYears);

    // Выбор элемента фестиваля
    const chooseFestivalItem = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const workFestCopy = Object.clone(currentWork.fest);

        if (data.type === 'festival') {
            const festivalName = {
                'en-US': data['name-en-US'],
                'ru-RU': data['name-ru-RU'],
            };
            
            workFestCopy[festivalIndex].festId = data.id;
            workFestCopy[festivalIndex].fest.name = festivalName;

            setFestivalSelectInput(data.name);
        } else if (data.type === 'year') {
            workFestCopy[festivalIndex].year = data.year;
            
            setYearsSelectInput(data.year);
        }

        updateCurrentWorkLocal({ fest: workFestCopy });
    };

    // Отлов ввода в поле ввода награды
    const handleAwardInputChange = event => {
        const input = event.target;
        const value = input.value.trim();

        const workFestCopy = Object.clone(currentWork.fest);
        const awardIndex = JSON.parse(input.closest('.WorkFestivalsItem__content').dataset.awardIndex);

        workFestCopy[festivalIndex].awards[awardIndex].name = value;

        updateCurrentWorkLocal({ fest: workFestCopy });
    };

    // Отлов изменения radio у награды
    const handleAwardRadioChange = event => {
        const radio = event.target;
        const value = radio.value;

        const otherRadios = radioBoxes.role.filter(radioBox => radioBox !== value);
        const awardIndex = JSON.parse(radio.closest('.WorkFestivalsItem__content').dataset.awardIndex);

        const workFestCopy = Object.clone(currentWork.fest);
        workFestCopy[festivalIndex].awards[awardIndex][value] = true;

        otherRadios.forEach(otherRadio => workFestCopy[festivalIndex].awards[awardIndex][otherRadio] = false);
        
        updateCurrentWorkLocal({ fest: workFestCopy });
    };

    // Отлов блюра в поле ввода года
    const handleYearSelectBlur = () => {
        setYearsSelectInput(festival.year ? festival.year : undefined);
    };

    // Добавить награду
    const addAward = () => {
        const workFestCopy = Object.clone(currentWork.fest);
        workFestCopy[festivalIndex].awards.push({ name: '', isParticipant: true, isWinner: false });

        updateCurrentWorkLocal({ fest: workFestCopy });
    };

    // Удалить фестиваль
    const removeFestival = () => {
        const workFestCopy = Object.clone(currentWork.fest);
        workFestCopy.splice(festivalIndex, 1);

        updateCurrentWorkLocal({ fest: workFestCopy });
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__content'>
                <div className = 'WorkFestivalsItem'>
                    <CabinetSection sub = { true }>
                        <div className = 'CabinetSection__title'>
                            <span className = 'CabinetSection__titleText'>
                                { translate('Festival', language) }
                            </span>
                        </div>

                        <div className = 'CabinetSection__content'>
                            <div className = 'WorkFestivalsItem__content'>
                                <SelectPure inputProps = { festivalSelectInputProps }
                                            items = { preparedFestivals }
                                            onInputFocus = { handleFestivalSelectFocus }
                                            onInputChange = { handleFestivalSelectInput }
                                            onDropdownItemClick = { chooseFestivalItem } />
                                
                                <SelectPure inputProps = { yearsSelectInputProps }
                                            items = { filteredYears }
                                            disabled = { !festival.fest.name }
                                            onInputFocus = { handleYearSelectFocus }
                                            onInputChange = { handleYearSelectInput }
                                            onInputBlur = { handleYearSelectBlur }
                                            onDropdownItemClick = { chooseFestivalItem } />
                            </div>
                        </div>
                    </CabinetSection>

                    <CabinetSection sub = { true }>
                        <div className = 'CabinetSection__title'>
                            <span className = 'CabinetSection__titleText'>{ translate('Award', language) }</span>
                        </div>

                        <div className = 'CabinetSection__content'>
                            <div className = 'WorkFestivalsItem__awards'>
                                { awards.map(award => {
                                    const { name, isParticipant, isWinner } = award;
                                    const awardIndex = awards.indexOf(award);

                                    return (
                                        <div className = 'WorkFestivalsItem__content' key = { awardIndex } data-award-index = { awardIndex }>
                                            <Input label = { translate('Name', language) }
                                                   defaultValue = { name }
                                                   disabled = { !festival.festId }
                                                   onInput = { handleAwardInputChange } />

                                            <div className = 'WorkFestivalsItem__contentChooseBoxes'>
                                                <ChooseBox type = 'radio' 
                                                           name = { `role-${festivalIndex}-${awardIndex}` } 
                                                           text = { translate('Participant', language) }
                                                           value = 'isParticipant'
                                                           disabled = { !festival.festId }
                                                           checked = { isParticipant }
                                                           onChange = { handleAwardRadioChange } />

                                                <ChooseBox type = 'radio' 
                                                           name = { `role-${festivalIndex}-${awardIndex}` }
                                                           text = { translate('Winner', language) }
                                                           value = 'isWinner'
                                                           disabled = { !festival.festId }
                                                           checked = { isWinner }
                                                           onChange = { handleAwardRadioChange } />
                                            </div>
                                        </div>
                                    )
                                }) }
                            </div>
                        </div>
                    </CabinetSection>
                    
                    { currentWork.fest.length > 1 && (
                        <Tooltip text = { translate('Remove festival', language) } wrapClassName = 'WorkFestivalsItem__removeTooltipWrap' position = 'left'>
                            <div className = 'WorkFestivalsItem__removeWrap' onClick = { removeFestival }>
                                <Icon name = 'cross-tiny' className = 'WorkFestivalsItem__remove' />
                            </div>
                        </Tooltip>
                    ) }
                </div>
            </div>

            <div className = 'CabinetSection__actions'>
                <div className = 'CabinetSection__actionsLeft'>
                    <Button content = {{ icon: 'plus-in-circle', text: translate('Add award', language) }} 
                            theme = 'grey' 
                            pure = { true }
                            disabled = { Boolean(awards.find(award => !award.name)) || !festival.festId }
                            onClick = { addAward } />
                </div>
            </div>
        </CabinetSection> 
    )
}));

export { WorkFestivalsItem };