import React from 'react';

import { Tooltip } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate } from 'helpers';

const ClassificationInfoTooltip = inject('LanguageStore')(observer(({ classification, children, LanguageStore, ...restProps }) => {
    const { language } = LanguageStore;

    const texts = {
        'pro': '10 videos, clips, or 1 full-length movie',
        'new-blood': 'Various experience in film and video projects',
        'specialist': 'Film and video industry employee',
        'graduated': 'First two years after film school'
    };

    return (
        <Tooltip { ...restProps }>
            { children }

            <div className = 'Tooltip__content'>
                <div className = 'ClassificationInfoTooltip'>
                    { translate(texts[classification], language) }
                </div>
            </div>
        </Tooltip>
    )
}));

export { ClassificationInfoTooltip };