// Хранилище с фестивалями

import { decorate, action, observable, runInAction } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class FestivalStore {
    festivals = []

    // Поиск фестиваля
    async findFestival(name) {
        const response = await request(`${BASE_URL}/api/fest/find-fest`, { method: 'POST', body: JSON.stringify({ name }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.festivals = data.result);
        }
    }
}

decorate(FestivalStore, {
    festivals: observable,
    findFestival: action.bound
});

export default new FestivalStore();