import React, { useState } from 'react';

import { CabinetSection, ImageWithCropper } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate, getImageFullPath } from 'helpers';

import './_WorkBackstageImages.scss';

const WorkBackstageImages = inject('LanguageStore', 'WorksStore')(observer(({ LanguageStore, WorksStore }) => {
    const [firstImageModal, setFirstImageModal] = useState(false);
    const [secondImageModal, setSecondImageModal] = useState(false);
    const [thirdImageModal, setThirdImageModal] = useState(false);
    const [fourthImageModal, setFourthImageModal] = useState(false);
    const [fifthImageModal, setFifthImageModal] = useState(false);
    const [sixthImageModal, setSixthImageModal] = useState(false);
    const [seventhImageModal, setSeventhImageModal] = useState(false);
    const [eighthImageModal, setEighthImageModal] = useState(false);

    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal } = WorksStore;

    const { photo } = currentWork.backstage;

    // Установка изображения
    const setCroppedImage = (index, link) => {
        const workPrepareCopy = Object.clone(currentWork.backstage);

        for (let i = 0; i < index; i++) {
            if (!workPrepareCopy.photo[i]) {
                workPrepareCopy.photo[i] = null;
            }
        }

        workPrepareCopy.photo[index] = link;

        updateCurrentWorkLocal({ backstage: workPrepareCopy });
    };

    // Удаление фотографии
    const removeImage = index => {
        const workPrepareCopy = Object.clone(currentWork.backstage);
        workPrepareCopy.photo[index] = null;

        updateCurrentWorkLocal({ backstage: workPrepareCopy });
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Photo', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'WorkBackstageImages'>
                    <div className = 'WorkBackstageImages__list'>
                        <ImageWithCropper src = { getImageFullPath(photo[0]) }
                                          size = 'md'
                                          modalOpened = { firstImageModal }
                                          setModalOpened = { setFirstImageModal }
                                          requirements = {{ minWidth: 300, minHeight: 300 }}
                                          settings = {{ minCropBoxWidth: 50, minCropBoxHeight: 50 }}
                                          bgImg = 'mountain'
                                          buttonText = { translate('Add photo', language) }
                                          onImageLoadOnServer = { setCroppedImage.bind(null, 0) }
                                          onRemoveClick = { removeImage.bind(null, 0) } />

                        <ImageWithCropper src = { getImageFullPath(photo[1]) }
                                          size = 'md'
                                          modalOpened = { secondImageModal }
                                          setModalOpened = { setSecondImageModal }
                                          requirements = {{ minWidth: 300, minHeight: 300 }}
                                          settings = {{ minCropBoxWidth: 50, minCropBoxHeight: 50 }}
                                          bgImg = 'mountain'
                                          buttonText = { translate('Add photo', language) }
                                          onImageLoadOnServer = { setCroppedImage.bind(null, 1) }
                                          onRemoveClick = { removeImage.bind(null, 1) } />

                        <ImageWithCropper src = { getImageFullPath(photo[2]) }
                                          size = 'md'
                                          modalOpened = { thirdImageModal }
                                          setModalOpened = { setThirdImageModal }
                                          requirements = {{ minWidth: 300, minHeight: 300 }}
                                          settings = {{ minCropBoxWidth: 50, minCropBoxHeight: 50 }}
                                          bgImg = 'mountain'
                                          buttonText = { translate('Add photo', language) }
                                          onImageLoadOnServer = { setCroppedImage.bind(null, 2) }
                                          onRemoveClick = { removeImage.bind(null, 2) } />

                        <ImageWithCropper src = { getImageFullPath(photo[3]) }
                                          size = 'md'
                                          modalOpened = { fourthImageModal }
                                          setModalOpened = { setFourthImageModal }
                                          requirements = {{ minWidth: 300, minHeight: 300 }}
                                          settings = {{ minCropBoxWidth: 50, minCropBoxHeight: 50 }}
                                          bgImg = 'mountain'
                                          buttonText = { translate('Add photo', language) }
                                          onImageLoadOnServer = { setCroppedImage.bind(null, 3) }
                                          onRemoveClick = { removeImage.bind(null, 3) } />
                        
                        <ImageWithCropper src = { getImageFullPath(photo[4]) }
                                          size = 'md'
                                          modalOpened = { fifthImageModal }
                                          setModalOpened = { setFifthImageModal }
                                          requirements = {{ minWidth: 300, minHeight: 300 }}
                                          settings = {{ minCropBoxWidth: 50, minCropBoxHeight: 50 }}
                                          bgImg = 'mountain'
                                          buttonText = { translate('Add photo', language) }
                                          onImageLoadOnServer = { setCroppedImage.bind(null, 4) }
                                          onRemoveClick = { removeImage.bind(null, 4) } />

                        <ImageWithCropper src = { getImageFullPath(photo[5]) }
                                          size = 'md'
                                          modalOpened = { sixthImageModal }
                                          setModalOpened = { setSixthImageModal }
                                          requirements = {{ minWidth: 300, minHeight: 300 }}
                                          settings = {{ minCropBoxWidth: 50, minCropBoxHeight: 50 }}
                                          bgImg = 'mountain'
                                          buttonText = { translate('Add photo', language) }
                                          onImageLoadOnServer = { setCroppedImage.bind(null, 5) }
                                          onRemoveClick = { removeImage.bind(null, 5) } />
                        
                        <ImageWithCropper src = { getImageFullPath(photo[6]) }
                                          size = 'md'
                                          modalOpened = { seventhImageModal }
                                          setModalOpened = { setSeventhImageModal }
                                          requirements = {{ minWidth: 300, minHeight: 300 }}
                                          settings = {{ minCropBoxWidth: 50, minCropBoxHeight: 50 }}
                                          bgImg = 'mountain'
                                          buttonText = { translate('Add photo', language) }
                                          onImageLoadOnServer = { setCroppedImage.bind(null, 6) }
                                          onRemoveClick = { removeImage.bind(null, 6) } />

                        <ImageWithCropper src = { getImageFullPath(photo[7]) }
                                          size = 'md'
                                          modalOpened = { eighthImageModal }
                                          setModalOpened = { setEighthImageModal }
                                          requirements = {{ minWidth: 300, minHeight: 300 }}
                                          settings = {{ minCropBoxWidth: 50, minCropBoxHeight: 50 }}
                                          bgImg = 'mountain'
                                          buttonText = { translate('Add photo', language) }
                                          onImageLoadOnServer = { setCroppedImage.bind(null, 7) }
                                          onRemoveClick = { removeImage.bind(null, 7) } />
                    </div>

                    <div className = 'WorkBackstageImages__warning'>
                        { translate('Up to 8 photos. JPG/png image from 300x300px to 15 MB.', language) }
                    </div>
                </div>
            </div>
        </CabinetSection> 
    )
}));

export { WorkBackstageImages };