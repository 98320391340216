import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Logo, Button } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate } from 'helpers';
import { routeNames } from 'routes';

import './_NotFound.scss';

const NotFound =  inject('LanguageStore')(observer(({ location, LanguageStore }) => {
    const { language } = LanguageStore;

    // Смена текущего заголовка страницы
    useEffect(() => {
        document.title = translate(routeNames[location.pathname], language);
    }, [location, language]);

    return (
        <div className = 'NotFound'>
            <div className = 'NotFound__wrap'>
                <Link to = '/' className = 'NotFound__link'>
                    <Logo round = { true } />
                </Link>

                <span className = 'NotFound__text'>
                    { translate('An error occurred and we did not find such a page.', language) }
                </span>

                <Link to = '/' className = 'NotFound__link NotFound__link--button'>
                    <Button theme = 'blue' content = {{ text: translate('Back to home', language) }} />
                </Link>
            </div>
        </div>
    )
}));

export { NotFound };