import React, { createRef, Component } from 'react';

import { Modal } from 'components/containers';
import { Button } from 'components/ui';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import NoImagePlug from 'static/images/mountain.png';

import { translate, getImageFullPath } from 'helpers';
import classNames from 'classnames';

import './_ImageWithCropperModal.scss';

class ImageWithCropperModal extends Component {
    state = {
        imageLink: this.props.imageLink,
        croppedBaseUrl: null,
        isLoading: false,
        error: null
    }

    cropperRef = createRef(null)

    // Функция для обрезки фото
    crop = () => {
        const { imageLink } = this.state;
        const { cropper } = this.cropperRef.current;
        
        const typeMatch = imageLink.match(/jpeg|png|jpg$/);
        const type = typeMatch ? typeMatch[0] : 'png';
        const dataUrl = cropper.getCroppedCanvas().toDataURL(`image/${type}`);
        
        this.setState({ croppedBaseUrl: dataUrl });
    }

    // Отлов клика по кнопке отмена
    handleButtonCancelClick = () => {
        this.props.setOpened(false);
    }

    // Сохранение текущего фото
    saveCurrentPhoto = () => {
        const { croppedBaseUrl } = this.state;
        const { uploadImage, onImageLoadOnServer, setOpened, requirements } = this.props;
        const { minWidth, minHeight } = requirements;

        const data = { image: croppedBaseUrl, minWidth, minHeight };
        
        this.setState({ isLoading: true });
        
        uploadImage(data)
            .then(file => {
                onImageLoadOnServer(file);
                setOpened(false);
            })
            .catch(error =>  this.setState({ error }))
            .finally(() => this.setState({ isLoading: false }));
    }

    // Выбор изображения
    selectImage = event => {
        const { uploadImage, requirements } = this.props;
        const { minWidth, minHeight } = requirements;

        const input = event.target;
        const image = input.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            const data = { image: reader.result, minWidth, minHeight };
            
            this.setState({ isLoading: true });
            
            uploadImage(data)
                .then(file => this.setState({ imageLink: getImageFullPath(file), error: null }))
                .catch(error => this.setState({ error }))
                .finally(() => this.setState({ isLoading: false }));

            input.value = '';
        };

        reader.readAsDataURL(image);
    }

    render() {
        const { imageLink, croppedBaseUrl, isLoading, error } = this.state;
        const { opened, setOpened, settings, requirements, language } = this.props;
        const { minWidth, minHeight } = requirements;

        const addNewButtonClass = classNames('ImageWithCropperModal__loadNew Button', {
            'Button--disabled': isLoading
        })
        
        return (
            <Modal title = { translate('Image', language) } opened = { opened } setOpened = { setOpened }>
                <div className = 'ImageWithCropperModal'>
                    { imageLink ? (
                        <Cropper ref = { this.cropperRef }
                                 src = { imageLink }
                                 ready = { this.crop }
                                 cropend = { this.crop }
                                 viewMode = { 2 }
                                 checkCrossOrigin = { false }
                                 crossOrigin = 'anonymous'
                                 zoomable = { false }
                                 { ...settings } />
                    ) : (
                        <img className = 'ImageWithCropperModal__noImagePlug' src = { NoImagePlug } alt = 'Горы' />
                    ) }
                </div>
    
                <div className = 'ImageWithCropperModal__info'>
                    { translate('Upload images in png or jpg format.', language) } <br></br>
                    { translate('The file size does not exceed', language) }&nbsp;
                    { '15MB' }
                    { translate(', and its dimensions are not less than', language) }&nbsp;
                    { `${minWidth}x${minHeight}px` }
                </div>

                { error && (
                    <div className = 'ImageWithCropperModal__error'>
                        { error }
                    </div>
                ) }
    
                <div className = 'Modal__actions'>
                    <div className = 'Modal__actionsSection Modal__actionsSection--left'>
                        <Button content = {{ text: translate('Cancel', language) }} onClick = { this.handleButtonCancelClick } />
                    </div>
    
                    <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                        { isLoading && (
                            <div className = 'ImageWithCropperModal__loadingText'>
                                { translate('Loading', language) }...
                            </div>
                        ) }

                        <label className = { addNewButtonClass }>
                            <div className = 'Button__wrap'>
                                <span className = 'Button__text'>
                                    { translate('Load new photo', language) }
                                </span>
                            </div>

                            <input type = 'file' 
                                   className = 'ImageWithCropperModal__loadNewInput'
                                   onChange = { this.selectImage } />
                        </label>

                        { imageLink && (
                            <Button content = {{ text: translate('Save', language) }}
                                    disabled = { isLoading || !croppedBaseUrl }
                                    onClick = { this.saveCurrentPhoto } />
                        ) }
                    </div>
                </div>
            </Modal>
        )
    }
}

export { ImageWithCropperModal };