import React from 'react';

import { Tooltip, ChooseBox } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate } from 'helpers';

import './_AgentDirectionTooltip.scss';

const AgentDirectionTooltip = inject('LanguageStore')(observer(({ agentId, agentDirection, showOn, direction, position, children, changeAgentDirection, LanguageStore }) => {
    const { language } = LanguageStore;
    
    return (
        <Tooltip showOn = { showOn } direction = { direction } position = { position }>
            { children }

            <div className = 'Tooltip__content'>
                <div className = 'AgentDirectionTooltip'>
                    <ChooseBox type = 'radio'
                               name = { `agent-direction-${agentId}` }
                               text = { translate('Not selected', language) }
                               value = 'no_select'
                               checked = { agentDirection === 'no_select' }
                               onChange = { changeAgentDirection } />
                    
                    <ChooseBox type = 'radio'
                               name = { `agent-direction-${agentId}` }
                               text = { translate('Film-1', language) }
                               value = 'movie'
                               checked = { agentDirection === 'movie' }
                               onChange = { changeAgentDirection } />
                    
                    <ChooseBox type = 'radio'
                               name = { `agent-direction-${agentId}` }
                               text = { translate('Advertising', language) }
                               value = 'adv'
                               checked = { agentDirection === 'adv' }
                               onChange = { changeAgentDirection } />
                </div>
            </div>
        </Tooltip>
    )
}));

export { AgentDirectionTooltip };