import React from 'react';

import { Icon, ClassificationInfoTooltip } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { getExecutorName, getStudioName, getAgentName } from 'helpers';
import { translate, getImageFullPath, getDeclination } from 'helpers';

import noAvatarSrc from 'static/images/no-avatar.jpg';

import { BASE_URL } from 'constants/BASE_URL';

import './_CabinetCard.scss';

const CabinetCard = inject('LanguageStore')(observer(({ cabinet, LanguageStore }) => {
    const { language } = LanguageStore;

    const { photo, locations, specialities, cabinetType, worksInfo } = cabinet;
    const { countryName, cityName } = locations[0] || {};

    const avatarSrc = photo || noAvatarSrc;
    const location = locations[0] ? `${countryName}, ${cityName}` : translate('Worldwide', language);
    const classification = specialities && specialities.find(({ classification }) => classification === 'pro' || classification === 'new-blood');

    const nameGetFunctions = { executor: getExecutorName, studio: getStudioName, agent: getAgentName };
    const name = nameGetFunctions[cabinetType](cabinet, language);

    const linkTypes = { executor: 'crew', studio: 'company', agent: 'agent', filmSchool: 'filmschool' };
    const link = `${BASE_URL}/${linkTypes[cabinetType]}/${cabinet.id}`;

    const statisticsDeclinations = {
        film: {
            'ru-RU': ['фильм', 'фильма', 'фильмов'],
            'en-US': ['film', 'films', 'films']
        },

        clips: {
            'ru-RU': ['клип', 'клипа', 'клипов'],
            'en-US': ['clip', 'clips', 'clips']
        },

        links: {
            'ru-RU': ['связь', 'связи', 'связей'],
            'en-US': ['link', 'links', 'links']
        },

        video: {
            'ru-RU': ['ролик', 'ролика', 'роликов'],
            'en-US': ['video', 'videos', 'videos']
        }
    };

    // Получение текста для статистики
    function getStatisticsItem(name) {
        const count = worksInfo[name];
        const declination = getDeclination(count, statisticsDeclinations[name][language]);

        return `${count} ${declination}`;
    }
    
    return (
        <div className = 'CabinetCard'>
            <div className = 'CabinetCard__avatarWrap'>
                <a href = { link }>
                    <img className = 'CabinetCard__avatar' src = { getImageFullPath(avatarSrc) } alt = 'Фотография пользователя' />
                </a>
            </div>

            <div className = 'CabinetCard__info'>
                <div className = 'CabinetCard__main'>
                    { classification && (
                        <ClassificationInfoTooltip classification = { classification.classification } wrapClassName = 'CabinetCard__classificationTooltipWrap' direction = 'down' position = 'left'>
                            <Icon name = { `${classification.classification}-${language}` } />
                        </ClassificationInfoTooltip>
                    ) }

                    <a className = 'CabinetCard__name' href = { link }>
                        { name }
                    </a>
                </div>

                <div className = 'CabinetCard__location'>
                    { location }
                </div>

                <div className = 'CabinetCard__statistics'>
                    { Object.keys(worksInfo).filter(name => Object.keys(statisticsDeclinations).includes(name) && worksInfo[name] > 0).map((name, index, names) => (
                        <span className = 'CabinetCard__statisticsItem' key = { name }>
                            { `${getStatisticsItem(name)}${index === names.length - 1 ? '' : ', '}` }
                        </span>
                    )) }
                </div>
            </div>
        </div>
    )
}));

export { CabinetCard };
