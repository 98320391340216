import React, { useState } from 'react';

import { InviteReferralModal } from 'components/containers';
import { Button, Tooltip } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { getDeclination, translate } from 'helpers';

import classNames from 'classnames';

import './_ReferralInvited.scss';

const ReferralInvited = inject('LanguageStore', 'ReferralsStore')(observer(({ invited, LanguageStore, ReferralsStore }) => {
    const [resended, setResended] = useState({});
    const [openedInviteReferralModal, setOpenedInviteReferralModal] = useState(false);

    const { language } = LanguageStore;
    const { resendReferralEmail } = ReferralsStore;

    const preparedInvited = prepareInvited(invited);

    function prepareInvited(invited) {
        return invited.map(item => (
            {
                id: item.id,
                email: item.email,
                date: prepareItemDate(item.created_at),
                status: item.status
            }
        ));
    }

    function prepareItemDate(timestamp) {
        const date = new Date(timestamp * 1000);
        const day = `0${date.getDate()}`.slice(-2);
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const year = `000${date.getFullYear()}`.slice(-4);
        
        return `${day}.${month}.${year}`;
    }

    function resendEmail(id) {
        setResended(prev => ({ ...prev, [id]: 60 }));

        const intervalId = setInterval(() => {
            setResended(prev => {
                const time = prev[id];
                const newValue = time - 1;

                if (newValue === 0) {
                    clearInterval(intervalId);
                }

                return { ...prev, [id]: newValue };
            });
        }, 1000);

        resendReferralEmail(id);
    }

    const secondsDectionations = {
        'ru-RU': ['секунду', 'секунды', 'секунд'],
        'en-US': ['second', 'seconds', 'seconds']
    };

    return (
        <div className = 'Referral__section'>
            <div className = 'Referral__sectionTitle'>
                <span className = 'Referral__sectionTitleText'>
                    { translate('Invited', language) }
                </span>

                <Button content = {{ icon: 'plus-in-circle', text: translate('Add friend', language) }}
                        className = 'ReferralInvited__button'
                        theme = 'grey'
                        pure = { true }
                        onClick = { setOpenedInviteReferralModal.bind(null, true) } />
            </div>

            <div className = 'Referral__sectionContent'>
                <div className = 'ReferralInvited'>
                    <div className = 'ReferralInvited__row ReferralInvited__row--head'>
                        <div className = 'ReferralInvited__headItem'>
                            <span className = 'ReferralInvited__headItemText'>
                                { translate('Person', language) }
                            </span>
                        </div>

                        <div className = 'ReferralInvited__headItem'>
                            <span className = 'ReferralInvited__headItemText'>
                                { translate('Date of invitation', language) }
                            </span>
                        </div>

                        <div className = 'ReferralInvited__headItem'>
                            <span className = 'ReferralInvited__headItemText'>
                                { translate('Status', language) }
                            </span>
                        </div>
                    </div>

                    { preparedInvited.length > 0 ? (
                        ( preparedInvited.map(item => {
                            const statusText = {
                                'registered': 'Registered',
                                'confirmed': 'Confirmed',
                                'unconfirmed': 'Not confirmed'
                            };

                            const statusBodyItemClass = classNames('ReferralInvited__bodyItemText', {
                                'ReferralInvited__bodyItemText--color-green': item.status === 'confirmed',
                                'ReferralInvited__bodyItemText--color-red': item.status === 'unconfirmed'
                            });
            
                            return (
                                <div className = 'ReferralInvited__row ReferralInvited__row--body' key = { item.id }>
                                    <div className = 'ReferralInvited__bodyItem ReferralInvited__bodyItem--name' title = { item.email }>
                                        <span className = 'ReferralInvited__bodyItemText ReferralInvited__bodyItemText--weight-bold'>
                                            { item.email }
                                        </span>
                                    </div>
            
                                    <div className = 'ReferralInvited__bodyItem'>
                                        <span className = 'ReferralInvited__bodyItemText'>
                                            { item.date }
                                        </span>
                                    </div>
            
                                    <div className = 'ReferralInvited__bodyItem ReferralInvited__bodyItem--status'>
                                        <span className = { statusBodyItemClass }>
                                            { translate(statusText[item.status], language) }
                                        </span>

                                        { item.status === 'unconfirmed' && !resended[item.id] && (
                                            <span className = 'ReferralInvited__bodyItemText ReferralInvited__bodyItemText--action' onClick = { resendEmail.bind(null, item.id) }>
                                                { translate('Invite again', language) } →
                                            </span>
                                        ) }

                                        { resended[item.id] !== undefined && resended[item.id] !== 0 && (
                                            <Tooltip text = { `${translate('You can resend the invitation in' , language)} ${resended[item.id]} ${getDeclination(resended[item.id], secondsDectionations[language])}` } direction = 'left'>
                                                <span className = 'ReferralInvited__bodyItemText'>
                                                    { translate('Invitation sent', language) }
                                                </span>
                                            </Tooltip>
                                        ) }
                                    </div>
                                </div>
                            )
                        }) )
                    ) : (
                        <div className = 'ReferralInvited__notFound'>
                            { translate("You haven't invited your friends yet", language) }.
                        </div>
                    ) }
                </div>
            </div>

            { openedInviteReferralModal && (
                <InviteReferralModal
                    modalOpened = { openedInviteReferralModal } 
                    setModalOpened = { setOpenedInviteReferralModal }
                />
            ) }
        </div>
    )
}));

export { ReferralInvited };