import React from 'react';

import { WorkActions, WorkCharacteristicsMusic, WorkCharacteristicsDocumentary,
         WorkCharacteristicsArt, WorkCharacteristicsSerial, WorkCharacteristicsAds,
         WorkCharacteristicsScreensaverIdMovie, WorkCharacteristicsInfoProductVideo,
         WorkCharacteristicsOther } from 'components/pages';

import { CabinetContent, CabinetSection } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_WorkCharacteristics.scss';

const WorkCharacteristics = inject('WorksStore', 'LanguageStore')(observer(({ WorksStore, LanguageStore }) => {
    const { currentWork } = WorksStore;
    const { language } = LanguageStore;

    const firstContainer = ['fullLengthDocumentaryMovie', 'shortDocumentaryMovie'].includes(currentWork.workType);
    const secondContainer = ['fullLengthArtMovie', 'shortArtMovie'].includes(currentWork.workType);
    const thirdContainer = ['serialArt', 'serialDoc'].includes(currentWork.workType);
    const fouthContainer = ['commercialAdvertising', 'socialAdvertisement'].includes(currentWork.workType);
    const fifthContainer = ['musicVideo'].includes(currentWork.workType);
    const eighthContainer = ['screensaverIdMovie'].includes(currentWork.workType);
    const ninthContainer = ['infoProductVideo'].includes(currentWork.workType);
    const tenthContainer = ['fashionFilm', 'imageVideo', 'corporateMovie', 'trainingVideo', 'videoAndInfographics', 'backstageShooting'].includes(currentWork.workType);

    const containerToRender = (() => (
        (firstContainer && <WorkCharacteristicsDocumentary />)         ||
        (secondContainer && <WorkCharacteristicsArt />)                ||
        (thirdContainer && <WorkCharacteristicsSerial />)              ||
        (fouthContainer && <WorkCharacteristicsAds />)                 ||
        (fifthContainer && <WorkCharacteristicsMusic />)               ||
        (eighthContainer && <WorkCharacteristicsScreensaverIdMovie />) ||
        (ninthContainer && <WorkCharacteristicsInfoProductVideo />)    ||
        (tenthContainer && <WorkCharacteristicsOther />)          
    ))();
    
    return (
        <div className = 'WorkCharacteristics'>
            <CabinetContent>
                <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinetSection__title'>
                        <span className = 'CabinetSection__titleText'>{ translate('Additional characteristics', language) }</span>
                    </div>

                    <div className = 'CabinetSection__content'>
                        <div className = 'CabinetSection__container'>
                            { containerToRender }
                        </div>
                    </div>
                </CabinetSection> 

                <WorkActions />
            </CabinetContent>
        </div>
    )
}));

export { WorkCharacteristics };