import React from 'react';

import { WorkActions, WorkFestivalsItem } from 'components/pages';
import { CabinetContent, CabinetAlerts } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';

import './_WorkFestivals.scss';

const WorkFestivals = inject('WorksStore', 'LanguageStore')(observer(({ WorksStore, LanguageStore }) => {
    const { currentWork } = WorksStore;
    const { language } = LanguageStore;

    const { fest } = currentWork;

    const alertText = {
        firstPart: {
            'ru-RU': 'Если вы не нашли в списке нужные фестиваль,',
            'en-US': 'If you do not find your festival in the list,'
        },
        
        secondPart: {
            'ru-RU': 'напишите нам, пожалуйста, на почту',
            'en-US': 'please email us'
        },

        thirdPart: {
            'ru-RU': 'и мы его скоро добавим',
            'en-US': 'and we will add it soon'
        }
    };
    
    return (
        <div className = 'WorkFestivals'>
            <CabinetContent>
                <CabinetAlerts>
                    <Alert>
                        { alertText.firstPart[language] }

                        &nbsp;
                        <a className = 'WorkFestivals__alertLink' href = 'mailto:support@radar.film'>
                            { alertText.secondPart[language] }
                        </a>
                        &nbsp;

                        { alertText.thirdPart[language] }
                    </Alert>
                </CabinetAlerts>

                { fest.map((festival, index) => (
                    <WorkFestivalsItem key = { festival.festId } 
                                       festival = { festival } 
                                       festivalIndex = { index } />
                )) }

                <WorkActions />
            </CabinetContent>
        </div>
    )
}));

export { WorkFestivals };