// Пропуск лишних вызовов функции в заданный промежуток времени

const debounce = (func, delay) => { 
    let timeoutId = null;

    return function(...args) {
        clearInterval(timeoutId);
        timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
};

export { debounce };