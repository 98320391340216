import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Main, MainAside, MainContent, Header } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import { routeNames, routes } from 'routes';

import './_Found.scss';

const Found = inject('LanguageStore', 'UserStore')(observer(({ location, LanguageStore, UserStore }) => {
    const { user } = UserStore;
    const { language } = LanguageStore;

    const currentCabinet = user.subCabinet || user.activeCabinet;

    // Смена текущего заголовка страницы
    useEffect(() => {
        const preparedLocation = location.pathname.replace(/\/\d+/g, '/:number');
        
        if (routeNames[preparedLocation]) {
            document.title = translate(routeNames[preparedLocation], language);
        }
    }, [location, language]);
    
    return (
        <div className = 'Found'>
            <Header />

            <Main>
                <MainAside routes = { routes[currentCabinet] } />

                <MainContent>
                    <Switch>
                        { currentCabinet && routes[currentCabinet].map(route => (
                            <Route key = { route.path }
                                   path = { route.path } 
                                   exact = { route.exact || true } 
                                   component = { route.component } />
                        )) }
                    </Switch>
                </MainContent>
            </Main>
        </div>
    )
}));

export { Found };