import React, { useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamMusicGroupProfession = inject('LanguageStore', 'WorksStore', 'CabinetStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { currentWork } = WorksStore;
    const { searchInCabinet } = CabinetStore;

    const { other_trick_master, other_stuntman } = currentWork.team;
    const { other_sfx, other_weapon, other_dog_handler } = currentWork.team;

    const { prepareGroup } = itemProps;

    const values = {
        'other_trick_master': other_trick_master.map(item => item.value),
        'other_stuntman': other_stuntman.map(item => item.value),
        'other_sfx': other_sfx.map(item => item.value),
        'other_weapon': other_weapon.map(item => item.value),
        'other_dog_handler': other_dog_handler.map(item => item.value)
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;
            const executorObj = { name: checkedValue, name_en: checkedValue, surname: checkedValue, surname_en: checkedValue };

            searchInCabinet('executor', 'personalData', 'profile', key, executorObj, result => {
                setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
            });
        }, 350)    
    , []);

    return (
        <div className = { WorkTeamSectionClass }>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'otherProfession') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Other profession', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(other_trick_master, 'other_trick_master', 'PersonName', findSelectItems, values) }
                            { prepareGroup(other_stuntman, 'other_stuntman', 'PersonName', findSelectItems, values) }
                            { prepareGroup(other_sfx, 'other_sfx', 'PersonName', findSelectItems, values) }
                            { prepareGroup(other_weapon, 'other_weapon', 'PersonName', findSelectItems, values) }
                            { prepareGroup(other_dog_handler, 'other_dog_handler', 'PersonName', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamMusicGroupProfession };