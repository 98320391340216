// Хранилище загрузочных экраннов
import { action, observable, decorate } from 'mobx';

class LoadingStore {
    loading = {
        page: true,
        main: false,
        mainContent: false
    }

    // Изменение статуса загрузки
    setLoading(name, newLoading) {
        this.loading[name] = newLoading;
    }
}

decorate(LoadingStore, {
    loading: observable,
    setLoading: action.bound
});

export default new LoadingStore();