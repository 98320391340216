import React, { memo } from 'react';

import classNames from 'classnames';

import './_Preloader.scss';

const Preloader = memo(({ loading }) => {
    const PreloaderClass = classNames('Preloader', {
        'Preloader--hide': !loading
    });

    return (
        <div className = { PreloaderClass }>
            <svg className = 'Preloader__spinner' width = '80' height = '80'>
                <circle className = 'Preloader__spinnerCircle' strokeWidth = '5' strokeLinecap = 'round' cx = '40' cy = '40' r = '35'></circle>
            </svg>
        </div>
    )
});

export { Preloader };