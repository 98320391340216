import React, { useState } from 'react';

import { RemoveSureModal } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate, getImageFullPath } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

import './_BookmarksWorksItem.scss';

const BookmarksWorksItem = inject('LanguageStore', 'BookmarksStore')(observer(({ work, LanguageStore, BookmarksStore }) => {
    const [removeModalOpened, setRemoveModalOpened] = useState(false);

    const { language } = LanguageStore;
    const { removeWork } = BookmarksStore;

    const { id, authorCabinet, previewImg } = work;

    const name = work[`name-${language}`] || work['name-ru-RU'];
    const cabinetType = authorCabinet.cabinetType;
    const linkType = cabinetType === 'executor' ? 'crew' : 'studio';

    const removeWorkBinded = removeWork.bind(null, id);

    // Отлов нажатия по кнопке удалить
    function openRemoveModal() {
        setRemoveModalOpened(true);
    }

    return (
        <div className = 'BookmarksWorksItem'>
            <img className = 'BookmarksWorksItem__preview' src = { getImageFullPath(previewImg) } alt = 'Превью видео' />

            <div className = 'BookmarksWorksItem__info'>
                <a href = { `${BASE_URL}/work/${id}` } target = '_blank' rel = 'noopener noreferrer'>
                    <span className = 'BookmarksWorksItem__name'>
                        { name }
                    </span>
                </a>

                <a href = { `${BASE_URL}/${linkType}/${authorCabinet.id}` } target = '_blank' rel = 'noopener noreferrer'>
                    <span className = 'BookmarksWorksItem__authorName'>
                        { name }
                    </span>
                </a>
            </div>

            <div className = 'BookmarksWorksItem__options'>
                <div className = 'BookmarksWorksItem__option BookmarksWorksItem__option--remove' onClick = { openRemoveModal }>
                    { translate('Remove', language) }
                </div>
            </div>

            { removeModalOpened && (
                <RemoveSureModal title = { translate('Do you really want to delete the work from bookmarks?', language) }
                                 modalOpened = { removeModalOpened }
                                 setModalOpened = { setRemoveModalOpened }
                                 onRemoveClick = { removeWorkBinded } />
            ) }
        </div>
    )
}));

export { BookmarksWorksItem };