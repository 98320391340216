import React from 'react';

import { BookmarksWorksItem } from 'components/pages';
import { CabinetAccordionList, CabinetAccordion, CabinetPreloader } from 'components/containers';
import { Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_BookmarksWorks.scss';

const BookmarksWorks = inject('LanguageStore')(observer(({ workFolders, isLoading, LanguageStore }) => {
    const { language } = LanguageStore;

    return (
        <div className = 'BookmarksWorks'>
            <CabinetAccordionList className = 'Bookmarks__accordionList'>
                { workFolders.map(folder => {
                    const title = folder.folderName;
                    const works = folder.works;
                    const text = `${translate('WorksBookmarks', language)} (${works.length})`;
                    
                    return (
                        <CabinetAccordion key = { folder.id } title = { title } text = { text }>
                            <div className = 'BookmarksWorks__accordionList'>
                                { works.map(work => (
                                    <BookmarksWorksItem key = { work.id } work = { work } />
                                )) }
                            </div>
                        </CabinetAccordion>
                    )
                }) }
            </CabinetAccordionList>

            { workFolders.length === 0 && (
                <Alert text = { translate('You don\'t have works in your bookmarks', language) } />
            ) }

            <CabinetPreloader loading = { isLoading } />
        </div>
    )
}));

export { BookmarksWorks };