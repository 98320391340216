import React from 'react';

import { Icon } from 'components/ui';

import classNames from 'classnames';

import './_Alert.scss';

const Alert = ({ text, icon = 'attention', className, type, padding = true, bordered = true, children }) => {
    const AlertClass = classNames('Alert', className, {
        'Alert--warn': type === 'warn',
        'Alert--with-padding': padding,
        'Alert--bordered': bordered
    });

    return (
        <div className = { AlertClass }>
            <Icon name = { icon } className = 'Alert__icon' />
            <span className = 'Alert__text'>{ text || children }</span>
        </div>
    )
};

export { Alert };