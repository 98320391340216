import React, { memo, useState, useEffect } from 'react';

import { Tooltip } from 'components/ui';

import { generateId } from 'helpers';
import classNames from 'classnames';

import './_ChooseIcon.scss';

const ChooseIcon = memo(({ text, changeText, tooltipDirection, className, tooltipPosition, disabled, checked, ...restProps }) => {
    const [inputId, setInputId] = useState('');

    const ChooseIconClass = classNames('ChooseIcon', className, {
        'ChooseIcon--selected': checked,
        'ChooseIcon--disabled': disabled
    });

    // Генерирование id для поля ввода
    useEffect(() => setInputId(generateId()), []);

    return (
        <div className = { ChooseIconClass }>
            <input className = 'ChooseIcon__input' id = { inputId } type = 'checkbox' checked = { checked } { ...restProps } />
            
            <Tooltip text = { checked ? changeText : text } direction = { tooltipDirection } position = { tooltipPosition }>
                <label className = 'ChooseIcon__label' htmlFor = { inputId }></label>
            </Tooltip>
        </div>
    )
});

export { ChooseIcon };