// Хранилище с рефараллами
import { decorate, action, observable, runInAction } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class ReferralsStore {
    referrals = []

    async getReferrals() {
        const response = await request(`${BASE_URL}/api/user/referrals/get`);
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.referrals = data.referrals);
        }
    }

    async resendReferralEmail(id) {
        const options = { method: 'POST', body: JSON.stringify({ id }) };
        const response = await request(`${BASE_URL}/api/user/referrals/resend-email`, options);
        const data = await response.json();

        return data.success;
    }

    async inviteReferral(email) {
        const options = { method: 'POST', body: JSON.stringify({ email }) };
        const response = await request(`${BASE_URL}/api/user/referrals/invite`, options);
        const data = await response.json();

        if (data.success === false) {
            return Promise.reject();
        }

        return data.success;
    }
}

decorate(ReferralsStore, {
    referrals: observable,
    getReferrals: action.bound,
    inviteReferral: action.bound,
    resendReferralEmail: action.bound
});

export default new ReferralsStore();