import React from 'react';

import { CabinetSection } from 'components/containers';
import { ChooseBox } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_WorkSkillsList.scss';

const WorkSkillsList = inject('LanguageStore', 'WorksStore')(observer(({ LanguageStore, WorksStore }) => {
    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal } = WorksStore;

    const { skils } = currentWork;

    // Изменение навыков
    const changeSkills = event => {
        const chooseBox = event.target;
        const id = Number(chooseBox.name);
        const checked = chooseBox.checked;

        const workSkillsCopy = Object.clone(skils);
        const skill = workSkillsCopy.find(skill => skill.id === id);
        const skillIndex = workSkillsCopy.indexOf(skill);
        
        workSkillsCopy[skillIndex].checked = checked;

        updateCurrentWorkLocal({ skils: workSkillsCopy });
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Skills used in this work', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'WorkSkillsList'>
                    { skils.map(skill => (
                        <ChooseBox key = { skill.id }
                                   name = { skill.id }
                                   text = { skill[`name-${language}`] }
                                   checked = { skill.checked }
                                   size = 'lg'
                                   onChange = { changeSkills } />
                    )) }
                </div>
            </div>
        </CabinetSection> 
    )
}));

export { WorkSkillsList };