import React, { useState } from 'react';

import { RemoveSureModal } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_PaymentCards.scss';

const PaymentCards = inject('LanguageStore', 'CardsStore')(observer(({ LanguageStore, CardsStore }) => {
    const { language } = LanguageStore;
    const { cards, setActive, deleteCard } = CardsStore;

    const [cardToRemove, setCardToRemove] = useState(null);
    const [openedRemoveModal, setOpenedRemoveModal] = useState(false);
    
    // Получение номера карты с пробелами
    const splitCardNumber = number => {
        return number.split(/(.{4})/).filter(number => number).join(' ');
    };

    // Открытие модалки для удаления
    const openRemoveModal = card => {
        setCardToRemove(card);
        setOpenedRemoveModal(true);
    };

    // Выставление активности
    const setCardActive = id => setActive(id);

    // Удаление карты
    const removeCard = () => {
        deleteCard(cardToRemove.id)
            .then(() => {
                setOpenedRemoveModal(false);
                setCardToRemove(null);
            });
    };

    return (
        <div className = 'Payment__section'>
            <div className = 'Payment__sectionTitle'>
                <span className = 'Payment__sectionTitleText'>
                    { translate('Cards', language) }
                </span>
            </div>

            <div className = 'Payment__sectionContent'>
                <div className = 'PaymentCards'>
                    { cards.length > 0 ? (
                        <>
                            { cards.map(card => {
                                const PaymentCardsItemClass = classNames('PaymentCards__item', {
                                    'PaymentCards__item--active': card.isActive
                                });

                                return (
                                    <div key = { card.id } className = { PaymentCardsItemClass }>
                                        <div className = 'PaymentCards__itemInfo'>
                                            <span className = 'PaymentCards__itemNumber'>
                                                { splitCardNumber(card.pan) }
                                            </span>

                                            <span className = 'PaymentCards__itemActivity'>
                                                { translate(card.isActive ? 'Active' : 'Not active', language) }
                                            </span>
                                        </div>

                                        <div className = 'PaymentCards__itemActions'>
                                            { !card.isActive && (
                                                <span className = 'PaymentCards__itemAction' onClick = { setCardActive.bind(null, card.id) }>
                                                    { translate('Make it active', language) }
                                                </span>
                                            ) }

                                            <span className = 'PaymentCards__itemAction' onClick = { openRemoveModal.bind(null, card) }>
                                                { translate('Remove', language) }
                                            </span>
                                        </div>
                                    </div>
                                )
                            }) }
                        </>
                    ) : (
                        <div className = 'PaymentCards__item'>
                            <div className = 'PaymentCards__empty'>
                                { translate('There are no linked cards.', language) }
                            </div>
                        </div>
                    ) }
                </div>
            </div>

            { openedRemoveModal && (
                <RemoveSureModal title = { translate('Do you really want to delete the card?', language) }
                                 text = { `${translate('This action is irreversible', language)}.` }
                                 modalOpened = { openedRemoveModal }
                                 setModalOpened = { setOpenedRemoveModal }
                                 onRemoveClick = { removeCard } />
            ) }
        </div>
    )
}));

export { PaymentCards };