import React, { useState, useEffect } from 'react';

import { Modal } from 'components/containers';
import { Button, Alert, FilterSelect, Input } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';
import { PHONE_CODES } from 'constants/constants';

import './_ChangePhoneModal.scss';

const ChangePhoneModal = inject('LanguageStore', 'UserStore')(observer(props => {
    const { modalOpened, setModalOpened, LanguageStore, UserStore } = props;

    const { language } = LanguageStore;
    const { user, updateUser } = UserStore;

    const [isWaitingCode, setIsWaitingCode] = useState(false);
    const [againCodeTime, setAgainCodeTime] = useState(59);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [phoneCode, setPhoneCode] = useState(user.phone ? PHONE_CODES.find(code => code.code === user.phone.match(/^\+\d+/)[0]) : PHONE_CODES.find(code => code.code === '+7'));
    const [phoneInput, setPhoneInput] = useState(undefined);

    const phoneWithoutCode = user.phone && user.phone.split(' ').slice(1).join(' ');
    const fullNumber = `${phoneCode.code} ${phoneInput !== undefined ? phoneInput : phoneWithoutCode}`;

    // Закрытие модального окна
    const closeModal = () => setModalOpened(false);

    // Изменение телефона
    const changePhone = event => {
        const input = event.target;
        const value = input.value.replace(/\s|\D/g, '');

        if (phoneError) {
            setPhoneError('');
        }

        setPhoneInput(value);
    };

    // Подтверждение
    const confirm = async() => {
        const options = { method: 'POST', body: JSON.stringify({ phone: fullNumber.replace(/\s/g, ''), code }) };
        const response = await request(`${BASE_URL}/api/verification/phone/verify`, options);
        const { success } = await response.json();

        if (success) {
            updateUser({ phone: fullNumber });
            closeModal();
        } else {
            setCodeError(translate('Wrong code', language));
        }
    };

    // Измениние кода
    const changeCode = event => {
        const input = event.target;
        const value = input.value;

        if (codeError) {
            setCodeError('');
        }

        setCode(value);
    };

    // Изменение кода страны для телефона
    const changePhoneCode = ({ item }) => {
        setPhoneCode(item);
    };

    // Получение кода
    const getCode = async() => {
        const options = { method: 'POST', body: JSON.stringify({ phone: fullNumber.replace(/\s/g, '') }) };
        const response = await request(`${BASE_URL}/api/verification/phone/send`, options);
        const { success } = await response.json();

        if (success) {
            setIsWaitingCode(true);
            setAgainCodeTime(59);
            setCode('');
            setCodeError('');
        } else {
            setPhoneError(translate('Invalid phone', language));
        }
    };

    // Проверка наличия телефона
    const isExistPhone = async() => {
        const options = { method: 'POST', body: JSON.stringify({ phone: fullNumber }) };
        const response = await request(`${BASE_URL}/api/search/phone-exist`, options);
        const { success } = await response.json();

        if (success) {
            setPhoneError(translate('This number is already in use', language));
        } else {
            getCode();
        }
    };

    useEffect(() => {
        if (isWaitingCode) {
            const intervalId = setInterval(() => {
                if (againCodeTime === 0) {
                    clearInterval(intervalId);
                } else {
                    setAgainCodeTime(prevCodeTime => prevCodeTime - 1);
                }
            }, 1000);
    
            return () => clearInterval(intervalId);
        }
    }, [againCodeTime, isWaitingCode]);

    return (
        <Modal title = { translate('Change your phone number', language) } titleAlign = 'center' opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'ChangePhoneModal'>
                <Alert text = { translate('An sms message will be sent to confirm your phone number', language) } />

                <div className = 'ChangePhoneModal__phone'>
                    <FilterSelect type = 'phone' 
                                  items = { PHONE_CODES } 
                                  current = { PHONE_CODES.find(code => code.phoneCode || code.code === phoneCode.code) }
                                  name = 'phone'
                                  id = { PHONE_CODES.find(code => code.phoneCode || code.code === phoneCode.code).id }
                                  disabled = { isWaitingCode }
                                  onChoose = { changePhoneCode } />

                    <Input label = { translate('Phone', language) } 
                           value = { phoneInput !== undefined ? phoneInput : phoneWithoutCode } 
                           name = 'phone'
                           disabled = { isWaitingCode }
                           error = { phoneError }
                           onChangeHandle = { changePhone } />
                </div>

                { isWaitingCode && (
                    <Input className = 'ChangePhoneModal__code'
                           label = { translate('Confirmation code', language) } 
                           value = { code }
                           error = { codeError }
                           onChangeHandle = { changeCode } />
                ) }
            </div>

            <div className = 'Modal__actions Modal__actions--fluid'>
                <div className = 'Modal__actionsSection Modal__actionsSection--left'>
                    { isWaitingCode ? (
                        <Button content = {{ text: againCodeTime !== 0 ? `${translate('Send code', language)} (${againCodeTime})` : translate('Send code', language) }}
                                fluid = { true }
                                disabled = { againCodeTime !== 0 }
                                onClick = { getCode } />
                    ) : (
                        <Button content = {{ text: translate('Cancel', language) }}
                                fluid = { true }
                                onClick = { closeModal } />
                    ) }
                </div>

                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    { !isWaitingCode ? (
                        <Button content = {{ text: translate('Send code', language) }}
                                fluid = { true }
                                disabled = { !phoneWithoutCode }
                                onClick = { isExistPhone } />
                    ) : (
                        <Button content = {{ text: translate('Confirm', language) }}
                                fluid = { true }
                                disabled = { codeError || code.length < 4 }
                                onClick = { confirm } />
                    ) }
                </div>
            </div>
        </Modal>
    )
}));

export { ChangePhoneModal };