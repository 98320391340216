import React from 'react';

import { StatisticsItem, StatisticsChart } from 'components/pages';
import { CabinetContent } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_Statistics.scss';

const Statistics = inject('LanguageStore', 'CabinetStore', 'UserStore')(observer(({ LanguageStore, CabinetStore, UserStore }) => {
    const { language } = LanguageStore;
    const { cabinet } = CabinetStore;
    const { user } = UserStore;
    
    const statistics = cabinet.personalData ? cabinet.personalData.statistics : null;

    const chartOptions = {
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                autoScaleYaxis: true
            }
        },

        annotations: {
            yaxis: [
                {
                    y: 30,
                    borderColor: '#999'
                }
            ],

            xaxis: [
                {
                    x: new Date('14 Nov 2012').getTime(),
                    borderColor: '#999',
                    yAxisIndex: 0
                }
            ]  
        },

        dataLabels: {
            enabled: false
        },

        markers: {
            size: 0,
            style: 'hollow',
        },

        xaxis: {
            type: 'datetime',
            min: new Date('01 Jan 2019').getTime(),
            tickAmount: 1,
            labels: {
                datetimeUTC: false
            }
        },

        yaxis: {
            tickAmount: 1,
            labels: {
                formatter: count => count.toFixed(0)
            }
        },

        tooltip: {
            x: {
                format: 'dd MM yyyy'
            }
        },

        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
            }
        }
    };

    function prepareSeries(timestamps, name) {
        const prepared = [...new Set(timestamps)].map(prepare);

        function prepare(timestamp) {
            return [timestamp * 1000, timestamps.filter(item => item <= timestamp + 1800 && item >= timestamp - 1800).length];
        }
    
        return [{ name, data: prepared }];
    }

    return statistics && (
        <div className = 'Statistics'>
            <CabinetContent>
                <div className = 'Statistics__contentWrap'>
                    <span className = 'Statistics__title'>
                        { translate('Statistics', language) }
                    </span>

                    <div className = 'Statistics__items'>
                        <StatisticsItem
                            label = { translate('Saving your profile to bookmarks', language) }
                            value = { statistics.inFavorites }
                        />
                        
                        { !['agent', 'filmSchool'].includes(user.activeCabinet) && (
                            <StatisticsItem
                                label = { translate('Saving works to bookmarks', language) }
                                value = { statistics.worksInFavorites }
                            />
                        ) }
                    </div>

                    <StatisticsChart
                        title = { translate('Go to the profile', language) }
                        id = 'visits-chart'
                        options = { chartOptions }
                        series = { prepareSeries(statistics.profileViews, translate('Visits', language)) }
                    />

                    {user.activeCabinet !== 'agent' && (
                        <StatisticsChart
                            title = { translate('Views of works', language) }
                            id = 'views-chart'
                            options = { chartOptions }
                            series = { prepareSeries(statistics.workViews, translate('Views', language)) }
                        />
                    )}
                </div>
            </CabinetContent>
        </div>
    )
}));

export { Statistics };