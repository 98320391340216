import React, { useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamFinancialAdministrativeShop = inject('LanguageStore', 'WorksStore', 'CabinetStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { currentWork } = WorksStore;
    const { searchInCabinet } = CabinetStore;

    const { fin_admin_line_producer, fin_admin_director, fin_admin_location_manager, fin_admin_producer_assist } = currentWork.team;
    const { fin_admin_director_deputy, fin_admin_admin, fin_admin_team_manager, fin_admin_fixer } = currentWork.team;

    const { prepareGroup } = itemProps;

    const values = {
        'fin_admin_line_producer': fin_admin_line_producer.map(item => item.value),
        'fin_admin_director': fin_admin_director.map(item => item.value),
        'fin_admin_location_manager': fin_admin_location_manager.map(item => item.value),
        'fin_admin_producer_assist': fin_admin_producer_assist.map(item => item.value),
        'fin_admin_director_deputy': fin_admin_director_deputy.map(item => item.value),
        'fin_admin_admin': fin_admin_admin.map(item => item.value),
        'fin_admin_team_manager': fin_admin_team_manager.map(item => item.value),
        'fin_admin_fixer': fin_admin_fixer.map(item => item.value)
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;
            const executorObj = { name: checkedValue, name_en: checkedValue, surname: checkedValue, surname_en: checkedValue };

            searchInCabinet('executor', 'personalData', 'profile', key, executorObj, result => {
                setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
            });
        }, 350)    
    , []);

    return (
        <div className = { WorkTeamSectionClass }>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'financialAdministrativeShop') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Financial and administrative shop', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(fin_admin_line_producer, 'fin_admin_line_producer', 'PersonName', findSelectItems, values) }
                            { prepareGroup(fin_admin_director, 'fin_admin_director', 'PersonName', findSelectItems, values) }
                            { prepareGroup(fin_admin_location_manager, 'fin_admin_location_manager', 'PersonName', findSelectItems, values) }
                            { prepareGroup(fin_admin_producer_assist, 'fin_admin_producer_assist', 'PersonName', findSelectItems, values) }
                            { prepareGroup(fin_admin_director_deputy, 'fin_admin_director_deputy', 'PersonName', findSelectItems, values) }
                            { prepareGroup(fin_admin_admin, 'fin_admin_admin', 'PersonName', findSelectItems, values) }
                            { prepareGroup(fin_admin_team_manager, 'fin_admin_team_manager', 'PersonName', findSelectItems, values) }
                            { prepareGroup(fin_admin_fixer, 'fin_admin_fixer', 'PersonName', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamFinancialAdministrativeShop };