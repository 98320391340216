import React from 'react';

import { CabinetSection } from 'components/containers';

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_AgentShowAs.scss';

const AgentShowAs = inject('CabinetStore', 'LanguageStore')(observer(({ CabinetStore, LanguageStore }) => {
    const { cabinet, updateCabinetSection } = CabinetStore;
    const { language } = LanguageStore;

    const convertedCabinet = toJS(cabinet);
    const convertedLanguage = toJS(language);

    const profile = convertedCabinet.personalData ? convertedCabinet.personalData.profile : null;
    const isHuman = profile && profile.isHuman;
    const isCompany = profile && profile.isCompany;

    const humanClass = classNames('AgentShowAs__item', {
        'AgentShowAs__item--active': isHuman
    });

    const companyClass = classNames('AgentShowAs__item', {
        'AgentShowAs__item--active': isCompany
    });

    // Смена типы отображения
    const changeType = event => {
        const item = event.currentTarget;
        const name = item.dataset.name;

        if (name === 'isHuman' && !isHuman) {
            updateCabinetSection('profile', { isHuman: true, isCompany: false });
        } else if(name === 'isCompany' && !isCompany) {
            updateCabinetSection('profile', { isHuman: false, isCompany: true });
        }
    };

    return profile && (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Show me as', convertedLanguage) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'AgentShowAs'>
                    <div className = { humanClass } data-name = 'isHuman' onClick = { changeType }>
                        { translate('Person', convertedLanguage) }
                    </div>

                    <div className = { companyClass } data-name = 'isCompany' onClick = { changeType }>
                        { translate('Company', convertedLanguage) }
                    </div>
                </div>
            </div>
        </CabinetSection> 
    )
}));

export { AgentShowAs };