import React, { useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, debounce } from 'helpers';
import classNames from 'classnames';

const WorkTeamMakeupSuit = inject('LanguageStore', 'WorksStore', 'CabinetStore')(observer(({ itemProps, opened, handleTitleClick, LanguageStore, WorksStore, CabinetStore }) => {
    const { language } = LanguageStore;
    const { currentWork } = WorksStore;
    const { searchInCabinet } = CabinetStore;

    const { art_workshop_designer_spec_makeup, art_workshop_designer_spec_makeup_assist } = currentWork.team;
    const { art_workshop_designer_suit_assist, art_workshop_dresser } = currentWork.team;

    const { prepareGroup } = itemProps;

    const values = {
        'art_workshop_designer_spec_makeup': art_workshop_designer_spec_makeup.map(item => item.value),
        'art_workshop_designer_spec_makeup_assist': art_workshop_designer_spec_makeup_assist.map(item => item.value),
        'art_workshop_designer_suit_assist': art_workshop_designer_suit_assist.map(item => item.value),
        'art_workshop_dresser': art_workshop_dresser.map(item => item.value)
    };

    const WorkTeamSectionClass = classNames('WorkTeam__section', {
        'WorkTeam__section--opened': opened
    });

    // Поиск элементов с задержкой
    const findSelectItems = useCallback(
        debounce((setSearches, name, index, value) => {
            const checkedValue = value === '' ? '*' : value;

            const key = `${name}-${index}`;
            const executorObj = { name: checkedValue, name_en: checkedValue, surname: checkedValue, surname_en: checkedValue };

            searchInCabinet('executor', 'personalData', 'profile', key, executorObj, result => {
                setSearches(prevSearches => ({ ...prevSearches, [key]: result }));
            });
        }, 350)    
    , []);

    return (
        <div className = { WorkTeamSectionClass }>
            <div className = 'WorkTeam__sectionTitle' onClick = { handleTitleClick.bind(null, 'makeupSuit') }>
                <div className = 'WorkTeam__sectionOpenWrap'>
                    <Icon name = 'angle' className = 'WorkTeam__sectionOpen' />
                </div>

                <span className = 'WorkTeam__sectionTitleText'>{ translate('Makeup suit', language) }</span>
            </div>

            { opened && (
                <CabinetSection sub = { true } padding = { true } bordered = { true } bgColor = 'white'>
                    <div className = 'CabinteSection__content'>
                        <div className = 'WorkTeam__sectionContent'>
                            { prepareGroup(art_workshop_designer_spec_makeup, 'art_workshop_designer_spec_makeup', 'PersonName', findSelectItems, values) }
                            { prepareGroup(art_workshop_designer_spec_makeup_assist, 'art_workshop_designer_spec_makeup_assist', 'PersonName', findSelectItems, values) }
                            { prepareGroup(art_workshop_designer_suit_assist, 'art_workshop_designer_suit_assist', 'PersonName', findSelectItems, values) }
                            { prepareGroup(art_workshop_dresser, 'art_workshop_dresser', 'PersonName', findSelectItems, values) }
                        </div>
                    </div>
                </CabinetSection>
            ) }
        </div>
    )
}));

export { WorkTeamMakeupSuit };