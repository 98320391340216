import React, { useState } from 'react';

import { Modal } from 'components/containers';
import { Button, Alert, Input } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, isValidEmail } from 'helpers';

import './_InviteReferralModal.scss';

const InviteReferralModal = inject('LanguageStore', 'ReferralsStore')(observer(({ modalOpened, setModalOpened, LanguageStore, ReferralsStore }) => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const { language } = LanguageStore;
    const { getReferrals, inviteReferral } = ReferralsStore;

    const isDisabled = !isValidEmail(email) || isLoading || error;

    const closeModal = () => setModalOpened(false);

    function handleEmailChange(event) {
        const input = event.target;
        const email = input.value.trim().toLowerCase();

        setEmail(email);

        if (error) {
            setError('');
        }
    }

    function invite() {
        setIsLoading(true);

        inviteReferral(email)
            .then(() => {
                getReferrals();
                closeModal();
            })
            .catch(() => {
                setIsLoading(false);
                setError('This user has already been invited');
            });
    }

    return (
        <Modal title = { translate('Invited friend', language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'InviteReferralModal'>
                <Alert text = { translate('Your friend will receive a welcome email with a link for registration.', language) } />

                <Input
                    label = 'Email'
                    value = { email }
                    error = { translate(error, language) }
                    onInput = { handleEmailChange }
                />
            </div>

            <div className = 'Modal__actions'>
                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button
                        content = {{ text: translate('Invite', language) }}
                        theme = 'grey'
                        disabled = { isDisabled }
                        onClick = { invite }
                    />
                </div>
            </div>
        </Modal>
    )
}));

export { InviteReferralModal };