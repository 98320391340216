import React, { useState } from 'react';

import { SubscriptionTimeModal, PaySubscriptionModal } from 'components/containers';

import { observer, inject } from 'mobx-react';
import { prepareSubscription } from 'helpers';

const SubscriptionModals = inject('CabinetStore', 'InvoicesStore', 'CouponsStore')(observer(({ cabinet, setModalsOpened, invoice, CabinetStore, InvoicesStore, CouponsStore }) => {
    const { subscriptions, getAllSubscriptions } = CabinetStore;
    const { updateInvoicePeriod } = InvoicesStore;
    const { checkCoupon } = CouponsStore;
    
    const [currentCoupon, setCurrentCoupon] = useState({ discount: 0, coupon: {} });
    const [openedSubscriptionTimeModal, setOpenedSubscriptionTimeModal] = useState(true);
    const [openedPaySubscriptionModal, setOpenedPaySubscriptionModal] = useState(false);
   
    const currentSubscription = subscriptions[cabinet] && subscriptions[cabinet].find(subscription => {
        return subscription.invoice.find(invoiceItem => invoiceItem.id === invoice.id);
    });

    const openPayModal = async(chosenTime) => {
        await updateInvoicePeriod(invoice.id, chosenTime);
        await getAllSubscriptions();
        
        setOpenedSubscriptionTimeModal(false);
        setOpenedPaySubscriptionModal(true);
    };

    return (
        <>
            { openedSubscriptionTimeModal && (
                <SubscriptionTimeModal modalOpened = { openedSubscriptionTimeModal } 
                                       setModalOpened = { setModalsOpened }
                                       openPayModal = { openPayModal }
                                       invoice = { { ...invoice, classification: currentSubscription.classification, cabinet } } />
            ) }

            { openedPaySubscriptionModal && (
                <PaySubscriptionModal modalOpened = { openedPaySubscriptionModal } 
                                      setModalOpened = { setModalsOpened }
                                      coupon = { currentCoupon }
                                      subscription = { prepareSubscription(currentSubscription, cabinet) } />
            ) }
        </>
    )
}));

export { SubscriptionModals }