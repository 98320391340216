import React, { useState, useCallback } from 'react';

import { CabinetSection } from 'components/containers';
import { ChooseIcon, Icon, SelectPure } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate, debounce } from 'helpers';

import './_AgentLanguages.scss';

const AgentLanguages = inject('LanguageStore', 'CabinetStore', 'GeolocationStore')(observer(({ LanguageStore, CabinetStore, GeolocationStore }) => {
    const [selectInput, setSelectInput] = useState('');

    const { language } = LanguageStore;
    const { cabinet, updateCabinetSection, deleteFromCabinetSection } = CabinetStore;
    const { geolocation, findLanguage } = GeolocationStore;

    const languages = cabinet.personalData ? cabinet.personalData.languages : null;

    const preparedLanguages = (languages && geolocation.languages) && [
        {
            id: 0,
            items: [
                ...(geolocation.languages.map(language => (
                    {
                        id: language.id,
                        text: language.name,
                        selected: Boolean(languages.find(languageItem => languageItem.languageId === language.id)),
                        data: {
                            ...language
                        }
                    }
                )))
            ]
        }
    ];

    // Поиск языка
    const findLanguageItem = useCallback(
        debounce(value => findLanguage(value === '' ? '*' : value), 350)
    , []);

    // Отлов ввода на поле ввода
    const handleInputChange = event => {
        const input = event.target;
        const value = input.value;

        findLanguageItem(value.trim());
        setSelectInput(value);
    };

    // Отлов фокуса на поле ввода
    const handleInputFocus = () => findLanguage('*');

    // Показ языка
    const showLanguage = event => {
        const chooseIcon = event.target;
        const checked = chooseIcon.checked;
        const languageIndex = chooseIcon.closest('.AgentLanguages__item').dataset.languageIndex;
        const language = languages[languageIndex];

        updateCabinetSection('languages', { id: language.id, show: checked });
    };

    // Удаление языка
    const deleteLanguage = event => {
        const token = event.target.closest('.AgentLanguages__token');
        const languageIndex = Number(token.closest('.AgentLanguages__item').dataset.languageIndex);
        const language = languages[languageIndex];

        deleteFromCabinetSection('languages', language.id, languageIndex);
    };

    // Добавление языка
    const addLanguage = event => {
        const dropdownItem = event.target.closest('.SelectDropdown__sectionItem');
        const data = JSON.parse(dropdownItem.dataset.data);
        const languageId = data.id;
        const equalLanguage = languages.find(language => language.languageId === languageId);

        if (!equalLanguage) {
            setSelectInput('');
            updateCabinetSection('languages', { languageId: languageId, show: false });
        }
    };

    return languages && (
        <CabinetSection id = 'languages' padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Languages', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'CabinetSection__container'>
                    <div className = 'AgentLanguages'>
                        <SelectPure inputProps = {{
                                        label: translate('Language', language),
                                        value: selectInput
                                    }}
                                    items = { preparedLanguages }
                                    onInputFocus = { handleInputFocus }
                                    onInputChange = { handleInputChange }
                                    onDropdownItemClick = { addLanguage } />

                        <div className = 'AgentLanguages__list'>
                            { languages.map(languageItem => {
                                const languageIndex = languages.indexOf(languageItem);

                                return (
                                    <div className = 'AgentLanguages__item' key = { languageIndex } data-language-index = { languageIndex }>
                                        <div className = 'AgentLanguages__token' onClick = { deleteLanguage }>
                                            <span className = 'AgentLanguages__tokenText'>{ languageItem.languageName }</span>
                                            <Icon name = 'cross-tiny' className = 'AgentLanguages__tokenIcon'  />
                                        </div>

                                        <ChooseIcon text = { translate('Show in profile', language) }
                                                    changeText = { translate('Don`t show in profile', language) }
                                                    tooltipDirection = 'right'
                                                    checked = { languageItem.show }
                                                    onChange = { showLanguage } />
                                    </div>
                                )
                            }) }
                        </div>
                    </div>
                </div>
            </div>
        </CabinetSection>
    )
}));

export { AgentLanguages };
