import React from 'react';

import { Container } from 'components/containers';

import './_Main.scss';

const Main = ({ children }) => (
    <main className = 'Main'>
        <Container>
            <div className = 'Main__wrap'>
                { children }
            </div>
        </Container>
    </main>
);

export { Main };