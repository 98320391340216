import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { WorkActions, WorkTeamAdvOther, WorkTeamMovieSerial, WorkTeamMusicGroup, WorkTeamItem } from 'components/pages';
import { CabinetContent, CabinetAlerts, NewExecutorModal, NewCabinetEntityModal, NewStudioModal } from 'components/containers';
import { Alert, Button } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, getExecutorName, getStudioName } from 'helpers';

import './_WorkTeam.scss';

const WorkTeam = inject('LanguageStore', 'WorksStore')(observer(({ LanguageStore, WorksStore }) => {
    const [newExecutorModalOpened, setNewExecutorModalOpened] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [searches, setSearches] = useState({});
    const [invitePersonKey, setInvitePersonKey] = useState(null);
    const [openedEntityModal, setOpenedEntityModal] = useState(false);
    const [newStudioModalOpened, setNewStuioModalOpened] = useState(false);

    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal } = WorksStore;
    
    // Отлов ввода в поле ввода
    const handleSelectInputChange = (key, debouncedFind, event) => {
        const input = event.target;
        const value = input.value;

        const [groupName, index] = key.split('-');

        setInputValues(prevInputValues => ({ ...prevInputValues, [key]: value }));
        debouncedFind(groupName, index, value.trim());
    };

    // Отлов клика по элементу выпадающего списка
    const handleSelectDropdownItemClick = (key, values, event) => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const [groupName, index] = key.split('-');

        const workTeamCopy = Object.clone(currentWork.team);
        const groupCopy = workTeamCopy[groupName].map(item => Object.clone(item));
        
        if (groupCopy[index].value !== (data.id, data.cabinet_id) && !(values[groupName].includes(data.id) || values[groupName].includes(data.cabinet_id))) {
            const validName = groupCopy[index].relationType === 'executor' ? getExecutorName(data, language) : getStudioName(data, language);

            groupCopy[index].value = data.id || data.cabinet_id;
            workTeamCopy[groupName] = groupCopy;

            updateCurrentWorkLocal({ team: workTeamCopy });
            setInputValues(prevInputValues => ({ ...prevInputValues, [key]: validName || data.email }));
        }
    };

    // Отлов изменения поля выбора
    const handleChooseBoxChange = (key, event) => {
        const checkbox = event.target;
        const checked = checkbox.checked;

        const [groupName, index] = key.split('-');

        const workTeamCopy = Object.clone(currentWork.team);
        const groupItemCopy = Object.clone(workTeamCopy[groupName][index]);

        groupItemCopy.show = checked;
        workTeamCopy[groupName][index] = groupItemCopy;

        updateCurrentWorkLocal({ team: workTeamCopy });
    };

    // Отлов клика по крестику
    const handleRemoveClick = key => {
        const [groupName, index] = key.split('-');

        const workTeamCopy = Object.clone(currentWork.team);
        const groupCopy = workTeamCopy[groupName];
        const nextKey = `${groupName}-${+index + 1}`;
        const nextInputValue = inputValues[nextKey] || undefined;
        
        if (groupCopy.length === 1) {
            groupCopy[index].value = null;
            groupCopy[index].show = false;
            groupCopy[index].object = {};
        } else {
            groupCopy.splice(index, 1);
        }
        
        setInputValues(prevInputValues => ({ ...prevInputValues, [key]: nextInputValue, [nextKey]: undefined }));
        updateCurrentWorkLocal({ team: workTeamCopy });
    };

    // Добавление элемента в группу
    const addGroupItem = groupName => {
        const workTeamCopy = Object.clone(currentWork.team);
        const groupCopy = workTeamCopy[groupName];
        const groupItemTemplate = Object.clone(groupCopy[groupCopy.length - 1]);

        groupItemTemplate.value = null;
        groupItemTemplate.show = false;
        groupItemTemplate.object = {};
        groupItemTemplate.canChange = true;

        groupCopy.push(groupItemTemplate);

        updateCurrentWorkLocal({ team: workTeamCopy });
    };

    // Подготовка элемента группы
    const prepareGroupItem = (group, item, name, label, debouncedFind, values) => {
        const index = group.indexOf(item);
        const key = `${name}-${index}`;
        
        Object.keys(values).forEach(name => {
            const items = values[name].map(item => typeof item === 'string' ? +item : item);
            values[name] = items;
        });      

        const currentItem = item.relationType === 'executor' ? getExecutorName(item.object, language) : getStudioName(item.object, language)
        const selectCurrentValue = inputValues[key] !== undefined ? inputValues[key] : currentItem;

        const selectActionButton = { 
            text: translate('Invite an executor', language),
            restProps: {
                onClick: () => {
                    setInvitePersonKey(key);
                    setNewExecutorModalOpened(true);
                }
            },
            subText: translate('You can invite a project participant if they are not in the list', language),
        };

        const selectCompanyActionButton = { 
            text: translate('Add a company', language),
            restProps: {
                onClick: () => {
                    setInvitePersonKey(key);
                    setOpenedEntityModal(true);
                }
            }
        };

        const selectStudioActionButton = { 
            text: translate('Invite an company', language),
            restProps: {
                onClick: () => {
                    setInvitePersonKey(key);
                    setNewStuioModalOpened(true);
                }
            },
            subText: translate('You can invite a project participant if they are not in the list', language),
        };

        const bindedDebouncedFind = debouncedFind.bind(null, setSearches);

        const preparedItems = searches[key] && [{ 
            id: 0, 
            items: searches[key].map(searchItem => {
                const validName = item.relationType === 'executor' ? getExecutorName(searchItem, language) : getStudioName(searchItem, language);
                
                return {
                    id: searchItem.cabinet_id || searchItem.id,
                    selected: values[name].includes(searchItem.cabinet_id) || values[name].includes(searchItem.id),
                    text: validName,
                    data: searchItem
                }
            })
        }];
        
        const preparedItemsSelectProps = {
            inputProps: {
                label: translate(label, language),
                value: selectCurrentValue
            },
            items: preparedItems,
            actionButton: (item.relationType === 'executor' && selectActionButton) || (item.relationType === 'company' && selectCompanyActionButton) || (item.relationType === 'studio' && selectStudioActionButton),
            onInputFocus: bindedDebouncedFind.bind(null, name, index, ''),
            onInputChange: handleSelectInputChange.bind(null, key, bindedDebouncedFind),
            onDropdownItemClick: handleSelectDropdownItemClick.bind(null, key, values)
        };

        return (
            <WorkTeamItem key = { key } 
                          id = { key } 
                          item = { item }
                          selectProps = { preparedItemsSelectProps }
                          canDelete = { group[0].value !== null  }
                          handleChooseBoxChange = { handleChooseBoxChange.bind(null, key) }
                          handleRemoveClick = { handleRemoveClick.bind(null, key) } />
        )
    }; 

    // Подготовка группы
    const prepareGroup = (group, name, inputLabel, debouncedFind, values) => (
        <div className = 'WorkTeam__group'>
            <div className = 'WorkTeam__groupTitle'>
                { group[0][`name-${language}`] || group[0]['name-ru-RU'] }
            </div>

            { group.map(item => {
                return prepareGroupItem(group, item, name, inputLabel, debouncedFind, values);
            }) }

            <Button content = {{ icon: 'plus-in-circle', text: translate('Add', language) }}
                    className = 'WorkTeam__groupAddButton'
                    pure = { true }
                    theme = 'grey'
                    disabled = { group[group.length - 1].value === null }
                    onClick = { addGroupItem.bind(null, name) } />
        </div>
    );

    // Выбор приглашенного исполнителя
    const chooseInvitedPerson = (key, cabinet) => {
        const [groupName, index] = key.split('-');

        const workTeamCopy = Object.clone(currentWork.team);
        const groupItem = Object.clone(workTeamCopy[groupName][index]);
        const name = cabinet.cabinetType === 'executor' ? getExecutorName(cabinet, language) : getStudioName(cabinet, language);

        groupItem.value = cabinet.cabinet_id;

        workTeamCopy[groupName][index] = groupItem;

        updateCurrentWorkLocal({ team: workTeamCopy });
        setInputValues(prevInputValues => ({ ...prevInputValues, [key]: name }));
        setSearches(prevSearches => ({ ...prevSearches, [key]: [{ cabinet_id: cabinet.cabinet_id, userObj: cabinet.userObj }] }));
    };

    // Добавление новой компании
    const addNewCompany = company => {
        const [groupName, index] = invitePersonKey.split('-');

        const workTeamCopy = Object.clone(currentWork.team);
        const groupItem = Object.clone(workTeamCopy[groupName][index]);

        groupItem.value = company.id;

        workTeamCopy[groupName][index] = groupItem;

        updateCurrentWorkLocal({ team: workTeamCopy });
        setInputValues(prevInputValues => ({ ...prevInputValues, [invitePersonKey]: company[`name-${language}`] || company[`name-ru-RU`] }));
        setOpenedEntityModal(false);
    };
    
    const firstContainer = ['commercialAdvertising', 'socialAdvertisement', 'fashionFilm', 'imageVideo', 'corporateMovie', 
                            'screensaverIdMovie', 'infoProductVideo', 'trainingVideo', 'videoAndInfographics', 'backstageShooting', 'showreel'].includes(currentWork.workType);

    const secondContainer = ['fullLengthArtMovie', 'shortArtMovie', 'fullLengthDocumentaryMovie', 'shortDocumentaryMovie', 'serialArt', 'serialDoc'].includes(currentWork.workType);
    const thirdContainer = ['musicVideo'].includes(currentWork.workType);

    const workTeamItemProps = { prepareGroup, addGroupItem, handleChooseBoxChange, handleRemoveClick };

    const containerToRender = (() => (
        (firstContainer && <WorkTeamAdvOther itemProps = { workTeamItemProps } />)     ||
        (secondContainer && <WorkTeamMovieSerial itemProps = { workTeamItemProps } />) ||
        (thirdContainer && <WorkTeamMusicGroup itemProps = { workTeamItemProps } />)
    ))();

    // Сброс ключа приглашенного исполнителя
    useEffect(() => {
        if (!newExecutorModalOpened) {
            setInvitePersonKey(null);
        }
    }, [newExecutorModalOpened])
    
    return (
        <div className = 'WorkTeam'>
            <CabinetContent>
                <CabinetAlerts className = 'WorkTeam__alerts'>
                    <Alert >
                        { translate('If possible, specify the team members and companies that you did this project with. In the appropriate section, you can add existing people to the site or invite new ones. For every 5 subscribers', language) }

                        &nbsp;
                        <Link to = '/month-free' className = 'Alert__textLink'>
                            <span className = 'Alert__text Alert__text--action'>
                                { translate('a free subscription month.', language) }
                            </span>
                        </Link>
                    </Alert>
                </CabinetAlerts>
                
                { containerToRender }

                <WorkActions />

                { newExecutorModalOpened && (
                    <NewExecutorModal place = 'team'
                                      title = 'Invite a new executor'
                                      modalOpened = { newExecutorModalOpened }
                                      setModalOpened = { setNewExecutorModalOpened }
                                      options = {{ teamAlias: invitePersonKey.split('-')[0], workId: currentWork.id }}
                                      onInvite = { chooseInvitedPerson.bind(null, invitePersonKey) } />
                ) }

                { newStudioModalOpened && (
                    <NewStudioModal place = 'team'
                                    title = 'Invite a new company'
                                    modalOpened = { newStudioModalOpened }
                                    setModalOpened = { setNewStuioModalOpened }
                                    options = {{ teamAlias: invitePersonKey.split('-')[0], workId: currentWork.id }}
                                    onInvite = { chooseInvitedPerson.bind(null, invitePersonKey) } />
                ) }

                { openedEntityModal && (
                    <NewCabinetEntityModal type = 'company'
                                           modalOpened = { openedEntityModal }
                                           setModalOpened = { setOpenedEntityModal }
                                           onAdd = { addNewCompany } />
                ) }
            </CabinetContent>
        </div>
    )
}));

export { WorkTeam };