import React from 'react';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_CabinetNav.scss';

const CabinetNav = inject('LanguageStore')(observer(({ items, updateItems, LanguageStore }) => {
    const { language } = LanguageStore;

    // Скролл до секции
    const scrollToSection = event => {
        const item = event.target.closest('.CabinetNav__listItem');

        if (item) {
            const value = item.dataset.value;
            
            const navItems = Object.clone(items);
            const activeNavItem = Object.keys(navItems).find(key => navItems[key].active === true);
            const navSection = document.querySelector(`#${value}`);

            if (navSection) {
                const CabinetNav = document.querySelector('.CabinetNav');
                const offset = 40;

                window.scroll({ top: `${navSection.offsetTop - CabinetNav.clientHeight + offset}`, behavior: 'smooth' });

                if (activeNavItem !== value) {
                    activeNavItem && (navItems[activeNavItem].active = false);
                    navItems[value].active = true;

                    updateItems(navItems);
                }
            }
        }
    };

    return (
        <nav className = 'CabinetNav'>
            <ul className = 'CabinetNav__list' onClick = { scrollToSection }>
                { Object.keys(items).map(key => {
                    const itemClass = classNames('CabinetNav__listItem', {
                        'CabinetNav__listItem--active': items[key].active
                    });

                    return (
                        <li key = { key } className = { itemClass } data-value = { key }>
                            { translate(items[key].translationKey.toTitleCase(), language) }
                        </li>
                    )
                }) }
            </ul>
        </nav>
    )
}));

export { CabinetNav };