// Хранилище с исполнителя агента

import { decorate, action, observable, runInAction } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class AgentExecutorsStore {
    executors = [];

    // Получение исполнителей агента
    async getExecutors() {
        if (this.root.stores.userStore.user.activeCabinet === 'agent') {
            const response = await request(`${BASE_URL}/api/user/cabinet/agent/executors`);
            const data = await response.json();

            if (response.status === 200) {
                runInAction(() => this.executors = data.executor);
            }
        }
    }

    // Обновление испонителя
    async updateExecutor(obj) {
        if (this.root.stores.userStore.user.activeCabinet === 'agent') {
            const response = await request(`${BASE_URL}/api/user/cabinet/agent/executors/attach`, { method: 'POST', body: JSON.stringify(obj) });
            const data = await response.json();

            if (response.status === 200) {
                runInAction(() => this.executors = data.executor);
            }
        }
    }

    // Обновление сортировки исполнителей
    async updateExecutorSort(obj) {
        if (this.root.stores.userStore.user.activeCabinet === 'agent') {
            const response = await request(`${BASE_URL}/api/user/cabinet/agent/executors/sort`, { method: 'POST', body: JSON.stringify(obj) });
            const data = await response.json();

            if (response.status === 200) {
                runInAction(() => this.executors = data.executor);
            }
        }
    }

    // Удаление исполнителя
    async removeExecutor(id) {
        if (this.root.stores.userStore.user.activeCabinet === 'agent') {
            const response = await request(`${BASE_URL}/api/user/cabinet/agent/executors/delete/${id}`, { method: 'DELETE' });
            const data = await response.json();

            if (response.status === 200) {
                runInAction(() => this.executors = data.executor);
            }
        }
    }
}

decorate(AgentExecutorsStore, {
    executors: observable,
    getExecutors: action.bound,
    updateExecutor: action.bound,
    updateExecutorSort: action.bound,
    removeExecutor: action.bound
});

export default new AgentExecutorsStore();