import React, { useState, Fragment } from 'react';

import { CabinetSection } from 'components/containers';
import { SelectPure, SelectMultiple, ChooseBox, Button, Tooltip, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

import './_WorkImagePropertyInfo.scss';

const WorkImagePropertyInfo = inject('LanguageStore', 'WorksStore')(observer(({ LanguageStore, WorksStore }) => {
    const [selectInputs, setSelectInputs] = useState({});

    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal } = WorksStore;

    const { imageProp } = currentWork;
    const { isNoSelect, isImax, is360, colorIsCb, colorIsNotCb, isHandCapture, frameFormat, lens, camera } = imageProp;

    const frameFormatCurrent = frameFormat.find(format => format.checked) || {};

    const frameFormatSelectInputProps = {
        value: selectInputs['frame-format'] !== undefined ? selectInputs['frame-format'] : frameFormatCurrent.name,
        name: 'frame-format'
    };

    // Удаление цвета
    const removeColor = event => {
        const crossIcon = event.currentTarget;
        const token = crossIcon.closest('.Token');
        const data = JSON.parse(token.dataset.data);

        const workImagePropCopy = Object.clone(imageProp);
        workImagePropCopy[data] = false;

        updateCurrentWorkLocal({ imageProp: workImagePropCopy });
    };

    const colorSelectInputProps = {
        value: selectInputs['color'],
        tokens: ['colorIsCb', 'colorIsNotCb'].filter(item => imageProp[item]).map((item, index) => ({
            id: index,
            text: item === 'colorIsCb' ? translate('Black-white', language) : translate('Colorful', language),
            data: item,
            canDelete: true,
            handlers: {
                handleRemoveIconClick: removeColor
            }
        }))
    };

    const filteredFrameFormats = frameFormat.filter(format => {
        const inputValue = selectInputs['frame-format'] || '';
        return format.name.toLowerCase().includes(inputValue.trim().toLowerCase());
    });

    const preparedColors = [{
        id: 0,
        items: [
            { id: 0, text: translate('Black-white', language), selected: colorIsCb, data: { type: 'colorIsCb', text: translate('Black-white', language) } },
            { id: 1, text: translate('Colorful', language), selected: colorIsNotCb, data:  { type: 'colorIsNotCb', text: translate('Color', language) } }
        ]
    }];

    const preparedFrameFormats = [{
        id: 0,
        items: filteredFrameFormats.map(format => ({
            id: format.id,
            text: format.name,
            selected: format.checked,
            data: format
        }))
    }];

    const lastCameraItem = camera[camera.length - 1];
    const isLastCameraItemChecked = lastCameraItem.cameraModels.some(model => model.checked) || lastCameraItem.cameraShooting.some(type => type.checked);

    const lastLensItem = lens[lens.length - 1].lensType;
    const isLastLensItemChecked = Boolean(lastLensItem.find(item => item.checked));

    const radioBoxes = {
        'additional': ['isNoSelect', 'isImax', 'is360']
    };

    // Отлов ввода в поле выбора
    const handleSelectInput = event => {
        const input = event.target;
        const value = input.value;
        const name = input.name;

        setSelectInputs(prevSelectInputs => ({ ...prevSelectInputs, [name]: value }));
    };

    // Отлов блюра в поле выбора
    const handleSelectBlur = (lensGroupIndex, event) => {
        const input = event.target;
        const name = input.name;

        const lens = imageProp.lens[0].lensType[lensGroupIndex].list.find(lens => lens.checked);

        setSelectInputs(prevSelectInputs => ({
            ...prevSelectInputs,
            [name]: lens ? lens.name : undefined
        }));
    };

    // Выбор формата кадра
    const chooseFrameFormat = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const workImagePropCopy = Object.clone(imageProp);

        const frameFormatCopy = Object.clone(frameFormat);
        const checkedFrameFormat = frameFormatCopy.find(format => format.checked);
        const newFrameFormat = frameFormatCopy.find(format => format.id === data.id);

        if ((checkedFrameFormat && checkedFrameFormat.id !== newFrameFormat.id) || !checkedFrameFormat) {
            checkedFrameFormat && (checkedFrameFormat.checked = false);
            newFrameFormat.checked = true;

            workImagePropCopy.frameFormat = frameFormatCopy;

            setSelectInputs(prevSelectInputs => ({ ...prevSelectInputs, 'frame-format': undefined }));
            updateCurrentWorkLocal({ imageProp: workImagePropCopy });
        }
    };

    // Выбор элемента камеры
    const chooseCameraItem = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const cameraIndex = data.cameraIndex;
        const cameraCategory = data.category;

        const workImagePropCopy = Object.clone(imageProp);
        const cameraGroup = workImagePropCopy.camera[cameraIndex];
        const cameraItem =  Object.clone(cameraGroup[cameraCategory]);

        const currentCameraItem = cameraItem.find(item => item.checked);
        const newCameraItem = cameraItem.find(item => item.id === data.id);

        if ((currentCameraItem && currentCameraItem.id !== newCameraItem.id) || !currentCameraItem) {
            if (newCameraItem && ['цифра фотоаппарат', 'цифра телефон'].includes(newCameraItem.name)) {
                const cameraModels = Object.clone(cameraGroup.cameraModels);

                cameraModels.forEach(model => model.checked = false);
                cameraGroup.cameraModels = cameraModels;

                setSelectInputs(prevSelectInputs => ({ ...prevSelectInputs, [`cameraModels-${cameraIndex}`]: undefined }));
            }

            currentCameraItem && (currentCameraItem.checked = false);
            newCameraItem.checked = true;

            cameraGroup[cameraCategory] = cameraItem;

            setSelectInputs(prevSelectInputs => ({ ...prevSelectInputs, [`${cameraCategory}-${cameraIndex}`]: undefined }));
            updateCurrentWorkLocal({ imageProp: workImagePropCopy });
        }
    };

    // Выбора типа объектива
    const chooseLensType = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const workImagePropCopy = Object.clone(imageProp);

        const lensGroupIndex = data.lensGroupIndex;
        const lensItem = Object.clone(workImagePropCopy.lens[lensGroupIndex].lensType);
        const lensIndex = lensItem.indexOf(lensItem.find(item => item.id === data.id));
        const lensCategory = data.category;

        const currentType = lensItem.find(item => item.checked);
        const newType = lensItem[lensIndex];

        if ((currentType && currentType.id !== newType.id) || !currentType) {
            if (currentType) {
                currentType.checked = false;
                currentType.list.forEach(item => item.checked = false);
            }

            newType.checked = true;

            workImagePropCopy.lens[lensGroupIndex].lensType = lensItem;

            setSelectInputs(prevSelectInputs => ({ ...prevSelectInputs, [`${lensCategory}-${lensGroupIndex}`]: undefined }));
            updateCurrentWorkLocal({ imageProp: workImagePropCopy });
        }
    };

    // Выбора названия объектива
    const chooseLensName = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const workImagePropCopy = Object.clone(imageProp);

        const lensGroupIndex = data.lensGroupIndex;
        const lensItem = Object.clone(workImagePropCopy.lens[lensGroupIndex].lensType);
        const lensIndex = lensItem.indexOf(lensItem.find(item => item.id === data.lensItemId));
        const lensCategory = data.category;
        const lensListItemIndex = data.lensListItemIndex;
        const lensList = lensItem[lensIndex].list;

        const currentListItem = lensList.find(item => item.checked);
        const newListItem = lensList[lensListItemIndex];

        if ((currentListItem && currentListItem.id !== newListItem.id) || !currentListItem) {
            currentListItem && (currentListItem.checked = false);
            newListItem.checked = true;

            workImagePropCopy.lens[lensGroupIndex].lensType[lensIndex].list = lensList;

            setSelectInputs(prevSelectInputs => ({ ...prevSelectInputs, [`${lensCategory}-${lensGroupIndex}`]: undefined }));
            updateCurrentWorkLocal({ imageProp: workImagePropCopy });
        }
    };

    // Выбор checkbox
    const chooseCheckedBox = event => {
        const checkBox = event.target;
        const checked = checkBox.checked;
        const name = checkBox.name;

        const workImagePropCopy = Object.clone(imageProp);
        workImagePropCopy[name] = checked;

        updateCurrentWorkLocal({ imageProp: workImagePropCopy });
    };

    // Выбор радио
    const chooseRadio = event => {
        const radio = event.target;
        const name = radio.name;
        const value = radio.value;

        const otherRadios = radioBoxes[name].filter(radioBox => radioBox !== value);
        const workImagePropCopy = Object.clone(imageProp);
        workImagePropCopy[value] = true;

        otherRadios.forEach(otherRadio => workImagePropCopy[otherRadio] = false);

        updateCurrentWorkLocal({ imageProp: workImagePropCopy });
    };

    // Добавление камеры
    const addCamera = () => {
        const cameraTemplate = Object.clone(camera[camera.length - 1]);
        const cameraShootingCopy = Object.clone(cameraTemplate.cameraShooting);
        const cameraModelsCopy = Object.clone(cameraTemplate.cameraModels);

        cameraShootingCopy.forEach(type => type.checked = false);
        cameraModelsCopy.forEach(model => model.checked = false);

        cameraTemplate.cameraShooting = cameraShootingCopy;
        cameraTemplate.cameraModels = cameraModelsCopy;
        cameraTemplate.fake = true;

        const workImagePropCopy = Object.clone(imageProp);
        workImagePropCopy.camera.push(cameraTemplate);

        updateCurrentWorkLocal({ imageProp: workImagePropCopy });
    };

    // Добавление объектива
    const addLens = () => {
        const lensTemplate = Object.clone(lens[lens.length - 1]);
        const lensTypes = Object.clone(lensTemplate.lensType);

        lensTypes.forEach(type => {
            const listCopy = Object.clone(type.list);
            listCopy.forEach(item => item.checked = false);

            type.checked = false;
            type.list = listCopy;
        });

        lensTemplate.lensType = lensTypes;

        const workImagePropCopy = Object.clone(imageProp);
        workImagePropCopy.lens.push(lensTemplate);

        updateCurrentWorkLocal({ imageProp: workImagePropCopy });
    };

    // Удаление камеры
    const removeCamera = event => {
        const removeWrap = event.currentTarget;
        const cameraIndex = JSON.parse(removeWrap.dataset.cameraIndex);

        const workImagePropCopy = Object.clone(imageProp);
        const cameraCopy = Object.clone(workImagePropCopy.camera);

        cameraCopy.splice(cameraIndex, 1);
        workImagePropCopy.camera = cameraCopy;

        updateCurrentWorkLocal({ imageProp: workImagePropCopy });
    };

    // Удаление объектива
    const removeLens = event => {
        const removeWrap = event.currentTarget;
        const lensGroupIndex = JSON.parse(removeWrap.dataset.lensGroupIndex);

        const workImagePropCopy = Object.clone(imageProp);
        const lensCopy = Object.clone(workImagePropCopy.lens);

        lensCopy.splice(lensGroupIndex, 1);
        workImagePropCopy.lens = lensCopy;

        updateCurrentWorkLocal({ imageProp: workImagePropCopy });
    };

    // Выбор цвета
    const chooseColor = event => {
        const item = event.currentTarget;
        const data = JSON.parse(item.dataset.data);

        const workImagePropCopy = Object.clone(imageProp);

        workImagePropCopy[data.type] = true;

        updateCurrentWorkLocal({ imageProp: workImagePropCopy });
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Image property', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'WorkImagePropertyInfo'>
                    <div className = 'WorkImagePropertyInfo__section WorkImagePropertyInfo__section--sm'>
                        <span className = 'WorkImagePropertyInfo__sectionTitle'>
                            { translate('Frame format', language) }
                        </span>

                        <div className = 'WorkImagePropertyInfo__sectionContent'>
                            <SelectPure inputProps = { frameFormatSelectInputProps }
                                        items = { preparedFrameFormats }
                                        onInputChange = { handleSelectInput }
                                        onDropdownItemClick = { chooseFrameFormat } />
                        </div>
                    </div>

                    <div className = 'WorkImagePropertyInfo__section WorkImagePropertyInfo__section--sm' style = {{ marginLeft: '8px' }}>
                        <span className = 'WorkImagePropertyInfo__sectionTitle'>
                            { translate('Color', language) }
                        </span>

                        <div className = 'WorkImagePropertyInfo__sectionContent'>
                            <SelectMultiple inputProps = { colorSelectInputProps }
                                            items = { preparedColors }
                                            onDropdownItemClick = { chooseColor } />
                        </div>
                    </div>

                    <div className = 'WorkImagePropertyInfo__section'>
                        <span className = 'WorkImagePropertyInfo__sectionTitle'>{ translate('Shooting', language) }</span>

                        <div className = 'WorkImagePropertyInfo__sectionContent'>
                            { camera.map(cameraItem => {
                                const cameraIndex = String(camera.indexOf(cameraItem));

                                const types = cameraItem.cameraShooting;
                                const models = cameraItem.cameraModels;

                                const currentType = types.find(type => type.checked) || {};
                                const currentModel = models.find(model => model.checked) || {};

                                const typeInputName = `cameraShooting-${cameraIndex}`;
                                const modelInputName = `cameraModels-${cameraIndex}`;

                                const typeSelectInputProps = {
                                    value: selectInputs[typeInputName] !== undefined ? selectInputs[typeInputName] : currentType[`name-${language}`],
                                    name: typeInputName
                                };

                                const modelSelectInputProps = {
                                    label: translate('Camera model', language),
                                    value: selectInputs[modelInputName] !== undefined ? selectInputs[modelInputName] : currentModel[`name-${language}`],
                                    name: modelInputName
                                };

                                const filteredTypes = types.filter(type => {
                                    const inputValue = selectInputs[typeInputName] || '';
                                    return type.name.toLowerCase().includes(inputValue.trim().toLowerCase());
                                });

                                const filteredModels = models.filter(model => {
                                    const inputValue = selectInputs[modelInputName] || '';
                                    return model.name.toLowerCase().includes(inputValue.trim().toLowerCase());
                                });

                                const preparedTypes = [{
                                    id: 0,
                                    items: filteredTypes.map(type => ({
                                        id: type.id,
                                        text: type[`name-${language}`] || type.name,
                                        selected: type.checked,
                                        data: { ...type, cameraIndex: cameraIndex, category: 'cameraShooting' }
                                    }))
                                }];

                                const preparedModels = [{
                                    id: 0,
                                    items: filteredModels.map(model => ({
                                        id: model.id,
                                        text: model[`name-${language}`] || model.name,
                                        selected: model.checked,
                                        data: { ...model, cameraIndex: cameraIndex, category: 'cameraModels' }
                                    }))
                                }];

                                return (
                                    <Fragment key = { cameraIndex }>
                                        <SelectPure inputProps = { typeSelectInputProps }
                                                    items = { preparedTypes }
                                                    onInputChange = { handleSelectInput }
                                                    onDropdownItemClick = { chooseCameraItem } />

                                        <span className = 'WorkImagePropertyInfo__sectionContentSeparate'></span>

                                        <SelectPure inputProps = { modelSelectInputProps }
                                                    items = { preparedModels }
                                                    disabled = { currentType && ['цифра фотоаппарат', 'цифра телефон'].includes(currentType.name) }
                                                    onInputChange = { handleSelectInput }
                                                    onDropdownItemClick = { chooseCameraItem } />

                                        { camera.length <= 1 ? (
                                            <span className = 'WorkImagePropertyInfo__sectionContentCross'></span>
                                        ) : (
                                            <Tooltip text = { translate('Remove' , language) } direction = 'down' position = 'left'>
                                                <div className = 'WorkImagePropertyInfo__removeWrap' data-camera-index = { cameraIndex } onClick = { removeCamera }>
                                                    <Icon name = 'cross-tiny' className = 'WorkImagePropertyInfo__remove' />
                                                </div>
                                            </Tooltip>
                                        ) }
                                    </Fragment>
                                )
                            }) }
                        </div>

                        <Button content = {{ icon: 'plus-in-circle', text: translate('Add', language) }}
                                className = 'WorkImagePropertyInfo__sectionContentButton'
                                theme = 'grey'
                                pure = { true }
                                disabled = { !isLastCameraItemChecked }
                                onClick = { addCamera } />
                    </div>

                    <div className = 'WorkImagePropertyInfo__section'>
                        <span className = 'WorkImagePropertyInfo__sectionTitle'>{ translate('Lens', language) }</span>

                        <div className = 'WorkImagePropertyInfo__sectionContent'>
                            { lens.map(len => {
                                const lensGroupIndex = lens.indexOf(len);
                                const lensItem = len.lensType;

                                const currentType = lensItem.find(type => type.checked) || {};
                                const currentNameList = currentType.list || [];
                                const currentName = currentNameList.find(item => item.checked) || {};

                                const typeInputName = `lensType-${lensGroupIndex}`;
                                const nameInputName = `lensName-${lensGroupIndex}`;

                                const typeSelectInputProps = {
                                    value: selectInputs[typeInputName] !== undefined
                                        ? selectInputs[typeInputName]
                                        : currentType[`name-${language}`] || currentType.name,
                                    name: typeInputName
                                };

                                const nameSelectInputProps = {
                                    label: translate('Name', language),
                                    value: selectInputs[nameInputName] !== undefined ? selectInputs[nameInputName] : currentName.name,
                                    name: nameInputName
                                };

                                const filteredTypes = lensItem.filter(type => {
                                    const inputValue = selectInputs[typeInputName] || '';
                                    return type.name.toLowerCase().includes(inputValue.trim().toLowerCase());
                                });

                                const filteredNames = currentNameList.filter(item => {
                                    const inputValue = selectInputs[nameInputName] || '';
                                    return item.name.toLowerCase().includes(inputValue.trim().toLowerCase());
                                });

                                const preparedTypes = [{
                                    id: 0,
                                    items: filteredTypes.map(type => ({
                                        id: type.id,
                                        text: type[`name-${language}`] || type.name,
                                        selected: type.checked,
                                        data: { ...type, lensGroupIndex: lensGroupIndex, category: 'lensType' }
                                    }))
                                }];

                                const preparedNames = [{
                                    id: 0,
                                    items: filteredNames.map((item, index) => ({
                                        id: item.id,
                                        text: item[`name-${language}`] || item.name,
                                        selected: item.checked,
                                        data: { ...item, lensGroupIndex: lensGroupIndex, lensListItemIndex: index, lensItemId: currentType.id, category: 'lensName' }
                                    }))
                                }];

                                return (
                                    <Fragment key = { lensGroupIndex }>
                                        <SelectPure inputProps = { typeSelectInputProps }
                                                    items = { preparedTypes }
                                                    onInputChange = { handleSelectInput }
                                                    onDropdownItemClick = { chooseLensType } />

                                        <Icon name = 'arrow-long-big' />

                                        <SelectPure inputProps = { nameSelectInputProps }
                                                    items = { preparedNames }
                                                    disabled = { !currentType.checked }
                                                    onInputChange = { handleSelectInput }
                                                    onInputBlur = { handleSelectBlur.bind(null, lensGroupIndex) }
                                                    onDropdownItemClick = { chooseLensName } />

                                        { lens.length <= 1 ? (
                                            <span className = 'WorkImagePropertyInfo__sectionContentCross'></span>
                                        ) : (
                                            <Tooltip text = { translate('Remove' , language) } direction = 'down' position = 'left'>
                                                <div className = 'WorkImagePropertyInfo__removeWrap' data-lens-group-index = { lensGroupIndex } onClick = { removeLens }>
                                                    <Icon name = 'cross-tiny' className = 'WorkImagePropertyInfo__remove' />
                                                </div>
                                            </Tooltip>
                                        ) }
                                    </Fragment>
                                )
                            }) }
                        </div>

                        <Button content = {{ icon: 'plus-in-circle', text: translate('Add', language) }}
                                className = 'WorkImagePropertyInfo__sectionContentButton'
                                theme = 'grey'
                                pure = { true }
                                disabled = { !isLastLensItemChecked }
                                onClick = { addLens } />
                    </div>

                    <div className = 'WorkImagePropertyInfo__section WorkImagePropertyInfo__section--with-choose-box'>
                        <span className = 'WorkImagePropertyInfo__sectionTitle'>{ translate('Additional', language) }</span>

                        <div className = 'WorkImagePropertyInfo__sectionContent'>
                            <ChooseBox type = 'radio'
                                       name = 'additional'
                                       value = 'isNoSelect'
                                       text = { translate('Not selected', language) }
                                       checked = { isNoSelect }
                                       onChange = { chooseRadio } />

                            <ChooseBox type = 'radio'
                                       name = 'additional'
                                       value = 'isImax'
                                       text = { translate('For IMAX', language) }
                                       checked = { isImax }
                                       onChange = { chooseRadio } />

                            <ChooseBox type = 'radio'
                                       name = 'additional'
                                       value = 'is360'
                                       text = '360'
                                       checked = { is360 }
                                       onChange = { chooseRadio } />

                            <ChooseBox name = 'isHandCapture'
                                       text = { translate('Handheld camera', language) }
                                       checked = { isHandCapture }
                                       onChange = { chooseCheckedBox } />
                        </div>
                    </div>
                </div>
            </div>
        </CabinetSection>
    )
}));

export { WorkImagePropertyInfo };
