import React from 'react';

import './_Footer.scss';

const Footer = () => (
    <footer className = 'Footer'>
        <span className = 'Footer__copyright'>
            © { new Date().getFullYear() } «Radar.film»
        </span>

        <a href = 'https://gc.moscow/' className = 'Footer__goldcarrotLink' target = '_blank' rel = 'noopener noreferrer'>
            <span className = 'Footer__goldcarrot'>by Gold Carrot</span>
        </a>
    </footer>
);

export { Footer };