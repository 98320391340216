import React from 'react';

import { RequestsListItem } from 'components/pages';

import './_RequestsList.scss';

const RequestsList = ({ requests }) => (
    <div className = 'RequestsList'>
        { requests.reverse().map(request => (
            <RequestsListItem key = { request.id } request = { request } />
        )) }
    </div>
);

export { RequestsList };