import React, { useEffect } from 'react';

import { ChooseBox, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, getImageFullPath } from 'helpers';
import Sortable from 'sortablejs';

import './_TabsListsClients.scss';

const TabsListsClients = inject('LanguageStore', 'TabsStore')(observer(({ items, LanguageStore, TabsStore }) => {
    const { language } = LanguageStore;
    const { changeTabShow, changeItemsSort } = TabsStore;

    // Окончание перетаскивания
    function handleDragEnd({ newIndex, oldIndex }) {
        if (newIndex !== oldIndex) {
            const itemsIds = [];
            const clientCards = [...document.querySelectorAll('.TabsListsClients__item')];

            items.forEach(item => {
                const card = clientCards.find(card => +card.dataset.id === item.id);
                const cardIndex = clientCards.indexOf(card);

                itemsIds[cardIndex] = item.id;                
            });

            changeItemsSort('clients', itemsIds);
        }
    }

    // Добавление dnd карточкам
    useEffect(() => {
        const list = document.querySelector('.TabsListsClients');

        if (list) {
            const sortable = new Sortable(list, { 
                animation: 150, 
                handle: '.TabsListsClients__itemDrag',
                onEnd: handleDragEnd
            });
    
            return () => {
                sortable.destroy();
            }
        }
    }, []);
   
    return items.length > 0 ? (
        <div className = 'TabsListsClients'>
            { items.map(item => (
                <div key = { item.id } data-id = { item.id } className = 'TabsListsClients__item'>
                    <img className = 'TabsListsClients__itemLogo' src = { getImageFullPath(item.logo) } alt = 'Логотип' />

                    <div className = 'TabsListsClients__itemOptions'>
                        <ChooseBox text = { translate('Show', language) }
                                   checked = { item.show }
                                   onChange = { changeTabShow.bind(null, { name: 'clients', equalFunc: client => client.id === item.id, id: item.id }) } />
                        
                        <div className = 'TabsListsClients__itemDragWrap'>
                            <Icon name = 'drag-points' className = 'TabsListsClients__itemDrag' />

                            <span className = 'TabsListsClients__itemDragText'>
                                { translate('Drag', language) }
                            </span>
                        </div>
                    </div>
                </div>
            )) }
        </div>
    ) : (
        <div className = 'TabsLists__empty'>
            { translate('You have no clients', language) }
        </div>
    )
}));

export { TabsListsClients };