// Хранилище с жанрами(кино, сериал и муз.клип)

import { decorate, action, observable, runInAction } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class GenresStore {
    movieGenres = []
    musicGenres = []

    // Поиск жанра для кино и сериала
    async findMovieGenre(name) {
        const response = await request(`${BASE_URL}/api/genres/find-genres-movie`, { method: 'POST', body: JSON.stringify({ name }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.movieGenres = data.result);
        }
    }

    // Поиск жанра для музыки
    async findMusicGenre(name) {
        const response = await request(`${BASE_URL}/api/genres/find-genres-music`, { method: 'POST', body: JSON.stringify({ name }) });
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.musicGenres = data.result);
        }
    }
}

decorate(GenresStore, {
    movieGenres: observable,
    musicGenres: observable,
    findMovieGenre: action.bound,
    findMusicGenre: action.bound
});

export default new GenresStore();