// Получение валидного имени для агента

export const getAgentName = (agent, language) => {
    const currentLanguageVersion = [agent[`name-${language}`], agent[`surname-${language}`]];

    if (currentLanguageVersion.every(item => !item)) {
        return agent.userObj.email
    }

    return currentLanguageVersion.filter(item => item !== null).join(' ');
};