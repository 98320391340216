// Переводы текстов на сайте
const translations = {
    'Executor': {
        'ru-RU': 'Специалист',
        'en-US': 'Crew'
    },

    'Agent': {
        'ru-RU': 'Агент',
        'en-US': 'Agent'
    },

    'Filmschool': {
        'ru-RU': 'Киношкола',
        'en-US': 'Film school'
    },

    'Client': {
        'ru-RU': 'Клиент',
        'en-US': 'Client'
    },

    'When paying for a year': {
        'ru-RU': 'При оплате за год',
        'en-US': 'When paying for a year'
    },

    'When paying for a month': {
        'ru-RU': 'При оплате за месяц',
        'en-US': 'When paying for a month'
    },

    'Among the works, you must have 1 full-length film or 10 commercial commercials, or 10 music videos': {
        'ru-RU': 'Среди работ необходимо иметь 1 полнометражный фильм или 10 коммерческих рекламных роликов, или 10 музыкальных клипов',
        'en-US': 'Among the projects, you must have 1 full-length film or 10 commercial commercials, or 10 music videos'
    },

    'To increase the number of packets you need to pay the difference': {
        'ru-RU': 'Для увеличения количества пакетов требуется доплатить разницу',
        'en-US': 'To increase the number of packets you need to pay the difference'
    },

    'Display on the site and in the search for the first 50 days — 1 ruble.': {
        'ru-RU': 'Отображение на сайте и в поиске первые 50 дней — 1 рубль.',
        'en-US': 'Display on the radar and in search result is RUB 1 for 50-days trial.'
    },

    'To work properly, you need to add and pay for at least one specialty': {
        'ru-RU': 'Для полноценной работы необходимо добавить и оплатить хотя бы одну специальность',
        'en-US': 'For best experience, please add and pay for at least one specialty'
    },

    'To work properly, you need to add and pay for at least one direction': {
        'ru-RU': 'Для полноценной работы необходимо добавить и оплатить хотя бы одно направление',
        'en-US': 'To work properly, you need to add and pay for at least one direction'
    },

    'Studio': {
        'ru-RU': 'Компания',
        'en-US': 'Company'
    },

    'If you disable the display, this festival will only be displayed on the page of this work. This festival will not be displayed in the Rewards tabs on the main page of Your profile.': {
        'ru-RU': 'При отключении отображения этот фестиваль будет показываться только на странице этой работы. В закладках Награды на заглавной странице Вашего профиле этот фестиваль отображаться не будет.',
        'en-US': 'If you disable the display, this festival will only be displayed on the page of this project. This festival will not be displayed in the Rewards tabs on the main page of Your profile.'
    },

    'Activate': {
        'ru-RU': 'Активировать',
        'en-US': 'Activate'
    },

    'Account': {
        'ru-RU': 'Личные данные',
        'en-US': 'Account'
    },

    'Search': {
        'ru-RU': 'Поиск',
        'en-US': 'Search'
    },

    'New account': {
        'ru-RU': 'Новый аккаунт',
        'en-US': 'New profile type'
    },

    'Sign out': {
        'ru-RU': 'Выйти',
        'en-US': 'Logout'
    },

    'Add work': {
        'ru-RU': 'Добавить работу',
        'en-US': 'Add project'
    },

    'Add section': {
        'ru-RU': 'Добавить раздел',
        'en-US': 'Add folder'
    },

    'See results': {
        'ru-RU': 'Посмотреть результаты',
        'en-US': 'See results'
    },

    'Menu': {
        'ru-RU': 'Меню',
        'en-US': 'Menu'
    },

    'Quick': {
        'ru-RU': 'Быстрый',
        'en-US': 'Easy'
    },

    'Advanced': {
        'ru-RU': 'Подробный',
        'en-US': 'Advanced'
    },

    'Professional': {
        'ru-RU': 'Профессиональный',
        'en-US': 'Pro'
    },

    'Add / check works': {
        'ru-RU': 'Добавим работы за вас',
        'en-US': 'Add my projects'
    },

    'Pick a team / staff': {
        'ru-RU': 'Подобрать команду / персонал',
        'en-US': 'Find a team'
    },

    'Search through detailed filters': {
        'ru-RU': 'Искать через подробные фильтры',
        'en-US': 'Search through detailed filters'
    },

    'Personal data': {
        'ru-RU': 'Личные данные',
        'en-US': 'Personal data'
    },

    'Works': {
        'ru-RU': 'Работы',
        'en-US': 'Projects'
    },

    'Statistics': {
        'ru-RU': 'Статистика',
        'en-US': 'Statistics'
    },

    'Tabs': {
        'ru-RU': 'Вкладки',
        'en-US': 'Tabs'
    },

    'Searches': {
        'ru-RU': 'Поиски',
        'en-US': 'Search results'
    },

    'Requests': {
        'ru-RU': 'Запросы',
        'en-US': 'Personal requests'
    },

    'Payment': {
        'ru-RU': 'Оплата',
        'en-US': 'Billing'
    },

    'Contractors': {
        'ru-RU': 'Исполнители',
        'en-US': 'Contractors'
    },

    'Graduates': {
        'ru-RU': 'Выпускники',
        'en-US': 'Graduates'
    },

    'Cost': {
        'ru-RU': 'Стоимость',
        'en-US': 'Cost'
    },

    'Display all directions in profile': {
        'ru-RU': 'Отображать все направления в профиле',
        'en-US': 'Display all directions in profile'
    },

    'If you disable the setting, Your profile will only display the direction that you will be found in the search': {
        'ru-RU': 'При отключении настройки, в Вашем профиле будет отображаться лишь то направление, через которое Вас найдут при поиске',
        'en-US': 'If you disable the setting, Your profile will only display the direction that you will be found in the search'
    },

    'You can add a graduate who has received a diploma no later than two years by this time': {
        'ru-RU': 'Вы можете добавить выпускника, получившего диплом не позднее двух лет к этому времени',
        'en-US': 'You can add a graduate who has received a diploma no later than two years by this time'
    },

    'Company name in English': {
        'ru-RU': 'Название компании на английском',
        'en-US': 'Company name in English'
    },

    'The website of the guild': {
        'ru-RU': 'Сайт гильдии',
        'en-US': 'The website of the guild'
    },

    'Add an new educational institution': {
        'ru-RU': 'Добавить новое учебное заведение',
        'en-US': 'Add new college'
    },

    'Company site': {
        'ru-RU': 'Сайт компании',
        'en-US': 'Company site'
    },

    'The website of the educational institution': {
        'ru-RU': 'Сайт учебного заведения',
        'en-US': 'The website of the educational institution'
    },

    'By subscribing, you can add graduates to your school': {
        'ru-RU': 'Оформив подписку, вы можете добавлять выпусников в школу',
        'en-US': 'By subscribing, you can add graduates to your school'
    },

    'Name of the educational institution in English': {
        'ru-RU': 'Название учебного заведения на английском',
        'en-US': 'Name of the educational institution in English'
    },

    'Company name': {
        'ru-RU': 'Название компании',
        'en-US': 'Company name'
    },

    'Name of the guild in English': {
        'ru-RU': 'Название гильдии на английском',
        'en-US': 'Name of the guild in English'
    },

    'Company name (for English version)': {
        'ru-RU': 'Company name (для Английской версии)',
        'en-US': 'Company name (for English version)'
    },

    'Graduate': {
        'ru-RU': 'Выпускник',
        'en-US': 'Graduate'
    },

    'Invite a new graduate': {
        'ru-RU': 'Пригласить нового выпускника',
        'en-US': 'Invite a new graduate'
    },

    'Discounts for friends': {
        'ru-RU': 'Скидки за друзей',
        'en-US': 'Discounts for friends'
    },

    'Month free': {
        'ru-RU': 'Месяц бесплатно',
        'en-US': 'Free month'
    },

    'Settings': {
        'ru-RU': 'Настройки',
        'en-US': 'Settings'
    },

    'General': {
        'ru-RU': 'Основное',
        'en-US': 'General'
    },
    'Main': {
        'ru-RU': 'Основные',
        'en-US': 'Main',
    },

    'General2': {
        'ru-RU': 'Основные',
        'en-US': 'General'
    },

    'Contacts': {
        'ru-RU': 'Контакты',
        'en-US': 'Contacts'
    },

    'Locations': {
        'ru-RU': 'Местоположение',
        'en-US': 'Location'
    },

    'LocationsLighting': {
        'ru-RU': 'На локации',
        'en-US': 'On location'
    },

    'For invitation': {
        'ru-RU': 'Для приглашения',
        'en-US': 'For invitation'
    },

    'Education': {
        'ru-RU': 'Образование',
        'en-US': 'Education'
    },

    'Languages': {
        'ru-RU': 'Языки',
        'en-US': 'Languages'
    },

    'Guilds/unionists': {
        'ru-RU': 'Гильдии/профсоюзы',
        'en-US': 'Guilds/unions'
    },

    'Guilds and unionists': {
        'ru-RU': 'Гильдии и профсоюзы',
        'en-US': 'Guilds and professional unions'
    },

    'Extra info': {
        'ru-RU': 'О себе',
        'en-US': 'About me'
    },

    'Social networks': {
        'ru-RU': 'Соцсети',
        'en-US': 'Socials'
    },

    'Filmography': {
        'ru-RU': 'Фильмография',
        'en-US': 'Filmography'
    },

    'Agents': {
        'ru-RU': 'Агенты',
        'en-US': 'Agents'
    },

    'Summary': {
        'ru-RU': 'Резюме',
        'en-US': 'CV'
    },

    'Result': {
        'ru-RU': 'Результат',
        'en-US': 'Result'
    },

    'To display on the site you need to pay for the profession. The first three months — 1 ruble': {
        'ru-RU': 'Для отображения на сайте и в результатах поиска необходимо оплатить специальность. Первые 50 дней — 1 рубль',
        'en-US': 'To be displayed on the site and in search results, you must pay for the specialty. First 50 days — 1 ruble'
    },

    'If you disable the settings, Your profile will display only the profession through which You will be found in the search': {
        'ru-RU': 'При отключении настройки, в Вашем профиле будет отображаться лишь та профессия, через которую Вас найдут при поиске',
        'en-US': 'When this is disabled, only the profession used to find you in the search will be displayed'
    },

    'Classification "Pro" and "New blood" are confirmed by the site administration after adding works': {
        'ru-RU': 'Классификация «Pro» и «New blood» подтверждаются администрацией сайта после добавления работ',
        'en-US': 'Classification "Pro" and "New blood" are confirmed by the site administration after adding projects'
    },

    'An error occurred and we did not find such a page.': {
        'ru-RU': 'Произошла ошибка, и мы не нашли такую страницу.',
        'en-US': 'An error occurred and we did not find such a page.'
    },

    'Back to home': {
        'ru-RU': 'Вернуться на главную',
        'en-US': 'Back to home'
    },

    'Specialties': {
        'ru-RU': 'Специальность',
        'en-US': 'Specialty'
    },

    'Major': {
        'ru-RU': 'Специальность',
        'en-US': 'Major'
    },

    'Change photo': {
        'ru-RU': 'Изменить фото',
        'en-US': 'Change photo'
    },

    'Change preview': {
        'ru-RU': 'Изменить превью',
        'en-US': 'Change thumbnail'
    },

    'Image': {
        'ru-RU': 'Изображение',
        'en-US': 'Image'
    },

    'Cancel': {
        'ru-RU': 'Отмена',
        'en-US': 'Cancel'
    },

    'Upload': {
        'ru-RU': 'Загрузить',
        'en-US': 'Upload'
    },

    'Display all specialties in profile together': {
        'ru-RU': 'Отображать все специальности в профиле',
        'en-US': 'Display all specialties in profile'
    },

    '30% discount for the first month': {
        'ru-RU': '30% скидка за первый месяц',
        'en-US': '30% discount for the first month'
    },

    'Discount on additional speciality': {
        'ru-RU': 'Cкидка на дополнительную специальность',
        'en-US': 'Discount on additional speciality'
    },

    'Discount on speciality': {
        'ru-RU': 'скидка на специальность',
        'en-US': 'discount on speciality'
    },

    'Discount on additional direction': {
        'ru-RU': 'скидка на дополнительное направление',
        'en-US': 'discount for an additional area'
    },
    // lunar616
    'Discount on direction': {
        'ru-RU': 'Скидка на направление',
        'en-US': 'Discount on direction'
    },

    'Cards': {
        'ru-RU': 'Карты',
        'en-US': 'Cards'
    },

    'Name': {
        'ru-RU': 'Название',
        'en-US': 'Name'
    },

    'Active': {
        'ru-RU': 'Активна',
        'en-US': 'Active'
    },

    'Make it active': {
        'ru-RU': 'Сделать активной',
        'en-US': 'Make active'
    },

    'Not active': {
        'ru-RU': 'Не активна',
        'en-US': 'Not active'
    },

    'There are no linked cards.': {
        'ru-RU': 'Привязанные карты отсутствуют.',
        'en-US': 'No cards added.'
    },

    'Working since': {
        'ru-RU': 'Работаю с',
        'en-US': 'I work from'
    },

    'Classification': {
        'ru-RU': 'Классификация',
        'en-US': 'Classification'
    },

    'Status': {
        'ru-RU': 'Статус',
        'en-US': 'Status'
    },

    'Registered': {
        'ru-RU': 'Зарегистрирован',
        'en-US': 'Registered'
    },

    "You haven't invited your friends yet": {
        'ru-RU': 'Вы еще не приглашали друзей',
        'en-US': "You have no friends invited yet"
    },

    'Not confirmed': {
        'ru-RU': 'Не подтвержден',
        'en-US': 'Not confirmed'
    },

    'Invitation sent': {
        'ru-RU': 'Приглашение отправлено',
        'en-US': 'Invitation sent'
    },

    'Invited friend': {
        'ru-RU': 'Пригласить друга',
        'en-US': 'Invited friend'
    },

    'Your friend will receive a welcome email with a link for registration.': {
        'ru-RU': 'Вашему другу будет выслано приветственное письмо с ссылкой на регистрацию.',
        'en-US': 'Your friend will receive an email with a registration link.'
    },

    'This user has already been invited': {
        'ru-RU': 'Данный пользователь уже приглашен',
        'en-US': 'This user has already been invited'
    },

    'For the next promo code it remains to invite': {
        'ru-RU': 'Для следующего промокода осталось пригласить',
        'en-US': 'To get the next code, invite'
    },

    'Invite again': {
        'ru-RU': 'Пригласить повторно',
        'en-US': 'Invite again'
    },

    'Your occupation with the classification "pro", "newblood" or "graduate" requires confirmation': {
        'ru-RU': 'ваша профессия с классификацией "pro", "newblood" или "выпускник" требует подтверждения',
        'en-US': 'your Pro, Newblood, or Graduate classification needs confirmation'
    },

    'Film and video industry employee': {
        'ru-RU': 'работник кино и видеоиндустрии',
        'en-US': 'Film and video production specialist'
    },

    'Various experience in film and video projects': {
        'ru-RU': 'Различный опыт в кино и видеопроектах',
        'en-US': 'Various experience in film and video projects'
    },

    '10 videos, clips, or 1 full-length movie': {
        'ru-RU': '10 рекламных роликов / музыкальных клипов, или 1 полнометражный художественный фильм или сериал',
        'en-US': 'ten commercials / music videos or one feature film or tv-series'
    },

    'Payment is made once a year.': {
        'ru-RU': 'Оплата происходит раз в год.',
        'en-US': 'Payment is made once a year.'
    },

    'Payment is made once a month.': {
        'ru-RU': 'Оплата происходит раз в месяц.',
        'en-US': 'Payment is made once a month.'
    },

    'Payment is made once a month after uploading the work and confirming the classification.': {
        'ru-RU': 'Оплата происходит раз в месяц после загрузки работ и подтверждения классификации.',
        'en-US': 'Payment is made once a month after uploading projects and classification confirmation.'
    },

    'Payment is made once a year after uploading the work and confirming the classification.': {
        'ru-RU': 'Оплата происходит раз в год после загрузки работ и подтверждения классификации.',
        'en-US': 'Payment is made once a year after uploading projects and classification confirmation.'
    },

    'First two years after film school': {
        'ru-RU': 'Первые два года после киношколы',
        'en-US': 'Two years after graduating from film school'
    },

    'Actor': {
        'ru-RU': 'Актер',
        'en-US': 'Actor'
    },

    'Add': {
        'ru-RU': 'Добавить',
        'en-US': 'Add'
    },

    'Add specialty': {
        'ru-RU': 'Добавить специальность',
        'en-US': 'Add specialty'
    },

    'Additional speciality': {
        'ru-RU': 'Дополнительная специальность',
        'en-US': 'Additional speciality'
    },

    'Add direction': {
        'ru-RU': 'Добавить направление',
        'en-US': 'Add area'
    },

    'Additional direction': {
        'ru-RU': 'Дополнительное направление',
        'en-US': 'Additional direction'
    },

    'Use account contacts': {
        'ru-RU': 'Использовать контакты аккаунта',
        'en-US': 'Use account contacts'
    },

    'To display on the site and add performers, you need to pay for the first package (5 performers). The first two months - 1 ruble': {
        'ru-RU': 'Для отображения на сайте и добавления специалистов необходимо оплатить первый пакет (5 специалистов). Первые 50 дней - 1 рубль',
        'en-US': 'To display on the site and add performers, you need to pay for the first package (5 performers). First 50 days - 1 ruble'
    },

    'To display on the site and add graduates, you need to pay for the first package (10 graduates). The first three months - 1 ruble': {
        'ru-RU': 'Для отображения на сайте и добавления выпускников необходимо оплатить первый пакет (10 выпускников). Первые три месяца - 1 рубль',
        'en-US': 'To display on the site and add graduates, you need to pay for the first package (10 graduates). The first three months - 1 ruble'
    },

    'To display on the site you need to pay for the direction of the company. The first two months — 1 ruble': {
        'ru-RU': 'Для отображения на сайте необходимо оплатить направление компании. Первые 50 дней — 1 рубль',
        'en-US': 'To display on the site you need to pay for the direction of the company. First 50 days — 1 ruble'
    },

    'Since what year': {
        'ru-RU': 'С какого года',
        'en-US': 'Since what year'
    },

    'These contacts can be used to autocomplete your profiles': {
        'ru-RU': 'Указанные контакты могут быть использованы для автозаполнения ваших профилей',
        'en-US': 'These contacts can be used to autocomplete your profiles'
    },

    'Phone': {
        'ru-RU': 'Телефон',
        'en-US': 'Phone'
    },

    'Password': {
        'ru-RU': 'Пароль',
        'en-US': 'Password'
    },

    'Old password': {
        'ru-RU': 'Старый пароль',
        'en-US': 'Old password'
    },

    'New password': {
        'ru-RU': 'Новый пароль',
        'en-US': 'New password'
    },

    'Repeat new password': {
        'ru-RU': 'Повторите новый пароль',
        'en-US': 'Repeat new password'
    },

    'The password has been successfully changed.': {
        'ru-RU': 'Пароль успешно изменен.',
        'en-US': 'The password has been successfully changed.',
    },

    'You don\'t have saved searches yet': {
        'ru-RU': 'У вас пока нет сохраненных поисков',
        'en-US': 'You have no saved search results'
    },

    'More detailed': {
        'ru-RU': 'Подробнее',
        'en-US': 'More details'
    },

    'Repeat': {
        'ru-RU': 'Повторить',
        'en-US': 'Repeat'
    },

    'Save': {
        'ru-RU': 'Сохранить',
        'en-US': 'Save'
    },

    'Account settings': {
        'ru-RU': 'Настройки аккаунта',
        'en-US': 'Account settings'
    },

    'Email-subscription': {
        'ru-RU': 'Email-подписка',
        'en-US': 'Email-subscription'
    },

    'Portal news': {
        'ru-RU': 'Новости портала',
        'en-US': 'Portal news'
    },

    'Monthly statistics': {
        'ru-RU': 'Ежемесячная статистика',
        'en-US': 'Monthly statistics'
    },

    'Notifications': {
        'ru-RU': 'Уведомления',
        'en-US': 'Notifications'
    },

    'Privacy': {
        'ru-RU': 'Приватность',
        'en-US': 'Privacy'
    },

    'Hide me in the search': {
        'ru-RU': 'Скрывать меня в поиске',
        'en-US': 'Hide me in the search'
    },

    'New guild': {
        'ru-RU': 'Новая гильдия',
        'en-US': 'New guild'
    },

    'New place of education': {
        'ru-RU': 'Новое учебное заведение',
        'en-US': 'New place of education'
    },

    'New company': {
        'ru-RU': 'Новая компания',
        'en-US': 'New company'
    },

    'When you turn off the phone and email display, you can be contacted via the "Contact" button, which can be configured separately': {
        'ru-RU': 'Вы можете указать другие контакты для кнопки "Связаться", если не хотите раскрывать основные контакты аккаунта.',
        'en-US': 'You can specify other contacts for the Contact button if you do not want to disclose the main contact information of your account.'
    },

    'Personal page': {
        'ru-RU': 'Ссылка на ваш сайт',
        'en-US': 'Link to your personal website'
    },

    'For the «Contact» button': {
        'ru-RU': 'Для кнопки «Связаться»',
        'en-US': 'For the «Contact» button'
    },

    'Use the main contacts': {
        'ru-RU': 'Использовать основные контакты',
        'en-US': 'Use main contacts'
    },

    'New agent': {
        'ru-RU': 'Новый агент',
        'en-US': 'New agent'
    },

    'Show agent': {
        'ru-RU': 'Показывать агента',
        'en-US': 'Show agent'
    },

    'How does my profile work?': {
        'ru-RU': 'Как работает мой профиль?',
        'en-US': 'How does my profile work?'
    },

    'How do they search for me?': {
        'ru-RU': 'Как меня ищут?',
        'en-US': 'How do they search for me?'
    },

    'You can turn off the link to go to your agent\`s profile on this site': {
        'ru-RU': 'Вы можете выключить ссылку для перехода в профиль своего агента на этом сайте',
        'en-US': 'You can turn off the link to go to your agent\`s profile on this site'
    },

    'You can include a link to go to guild profile': {
        'ru-RU': 'Вы можете включить ссылку для перехода в профиль гильдии',
        'en-US': 'You can add a link to  the profile of your guild'
    },

    'The work was created': {
        'ru-RU': 'Работа добавлена, можно продолжить добавлять информацию',
        'en-US': 'Project added, you can continue to add information'
    },

    'Continue': {
        'ru-RU': 'Продолжить',
        'en-US': 'Continue'
    },

    'Remove agent': {
        'ru-RU': 'Удалить агента',
        'en-US': 'Remove agent'
    },

    'Main place of work and residence': {
        'ru-RU': 'Основное место работы и проживания',
        'en-US': 'Main location of work and residence'
    },

    'Main place': {
        'ru-RU': 'Основное место',
        'en-US': 'Main place'
    },

    'General settings': {
        'ru-RU': 'Общие настройки',
        'en-US': 'General settings'
    },

    'Edit profile': {
        'ru-RU': 'Редактировать профиль',
        'en-US': 'Edit profile'
    },

    'View profile': {
        'ru-RU': 'Смотреть профиль',
        'en-US': 'View profile'
    },

    'View': {
        'ru-RU': 'Смотреть',
        'en-US': 'View'
    },

    'Show in profile': {
        'ru-RU': 'Показывать в профиле',
        'en-US': 'Show in profile'
    },

    'The status of your requests is shown here. The first request is free. You can create a query through the search page when there are no matching results. We will select the necessary performer (company or staff) for any of your tasks for shooting and post-production': {
        'ru-RU': 'Здесь показывается статус ваших персональных запросов. Первый запрос - бесплатный. Запрос можно создать через страницу любого вида поиска, когда нет подходящих результатов. После оформления заявки через кнопку "Персональный запрос", мы подберем необходимых исполнителей (компанию или специалиста) по указанным фильтрам в поиске и ранее не отображаемых на сайте.',
        'en-US': 'The status of your personal requests is shown here. The first request is free. You can send a personal request through any search page when there are no suitable results. After you send an application though the Personal Request button, we will find you a suitable companies or crew according to the specified filters that was not previously displayed in the search results.'
    },

    'A request from': {
        'ru-RU': 'Запрос от',
        'en-US': 'Request from'
    },

    'Do not disclose the applicant': {
        'ru-RU': 'Соискателя не разглашать',
        'en-US': 'Do not disclose the name of the candidate'
    },

    'ResultsSearch': {
        'ru-RU': 'Результатов',
        'en-US': 'Results'
    },

    'FromTime': {
        'ru-RU': 'с года',
        'en-US': 'from'
    },

    'Disclose the applicant': {
        'ru-RU': 'Разглашать соискателя',
        'en-US': 'Disclose the name of the candidate'
    },

    'Don`t show in profile': {
        'ru-RU': 'Не показывать в профиле',
        'en-US': 'Don`t show in profile'
    },

    'Tell us about yourself': {
        'ru-RU': 'Расскажите о себе',
        'en-US': 'Discribe yourself more'
    },

    'Tell us about yourself in English': {
        'ru-RU': 'Расскажите о себе на английском',
        'en-US': 'Discribe yourself more'
    },

    'Kinopoisk': {
        'ru-RU': 'Кинопоиск',
        'en-US': 'Kinopoisk link'
    },

    'Your summary can be downloaded from your profile page': {
        'ru-RU': 'Загрузите свое резюме в виде pdf или word. Его можно будет скачать на странице Вашего профиля.',
        'en-US': 'Upload your CV in as a PDF or MS Word file. It will be available for download from your profile.'
    },

    'Country': {
        'ru-RU': 'Страна',
        'en-US': 'Country'
    },

    'City': {
        'ru-RU': 'Город',
        'en-US': 'City'
    },

    'Show in the search for this location too': {
        'ru-RU': 'Показывать в поиске по этой локации',
        'en-US': 'Show in the search for this location'
    },

    'Don`t show in the search for this location too': {
        'ru-RU': 'Не показывать в поиске по этой локации',
        'en-US': 'Don`t show in the search for this location'
    },

    'Additional place of work': {
        'ru-RU': 'Дополнительное место работы',
        'en-US': 'Additional place of work'
    },

    'Additional place': {
        'ru-RU': 'Дополнительное место',
        'en-US': 'Additional place'
    },

    'Abbreviation of educational institution': {
        'ru-RU': 'Аббревиатура учебного заведения',
        'en-US': 'Abbreviated college name'
    },

    'Year of graduation': {
        'ru-RU': 'Год окончания',
        'en-US': 'Graduated'
    },

    'Full name': {
        'ru-RU': 'Полное название',
        'en-US': 'Full name'
    },

    'Enable cross-reference': {
        'ru-RU': 'Включить кросс-ссылку',
        'en-US': 'Enable cross-link'
    },

    'You can include a link to go to your film school profile': {
        'ru-RU': 'Вы можете включить ссылку для перехода в профиль своей киношколы',
        'en-US': 'You can add a link to the profile of your film school'
    },

    'Language': {
        'ru-RU': 'Язык',
        'en-US': 'Language'
    },

    'The name of the guild': {
        'ru-RU': 'Название гильдии',
        'en-US': 'Guild'
    },

    'Name of the educational institution': {
        'ru-RU': 'Название учебного заведения',
        'en-US': 'Name of the educational institution'
    },

    'You can include a cross-reference': {
        'ru-RU': 'Вы можете включить кросс-ссылку',
        'en-US': 'You can include a cross-reference'
    },

    'Remove image': {
        'ru-RU': 'Удалить изображение',
        'en-US': 'Remove image'
    },

    'Remove video': {
        'ru-RU': 'Удалить видео',
        'en-US': 'Remove video'
    },

    'PersonName': {
        'ru-RU': 'Имя',
        'en-US': 'Name'
    },

    'Surname': {
        'ru-RU': 'Фамилия',
        'en-US': 'Surname'
    },

    'Name (for English version)': {
        'ru-RU': 'Name (для английской версии)',
        'en-US': 'Name (for English version)'
    },

    'Surname (for English version)': {
        'ru-RU': 'Surname (для английской версии)',
        'en-US': 'Surname (for English version)'
    },

    'Download file': {
        'ru-RU': 'Скачать файл',
        'en-US': 'Download file'
    },

    'Remove file': {
        'ru-RU': 'Удалить файл',
        'en-US': 'Remove file'
    },

    'Company': {
        'ru-RU': 'Компания',
        'en-US': 'Company'
    },

    'Company (for English version)': {
        'ru-RU': 'Company (для английской версии)',
        'en-US': 'Company (for English version)'
    },

    'School name': {
        'ru-RU': 'Название школы',
        'en-US': 'School name'
    },

    'Abbreviation': {
        'ru-RU': 'Аббревиатура',
        'en-US': 'Abbreviation'
    },

    'Add a company': {
        'ru-RU': 'Добавить компанию',
        'en-US': 'Add a company'
    },

    'Add a new guild': {
        'ru-RU': 'Добавить новую гильдию',
        'en-US': 'Add new guild'
    },

    'School name (for English version)': {
        'ru-RU': 'Название школы (для английской версии)',
        'en-US': 'School name (for English version)'
    },

    'All works in this section will be moved to the Archive': {
        'ru-RU': 'Все работы раздела будут перемещены в Архив',
        'en-US': 'All projects in this folder will be moved to the Archive'
    },

    'Abbreviation (for English version)': {
        'ru-RU': 'Аббревиатура (для английской версии)',
        'en-US': 'Abbreviation (for English version)'
    },

    'Position': {
        'ru-RU': 'Должность',
        'en-US': 'Position'
    },

    'You can be found through the content and description of your projects. The more projects you add and the better you describe them, the most likely you are to be invited to a new one. Do not forget to add team members in Credits if you worked with someone else.': {
        'ru-RU': 'Вас смогут найти через содержание и описание ваших работ. Чем больше работ вы добавите и полнее их опишите, тем больше шансов получить новый проект. Не забудьте указать участников команды, вы делали это вместе.',
        'en-US': 'You can be found through the content and description of your projects. The more projects you add and the better you describe them, the most likely you are to be invited to a new one. Do not forget to add team members in Credits if you worked with someone else.'
    },

    'You can add work yourself or entrust it to us. Works will be assigned the maximum search characteristics-first': {
        'ru-RU': 'Вы можете добавлять работы самостоятельно или',
        'en-US': 'You can add your projects yourself or'
    },

    'You can add work yourself or entrust it to us. Works will be assigned the maximum search characteristics-middle': {
        'ru-RU': 'поручить это нам',
        'en-US': 'ask us to do it'
    },

    'You can add work yourself or entrust it to us. Works will be assigned the maximum search characteristics-end': {
        'ru-RU': '. Работам будут присвоены максимально поисковые характеристики',
        'en-US': '. Works will be added with the maximum possible search parameters.'
    },

    'Change the order': {
        'ru-RU': 'Изменить порядок',
        'en-US': 'Change order'
    },

    'Save the order': {
        'ru-RU': 'Сохранить порядок',
        'en-US': 'Save order'
    },

    'Change name': {
        'ru-RU': 'Изменить название',
        'en-US': 'Change name'
    },

    'WorksGenitive': {
        'ru-RU': 'Работ',
        'en-US': 'Projects'
    },

    'Show': {
        'ru-RU': 'Показывать',
        'en-US': 'Show'
    },

    'Remove section': {
        'ru-RU': 'Удалить раздел',
        'en-US': 'Delete folder'
    },

    'Show me as': {
        'ru-RU': 'Отображать меня как',
        'en-US': 'Show me as'
    },

    'Person': {
        'ru-RU': 'Email',
        'en-US': 'Email'
    },

    'Date of invitation': {
        'ru-RU': 'Дата приглашения',
        'en-US': 'Invite date'
    },

    'Hide': {
        'ru-RU': 'Скрыть',
        'en-US': 'Hide'
    },

    'Edit': {
        'ru-RU': 'Редактировать',
        'en-US': 'Edit'
    },

    'To archive': {
        'ru-RU': 'В архив',
        'en-US': 'Archive'
    },

    'You are team member': {
        'ru-RU': 'Вас добавили в команду, поменяйте раздел работы, чтобы она отображалась',
        'en-US': 'you have been added to a team, please change the folder for it to be displayed'
    },

    'Adding work': {
        'ru-RU': 'Добавление работы',
        'en-US': 'Adding work'
    },

    'Editing work': {
        'ru-RU': 'Редактирование работы',
        'en-US': 'Editing work'
    },

    'For quick search': {
        'ru-RU': 'Для быстрого поиска',
        'en-US': 'For Easy search'
    },

    'For detailed search': {
        'ru-RU': 'Для подробного поиска',
        'en-US': 'For Advanced search'
    },

    'For professional search': {
        'ru-RU': 'Для профессионального поиска',
        'en-US': 'For Pro search'
    },

    'General information': {
        'ru-RU': 'Основная информация',
        'en-US': 'General information'
    },

    'Team': {
        'ru-RU': 'Команда',
        'en-US': 'Credits'
    },

    'Characteristics': {
        'ru-RU': 'Характеристики',
        'en-US': 'More details'
    },

    'Preparation': {
        'ru-RU': 'Подготовка',
        'en-US': 'Pre production'
    },

    'Backstage': {
        'ru-RU': 'Backstage',
        'en-US': 'Backstage'
    },

    'Festivals and awards': {
        'ru-RU': 'Фестивали и награды',
        'en-US': 'Awards'
    },

    'Skills': {
        'ru-RU': 'Навыки',
        'en-US': 'Skills'
    },

    'Image property': {
        'ru-RU': 'Свойства изображения',
        'en-US': 'Image specifications'
    },

    'Lighting and style': {
        'ru-RU': 'Освещение и стиль',
        'en-US': 'Visual style'
    },

    'Additional equipment': {
        'ru-RU': 'Доп оборудование',
        'en-US': 'Additional equipment'
    },

    'Locations, time, weather': {
        'ru-RU': 'Локации, время, погода',
        'en-US': 'Story details'
    },

    'Budgets': {
        'ru-RU': 'Бюджет',
        'en-US': 'Budget'
    },

    'Page not found': {
        'ru-RU': 'Страница не найдена',
        'en-US': 'Page not found'
    },

    'To works': {
        'ru-RU': 'Назад к работам',
        'en-US': 'Back to all projects'
    },

    'Work type': {
        'ru-RU': 'Тип работы',
        'en-US': 'Project type'
    },

    'Film-1': {
        'ru-RU': 'Кино',
        'en-US': 'Cinema'
    },
    'Film-2': {
        'ru-RU': 'Кино',
        'en-US': 'Film',
    },
    'FilmSelect': {
        'ru-RU': 'Кино',
        'en-US': 'Film',
    },

    'Serial': {
        'ru-RU': 'Сериал',
        'en-US': 'Tv series'
    },

    'Invite a new agent': {
        'ru-RU': 'Пригласить нового агента',
        'en-US': 'Invite a new agent'
    },

    'Invite a new company': {
        'ru-RU': 'Пригласить новую компанию',
        'en-US': 'Invite a new company'
    },

    'Interior': {
        'ru-RU': 'Интерьер',
        'en-US': 'Interior'
    },

    'Exterior': {
        'ru-RU': 'Экстерьер',
        'en-US': 'Exterior'
    },

    'Advertising': {
        'ru-RU': 'Реклама',
        'en-US': 'Advertisement'
    },

    'MusicVideoShort': {
        'ru-RU': 'Муз.клип',
        'en-US': 'Music video'
    },

    'Music video': {
        'ru-RU': 'Музыкальный клип',
        'en-US': 'Music video'
    },

    'Music group': {
        'ru-RU': 'Музыкальная группа',
        'en-US': 'Band'
    },

    'Other': {
        'ru-RU': 'Другое',
        'en-US': 'Other'
    },

    'Other video': {
        'ru-RU': 'Другое видео',
        'en-US': 'Other video'
    },

    'Full-length art': {
        'ru-RU': 'Полнометражное художественное',
        'en-US': 'Full-length fiction'
    },

    'Short fiction': {
        'ru-RU': 'Короткометражное художественное',
        'en-US': 'Short fiction'
    },

    'Full-length documentary': {
        'ru-RU': 'Полнометражное документальное',
        'en-US': 'Full-length documentary'
    },

    'Short documentary': {
        'ru-RU': 'Короткометражное документальное',
        'en-US': 'Short documentary'
    },

    'Full-length art 2': {
        'ru-RU': 'Кино полнометражное художественное',
        'en-US': 'Full-length fiction film'
    },

    'Short fiction 2': {
        'ru-RU': 'Кино короткометражное художественное',
        'en-US': 'Short fiction film'
    },

    'Full-length documentary 2': {
        'ru-RU': 'Кино полнометражное документальное',
        'en-US': 'Full-length documentary film'
    },

    'Short documentary 2': {
        'ru-RU': 'Кино короткометражное документальное',
        'en-US': 'Short documentary film'
    },

    'Commercial advertising': {
        'ru-RU': 'Реклама коммерческая',
        'en-US': 'Commercial advertising'
    },

    'Social advertising': {
        'ru-RU': 'Реклама социальная',
        'en-US': 'Social advertising'
    },

    'Commercial': {
        'ru-RU': 'Коммерческая',
        'en-US': 'Commercial'
    },

    'Social': {
        'ru-RU': 'Социальная',
        'en-US': 'Social'
    },

    'Fashion-film': {
        'ru-RU': 'Fashion-фильм',
        'en-US': 'Fashion film'
    },

    'Image video': {
        'ru-RU': 'Имиджевое видео',
        'en-US': 'Image video'
    },

    'Corporate film': {
        'ru-RU': 'Корпоративный фильм',
        'en-US': 'Corporate film'
    },

    'The intro / id video': {
        'ru-RU': 'Заставка / id ролик',
        'en-US': 'Intro / id video'
    },

    'Info / grocery video': {
        'ru-RU': 'Инфо / продуктовый ролик',
        'en-US': 'Info / product video'
    },

    'Training video': {
        'ru-RU': 'Обучающее видео',
        'en-US': 'Training video'
    },

    'Video with infographics': {
        'ru-RU': 'Видео и инфографика',
        'en-US': 'With infographics'
    },

    'Shooting backstage': {
        'ru-RU': 'Съемка backstage',
        'en-US': 'Backstage video'
    },

    'Link to the video (YouTube / Vimeo)': {
        'ru-RU': 'Ссылка на видео (YouTube / Vimeo / Rutube)',
        'en-US': 'Link to the video (YouTube / Vimeo)'
    },

    'Description': {
        'ru-RU': 'Описание',
        'en-US': 'Description'
    },

    'Minutes': {
        'ru-RU': 'Минут',
        'en-US': 'Minutes'
    },

    'Seconds': {
        'ru-RU': 'Секунд',
        'en-US': 'Seconds'
    },

    'Year': {
        'ru-RU': 'Год',
        'en-US': 'Year'
    },

    'Display in the profile': {
        'ru-RU': 'Выбрать папку работы',
        'en-US': 'Choose project folder'
    },

    'Colorful': {
        'ru-RU': 'Цветной',
        'en-US': 'Colorful'
    },

    'Black-white': {
        'ru-RU': 'Черно-белый',
        'en-US': 'Black-white'
    },

    'Profession in the project': {
        'ru-RU': 'Профессия в проекте',
        'en-US': 'Profession in the project'
    },

    'ObjectName': {
        'ru-RU': 'Позиция',
        'en-US': 'Item'
    },

    'ObjectNameOriginal': {
        'ru-RU': 'Название',
        'en-US': 'Title on original language'
    },
    'ObjectNameEnglish': {
        'ru-RU': 'Название на английском',
        'en-US': 'Title in English'
    },

    'Animations': {
        'ru-RU': 'Анимации',
        'en-US': 'Animations'
    },

    'With animation': {
        'ru-RU': 'с анимацией',
        'en-US': 'with animation'
    },

    'As a gift, 1 month of free subscription for every 5 artists who subscribe through Your invitation': {
        'ru-RU': 'В подарок 1 месяц бесплатной подписки за каждые 5 исполнителей , оформивших подписку через Ваше приглашение',
        'en-US': 'As a gift, 1 month of free subscription for every 5 artists who subscribe through Your invitation'
    },

    'Hand drawn': {
        'ru-RU': 'рисованная',
        'en-US': 'traditional'
    },

    'Sand': {
        'ru-RU': 'песочная',
        'en-US': 'sand animation'
    },

    'On the glass': {
        'ru-RU': 'по стеклу',
        'en-US': 'paint-on-glass'
    },

    'Puppet': {
        'ru-RU': 'кукольная',
        'en-US': 'puppet'
    },

    'Anime': {
        'ru-RU': 'аниме',
        'en-US': 'anime'
    },

    'Plasticine': {
        'ru-RU': 'пластилиновая',
        'en-US': 'clay animation'
    },

    'Delete permanently': {
        'ru-RU': 'Удалить',
        'en-US': 'Delete'
    },

    'Replace to archive': {
        'ru-RU': 'В архив',
        'en-US': 'To archive'
    },

    'Video': {
        'ru-RU': 'Видео',
        'en-US': 'Video'
    },

    'Preview': {
        'ru-RU': 'Превью',
        'en-US': 'Preview'
    },

    'Additional characteristics': {
        'ru-RU': 'Дополнительные характеристики',
        'en-US': 'Additional characteristics'
    },

    'Genre': {
        'ru-RU': 'Жанр',
        'en-US': 'Genre'
    },

    'Shows': {
        'ru-RU': 'Показы',
        'en-US': 'Screening'
    },

    'The internet': {
        'ru-RU': 'Интернет',
        'en-US': 'Internet'
    },

    'Cinema': {
        'ru-RU': 'Кино',
        'en-US': 'Cinema',
    },

    'TV': {
        'ru-RU': 'Тв',
        'en-US': 'TV'
    },

    'Additional': {
        'ru-RU': 'Дополнительно',
        'en-US': 'Additional'
    },

    'Not selected': {
        'ru-RU': 'Не выбрано',
        'en-US': 'Not specified'
    },

    'Record company': {
        'ru-RU': 'Лейбл',
        'en-US': 'Label'
    },

    'Independent group': {
        'ru-RU': 'Независимая группа',
        'en-US': 'Indie band'
    },

    'Composition': {
        'ru-RU': 'Состав',
        'en-US': 'Composition'
    },

    'The entire crew': {
        'ru-RU': 'Вся съемочная группа',
        'en-US': 'All production crew'
    },

    'Post production and VFX': {
        'ru-RU': 'Постобработка и VFX',
        'en-US': 'Post production and VFX'
    },

    'Promotion': {
        'ru-RU': 'Продвижение',
        'en-US': 'Promotion & distribution'
    },

    'Animation': {
        'ru-RU': 'Анимация',
        'en-US': 'Animation'
    },

    'Preparatory period': {
        'ru-RU': 'Подготовительный период',
        'en-US': 'Pre production'
    },

    'Profession': {
        'ru-RU': 'Профессия',
        'en-US': 'Profession'
    },

    'Select manually': {
        'ru-RU': 'Выбрать из списка',
        'en-US': 'Choose from the list'
    },

    'Direction': {
        'ru-RU': 'Направление',
        'en-US': 'Direction'
    },

    'Add poster': {
        'ru-RU': 'Добавить постер',
        'en-US': 'Add poster'
    },

    'Link to kinopoisk': {
        'ru-RU': 'Ссылка на кинопоиск',
        'en-US': 'Kinopoisk link'
    },

    'Link to IMDb': {
        'ru-RU': 'Ссылка на IMDb',
        'en-US': 'IMDb link'
    },

    'Genres': {
        'ru-RU': 'Жанры',
        'en-US': 'Genres'
    },

    'Content': {
        'ru-RU': 'Содержание',
        'en-US': 'Content'
    },

    'Any': {
        'ru-RU': 'Любое',
        'en-US': 'Any'
    },

    'Musical style': {
        'ru-RU': 'Музыкальный стиль',
        'en-US': 'Musical style'
    },

    'Pop': {
        'ru-RU': 'Поп',
        'en-US': 'Pop'
    },

    'Rock': {
        'ru-RU': 'Рок',
        'en-US': 'Rock'
    },

    'Rap': {
        'ru-RU': 'Рэп',
        'en-US': 'Rap'
    },

    'Electronic': {
        'ru-RU': 'Электронная',
        'en-US': 'Electronic'
    },

    'Classic': {
        'ru-RU': 'Классическая',
        'en-US': 'Classic'
    },

    'Jazz': {
        'ru-RU': 'Джазовая',
        'en-US': 'Jazz'
    },

    'Official link to watch the series online': {
        'ru-RU': 'Официальная ссылка для просмотра сериала online',
        'en-US': 'Official link to watch online'
    },

    'Official link to watch the movie online': {
        'ru-RU': 'Официальная ссылка для просмотра фильма online',
        'en-US': 'Official link to watch online'
    },

    'FilmRental': {
        'ru-RU': 'Прокат',
        'en-US': 'Distribution'
    },

    'Link': {
        'ru-RU': 'Ссылка',
        'en-US': 'Link'
    },

    'FilmDirection': {
        'ru-RU': 'Направленность',
        'en-US': 'Type'
    },

    'Auteur': {
        'ru-RU': 'Авторское',
        'en-US': 'Auteur'
    },

    'Сommercial': {
        'ru-RU': 'Коммерческое',
        'en-US': 'Сommercial'
    },

    'Historical': {
        'ru-RU': 'Исторический',
        'en-US': 'Historical'
    },

    'Modern': {
        'ru-RU': 'Современный',
        'en-US': 'Modern'
    },

    'Military': {
        'ru-RU': 'Военный',
        'en-US': 'War'
    },

    'TV series / series (up to 12 episodes)': {
        'ru-RU': 'ТВ-фильм / мини-сериал (до 12 серий)',
        'en-US': 'TV film / miniseries  (up to 12 episodes)'
    },

    'Multi-part series (12 + episodes)': {
        'ru-RU': 'Многосерийный формат (больше 12 серий)',
        'en-US': 'Series (more than 12 episodes)'
    },

    'Format': {
        'ru-RU': 'Формат',
        'en-US': 'Format'
    },

    'Viral advertising': {
        'ru-RU': 'Вирусная реклама',
        'en-US': 'Viral'
    },

    'Manifest': {
        'ru-RU': 'Манифест',
        'en-US': 'Manifest'
    },

    'For TV': {
        'ru-RU': 'Для тв',
        'en-US': 'For TV'
    },

    'Apps': {
        'ru-RU': 'Приложения',
        'en-US': 'Apps'
    },

    'Shooting': {
        'ru-RU': 'Съемка',
        'en-US': 'Filmed on'
    },

    'For this type of work there are no additional characteristics': {
        'ru-RU': 'Для данного вида работ нет дополнительных характеристик',
        'en-US': 'For this type of work there are no additional characteristics'
    },

    'Add photo': {
        'ru-RU': 'Добавить фото',
        'en-US': 'Add photo'
    },

    'Photo': {
        'ru-RU': 'Фото',
        'en-US': 'Photo'
    },

    'Up to 8 photos. JPG/png image from 300x300px to 15 MB.': {
        'ru-RU': 'Можно добавить до 8 фотографий. Файлы jpg и png, размером от 300х300рх и объемом до 15 МБ.',
        'en-US': 'You can upload up to 8 photos. Files in the .jpg and .png format, from 300х300 рх and up to 15 Mb.'
    },

    'Add festival': {
        'ru-RU': 'Добавить фестиваль',
        'en-US': 'Add festival'
    },

    'Soon': {
        'ru-RU': 'Скоро',
        'en-US': 'Soon'
    },

    'If you do not find in the list of the necessary documents, write to us on mail and we will add it soon': {
        'ru-RU': 'Если вы не нашли в списке нужный фестиваль, напишите нам, пожалуйста, на почту и мы его скоро добавим',
        'en-US': 'If you do not find your festival in the list, please email us and we will add it soon'
    },

    'If you do not find the necessary skills in the list, write to us on mail and we will add it soon': {
        'ru-RU': 'Если вы не нашли в списке нужные навыки, напишите нам, пожалуйста, на почту и мы его скоро добавим',
        'en-US': 'If you have not found the required skills in the list, please email us and we will add them'
    },

    'If you do not find the necessary equipment in the list, write to us on mail and we will add it soon': {
        'ru-RU': 'Если вы не нашли в списке нужную технику, напишите нам, пожалуйста, на почту и мы ее скоро добавим',
        'en-US': 'If some equipment is missing, email us and we will add it soon.'
    },

    'If you do not find the desired option in the list, write to us by mail and we will add it soon': {
        'ru-RU': 'Если вы не нашли в списке нужный параметр, напишите нам, пожалуйста, на почту и мы его скоро добавим',
        'en-US': 'If you do not find the desired option in the list, write to us by mail and we will add it soon'
    },

    'Festival': {
        'ru-RU': 'Фестиваль',
        'en-US': 'Film festival'
    },

    'Award': {
        'ru-RU': 'Номинация',
        'en-US': 'Nomination'
    },

    'Add award': {
        'ru-RU': 'Добавить награду',
        'en-US': 'Add nomination'
    },

    'Participant': {
        'ru-RU': 'Участник',
        'en-US': 'Selection'
    },

    'Winner': {
        'ru-RU': 'Победитель',
        'en-US': 'Winner'
    },

    'Remove festival': {
        'ru-RU': 'Удалить фестиваль',
        'en-US': 'Remove festival'
    },

    'There are different stories and places of action, specify your special characteristics and properties of the story': {
        'ru-RU': 'Искать можно по различным деталям истории и местам действий. Укажите особенные характеристики проекта для профессионального поиска.',
        'en-US': 'Search by story details and locations. Specify  special project characteristics for Pro search.'
    },

    'Time of day': {
        'ru-RU': 'Время суток',
        'en-US': 'Time of day'
    },

    'Day': {
        'ru-RU': 'День',
        'en-US': 'Day'
    },

    'Night': {
        'ru-RU': 'Ночь',
        'en-US': 'Night'
    },

    'Dawn': {
        'ru-RU': 'Рассвет',
        'en-US': 'Dawn'
    },

    'Sunset': {
        'ru-RU': 'Закат',
        'en-US': 'Sunset'
    },

    'Interior / Exterior': {
        'ru-RU': 'Интерьер / Экстерьер',
        'en-US': 'Interior / Exterior'
    },

    'In the interior': {
        'ru-RU': 'В интерьере',
        'en-US': 'In the interior'
    },

    'On nature': {
        'ru-RU': 'На натуре',
        'en-US': 'On nature'
    },

    'Special location': {
        'ru-RU': 'Специальное место действия',
        'en-US': 'Special location'
    },

    'On the water': {
        'ru-RU': 'На воде',
        'en-US': 'On water'
    },

    'Under water': {
        'ru-RU': 'Под водой',
        'en-US': 'Underwater'
    },

    'In the air': {
        'ru-RU': 'В воздухе',
        'en-US': 'In the air'
    },

    'Under the ground': {
        'ru-RU': 'Под землей',
        'en-US': 'Underground'
    },

    'In space': {
        'ru-RU': 'В космосе',
        'en-US': 'In space'
    },

    'Other planet': {
        'ru-RU': 'Другая планета',
        'en-US': 'On another planet'
    },

    'Another reality': {
        'ru-RU': 'Другая реальность',
        'en-US': 'In another world'
    },

    'Action time': {
        'ru-RU': 'Время действия',
        'en-US': 'Time'
    },

    'In the past': {
        'ru-RU': 'В прошлом',
        'en-US': 'In the past'
    },

    'In the future': {
        'ru-RU': 'В будущем',
        'en-US': 'In the future'
    },

    'Present': {
        'ru-RU': 'Настоящее',
        'en-US': 'Our time'
    },

    'In the imagination': {
        'ru-RU': 'В воображении',
        'en-US': 'Imagination'
    },

    'Clarification of the place of action': {
        'ru-RU': 'Уточнения по месту действия',
        'en-US': 'More location details'
    },

    'House': {
        'ru-RU': 'Дом',
        'en-US': 'House'
    },

    'Multistory building': {
        'ru-RU': 'Многоэтажное здание',
        'en-US': 'Multistory building'
    },

    'City/metropolis': {
        'ru-RU': 'Город/мегаполис',
        'en-US': 'City/metropolis'
    },

    'On nature/village': {
        'ru-RU': 'На природе/деревня',
        'en-US': 'On nature/countryside'
    },

    'Garden': {
        'ru-RU': 'Сад',
        'en-US': 'Garden'
    },

    'Field': {
        'ru-RU': 'Поле',
        'en-US': 'Field'
    },

    'Forest': {
        'ru-RU': 'Лес',
        'en-US': 'Forest'
    },

    'Mountains': {
        'ru-RU': 'Горы',
        'en-US': 'The mountains'
    },

    'Desert': {
        'ru-RU': 'Пустыня',
        'en-US': 'Desert'
    },

    'Lake': {
        'ru-RU': 'Озеро',
        'en-US': 'Lake'
    },

    'River': {
        'ru-RU': 'Река',
        'en-US': 'River'
    },

    'Waterfall': {
        'ru-RU': 'Водопад',
        'en-US': 'Waterfall'
    },

    'Sea/ocean': {
        'ru-RU': 'Море/океан',
        'en-US': 'Sea/ocean'
    },

    'Road': {
        'ru-RU': 'Дорога',
        'en-US': 'Road'
    },

    'Rails': {
        'ru-RU': 'Рельсы',
        'en-US': 'Rails'
    },

    'Bridge': {
        'ru-RU': 'Мост',
        'en-US': 'Bridge'
    },

    'Weather and light conditions': {
        'ru-RU': 'Погодные и световые условия',
        'en-US': 'Weather and light conditions'
    },

    'Sun': {
        'ru-RU': 'Солнце',
        'en-US': 'The sun'
    },

    'Clouds': {
        'ru-RU': 'Облака',
        'en-US': 'Clouds'
    },

    'Fog': {
        'ru-RU': 'Туман',
        'en-US': 'Fog'
    },

    'Rain': {
        'ru-RU': 'Дождь',
        'en-US': 'Rain'
    },

    'Dew': {
        'ru-RU': 'Роса',
        'en-US': 'Dew'
    },

    'Hail': {
        'ru-RU': 'Град',
        'en-US': 'Hail'
    },

    'Snow/snowfall': {
        'ru-RU': 'Снег/снегопад',
        'en-US': 'Snow/snowfall'
    },

    'Ice/icing': {
        'ru-RU': 'Лед/обледенение',
        'en-US': 'Ice/icing'
    },

    'Storm': {
        'ru-RU': 'Гроза',
        'en-US': 'Thunderstorm'
    },

    'Strong wind': {
        'ru-RU': 'Сильный ветер',
        'en-US': 'Strong wind'
    },

    'Hurricane': {
        'ru-RU': 'Шторм',
        'en-US': 'Storm'
    },

    'Blizzard/blizzard': {
        'ru-RU': 'Метель/вьюга',
        'en-US': 'Blizzard'
    },

    'Drought': {
        'ru-RU': 'Засуха',
        'en-US': 'Drought'
    },

    'Flood': {
        'ru-RU': 'Наводнение',
        'en-US': 'Flood'
    },

    'Smoke/fire': {
        'ru-RU': 'Дым/огонь',
        'en-US': 'Smoke/fire'
    },

    'Tornado': {
        'ru-RU': 'Смерч',
        'en-US': 'Tornado'
    },

    'Sandstorm': {
        'ru-RU': 'Песчаная буря',
        'en-US': 'Sandstorm'
    },

    'Below are the figures related to the General practice of Russian film and video production. In international production, you can choose one of three General concepts as close to the pricing of your country': {
        'ru-RU': 'Ниже приведены цифры, относящиеся к общей практике российского кино-видео производства. При международном производстве можно выбрать одно из трех общих понятий, как близкое к ценообразованию вашей страны',
        'en-US': 'Below are numbers relevant to the general practice of film/video production in Russia. For international production, please choose one of the three general items that suits your country best.'
    },

    'Low (1-30 million rubles)': {
        'ru-RU': 'Низкий (1-30 млн руб)',
        'en-US': 'Low (RUB 1–30 million)'
    },

    'Average (30-50 million rubles)': {
        'ru-RU': 'Средний (30-50 млн руб)',
        'en-US': 'Medium (RUB 30–50 million)'
    },

    'Large (from 50 million rubles)': {
        'ru-RU': 'Большой (от 50 млн руб)',
        'en-US': 'Big (from RUB 50 million)'
    },

    'Saving your profile to bookmarks': {
        'ru-RU': 'Сохранений профиля в закладки',
        'en-US': 'Profile bookmarks'
    },

    'Go to the profile': {
        'ru-RU': 'Заходы в профиль',
        'en-US': 'Profile visits'
    },

    'Views of works': {
        'ru-RU': 'Просмотров работ',
        'en-US': 'Views of projects'
    },

    'For all time': {
        'ru-RU': 'За все время',
        'en-US': 'Overall'
    },

    'For a month': {
        'ru-RU': 'За месяц',
        'en-US': 'Per month'
    },

    'For a week': {
        'ru-RU': 'За неделю',
        'en-US': 'Per week'
    },

    'For a day': {
        'ru-RU': 'За день',
        'en-US': 'Per day'
    },

    'Visits': {
        'ru-RU': 'Посещений',
        'en-US': 'Visits'
    },

    'Project colleagues have a 120-day trial period': {
        'ru-RU': 'Коллегам по проекту 70 дней пробного периода',
        'en-US': 'Your project colleagues get 70 free trial days'
    },

    'Friends 10 bonus days': {
        'ru-RU': 'Друзьям 10 бонусных дней',
        'en-US': 'Ten free days for your friends'
    },

    'For every 5 subscribers - 1 month for free' : {
        'ru-RU': 'За каждые 5 подписчиков - 1 месяц бесплатно',
        'en-US': 'You get one free month for each five new subscribers'
    },

    'Views': {
        'ru-RU': 'Просмотров',
        'en-US': 'Views'
    },

    'Saving works to bookmarks': {
        'ru-RU': 'Сохранений работ в закладки',
        'en-US': 'Project bookmarks'
    },

    'Low (0.5-1 million rubles)': {
        'ru-RU': 'Низкий (0,5-1 млн руб)',
        'en-US': 'Low (RUB 0,5–1 million)'
    },

    'Average (1-5 million rubles)': {
        'ru-RU': 'Средний (1-5 млн руб)',
        'en-US': 'Medium (RUB 1–5 million)'
    },

    'Large (from 5 million rubles)': {
        'ru-RU': 'Большой (от 5 млн руб)',
        'en-US': 'Big (from RUB 5 million)'
    },

    'Individual image characteristics to search for a specific image style': {
        'ru-RU': 'Характеристики изображения для поиска по определенной стилистике проекта',
        'en-US': 'Characteristics for delicated Pro search through visual style of project'
    },

    'One film school package includes 10 graduates. In the future, you can increase the number of packages': {
        'ru-RU': 'В один пакет киношколы входит 10 выпускников. В дальнейшем можно увеличить количество пакетов',
        'en-US': 'One film school package includes 10 graduates. In the future, you can increase the number of packages'
    },

    'Invite a graduate': {
        'ru-RU': 'Пригласить выпускника',
        'en-US': 'Invite a graduate'
    },

    'Add graduate': {
        'ru-RU': 'Добавить выпускника',
        'en-US': 'Add graduate'
    },

    'Paid by the agent': {
        'ru-RU': 'Оплачена агентом',
        'en-US': 'Paid by the agent'
    },

    'The text of the invitation': {
        'ru-RU': 'Текст приглашения',
        'en-US': 'The text of the invitation'
    },

    'Shooting location': {
        'ru-RU': 'Место съемки',
        'en-US': 'Type of location'
    },

    'Pay to display the work again': {
        'ru-RU': 'Оплатите, чтобы вновь отображать работу',
        'en-US': 'Pay to display the work again'
    },

    'In Russian': {
        'ru-RU': 'На русском',
        'en-US': 'In Russian'
    },

    'In English': {
        'ru-RU': 'На английском',
        'en-US': 'In English'
    },

    'New specialist': {
        'ru-RU': 'Новый специалист',
        'en-US': 'New specialist'
    },

    'Choose item': {
        'ru-RU': 'Выберите пункт',
        'en-US': 'Choose item'
    },

    'Choose one or more items': {
        'ru-RU': 'Выберите один или несколько пунктов',
        'en-US': 'Choose one or several items',
    },

    'Select to display the work': {
        'ru-RU': 'Выберите, чтобы отображать работу',
        'en-US': ' Please select profession / area to display project.'
    },

    'In the sections, there are jobs that are not displayed with a marked unpaid profession': {
        'ru-RU': 'В разделах есть работы, которые не отображаются в профиле. Нужно отметить хотя бы одну профессию в ней.',
        'en-US': 'There are projects in the sections that are not displayed in the profile. It is necessary to note at least one profession in it.'
    },

    'In the sections, there are jobs that are not displayed with a marked unpaid direction': {
        'ru-RU': 'В разделах есть работы, которые не отображаются в профиле. Нужно отметить хотя бы одно направление в ней.',
        'en-US': 'There are projects in the sections that are not displayed in the profile. It is necessary to note at least one direction in it.'
    },

    'There are jobs in the sections that are not displayed with a profession that is not selected': {
        'ru-RU': 'В разделах есть работы, которые не отображаются из-за невыбранной профессии.',
        'en-US': 'Folders have projects that are not displayed because the profession is not selected.'
    },

    'There are works in folders that are not displayed because of an unselected direction': {
        'ru-RU': 'В разделах есть работы, которые не отображаются из-за невыбранного направления компании.',
        'en-US': 'Folders have projects that are not displayed because the area is not selected.'
    },

    'Your subscription has expired': {
        'ru-RU': 'Истекла подписка',
        'en-US': 'Your subscription has expired'
    },

    'No profession selected': {
        'ru-RU': 'Не выбрана профессия',
        'en-US': 'Profession not selected'
    },

    'No direction selected': {
        'ru-RU': 'Не выбрано направление',
        'en-US': 'No direction selected'
    },

    'Worldwide': {
        'ru-RU': 'Весь мир',
        'en-US': 'Worldwide'
    },

    'The chosen profession has no different classifications': {
        'ru-RU': 'У выбранной профессии нет различных классификаций',
        'en-US': 'The chosen profession has no different classifications'
    },

    'In the pavilion': {
        'ru-RU': 'В павильоне',
        'en-US': 'On stage'
    },

    'MonthShort': {
        'ru-RU': 'Мес',
        'en-US': 'Month'
    },

    'Stylistics': {
        'ru-RU': 'Стилистика',
        'en-US': 'Low & high light'
    },

    'Low key': {
        'ru-RU': 'Низкий ключ',
        'en-US': 'Low key'
    },

    'High key': {
        'ru-RU': 'Высокий ключ',
        'en-US': 'High key'
    },

    'Contrast': {
        'ru-RU': 'Контраст',
        'en-US': 'Contrast'
    },

    'Low': {
        'ru-RU': 'Низкий',
        'en-US': 'Low'
    },

    'High': {
        'ru-RU': 'Высокий',
        'en-US': 'High'
    },

    'Under natural': {
        'ru-RU': 'Под натуральный',
        'en-US': 'Natural'
    },

    'Stylized': {
        'ru-RU': 'Стилизованный',
        'en-US': 'Staged'
    },

    'Light': {
        'ru-RU': 'Свет',
        'en-US': 'Style of light'
    },

    'Roll up': {
        'ru-RU': 'Свернуть',
        'en-US': 'Hide'
    },

    'Color': {
        'ru-RU': 'Цвет',
        'en-US': 'Color'
    },

    'Deep': {
        'ru-RU': 'Насыщенный',
        'en-US': 'Saturated'
    },

    'Solid': {
        'ru-RU': 'Спокойный',
        'en-US': 'Muted'
    },

    'Frame format': {
        'ru-RU': 'Формат кадра',
        'en-US': 'Aspect ratio'
    },

    'Changes were saved': {
        'ru-RU': 'Изменения были сохранены',
        'en-US': 'Changes were saved'
    },

    'For IMAX': {
        'ru-RU': 'Для IMAX',
        'en-US': 'For IMAX'
    },

    'Handheld camera': {
        'ru-RU': 'Ручная камера',
        'en-US': 'Hand-held camera'
    },

    'Film/digital/phone': {
        'ru-RU': 'Пленка/цифра/телефон',
        'en-US': 'Film/digital/phone'
    },

    'Camera model': {
        'ru-RU': 'Модель камеры',
        'en-US': 'Camera model'
    },

    'Remove': {
        'ru-RU': 'Удалить',
        'en-US': 'Delete'
    },

    'Lens': {
        'ru-RU': 'Объективы',
        'en-US': 'Lenses'
    },

    'Lens type': {
        'ru-RU': 'Тип объектива',
        'en-US': 'Lens type'
    },

    'Change year': {
        'ru-RU': 'Изменить год',
        'en-US': 'Change year'
    },

    'Change': {
        'ru-RU': 'Изменить',
        'en-US': 'Change'
    },

    'Change your phone number': {
        'ru-RU': 'Изменить номер телефона',
        'en-US': 'Change your phone number'
    },

    'An sms message will be sent to confirm your phone number': {
        'ru-RU': 'Для подтверждения телефона вам будет выслано sms-сообщение',
        'en-US': 'An sms message will be sent to confirm your phone number'
    },

    'Subscription': {
        'ru-RU': 'Подписка',
        'en-US': 'Subscription'
    },

    'Confirm': {
        'ru-RU': 'Подтвердить',
        'en-US': 'Confirm'
    },

    'Send code': {
        'ru-RU': 'Выслать код',
        'en-US': 'Send code'
    },

    'Wrong code': {
        'ru-RU': 'Неверный код',
        'en-US': 'Wrong code'
    },

    'Confirmation code': {
        'ru-RU': 'Код подтверждения',
        'en-US': 'Confirmation code'
    },

    'To subscribe, add a specialty in the "Personal data" section and wait for confirmation. By subscribing, you will be able to find the chosen specialty': {
        'ru-RU': 'Чтобы подписаться, добавьте специальность в разделе «Личные данные» и дождитесь подтверждения. Оформив подписку, вас смогут находить по выбранной специальности',
        'en-US': 'To subscribe, add a specialty in the "Personal data" folder and wait for confirmation. By subscribing, you will be able to find the chosen specialty'
    },

    'Paid until': {
        'ru-RU': 'Оплачено до',
        'en-US': 'Paid until'
    },

    'Paid': {
        'ru-RU': 'Оплачено',
        'en-US': 'Paid'
    },

    'You must specify the full page address': {
        'ru-RU': 'Необходимо указать полный адрес страницы',
        'en-US': 'Add the full link to your page'
    },

    'Invite to the team': {
        'ru-RU': 'Приглашай в команду',
        'en-US': 'Invite people or companies to the team'
    },

    'So that you have one project for all, that you were working on': {
        'ru-RU': 'И у вас будет одинаковый проект для всех участников для отображения в личном кабинете.',
        'en-US': 'And you will have a joint project to display in the personal account.'
    },

    'For every 5 subscribers — 1 month free of charge': {
        'ru-RU': 'За каждые 5 подписчиков — 1 месяц бесплатно',
        'en-US': 'You get one free month for each five new subscribers'
    },

    'Share a link': {
        'ru-RU': 'Делись ссылкой',
        'en-US': 'Share link'
    },

    'Referral link': {
        'ru-RU': 'Реферальная ссылка',
        'en-US': 'Referral link'
    },

    'You can use this link to register your friends to receive free months of subscriptions. It can be used in social networks. networks, via email or SMS:': {
        'ru-RU': 'В разделе Команда вашего проекта можно пригласить ваших коллег, с которыми вы этот проект делали. Или использовать ссылку ниже, чтобы получать месяцы бесплатной подписки. Ее можно указывать в социальных сетях, через email или смс:',
        'en-US': 'Invite colleagues you worked with in the Credits section of your project. Or use the link below to get free subscription months. Post it on social media websites or send via email or SMS:'
    },

    '- friends will get an additional free period': {
        'ru-RU': '- друзья получат 10 бонусных дней подписки',
        'en-US': '- friends will receive 10 bonus days of subscription'
    },

    '- 2 months - for every 5 friends You have-1 month free period': {
        'ru-RU': '- вы получите за каждых 5 друзей, оформивших подписку - 1 месяц бесплатного периода',
        'en-US': '- you will get 1 month free period for every 5 friends who subscribe'
    },

    'Used': {
        'ru-RU': 'Использован',
        'en-US': 'Used'
    },

    'In Facebook, Vkontakte, chats and conversations': {
        'ru-RU': 'В Facebook, ВКонтакте, чатах и переписках',
        'en-US': 'On Facebook, in chats and messengers'
    },

    'Copy the link': {
        'ru-RU': 'Скопировать ссылку',
        'en-US': 'Copy link'
    },

    'Promo codes': {
        'ru-RU': 'Статус промокодов',
        'en-US': 'Promocode status'
    },

    'You can use the promo code when paying for a new account. To prevent the free period from being burned out, we recommend using the free months received in advance': {
        'ru-RU': 'Промокод можно использовать при оплате за новый счет. Чтобы бесплатный период не сгорел, рекомендуем заранее использовать полученные бесплатные месяцы. Промокод начисляется за каждые 5 приглашенных со статусом "Подтверждено", которые оформили пробную подписку.',
        'en-US': 'You can use the code when paying a new invoice. We recommend using free months in advance so that they do not expire. A code is awarded for each five invited people with the Confirmed status who subscribed for a trial period.'
    },

    'Add friend': {
        'ru-RU': 'Добавить друга',
        'en-US': 'Add friend'
    },

    'Invited': {
        'ru-RU': 'Приглашенные',
        'en-US': 'Invited'
    },

    'Valid until': {
        'ru-RU': 'Действует до',
        'en-US': 'Valid until'
    },

    'Link copied': {
        'ru-RU': 'Ссылка скопирована',
        'en-US': 'Link copied'
    },

    'Not paid': {
        'ru-RU': 'Не оплачено',
        'en-US': 'Not paid'
    },

    'After saving a job, you will not be able to change the job type.': {
        'ru-RU': 'После загрузки вы не сможете поменять тип работы.',
        'en-US': 'When uploaded, you will not be able to change the project type.'
    },

    'Cancel the subscription': {
        'ru-RU': 'Отменить подписку',
        'en-US': 'Cancel subscription'
    },

    'You don\'t have works in your bookmarks': {
        'ru-RU': 'Вы пока не добавили работы в закладки.',
        'en-US': 'You have no projects bookmarked.'
    },

    'Select the type of work to add and specify the link': {
        'ru-RU': 'Выберите тип добавляемой работы и укажите ссылку',
        'en-US': 'Choose the project type and add link'
    },

    'Pay': {
        'ru-RU': 'Оплатить',
        'en-US': 'Pay'
    },

    'Do you really want to delete the work from bookmarks?': {
        'ru-RU': 'Вы действительно хотите удалить работу из закладок?',
        'en-US': 'Do you really want to delete the project from bookmarks?'
    },

    'Do you really want to delete the card?': {
        'ru-RU': 'Вы действительно хотите удалить карту?',
        'en-US': 'Do you really want to delete the card?'
    },

    'Free': {
        'ru-RU': 'Бесплатно',
        'en-US': 'Free'
    },

    'Select an image area': {
        'ru-RU': 'Выберите область изображения',
        'en-US': 'Select an image area'
    },

    'Do you really want to delete the company from bookmarks?': {
        'ru-RU': 'Вы действительно хотите удалить работу из закладок?',
        'en-US': 'Do you really want to delete the company from bookmarks?'
    },

    'Do you really want to delete the specialist from bookmarks?': {
        'ru-RU': 'Вы действительно хотите удалить специалиста из закладок?',
        'en-US': 'Do you really want to delete the specialist from bookmarks?'
    },

    'WorksBookmarks': {
        'ru-RU': 'Работ',
        'en-US': 'Projects'
    },

    'Clients': {
        'ru-RU': 'Клиенты',
        'en-US': 'Clients'
    },

    'To display in the profile': {
        'ru-RU': 'Отображать в профиле',
        'en-US': 'Edit summary tabs'
    },

    'Special skills': {
        'ru-RU': 'Спецнавыки',
        'en-US': 'Skills'
    },

    'Drag': {
        'ru-RU': 'Перетащить',
        'en-US': 'Drag'
    },

    'Communication': {
        'ru-RU': 'Связи',
        'en-US': 'Connections'
    },

    'Do you really want to delete the section?': {
        'ru-RU': 'Вы действительно хотите удалить раздел?',
        'en-US': 'Do you really want to delete the folder?'
    },

    'This work will also be removed from the work portfolio in the profiles of your team members': {
        'ru-RU': 'Эта работа так же будет удалена из портфолио работ в профилях ваших членов команды',
        'en-US': 'This project will also be removed from the project portfolio in the profiles of your team members'
    },

    'Awards': {
        'ru-RU': 'Награды',
        'en-US': 'Awards'
    },

    'Save and continue': {
        'ru-RU': 'Сохранить и продолжить',
        'en-US': 'Save and continue'
    },

    'Hide a section from the site': {
        'ru-RU': 'Скрыть вкладку с сайта',
        'en-US': 'Hide tab on the site'
    },

    'Don`t hide a section from the site': {
        'ru-RU': 'Не скрывать раздел с сайта',
        'en-US': 'Don`t hide a folder from the site'
    },

    'Information about the work': {
        'ru-RU': 'Информация, собранная из ваших работ',
        'en-US': 'Information taken from your projects'
    },

    'Collect information about your work in the profile tabs.': {
        'ru-RU': 'Эти позиции собираются и отображаются на главной странице вашего профиля. Можно менять отображение вкладок целиком или отдельных позиций, не влияя на отображение в самих работах.',
        'en-US': 'These positions are collected and displayed on the main page of your profile. You can change the display of tabs overall or that of certain positions. It will not affect the display in the projects themselves.'
    },

    'Bookmarks': {
        'ru-RU': 'Закладки',
        'en-US': 'Bookmarks'
    },

    'Expenses': {
        'ru-RU': 'Счета',
        'en-US': 'Invoices'
    },

    'UnpaidSeveral': {
        'ru-RU': 'Неоплаченные',
        'en-US': 'Not paid'
    },

    'PaidSeveral': {
        'ru-RU': 'Оплаченные',
        'en-US': 'Paid'
    },

    'All': {
        'ru-RU': 'Все',
        'en-US': 'All'
    },

    'Date of the deduction': {
        'ru-RU': 'Дата списания',
        'en-US': 'Date'
    },

    'The amount': {
        'ru-RU': 'Сумма',
        'en-US': 'Amount'
    },

    'Ruble': {
        'ru-RU': 'руб',
        'en-US': 'RUB'
    },

    'The work will be moved to the archive section': {
        'ru-RU': 'Работа будет перемещена в архивный раздел',
        'en-US': 'The project will be moved to the archive folder'
    },

    'Who works for a long time and has more than one award, a full-length film or a dozen videos': {
        'ru-RU': 'В работах необходимо иметь от 10 роликов, клипов или 1 полнометражный фильм',
        'en-US': 'In the projects, you must have at least 10 videos, clips, or 1 full-length film'
    },

    'Additional comment': {
        'ru-RU': 'Дополнительный комментарий',
        'en-US': 'Additional comment'
    },

    'ReadyPerson': {
        'ru-RU': 'готов',
        'en-US': 'ready'
    },

    'In work': {
        'ru-RU': 'в работе',
        'en-US': 'is being processed'
    },

    'Who starts, but has already made interesting projects': {
        'ru-RU': 'Различный опыт в кино и видео производстве',
        'en-US': 'Various experience in film and video production'
    },

    'Print out': {
        'ru-RU': 'Распечатать',
        'en-US': 'Print out'
    },

    'Disclose': {
        'ru-RU': 'Show',
        'en-US': 'Disclose'
    },

    'Back': {
        'ru-RU': 'Назад',
        'en-US': 'Back'
    },

    'Documentary serial': {
        'ru-RU': 'Документальный',
        'en-US': 'Documentary'
    },

    'Art serial': {
        'ru-RU': 'Художественный',
        'en-US': 'Fiction'
    },

    'PeopleBookmarks': {
        'ru-RU': 'Людей',
        'en-US': 'People'
    },

    'You don\'t have people in your bookmarks': {
        'ru-RU': 'Вы пока не добавили специалистов в закладки.',
        'en-US': 'You have no specialists bookmarked.'
    },

    'You don\'t have companies in your bookmarks': {
        'ru-RU': 'Вы пока не добавили компании в закладки.',
        'en-US': 'You have no companies bookmarked.'
    },

    'CompaniesBookmarks': {
        'ru-RU': 'Компаний',
        'en-US': 'Companies'
    },

    'Subscriptions of other personal accounts': {
        'ru-RU': 'Подписки других личных кабинетов',
        'en-US': 'Subscriptions of other personal accounts'
    },

    'Do you really want to delete the summary?': {
        'ru-RU': 'Вы действительно хотите удалить резюме?',
        'en-US': 'Do you want to delete your CV?'
    },

    'You have no requests': {
        'ru-RU': 'У вас нет запросов',
        'en-US': 'You have no requests'
    },

    'Manager\'s comment': {
        'ru-RU': 'Комментарий менеджера',
        'en-US': 'Manager\'s comment'
    },

    'People': {
        'ru-RU': 'Специалисты',
        'en-US': 'Specialists'
    },

    'Companies': {
        'ru-RU': 'Компании',
        'en-US': 'Companies'
    },

    'Do you really want to delete the search?': {
        'ru-RU': 'Вы действительно хотите удалить поиск?',
        'en-US': 'Do you really want to delete the search?'
    },

    'Do you really want to delete the work?': {
        'ru-RU': 'Вы действительно хотите удалить работу?',
        'en-US': 'Do you really want to delete the project?'
    },

    'Do you really want to delete the agent?': {
        'ru-RU': 'Вы действительно хотите удалить агента?',
        'en-US': 'Do you really want to delete the agent?'
    },

    'No, leave': {
        'ru-RU': 'Нет, оставить',
        'en-US': 'No, keep it'
    },

    'Yes, remove': {
        'ru-RU': 'Да, удалить',
        'en-US': 'Yes, delete'
    },

    'This action is irreversible': {
        'ru-RU': 'Это действие необратимо',
        'en-US': 'This cannot be undone.'
    },

    'One agent package includes 5 executors. In the future you can increase the number of packages': {
        'ru-RU': 'В один пакет агента входит 5 специалистов. В дальнейшем можно увеличить количество пакетов',
        'en-US': 'One agent package includes 5 specialist. In the future you can increase the number of packages'
    },

    'Add executor': {
        'ru-RU': 'Добавить специалиста',
        'en-US': 'Add specialist'
    },

    'Login details': {
        'ru-RU': 'Данные для входа',
        'en-US': 'Login details'
    },

    'Unpin': {
        'ru-RU': 'Открепить',
        'en-US': 'Unpin'
    },

    'Invite': {
        'ru-RU': 'Пригласить',
        'en-US': 'Invite'
    },

    'Skills used in this work': {
        'ru-RU': 'Навыки, используемые в этом проекте',
        'en-US': 'Skills used in this project'
    },

    'Without direction': {
        'ru-RU': 'Без направления',
        'en-US': 'Without direction'
    },

    'Site': {
        'ru-RU': 'Сайт',
        'en-US': 'Site'
    },

    'Shootings': {
        'ru-RU': 'Съемки',
        'en-US': 'Shootings'
    },

    'Choose direction': {
        'ru-RU': 'Выбрать направление',
        'en-US': 'Choose direction'
    },

    'Pavilion': {
        'ru-RU': 'Павильон',
        'en-US': 'Pavilion'
    },

    'Choose speciality': {
        'ru-RU': 'Выбрать специальность',
        'en-US': 'Choose speciality'
    },

    'Nature': {
        'ru-RU': 'Натура',
        'en-US': 'Nature'
    },

    'Add yourself and invite others to the team to have one project at all. Shooting is a team effort': {
        'ru-RU': 'Добавьте себя и пригласите других в команду, чтобы был один проект на всех. Съемки, это командная работа',
        'en-US': 'Add yourself and invite others to the team to have one project at all. Shooting is a team effort'
    },

    'If possible, specify the team members and companies that you did this project with. In the appropriate section, you can add existing people to the site or invite new ones. For every 5 subscribers': {
        'ru-RU': 'По возможности укажите членов команды и студии, с которыми Вы делали этот проект. Можно указать существующих пользователей Радара или пригласить новых. Они получат приглашение на email и бонусные дни при регистрации, а вы',
        'en-US': 'If possible, please add your team members and studios you worked on this project with. You can add existing Radar users or invite new one. They will receive an invitation via email and bonus days when registered, and you will get'
    },
    'a free subscription month.': {
        'ru-RU': 'бесплатный месяц подписки.',
        'en-US': 'a free subscription month.',
    },

    'This number is already in use': {
        'ru-RU': 'Данный номер уже используется',
        'en-US': 'This number is already in use'
    },

    'StudioShort': {
        'ru-RU': 'Студия',
        'en-US': 'Studio producers'
    },
    'StudioShort2': {
        'ru-RU': 'Студия',
        'en-US': 'Production'
    },

    'Post-production': {
        'ru-RU': 'Постпродакшн',
        'en-US': 'Post production'
    },

    'Computer graphics': {
        'ru-RU': 'Компьютерная графика',
        'en-US': 'VFX'
    },

    'Computer graphics studio': {
        'ru-RU': 'Студия компьютерной графики',
        'en-US': 'VFX studio'
    },

    'Sound and music': {
        'ru-RU': 'Звук и музыка',
        'en-US': 'Sound & music'
    },

    'Sound and music design': {
        'ru-RU': 'Звуковое и музыкальное оформление',
        'en-US': 'Sound & music studio'
    },

    'Invite a new executor': {
        'ru-RU': 'Пригласить нового специалиста',
        'en-US': 'Invite a new specialist'
    },

    'Sent with the prior oral consent of the recipient for cooperation': {
        'ru-RU': 'Отправляется с предварительным устным согласием получателя на сотрудничество',
        'en-US': 'Sent with the prior oral consent of the recipient for cooperation'
    },

    'Compound': {
        'ru-RU': 'Основной состав',
        'en-US': 'Department heads'
    },

    'PayYou': {
        'ru-RU': 'ОПЛАТИТЕ',
        'en-US': 'PLEASE MAKE PAYMENT'
    },

    'To display in search': {
        'ru-RU': 'для отображения в поиске',
        'en-US': 'to be displayed in search results'
    },

    'Paid to': {
        'ru-RU': 'Оплачено до',
        'en-US': 'Paid until'
    },

    'Confirmation by the administration is expected.': {
        'ru-RU': 'Ожидается подтверждение администрацией.',
        'en-US': 'Confirmation by the administration is expected.'
    },

    'Director\'s shop': {
        'ru-RU': 'Режиссерский цех',
        'en-US': 'Director department'
    },

    'Operator shop': {
        'ru-RU': 'Операторский цех',
        'en-US': 'Cinematographer department'
    },

    'Financial and administrative shop': {
        'ru-RU': 'Финансово-административный цех',
        'en-US': 'Producing & managing department'
    },

    'Art and production shop': {
        'ru-RU': 'Художественно-постановочный цех',
        'en-US': 'Art department'
    },

    'Makeup suit': {
        'ru-RU': 'Грим-костюм',
        'en-US': 'Makeup & Costumes'
    },

    'Sound(playground)': {
        'ru-RU': 'Звук(площадка)',
        'en-US': 'Set sound department'
    },

    'Other profession': {
        'ru-RU': 'Другие профессии',
        'en-US': 'Other crew'
    },

    'Services': {
        'ru-RU': 'Сервисные услуги',
        'en-US': 'Extra services'
    },

    'Editing and color correction': {
        'ru-RU': 'Монтаж и цветокоррекция',
        'en-US': 'Editing & color grading'
    },

    'Agency': {
        'ru-RU': 'Агентство',
        'en-US': 'Agency'
    },

    'Production': {
        'ru-RU': 'Продакшн',
        'en-US': 'Production'
    },

    'Direction in the project': {
        'ru-RU': 'Направление в проекте',
        'en-US': 'Direction in the project'
    },

    'Display the current status of your profession': {
        'ru-RU': 'Отображение действующего статуса вашей профессии',
        'en-US': 'Display your current profession status'
    },

    'Display the current status of your direction': {
        'ru-RU': 'Отображение действующего статуса вашего направления',
        'en-US': 'Display the current status of your direction'
    },

    'When looking to display direction separately in the profile': {
        'ru-RU': 'При поиске отображать направления по отдельности в профиле',
        'en-US': 'When looking to display direction separately in the profile'
    },

    'Invalid mail format': {
        'ru-RU': 'Неверный формат почты',
        'en-US': 'Invalid mail format'
    },

    'Invalid phone': {
        'ru-RU': 'Неверный телефон',
        'en-US': 'Invalid phone'
    },

    'Confirmation is expected': {
        'ru-RU': 'Ожидается подтверждение',
        'en-US': 'Confirmation is expected'
    },

    'Send a confirmation email': {
        'ru-RU': 'Отправить письмо с подтверждение',
        'en-US': 'Send a confirmation email'
    },

    'Email not confirmed': {
        'ru-RU': 'Почта не подтверждена',
        'en-US': 'Email not confirmed'
    },

    'Invite a executor': {
        'ru-RU': 'Пригласить специалиста',
        'en-US': 'Invite a specialist'
    },

    'Or you can invite a project participant if it is not in the list': {
        'ru-RU': 'Или Вы можете пригласить участника проекта, если его нет в списке',
        'en-US': 'Or you can invite a project participant if it is not in the list'
    },

    'You can invite a project participant if they are not in the list': {
        'ru-RU': 'Можете пригласить участника проекта, если его нет в списке',
        'en-US': 'You can invite a project participant if they are not in the list'
    },

    'Request sent': {
        'ru-RU': 'Выслан запрос',
        'en-US': 'Request sent'
    },

    'Send again': {
        'ru-RU': 'Выслать еще раз',
        'en-US': 'Send again'
    },

    'Send email again': {
        'ru-RU': 'Отправить еще раз',
        'en-US': 'Send again'
    },

    'Payment period': {
        'ru-RU': 'Период оплаты',
        'en-US': 'Paid period'
    },

    'Per month': {
        'ru-RU': 'За месяц',
        'en-US': 'Per month'
    },

    'For a year': {
        'ru-RU': 'За год',
        'en-US': 'For a year'
    },

    'Autopay': {
        'ru-RU': 'Автопродление',
        'en-US': 'Automatic subscription renewal'
    },

    'Will be debited': {
        'ru-RU': 'спишется',
        'en-US': 'will be debited on'
    },

    'Subscription expired': {
        'ru-RU': 'Подписка просрочена c',
        'en-US': 'Subscription expired from'
    },

    'Perpetual': {
        'ru-RU': 'Бессрочный',
        'en-US': 'Perpetual'
    },

    'You can resend the invitation in': {
        'ru-RU': 'Повторно отправить приглашение возможно через',
        'en-US': 'You can resend the invitation in'
    },

    'Pay automatically': {
        'ru-RU': 'Оплачивать автоматически',
        'en-US': 'Pay automatically'
    },

    'If your guild is not in the list, you can add your own name, and after confirmation, it will appear in your profile.': {
        'ru-RU': 'Если в списке нет вашей гильдии, Вы можете добавить свое название, и после подтверждения оно появится у вас в профиле.',
        'en-US': 'If your guild is not in the list, you can add your own name, and after confirmation, it will appear in your profile.'
    },

    'If your school is not in the list, you can add your name, and after confirmation, it will appear in your profile.': {
        'ru-RU': 'Если в списке нет вашего учебного заведения, Вы можете добавить свое название, и после подтверждения оно появится у вас в профиле.',
        'en-US': 'If your school is not in the list, you can add your name, and after confirmation, it will appear in your profile.'
    },

    'If your company is not in the list, you can add your name, and after confirmation, it will appear in your profile.': {
        'ru-RU': 'Если в списке нет вашей компании, Вы можете добавить свое название, и после подтверждения оно появится у вас в профиле.',
        'en-US': 'If your company is not in the list, you can add your name, and after confirmation, it will appear in your profile.'
    },

    'Send': {
        'ru-RU': 'Отправить',
        'en-US': 'Send'
    },

    'Paid. Confirmation by the administration is expected': {
        'ru-RU': 'Оплачено. Ожидается подтверждение администрацией',
        'en-US': 'Paid. Confirmation by the administration is expected'
    },

    'Do you really want to unsubscribe?': {
        'ru-RU': 'Вы действительно хотите отменить подписку?',
        'en-US': 'Do you really want to unsubscribe?'
    },

    'Yes, cancel': {
        'ru-RU': 'Да, отменить',
        'en-US': 'Yes, cancel'
    },

    'Expenses not found': {
        'ru-RU': 'Счета не найдены',
        'en-US': 'Invoices not found'
    },

    'By subscribing, you can search and submit requests without restrictions': {
        'ru-RU': 'Оформив подписку, вы можете искать и подавать запросы без ограничений',
        'en-US': 'By subscribing, you can search and submit requests without restrictions'
    },

    'To pay for a subscription?': {
        'ru-RU': 'Оплатить подписку?',
        'en-US': 'Pay for the subscription?'
    },

    'Loading': {
        'ru-RU': 'Загрузка',
        'en-US': 'Loading'
    },

    'Load new photo': {
        'ru-RU': 'Загрузить новое',
        'en-US': 'Upload new image'
    },

    'To pay for a service?': {
        'ru-RU': 'Оплатить услугу?',
        'en-US': 'Pay for the service?'
    },

    'You will be redirected to the payment page.': {
        'ru-RU': 'Вы будете переадресованы на страницу оплаты.',
        'en-US': 'You will be taken to the payment page.'
    },

    'Will be active until': {
        'ru-RU': 'Будет активно до',
        'en-US': 'Will active until'
    },

    'Price': {
        'ru-RU': 'Стоимость',
        'en-US': 'Price'
    },

    'Discount': {
        'ru-RU': 'Скидка',
        'en-US': 'Discount'
    },

    'TotalPrice': {
        'ru-RU': 'Итог',
        'en-US': 'Total'
    },

    'The current subscription price will be': {
        'ru-RU': 'Текущая стоимость подписки составит',
        'en-US': 'The current subscription price will be'
    },

    'RubleFull': {
        'ru-RU': 'Рубль',
        'en-US': 'Ruble'
    },

    'Promo code': {
        'ru-RU': 'промокод',
        'en-US': 'promocode'
    },

    'Invite an executor': {
        'ru-RU': 'Пригласить специалиста',
        'en-US': 'Invite an specialist'
    },

    'Invite an company': {
        'ru-RU': 'Пригласить компанию',
        'en-US': 'Invite an company'
    },

    'PleaseAddWorks': {
        'ru-RU': 'ДОБАВЬТЕ РАБОТЫ',
        'en-US': 'ADD PROJECTS'
    },

    'To confirm the classification': {
        'ru-RU': 'Для подтверждения классификации',
        'en-US': 'To confirm your classification'
    },

    'When you subscribe, you will be able to search without restrictions': {
        'ru-RU': 'Оформив подписку, вы сможете искать без ограничений',
        'en-US': 'When you subscribe, you will be able to search without restrictions'
    },

    'Rub / month': {
        'ru-RU': 'руб / мес',
        'en-US': 'RUB / per month'
    },

    'Rub / year': {
        'ru-RU': 'руб / год',
        'en-US': 'RUB / per year'
    },

    'Rub': {
        'ru-RU': 'руб',
        'en-US': 'RUB'
    },

    'SheConfirmed': {
        'ru-RU': 'Подтверждена',
        'en-US': 'Confirmed'
    },

    'Confirmed': {
        'ru-RU': 'Подтверждено',
        'en-US': 'Confirmed'
    },

    'Auto payment included': {
        'ru-RU': 'Автоплатеж включен',
        'en-US': 'Auto payment included'
    },

    'In the next step': {
        'ru-RU': 'На следующем шаге',
        'en-US': 'In the next step'
    },

    'Further': {
        'ru-RU': 'Далее',
        'en-US': 'Next'
    },

    'The subscription price will change from the next payment date': {
        'ru-RU': 'Стоимость подписки изменится со следующей даты оплаты',
        'en-US': 'The subscription price will change from the next payment date'
    },

    'Upload images in png or jpg format.': {
        'ru-RU': 'Загрузите изображения в формате png или jpg.',
        'en-US': 'Upload .png or .jpg images.'
    },

    'Monthly subscription': {
        'ru-RU': 'Месячная подписка',
        'en-US': 'Monthly subscription'
    },

    'Annual subscription': {
        'ru-RU': 'Годовая подписка',
        'en-US': 'Annual subscription'
    },

    'Subscription period': {
        'ru-RU': 'Срок подписки',
        'en-US': 'Subscription period'
    },

    'By subscribing, you can add 5 more performers to your team': {
        'ru-RU': 'Оформив подписку, вы можете добавлять в команду специалистов на 5 человек больше',
        'en-US': 'By subscribing, you can add 5 more performers to your team'
    },

    'The file size does not exceed': {
        'ru-RU': 'Объем файла не должен превышать',
        'en-US': 'File sizeshould not exceed'
    },

    ', and its dimensions are not less than': {
        'ru-RU': ', а его размер не менее',
        'en-US': ', and it should be at least'
    },

    'Edit subscription': {
        'ru-RU': 'Редактировать подписку',
        'en-US': 'Edit subscription'
    },

    'Current subscription': {
        'ru-RU': 'Текущая подписка',
        'en-US': 'Current subscription'
    },

    'New number of packages': {
        'ru-RU': 'Добавить',
        'en-US': 'Add'
    },

    'Number of packets': {
        'ru-RU': 'Количество пакетов',
        'en-US': 'Number of packets'
    },

    'Specify the number of packets': {
        'ru-RU': 'Укажите количество пакетов',
        'en-US': 'Specify the number of packets'
    },

    'Difference in the number of packets': {
        'ru-RU': 'Разница в количестве пакетов',
        'en-US': 'Difference in the number of packages'
    },

    'Total cost for the period': {
        'ru-RU': 'Итоговая стоимость за период',
        'en-US': 'Total cost for the period'
    },

    'You have no clients': {
        'ru-RU': 'В работах не указаны клиенты',
        'en-US': 'No clients specified in projects'
    },

    'You don\'t have special skills': {
        'ru-RU': 'В работах не указаны навыки',
        'en-US': 'No skills specified in projects'
    },

    'You have no communications': {
        'ru-RU': 'В работах не указаны контакты',
        'en-US': 'No contacts specified in projects'
    },

    'You don\'t have any awards': {
        'ru-RU': 'В работах не указаны награды',
        'en-US': 'No awards specified in projects'
    },

    'You have unsaved data': {
        'ru-RU': 'У вас есть несохраненные данные',
        'en-US': 'You have unsaved data'
    },

    'Yes': {
        'ru-RU': 'Да',
        'en-US': 'Yes'
    },

    'No': {
        'ru-RU': 'Нет',
        'en-US': 'No'
    },

    'You want to leave the page?': {
        'ru-RU': 'Вы хотите уйти со страницы?',
        'en-US': 'You want to leave the page?'
    },

    'Subscription to works': {
        'ru-RU': 'Подписка на работы',
        'en-US': 'Subscription to projects'
    },

    'Nothing found': {
        'ru-RU': 'Ничего не найдено',
        'en-US': 'Nothing found'
    },

    'Added': {
        'ru-RU': 'Добавил',
        'en-US': 'Added by'
    },

    'You don\'t have any specializations': {
        'ru-RU': 'У вас нет специальностей',
        'en-US': 'You don\'t have any specializations'
    },

    'You don\'t have directions': {
        'ru-RU': 'У вас нет направлений',
        'en-US': 'You don\'t have directions'
    },

    'You need to select a field from the list': {
        'ru-RU': 'Вам необходимо выбрать поле из списка',
        'en-US': 'You need to select a field from the list'
    }
};

export { translations };
