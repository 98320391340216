import React, { useState, useEffect } from 'react';

import { PaymentCurrent, PaymentExpenses, PaymentOther, PaymentCards } from 'components/pages';
import { CabinetContent } from 'components/containers';

import { inject, observer } from 'mobx-react';
import { prepareSubscription } from 'helpers';

import './_Payment.scss';

const Payment = inject('CabinetStore', 'InvoicesStore', 'CardsStore', 'LoadingStore')(observer((({ CabinetStore, InvoicesStore, CardsStore, LoadingStore }) => {
    const { subscriptions, getAllSubscriptions } = CabinetStore;
    const { invoices, getInvoices } = InvoicesStore;
    const { cards, getCards } = CardsStore;
    const { setLoading } = LoadingStore;

    const [isLoading, setIsLoading] = useState(cards.length === 0 && invoices.length === 0 && Object.keys(subscriptions).every(type => !subscriptions[type]));

    const titles = {
        'studio': 'By subscribing, you can search and submit requests without restrictions',
        'executor': 'To subscribe, add a specialty in the "Personal data" section and wait for confirmation. By subscribing, you will be able to find the chosen specialty',
        'client': 'When you subscribe, you will be able to search without restrictions',
        'agent': 'By subscribing, you can add 5 more performers to your team',
        'filmSchool': 'By subscribing, you can add graduates to your school'
    };

    useEffect(() => {
        const promise = Promise.all([getAllSubscriptions(), getInvoices(), getCards()]);
        
        if (isLoading) {
            setLoading('mainContent', true);

            promise
                .then(() => {
                    setIsLoading(false);
                    setLoading('mainContent', false);
                });

            return () => {
                setLoading('mainContent', false);
            }
        }
    }, []);

    return !isLoading && (
        <div className = 'Payment'>
            <CabinetContent>
                <PaymentCurrent titles = { titles } prepareSubscription = { prepareSubscription } />
                <PaymentExpenses prepareSubscription = { prepareSubscription } />
                <PaymentCards />
                <PaymentOther titles = { titles } prepareSubscription = { prepareSubscription } />
            </CabinetContent>
        </div>
    )
})));

export { Payment };