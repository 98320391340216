// Генератор id

const idArr = [];
const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateId = (length = 10) => {
    const id = [];

    for (let i = 0; i < length; i++) {
        id.push(characters.charAt(Math.floor(Math.random() * characters.length)));
    }

    return idArr.includes(id.join('')) ? generateId(length) : id.join('');
};