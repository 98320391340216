import React from 'react';

import classNames from 'classnames';

import './_MainContentPreloader.scss';

const MainContentPreloader = ({ loading }) => {
    const MainContentPreloaderClass = classNames('MainContentPreloader', {
        'MainContentPreloader--hide': !loading
    });

    return (
        <div className = { MainContentPreloaderClass }>
            <svg className = 'MainContentPreloader__spinner' width = '40' height = '40'>
                <circle className = 'MainContentPreloader__spinnerCircle' strokeWidth = '3' strokeLinecap = 'round' cx = '20' cy = '20' r = '17'></circle>
            </svg>
        </div>
    )
};

export { MainContentPreloader };