import React from 'react';

import { Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';
import classNames from 'classnames';

import './_CabinetAccordion.scss';

const CabinetAccordion = inject('LanguageStore')(observer(({ title, text, isOpened, onOpenClick, children, LanguageStore }) => {
    const { language } = LanguageStore;

    const CabinetAccordionClass = classNames('CabinetAccordion', {
        'CabinetAccordion--opened': isOpened
    });

    // Останока всплытия
    function stopPropagation(event) {
        event.stopPropagation();
    }

    return (
        <div className = { CabinetAccordionClass }>
            <div className = 'CabinetAccordion__head' onClick = { onOpenClick }>
                <Icon name = 'arrow-long' className = 'CabinetAccordion__showArrow' />

                <div className = 'CabinetAccordion__info'>
                    <div className = 'CabinetAccordion__infoLeft'>
                        <span className = 'CabinetAccordion__name'>
                            { title }
                        </span>
                    </div>

                    <div className = 'CabinetAccordion__infoRight'>
                        <span className = 'CabinetAccordion__count'>
                            { text }
                        </span>
                    </div>
                </div>
            </div>

            <div className = 'CabinetAccordion__body'>
                <div className = 'CabinetAccordion__content' onClick = { stopPropagation }>
                    { children }
                </div>

                <div className = 'CabinetAccordion__hide' onClick = { onOpenClick }>
                    { translate('Hide', language) } ↑
                </div>
            </div>
        </div>
    )
}));

export { CabinetAccordion };