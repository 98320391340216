import React from 'react';

import { Tooltip, Icon } from 'components/ui';

import { observer, inject } from 'mobx-react';
import { translate } from 'helpers';

import './_ClassificationTooltip.scss';

const ClassificationTooltip = inject('LanguageStore')(observer(({ wrapClassName, direction, position, children, LanguageStore }) => {
    const { language } = LanguageStore;

    return (
        <Tooltip wrapClassName = { wrapClassName } direction = { direction } position = { position }>
            { children }

            <div className = 'Tooltip__content'>
                <div className = 'ClassficationTooltip'>
                    <div className = 'ClassficationTooltip__section'>
                        <Icon name = { `pending-${language}` } className = 'ClassficationTooltip__icon' />
                        — { translate('Your occupation with the classification "pro", "newblood" or "graduate" requires confirmation', language).toLowerCase() }
                    </div>

                    <div className = 'ClassficationTooltip__section'>
                        <Icon name = { `pro-${language}` } className = 'ClassficationTooltip__icon' />
                        — { translate('10 videos, clips, or 1 full-length movie', language).toLowerCase() }
                    </div>

                    <div className = 'ClassficationTooltip__section'>
                        <Icon name = { `new-blood-${language}` } className = 'ClassficationTooltip__icon' />
                        — { translate('Various experience in film and video projects', language).toLowerCase() }
                    </div>

                    <div className = 'ClassficationTooltip__section'>
                        <Icon name = { `specialist-${language}` } className = 'ClassficationTooltip__icon' />
                        — { translate('Film and video industry employee', language).toLowerCase() }
                    </div>

                    <div className = 'ClassficationTooltip__section'>
                        <Icon name = { `graduated-${language}` } className = 'ClassficationTooltip__icon' />
                        — { translate('First two years after film school', language).toLowerCase() }
                    </div>
                </div>
            </div>
        </Tooltip>
    )
}));

export { ClassificationTooltip };