import React from 'react';

import { inject, observer } from 'mobx-react';
import { translate } from 'helpers';

const WorkCharacteristicsOther = inject('LanguageStore')(observer(({ LanguageStore }) => {
    const { language } = LanguageStore;

    return (
        <div className = 'WorkCharacteristics'>
            <span className = 'WorkCharacteristics__notFound'>
                { translate('For this type of work there are no additional characteristics', language) }
            </span>
        </div>
    )
}));

export { WorkCharacteristicsOther };