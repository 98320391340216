// Хранилище с вкладками
import { action, observable, runInAction, decorate } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class TabsStore {
    tabs = {}

    // Получение вкладок
    async getTabs() {
        const response = await request(`${BASE_URL}/api/user/work-info/all`);
        const data = await response.json();

        runInAction(() => this.tabs = data);
    
        return response
    }

    // Изменение видимости вкладки
    async changeTabShow({ name, id, equalFunc, options = {} }) {
        const tab = this.tabs[name];
        const isChangeItem = id !== undefined;
        const item = isChangeItem ? tab.items.find(equalFunc) : tab;
        const action = item.show ? 'hide' : 'show';

        const url = `${BASE_URL}/api/user/work-info/${name}${isChangeItem ? `/${id}` : ''}/${action}`;
        const responseOptions = { method: 'POST', body: JSON.stringify(options) };
        const response = await request(url, responseOptions);
        
        if (response.status === 200) {
            runInAction(() => item.show = !item.show);
        }
    }

    async changeItemsSort(name, items) {
        const options = { method: 'POST', body: JSON.stringify({ items }) };
        const response = await request(`${BASE_URL}/api/user/work-info/${name}/sort`, options);
        const data = await response.json();

        if (response.status === 200) {
            runInAction(() => this.tabs[name].items = data.items);
        }
    }
}

decorate(TabsStore, {
    tabs: observable,
    getTabs: action.bound,
    changeTabShow: action.bound,
    changeItemsSort: action.bound
});

export default new TabsStore();