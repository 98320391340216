import React, { useState } from 'react';

import { CabinetSection, ImageWithCropper } from 'components/containers';
import { Input, Button, Tooltip, Icon } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, getYouTubeVideoId, getImageFullPath } from 'helpers';

import './_WorkBackstageVideos.scss';

const WorkBackstageVideos = inject('LanguageStore', 'WorksStore', 'ToolsStore')(observer(({ LanguageStore, WorksStore, ToolsStore }) => {
    const [inputValue, setInputValue] = useState(undefined);
    const [firstVideoModalOpened, setFirstVideoModalOpened] = useState(false);
    const [secondVideoModalOpened, setSecondVideoModalOpened] = useState(false);

    const { language } = LanguageStore;
    const { currentWork, updateCurrentWorkLocal, updateCurrentWorkErrors } = WorksStore;
    const { getPreviewFromLink } = ToolsStore;

    const { video } = currentWork.backstage;

    const firstVideo = video[0] || {};
    const secondVideo = video[1] || {};
    
    const videoLinkError = currentWork.errors.backstage && currentWork.errors.backstage.link;

    // Отлов клика по кнопке
    const handleButtonClick = () => {
        getPreviewFromLink(inputValue.trim(), link => {
            const workBackstageCopy = Object.clone(currentWork.backstage);
            workBackstageCopy.video.push({ link: inputValue.trim(), preview: link });

            updateCurrentWorkLocal({ backstage: workBackstageCopy });
            
            setInputValue(undefined);
        }).catch(data => {
            updateCurrentWorkErrors('backstage', { link: data.errors.link });
        });
    };

    // Отлов ввода в поле ввода
    const handleInputChange = event => {
        const input = event.target;
        const value = input.value;

        setInputValue(value);
        updateCurrentWorkErrors('backstage', { link: null });
    };

    // Удаление ролика
    const removeVideo = event => {
        const removeWrap = event.currentTarget;
        const videoItem = removeWrap.closest('.WorkBackstageVideos__item');
        const videoIndex = JSON.parse(videoItem.dataset.videoIndex);

        const workBackstageCopy = Object.clone(currentWork.backstage);
        workBackstageCopy.video.splice(videoIndex, 1);

        updateCurrentWorkLocal({ backstage: workBackstageCopy });
    };

    // Удаление своего провью
    const removeCustomPreview = event => {
        const removeWrap = event.currentTarget;
        const videoItem = removeWrap.closest('.WorkBackstageVideos__item');
        const videoIndex = JSON.parse(videoItem.dataset.videoIndex);

        const workBackstageCopy = Object.clone(currentWork.backstage);
        workBackstageCopy.video[videoIndex].customPreview = null;

        updateCurrentWorkLocal({ backstage: workBackstageCopy });
    };

    // Установка своего превью
    const setCustomPreview = (index, link) => {
        const workBackstageCopy = Object.clone(currentWork.backstage);
        workBackstageCopy.video[index].customPreview = link;

        updateCurrentWorkLocal({ backstage: workBackstageCopy });
    };

    return (
        <CabinetSection padding = { true } bordered = { true } bgColor = 'white'>
            <div className = 'CabinetSection__title'>
                <span className = 'CabinetSection__titleText'>{ translate('Video', language) }</span>
            </div>

            <div className = 'CabinetSection__content'>
                <div className = 'WorkBackstageVideos'>
                    <div className = 'WorkBackstageVideos__item' data-video-index = { 0 }>
                        { firstVideo.link ? (
                            <div className = 'WorkBackstageVideos__itemVideo'>
                                {  /vimeo.com/.test(firstVideo.link) 
                                ? (
                                    <iframe width = '100%' height = '245'  src = { firstVideo.link.replace(/vimeo.com\//, 'player.vimeo.com/video/') } frameBorder = '0' allow = 'autoplay; fullscreen' allowFullScreen>
                                    </iframe>
                                ) 
                                : /rutube.ru/.test(firstVideo.link)     
                                    ? (
                                        <iframe width = '100%' height = '245'  src = { firstVideo.link.replace(/video\//, '/embed/') } frameBorder = '0' allow = 'autoplay; fullscreen' allowFullScreen>
                                        </iframe>
                                    )
                                    : (
                                        <iframe width = '100%' height = '245' src = { `https://www.youtube.com/embed/${getYouTubeVideoId(firstVideo.link)}` } frameBorder = '0' allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen>
                                        </iframe>
                                    )
                            }

                                <Tooltip wrapClassName = 'WorkBackstageVideos__itemRemoveTooltip' text = { translate('Remove video', language) } position = 'left'>
                                    <div className = 'WorkBackstageVideos__itemRemoveWrap' onClick = { removeVideo }>
                                        <Icon name = 'cross-tiny' className = 'WorkBackstageVideos__itemRemove' />
                                    </div>
                                </Tooltip>
                            </div>
                        ) : (
                            <div className = 'WorkBackstageVideos__itemInput'>
                                <Input label = { translate('Link to the video (YouTube / Vimeo)', language) }
                                       value = { inputValue }
                                       error = { videoLinkError }
                                       onChangeHandle = { handleInputChange } />

                                <Button content = {{ text: translate('Upload', language) }}
                                        disabled = { !inputValue }
                                        onClick = { handleButtonClick } />
                            </div>
                        ) }

                        <ImageWithCropper src = { firstVideo.customPreview ? getImageFullPath(firstVideo.customPreview) : getImageFullPath(firstVideo.preview) }
                                          size = 'md'
                                          modalOpened = { firstVideoModalOpened }
                                          setModalOpened = { setFirstVideoModalOpened }
                                          requirements = {{ minWidth: 533, minHeight: 300 }}
                                          settings = {{ minCropBoxWidth: 88, minCropBoxHeight: 50, aspectRatio: 16 / 9 }}
                                          bgImg = 'mountain'
                                          buttonText = { translate('Change preview', language) }
                                          disabled = { !firstVideo.preview }
                                          onImageLoadOnServer = { setCustomPreview.bind(null, 0) }
                                          onRemoveClick = { firstVideo.customPreview && removeCustomPreview } />
                    </div>

                    { firstVideo.link && (
                        <div className = 'WorkBackstageVideos__item' data-video-index = { 1 }>
                            { secondVideo.link ? (
                                <div className = 'WorkBackstageVideos__itemVideo'>
                                    {  /vimeo.com/.test(secondVideo.link) 
                                        ? (
                                            <iframe width = '100%' height = '245'  src = { secondVideo.link.replace(/vimeo.com\//, 'player.vimeo.com/video/') } frameBorder = '0' allow = 'autoplay; fullscreen' allowFullScreen>
                                            </iframe>
                                        ) 
                                        : /rutube.ru/.test(secondVideo.link)     
                                            ? (
                                                <iframe width = '100%' height = '245'  src = { secondVideo.link.replace(/video\//, '/embed/') } frameBorder = '0' allow = 'autoplay; fullscreen' allowFullScreen>
                                                </iframe>
                                            )
                                            : (
                                                <iframe width = '100%' height = '245' src = { `https://www.youtube.com/embed/${getYouTubeVideoId(secondVideo.link)}` } frameBorder = '0' allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen>
                                                </iframe>
                                            )
                                    }

                                    <Tooltip wrapClassName = 'WorkBackstageVideos__itemRemoveTooltip' text = { translate('Remove video', language) } position = 'left'>
                                        <div className = 'WorkBackstageVideos__itemRemoveWrap' onClick = { removeVideo }>
                                            <Icon name = 'cross-tiny' className = 'WorkBackstageVideos__itemRemove' />
                                        </div>
                                    </Tooltip>
                                </div>
                            ) : (
                                <div className = 'WorkBackstageVideos__itemInput'>
                                    <Input label = { translate('Link to the video (YouTube / Vimeo)', language) }
                                        value = { inputValue }
                                        error = { videoLinkError }
                                        onChangeHandle = { handleInputChange } />

                                    <Button content = {{ text: translate('Upload', language) }}
                                            disabled = { !inputValue }
                                            onClick = { handleButtonClick } />
                                </div>
                            ) }

                            <ImageWithCropper src = { secondVideo.customPreview ? getImageFullPath(secondVideo.customPreview) : getImageFullPath(secondVideo.preview) }
                                              size = 'md'
                                              modalOpened = { secondVideoModalOpened }
                                              setModalOpened = { setSecondVideoModalOpened }
                                              requirements = {{ minWidth: 533, minHeight: 300 }}
                                              settings = {{ minCropBoxWidth: 88, minCropBoxHeight: 50, aspectRatio: 16 / 9 }}
                                              bgImg = 'mountain'
                                              buttonText = { translate('Change preview', language) }
                                              disabled = { !secondVideo.preview }
                                              onImageLoadOnServer = { setCustomPreview.bind(null, 1) }
                                              onRemoveClick = { secondVideo.customPreview && removeCustomPreview } />
                        </div>
                    ) }
                </div>
            </div>
        </CabinetSection> 
    )
}));

export { WorkBackstageVideos };