// Хранилище c полезными функции
import { decorate, action } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class ToolsStore {
    // Загрузка фотографии 
    async uploadImage(data) {
        const response = await request(`${BASE_URL}/api/tools/image-upload`, { method: 'POST', body: JSON.stringify(data) });
        const responseData = await response.json();

        if (response.status === 200) {
            return responseData.file;
        } else if (response.status === 422) {
            return Promise.reject(responseData.error);
        }
    }

    // Загрузка документов 
    async uploadFile(fileName, file, callback) {
        const response = await request(`${BASE_URL}/api/tools/file-upload`, { method: 'POST', body: file, headers: {} });
        const data = await response.json();

        if (response.status === 200) {
            callback(fileName, data.file);
        }
    }

    // Получение превью из ссылки
    async getPreviewFromLink(link, callback) {
        const response = await request(`${BASE_URL}/api/tools/video-preview`, { method: 'POST', body: JSON.stringify({ link }) });
        const data = await response.json();

        if (response.status === 200) {
            callback(data.file);
        } else if (response.status === 422) {
            return Promise.reject(data);
        }
    }
}

decorate(ToolsStore, {
    uploadImage: action.bound,
    uploadFile: action.bound,
    getPreviewFromLink: action.bound
});

export default new ToolsStore();