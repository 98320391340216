import React, { useState } from 'react';

import { Modal } from 'components/containers';
import { Button, Input, FilterSelect, ChooseBox, Alert } from 'components/ui';

import { inject, observer } from 'mobx-react';
import { translate, isValidEmail } from 'helpers';
import { PHONE_CODES } from 'constants/constants';

import './_NewStudioModal.scss';

const NewStudioModal = inject('LanguageStore', 'UserStore')(observer(({ place, title, options, modalOpened, setModalOpened, onInvite, LanguageStore, UserStore }) => {
    const { language } = LanguageStore;
    const { inviteUser } = UserStore;

    const [studio, setStudio] = useState({ nameForEmail: '', surnameForEmail: '', name: '', email: '', phoneCode: '+7', emailLanguage: language.split('-')[0], phoneWithoutCode: '' });
    const [errors, setErrors] = useState({});

    const { nameForEmail, surnameForEmail, name, email, emailLanguage, phoneCode, phoneWithoutCode } = studio;

    const isEnoughData = name && isValidEmail(email);
    const isError = Object.keys(errors).find(name => errors[name]);

    const currentPhoneCode = PHONE_CODES.find(code => code.code === phoneCode);

    // Отлов ввода в поле ввода
    const handleInputCnange = event => {
        const input = event.target;
        const value = input.value.trim();
        const name = input.name;

        if (errors[name]) {
            setErrors(prevErrors => (
                { ...prevErrors, [name]: '' }
            ));
        }

        setStudio(prevStudio => ({ ...prevStudio, [name]: value }));
    };

    // Выбор кода страны(телефон)
    const choosePhoneCode = ({ item }) => {
        if (item.code !== phoneCode) {
            setStudio(prevStudio => ({ ...prevStudio, phoneCode: item.code }));
        }
    };

    // Смена телефона
    const changePhone = event => {
        const input = event.target;
        const value = input.value.replace(/\D/g, '');

        if (errors.phone) {
            setErrors(prevErrors => (
                { ...prevErrors, phone: '' }
            ));
        }

        setStudio(prevStudio => ({ ...prevStudio, phoneWithoutCode: value }));
    };

    // Выбор языка письма
    const chooseEmailLanguage = event => {
        const radio = event.target;
        const value = radio.value;

        setStudio(prevStudio => ({ ...prevStudio, emailLanguage: value }));
    };

    // Пригласить агента
    const inviteStudio = () => {
        inviteUser({
            nameForEmail: nameForEmail,
            surnameForEmail: surnameForEmail,
            name: name,
            email: email,
            emailLanguage: emailLanguage,
            phone: phoneWithoutCode ? `${phoneCode} ${phoneWithoutCode}` : '',
            cabinetType: 'studio',
            ...options
        }).then(data => onInvite(data))
          .then(() => setModalOpened(false))
          .catch(errors => setErrors(errors));
    };

    return (
        <Modal title = { translate(title || 'New studio', language) } opened = { modalOpened } setOpened = { setModalOpened }>
            <div className = 'NewStudioModal'>
                <Input label = { translate('Name', language) } name = 'nameForEmail' onInput = { handleInputCnange } />
                <Input label = { translate('Surname', language) } name = 'surnameForEmail' onInput = { handleInputCnange } />
                <Input label = { translate('Name', language) } name = 'name' required onInput = { handleInputCnange } />

                <Input label = { `Email ${translate('For invitation', language).toLowerCase()}` } 
                       name = 'email' 
                       required
                       error = { errors.email }
                       onInput = { handleInputCnange } />

                <div className = 'NewStudioModal__phone'>
                    <FilterSelect type = 'phone'
                                  current = { currentPhoneCode }
                                  items = { PHONE_CODES }
                                  onChoose = { choosePhoneCode } />

                    <Input label = { translate('Phone', language) } 
                           value = { phoneWithoutCode }
                           error = { errors.phone }
                           onChangeHandle = { changePhone } />
                </div>

                {/* { place === 'team' && (
                    <Alert text = { translate('As a gift, 1 month of free subscription for every 5 artists who subscribe through Your invitation', language) } />
                ) } */}
            </div>

            <div className = 'Modal__actions'>
                <div className = 'Modal__actionsSection Modal__actionsSection--left'>
                    <div className = 'NewStudioModal__emailLanguage'>
                        <span className = 'NewStudioModal__emailLanguageText'>
                            { translate('The text of the invitation', language) }
                        </span>

                        <ChooseBox type = 'radio'
                                   value = 'ru' 
                                   checked = { emailLanguage === 'ru' }
                                   size = 'lg-sm'
                                   text = { translate('In Russian', language).toLowerCase() }
                                   onChange = { chooseEmailLanguage } />
                        
                        <ChooseBox type = 'radio'  
                                   value = 'en' 
                                   checked = { emailLanguage === 'en' }
                                   size = 'lg-sm'
                                   text = { translate('In English', language).toLowerCase() }
                                   onChange = { chooseEmailLanguage } />
                    </div>
                </div>

                <div className = 'Modal__actionsSection Modal__actionsSection--right'>
                    <Button content = {{ text: translate('Add', language) }} 
                            disabled = { !isEnoughData || isError }
                            onClick = { inviteStudio } />
                </div>
            </div>
        </Modal>
    )
}));

export { NewStudioModal };