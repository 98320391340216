// Хранилище музыки

import { decorate, action } from 'mobx';

import { request } from 'helpers';
import { BASE_URL } from 'constants/BASE_URL';

class MusicStore {
    // Поиск группы
    async findMusicGroup(obj, callback) {
        const response = await request(`${BASE_URL}/api/music/find-music-group`, { method: 'POST', body: JSON.stringify(obj) });
        const data = await response.json();

        if (response.status === 200) {
            callback(data.result);
        }
    }

    async findMusicLabel(obj, callback) {
        const response = await request(`${BASE_URL}/api/music/find-music-label`, { method: 'POST', body: JSON.stringify(obj) });
        const data = await response.json();

        if (response.status === 200) {
            callback(data.result);
        }
    }
}

decorate(MusicStore, {
    findMusicGroup: action.bound
});

export default new MusicStore();