module.exports = {
    writeText(text) {
        return new Promise(resolve => {
            const input = document.createElement('input');

            input.style.position = 'fixed';
            input.style.top = '-100%';
            input.style.left = '-100%';

            input.value = text;
            
            document.body.appendChild(input);

            input.select();

            document.execCommand('copy');
            document.body.removeChild(input);

            resolve();
        });
    }
};